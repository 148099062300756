// src/App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {
  SignedIn,
  SignedOut,
  RedirectToSignIn,
} from '@clerk/clerk-react';

import AuroraPrepLanding from './pages/AuroraPrepLanding';
import NotesAurora from './pages/NotesAurora';
import PasswordManager from './pages/PasswordManager';
import Test from './pages/Test';
import AuroraPrepDashboard from './pages/AuroraPrepDashboard';
import LessonPage from './pages/LessonPage';
import CaseStudySelection from './pages/CaseStudySelection';
import ConsultingMathPractice from './pages/ConsultingMathPractice';
import Math from './pages/MathFundamentals';
import IntroductionPage from './course/IntroductionPage';
import ProfitabilityAnalysisPage from './course/ProfitabilityAnalysisPage';
import SWOTAnalysisPage from './course/SwotAnalysisPage';
import ThreeCsModelPage from './course/ThreeCsModelPage';
import FourPsMarketingPage from './course/FourPsMarketingPage';
import MarketSizingPage from './course/MarketSizingPage';
import PortersFiveForcesPage from './course/PortersFiveForcesPage';
import GrowthStrategyPage from './course/GrowthStrategyPage';
import RevenueCostSynergyPage from './course/RevenueCostSynergyPage';
import BreakEvenAnalysisPage from './course/BreakEvenAnalysisPage';
import BcgMatrixPage from './course/BcgMatrixPage';
import CaseStudyDetails from './pages/CaseStudyDetails';
import AiTutor from './pages/AiTutor';
import Dashboard from './pages/Dashboard';
import Resume from './Resume';
import ResumeReview from './pages/ResumeReview';

import MentalMathGuide from './mathCourse/MentalMathGuide';
import EstimationGuide from './mathCourse/EstimationGuide';
import ApproximationGuide from './mathCourse/ApproximationGuide';
import ConversionsGuide from './mathCourse/ConversionsGuide';


import Noted from './Noted/LandingPage';
import Notes from './Noted/Notes'
import TermsAndConditions from './pages/TermsAndConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';

const App = () => {
  useEffect(() => {
    // Google Tag Manager
    const gtagScript = document.createElement('script');
    gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-6YQYMBQQNY';
    gtagScript.async = true;
    document.head.appendChild(gtagScript);

    const gtagConfig = document.createElement('script');
    gtagConfig.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-6YQYMBQQNY');
    `;
    document.head.appendChild(gtagConfig);
  }, []);

  return (
    <Router>
      <Routes>
        {/* Optional: Public Landing page at "/" */}
        <Route path="/" element={<AuroraPrepLanding />} />
        <Route path="/terms" element={<TermsAndConditions />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />

        {/* For all other routes, require sign-in */}
        <Route
          path="*"
          element={
            <>
              <SignedIn>
                <Routes>
                  {/* Now list all your protected routes here */}
                  <Route path="/notes" element={<NotesAurora />} />
                  <Route path="/pm" element={<PasswordManager />} />
                  <Route path="/test" element={<Test />} />
                  <Route path="/main" element={<AuroraPrepDashboard />} />
                  <Route path="/course" element={<LessonPage />} />
                  <Route path="/cases" element={<CaseStudySelection />} />
                  <Route path="/mathConsulting" element={<ConsultingMathPractice />} />
                  <Route path="/math" element={<Math />} />
                  <Route path="/intro" element={<IntroductionPage />} />
                  <Route path="/profit" element={<ProfitabilityAnalysisPage />} />
                  <Route path="/swot" element={<SWOTAnalysisPage />} />
                  <Route path="/3cs" element={<ThreeCsModelPage />} />
                  <Route path="/4ps" element={<FourPsMarketingPage />} />
                  <Route path="/market" element={<MarketSizingPage />} />
                  <Route path="/porters" element={<PortersFiveForcesPage />} />
                  <Route path="/growth" element={<GrowthStrategyPage />} />
                  <Route path="/revcost" element={<RevenueCostSynergyPage />} />
                  <Route path="/break-even" element={<BreakEvenAnalysisPage />} />
                  <Route path="/bcg" element={<BcgMatrixPage />} />
                  <Route path="/case/:id" element={<CaseStudyDetails />} />
                  <Route path="/ai" element={<AiTutor />} />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/resume" element={<Resume />} />
                  <Route path="/resumeReview" element={<ResumeReview />} />
                  <Route path="/mentalMath" element={<MentalMathGuide />} />
                  <Route path="/estimation" element={<EstimationGuide />} />
                  <Route path="/approximation" element={<ApproximationGuide />} />
                  <Route path="/conversions" element={<ConversionsGuide />} />
                  <Route path="/noted" element={<Noted />} />
                 
                  

                  {/* No match inside protected routes → go home or 404 */}
                  <Route path="*" element={<AuroraPrepLanding />} />
                </Routes>
              </SignedIn>

              {/* If user is signed out, redirect them to Clerk sign-in */}
              <SignedOut>
                <RedirectToSignIn />
              </SignedOut>
            </>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
