// AuroraPrepDashboard.jsx

import React, { useState, useEffect } from 'react';
import { useAuth, useUser, ClerkLoaded } from '@clerk/clerk-react';
import { Link, useNavigate } from 'react-router-dom';
import {
  ChevronRight,
  BookOpen,
  Calculator,
  Users,
  Bot,
  ArrowRight,
  BookCopy,
  Star,
  FileText,
  Upload,
  CheckCircle,
  AlertTriangle,
  TrendingUp,
  Rocket,
  Clock,
  Award
} from 'lucide-react';

// For your existing Navbar
import Navbar from '../components/Navbar';

// For Markdown in the ResumeGrader
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

// For the spinning overlay
import ProcessingAnimation from '../components/ProcessingAnimation';

/* ------------------------------------------------------------------
   ResumeGrader Component
   ------------------------------------------------------------------ */
const ResumeGrader = () => {
  const [file, setFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');
  const [fileType, setFileType] = useState('');
  const [grade, setGrade] = useState('');
  const [numericGrade, setNumericGrade] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  // Clerk hooks
  const { isSignedIn } = useUser();
  const auth = useAuth();

  // Replace with your actual backend URL
  const url = process.env.REACT_APP_BACK_END_URL;

  const handleFileUpload = (event) => {
    const uploadedFile = event.target.files[0];
    if (!uploadedFile) return;

    setFile(uploadedFile);
    setFileType(uploadedFile.type);
    setError('');
    setPreviewUrl(URL.createObjectURL(uploadedFile));
  };

  const gradeResumeWithChatGPT = async () => {
    if (!file) {
      setError('No file selected.');
      return;
    }

    setLoading(true);
    setError('');
    setGrade('');
    setNumericGrade(null);

    try {
      if (!isSignedIn) {
        throw new Error('Please sign in before grading your résumé.');
      }

      const token = await auth.getToken();
      if (!token) {
        throw new Error('Unable to retrieve authentication token from Clerk.');
      }

      const formData = new FormData();
      formData.append('resumeFile', file);

      const response = await fetch(`${url}/api/grade`, {
        method: 'POST',
        body: formData,
        headers: { Authorization: `Bearer ${token}` },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch grade from the server');
      }

      const data = await response.json();
      console.log('Received grade data from server:', data);

      let feedback = data.review || '';
      feedback = feedback.trim();

      const receivedGrade = data.numericGrade || 0;

      // Optional: Clean up headings
      feedback = feedback.replace(/(?:^|\n)(#{1,3})\s+/g, '\n\n$1 ');
      feedback = feedback.replace(/\n\s*\n/g, '\n\n');

      setGrade(feedback);
      setNumericGrade(receivedGrade);
    } catch (err) {
      console.error('Error during grading:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="space-y-8 relative">
      {/* Loading overlay if needed */}
      <ProcessingAnimation
        isLoading={loading}
        onComplete={() => {
          // Optional callback after finishing
        }}
      />

      {/* ---- Upload Section ---- */}
      <div className="border-2 border-dashed border-indigo-200 rounded-2xl p-8 text-center bg-indigo-50 hover:bg-indigo-100 transition-colors duration-300">
        <input
          type="file"
          onChange={handleFileUpload}
          accept=".pdf,.doc,.docx"
          className="hidden"
          id="resume-upload"
        />
        <label
          htmlFor="resume-upload"
          className="cursor-pointer flex flex-col items-center gap-3"
        >
          <div className="h-16 w-16 bg-gradient-to-br from-indigo-500 to-purple-600 rounded-full flex items-center justify-center shadow-lg transform transition-transform hover:scale-105">
            <Upload className="h-7 w-7 text-white" />
          </div>
          <span className="text-gray-700 text-lg font-medium">Click to upload your resume</span>
          <span className="text-sm text-gray-600">Supports PDF documents</span>
        </label>
      </div>

      {/* ---- Error Message ---- */}
      {error && (
        <div className="bg-red-50 border border-red-100 rounded-xl p-4 text-red-700 shadow-sm">
          <div className="flex items-center">
            <AlertTriangle className="h-5 w-5 mr-2 text-red-500" />
            <p>{error}</p>
          </div>
        </div>
      )}

      {/* ---- Preview & Grade Button ---- */}
      {previewUrl && (
        <div className="space-y-4">
          <h3 className="text-lg font-semibold text-gray-900">Preview</h3>
          <div className="bg-white border border-gray-100 rounded-2xl h-96 overflow-hidden shadow-md">
            {fileType.includes('pdf') ? (
              <iframe
                src={previewUrl}
                className="w-full h-full"
                title="Resume Preview"
              />
            ) : (
              <div className="w-full h-full flex items-center justify-center">
                <p className="text-gray-500">
                  Preview not available for Word documents
                </p>
              </div>
            )}
          </div>

          <button
            onClick={gradeResumeWithChatGPT}
            disabled={!file || loading}
            className="w-full bg-gradient-to-r from-indigo-600 to-purple-600 text-white py-4 px-6 rounded-xl font-medium
                       hover:from-indigo-700 hover:to-purple-700 transition-all duration-300 shadow-lg
                       disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center gap-2"
          >
            <CheckCircle className="h-5 w-5" />
            {loading ? 'Analyzing Resume...' : 'Grade Resume'}
          </button>
        </div>
      )}

      {/* ---- Graded Feedback ---- */}
      {grade && (
        <div className="mt-8 space-y-6 bg-white rounded-2xl p-8 shadow-lg border border-gray-100">
          <div className="prose prose-lg max-w-none">
            <ReactMarkdown
              remarkPlugins={[remarkGfm]}
              components={{
                h1: ({ node, ...props }) => (
                  <h1 className="text-3xl font-bold text-indigo-900 mb-4" {...props} />
                ),
                h2: ({ node, ...props }) => (
                  <h2 className="text-2xl font-semibold text-indigo-800 mb-3" {...props} />
                ),
                h3: ({ node, ...props }) => (
                  <h3 className="text-xl font-medium text-indigo-700 mb-2" {...props} />
                ),
                p: ({ node, ...props }) => (
                  <p className="text-gray-700 mb-4 leading-relaxed" {...props} />
                ),
                ul: ({ node, ...props }) => (
                  <ul className="list-disc pl-6 mb-4 space-y-2 text-gray-700" {...props} />
                ),
                li: ({ node, ...props }) => <li className="mb-2" {...props} />
              }}
            >
              {grade}
            </ReactMarkdown>
          </div>
        </div>
      )}
    </div>
  );
};

/* ------------------------------------------------------------------
   AuroraPrepDashboard Component
   ------------------------------------------------------------------ */
const AuroraPrepDashboard = () => {
  // Clerk
  const { isSignedIn, getToken } = useAuth();
  const { isSignedIn: userIsSignedIn } = useUser(); 
  // (Either is fine; one is from useAuth, one from useUser.)

  const [isLoadingPaymentStatus, setIsLoadingPaymentStatus] = useState(true);
  const [isPaying, setIsPaying] = useState(false);

  // Used for the rest of the dashboard
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [dashboardData, setDashboardData] = useState(null);

  const url = process.env.REACT_APP_BACK_END_URL;
  const navigate = useNavigate();

  // 1) Check if user is a paying customer
  useEffect(() => {
    if (!isSignedIn) {
      // If not signed in, you could either block or let them see a fallback
      setIsLoadingPaymentStatus(false);
      return;
    }

    (async () => {
      try {
        setIsLoadingPaymentStatus(true);

        const token = await getToken();
        if (!token) {
          throw new Error('No Clerk token available. Please log in again.');
        }

        // Call /api/offerings to check payingCustomer
        const offeringsResp = await fetch(`${url}/api/offerings`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        if (!offeringsResp.ok) {
          // If 404, 500, etc. => redirect to Lemon Squeezy
          window.location.href =
            'https://auroraprep.lemonsqueezy.com/buy/11755dd1-377b-42ef-b1a8-e0d99615a807';
          return;
        }

        const offeringsData = await offeringsResp.json();
        console.log('Offerings check:', offeringsData);

        if (offeringsData.payingCustomer !== true) {
          // If not paying => redirect to Lemon Squeezy
          window.location.href =
            'https://auroraprep.lemonsqueezy.com/buy/11755dd1-377b-42ef-b1a8-e0d99615a807';
          return;
        }

        // If paying => allow them to see /main
        setIsPaying(true);
      } catch (err) {
        // If an error occurs => fallback to Lemon Squeezy
        console.error('Error checking paying customer status:', err);
        window.location.href =
          'https://auroraprep.lemonsqueezy.com/buy/11755dd1-377b-42ef-b1a8-e0d99615a807';
      } finally {
        setIsLoadingPaymentStatus(false);
      }
    })();
  }, [isSignedIn, url, getToken]);

  // 2) If user is paying, fetch "dashboard data"
  useEffect(() => {
    if (!isPaying || !isSignedIn) return;
    if (dashboardData) return; // already fetched

    const fetchDashboardData = async () => {
      try {
        setLoading(true);
        setError('');

        const token = await getToken();
        if (!token) {
          throw new Error('Clerk token not available.');
        }

        console.log('Fetching dashboard data from /api/dashboard/progress...');
        const resp = await fetch(`${url}/api/dashboard/progress`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        if (!resp.ok) {
          throw new Error('Failed to fetch dashboard data');
        }

        const data = await resp.json();
        console.log('Fetched dashboard data:', data);
        setDashboardData(data);
      } catch (err) {
        console.error('Error fetching dashboard data:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, [isPaying, isSignedIn, url, getToken, dashboardData]);

  // 3) Render states
  // a) If still checking payment status
  if (isLoadingPaymentStatus) {
    return (
      <>
        <Navbar />
        <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-indigo-50 to-purple-50">
          <div className="flex flex-col items-center p-12 bg-white rounded-2xl shadow-xl">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-indigo-500 mb-4"></div>
            <p className="text-indigo-800 font-medium">Verifying your subscription...</p>
          </div>
        </div>
      </>
    );
  }

  // b) If user not signed in
  if (!isSignedIn) {
    return (
      <>
        <Navbar />
        <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-indigo-50 to-purple-50">
          <div className="p-12 bg-white rounded-2xl shadow-xl text-center">
            <h2 className="text-2xl font-bold text-indigo-900 mb-4">Welcome to Aurora Prep</h2>
            <p className="text-gray-700 mb-6">Please sign in to access your personalized dashboard.</p>
            <button className="px-8 py-3 bg-gradient-to-r from-indigo-600 to-purple-600 text-white rounded-xl font-medium shadow-lg hover:shadow-xl transition-all duration-300">
              Sign In
            </button>
          </div>
        </div>
      </>
    );
  }

  // c) If user is signed in but not paying
  if (!isPaying) {
    // We likely already redirected, but as a fallback:
    return null;
  }

  // d) Now handle the loading/error for the dashboard data
  if (loading) {
    return (
      <>
        <Navbar />
        <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-indigo-50 to-purple-50">
          <div className="flex flex-col items-center p-12 bg-white rounded-2xl shadow-xl">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-indigo-500 mb-4"></div>
            <p className="text-indigo-800 font-medium">Loading your dashboard...</p>
          </div>
        </div>
      </>
    );
  }

  if (error) {
    return (
      <>
        <Navbar />
        <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-indigo-50 to-purple-50">
          <div className="bg-red-100 text-red-700 p-8 rounded-2xl shadow-lg max-w-md">
            <h2 className="font-bold text-xl mb-4">Error</h2>
            <p className="mb-4">{error}</p>
            <button 
              onClick={() => window.location.reload()} 
              className="px-6 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors duration-300"
            >
              Retry
            </button>
          </div>
        </div>
      </>
    );
  }

  if (!dashboardData) {
    return (
      <>
        <Navbar />
        <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-indigo-50 to-purple-50">
          <div className="p-12 bg-white rounded-2xl shadow-xl text-center">
            <h2 className="text-2xl font-bold text-indigo-900 mb-4">No Data Available</h2>
            <p className="text-gray-700 mb-6">We couldn't find your dashboard data. Please try again later.</p>
            <button 
              onClick={() => window.location.reload()} 
              className="px-8 py-3 bg-gradient-to-r from-indigo-600 to-purple-600 text-white rounded-xl font-medium shadow-lg hover:shadow-xl transition-all duration-300"
            >
              Refresh
            </button>
          </div>
        </div>
      </>
    );
  }

  // ------------------------------------------------------------------
  // 4) Compute Unified Progress so "Core Curriculum" matches Quick Stats
  // ------------------------------------------------------------------

  // Framework progress
  const userFrameworkSessions =
    dashboardData?.lessonsCompleted?.filter(Boolean)?.length ?? 0;
  const totalFrameworkSessions = dashboardData?.allLessons?.length ?? 0;
  const frameworksPercent = totalFrameworkSessions > 0
    ? Math.floor((userFrameworkSessions / totalFrameworkSessions) * 100)
    : 0;

  // Case Studies progress
  const userCaseStudiesDone =
    (dashboardData?.savedCases?.length ?? 0) +
    (dashboardData?.newCaseGrades?.length ?? 0);
  const totalCaseStudies = 35;
  const caseStudiesPercent = totalCaseStudies > 0
    ? Math.floor((userCaseStudiesDone / totalCaseStudies) * 100)
    : 0;

  // Math progress
  const userMathQuestionsDone = dashboardData?.totalQuestions ?? 0;
  const totalMathQuestions = 150;
  const mathQuestionsPercent = totalMathQuestions > 0
    ? Math.floor((userMathQuestionsDone / totalMathQuestions) * 100)
    : 0;

  // Overall progress
  const overallProgress = Math.floor(
    (frameworksPercent + mathQuestionsPercent + caseStudiesPercent) / 3
  );

  // ------------------------------------------------------------------
  // Build courses array with these computed percentages
  // ------------------------------------------------------------------
  const courses = [
    {
      title: 'Strategy Framework',
      description: 'Master essential consulting frameworks and problem-solving methodologies.',
      icon: BookOpen,
      step: 1,
      progress: frameworksPercent
    },
    {
      title: 'Math Practice',
      description: 'Build your quantitative skills with targeted exercises and real-time feedback.',
      icon: Calculator,
      step: 2,
      progress: mathQuestionsPercent
    },
    {
      title: 'Case Studies',
      description: 'Apply frameworks to real business scenarios with guided practice.',
      icon: BookCopy,
      step: 3,
      progress: caseStudiesPercent
    }
  ];

  // Confirm we have data
  console.log('Rendering AuroraPrepDashboard with data:', dashboardData);
  console.log('Computed progress => frameworks:', frameworksPercent, 'math:', mathQuestionsPercent, 'cases:', caseStudiesPercent);

  // ------------------------------------------------------------------
  // Rendering
  // ------------------------------------------------------------------
  return (
    <>
      <Navbar />

      <div className="min-h-screen bg-gradient-to-br from-indigo-50 via-purple-50 to-pink-50">
        <div className="max-w-6xl mx-auto p-8">
          {/* ------------------- Header ------------------- */}
          <div className="mb-12 relative">
            <div className="absolute -top-4 -left-4 w-24 h-24 bg-purple-200 rounded-full opacity-50 blur-xl"></div>
            <div className="absolute top-10 right-10 w-16 h-16 bg-indigo-200 rounded-full opacity-40 blur-xl"></div>
            
            <div className="relative">
              <div className="flex items-center space-x-2 mb-2">
                <div className="bg-gradient-to-r from-indigo-500 to-purple-500 p-2 rounded-lg shadow-md">
                  <Star className="w-5 h-5 text-white" />
                </div>
                <span className="text-sm font-medium bg-gradient-to-r from-indigo-600 to-purple-600 text-transparent bg-clip-text">
                  Your Personal Learning Path
                </span>
              </div>
              <h1 className="text-5xl font-bold bg-gradient-to-r from-indigo-600 via-purple-600 to-pink-600 text-transparent bg-clip-text">
                Welcome Back!
              </h1>
              <p className="text-xl text-gray-700 mt-3 max-w-2xl">
                Continue your journey to consulting excellence. You're making great progress!
              </p>
            </div>

            {/* Overall progress indicator */}
            <div className="mt-8 bg-white rounded-2xl p-6 shadow-lg border border-indigo-100">
              <div className="flex items-center justify-between mb-2">
                <h3 className="text-lg font-semibold text-gray-900">Overall Progress</h3>
                <span className="text-sm font-medium bg-indigo-100 text-indigo-800 px-3 py-1 rounded-full">
                  {overallProgress}% Complete
                </span>
              </div>
              <div className="h-3 w-full bg-gray-100 rounded-full overflow-hidden">
                <div 
                  className="h-full bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 rounded-full"
                  style={{ width: `${overallProgress}%` }}
                ></div>
              </div>
            </div>
          </div>

          {/* ------------------- Main Content ------------------- */}
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
            {/* ---- Left / Center Column for Course Progress ---- */}
            <div className="lg:col-span-2 space-y-8">
              <div className="flex items-center gap-4">
                <h2 className="text-2xl font-bold text-gray-900">Core Curriculum</h2>
                <div className="h-px flex-grow bg-gradient-to-r from-indigo-200 to-transparent"></div>
              </div>

              {courses.map((course, index) => {
                let route = '#';
                if (course.title === 'Strategy Framework') {
                  route = '/intro';
                } else if (course.title === 'Math Practice') {
                  route = '/math';
                } else if (course.title === 'Case Studies') {
                  route = '/cases';
                }

                // Label bubble logic
                let bubbleLabel = '';
                let bubbleClass = '';

                if (course.progress === 0) {
                  bubbleLabel = 'Start Here';
                  bubbleClass = 'bg-purple-100 text-purple-800 border border-purple-200';
                } else if (course.progress > 0 && course.progress < 100) {
                  bubbleLabel = 'In progress';
                  bubbleClass = 'bg-amber-100 text-amber-800 border border-amber-200';
                } else if (course.progress === 100) {
                  bubbleLabel = 'Completed';
                  bubbleClass = 'bg-emerald-100 text-emerald-800 border border-emerald-200';
                }

                const isActive = course.progress > 0 && course.progress < 100;

                return (
                  <div
                    key={index}
                    className={`relative p-8 rounded-2xl transition-all duration-300 group ${
                      isActive
                        ? 'bg-gradient-to-r from-indigo-600 via-purple-600 to-pink-600 text-white shadow-xl hover:shadow-2xl'
                        : 'bg-white hover:shadow-xl border border-gray-100'
                    }`}
                  >
                    {/* Decorative circles */}
                    <div className={`absolute -bottom-4 -right-4 w-20 h-20 rounded-full ${isActive ? 'bg-pink-500 opacity-20' : 'bg-indigo-100 opacity-30'} blur-xl`}></div>
                    
                    <div
                      className={`flex items-center justify-between ${
                        isActive ? 'mb-6' : 'mb-5'
                      }`}
                    >
                      <div className="flex items-center space-x-4">
                        <div
                          className={`p-3 rounded-xl transform transition-transform group-hover:scale-110 ${
                            isActive ? 'bg-white/20 shadow-inner' : 'bg-indigo-100'
                          }`}
                        >
                          <course.icon
                            className={`w-7 h-7 ${
                              isActive ? 'text-white' : 'text-indigo-600'
                            }`}
                          />
                        </div>
                        <div>
                          <div className="text-sm font-medium mb-1">
                            Step {course.step}
                          </div>
                          <h3
                            className={`text-xl font-bold ${
                              isActive ? 'text-white' : 'text-gray-900'
                            }`}
                          >
                            {course.title}
                          </h3>
                        </div>
                      </div>
                      {bubbleLabel && (
                        <span
                          className={`px-4 py-1.5 rounded-full text-sm font-medium shadow-sm whitespace-nowrap ${bubbleClass}`}
                        >
                          {bubbleLabel}
                        </span>
                      )}
                    </div>

                    <p
                      className={`mb-6 text-lg ${
                        isActive ? 'text-white/90' : 'text-gray-600'
                      }`}
                    >
                      {course.description}
                    </p>

                    {/* Progress bar */}
                    <div className="flex items-center justify-between">
                      <div className="flex-1 mr-4">
                        <div className={`h-3 rounded-full overflow-hidden ${isActive ? 'bg-black/20' : 'bg-gray-100'}`}>
                          <div
                            className={`h-full rounded-full ${
                              isActive
                                ? 'bg-white'
                                : 'bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500'
                            }`}
                            style={{ width: `${course.progress}%` }}
                          />
                        </div>
                        <div className={`mt-2 text-sm font-medium ${isActive ? 'text-white/80' : 'text-gray-500'}`}>
                          {course.progress}% Complete
                        </div>
                      </div>
                      <Link
                        to={route}
                        className={`flex items-center px-6 py-3 rounded-xl transition-all duration-300 font-medium ${
                          isActive
                            ? 'bg-white text-indigo-600 hover:bg-white/90 shadow-lg'
                            : 'bg-gradient-to-r from-indigo-500 to-purple-500 text-white hover:shadow-lg'
                        }`}
                      >
                        {course.progress === 0 ? 'Start' : 'Continue'}
                        <ChevronRight className="w-5 h-5 ml-1" />
                      </Link>
                    </div>
                  </div>
                );
              })}

              {/* ---- Resume Review Section ---- */}
              <div className="mt-12">
                <div className="bg-white rounded-2xl p-8 shadow-lg border border-gray-100">
                  <div className="flex items-center justify-between mb-8">
                    <div>
                      <h2 className="text-2xl font-bold text-gray-900">Resume Review</h2>
                      <p className="text-gray-600 text-lg mt-1">Get AI-powered feedback on your resume</p>
                    </div>
                    <div className="p-3 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-xl shadow-lg">
                      <FileText className="w-6 h-6 text-white" />
                    </div>
                  </div>

                  {/* ClerkLoaded ensures Clerk is fully ready before using it */}
                  <ClerkLoaded>
                    <ResumeGrader />
                  </ClerkLoaded>

                  <div className="mt-8 bg-indigo-50 rounded-xl p-6 border border-indigo-100">
                    <h4 className="font-semibold text-indigo-900 text-lg mb-3">Quick Tips</h4>
                    <ul className="text-indigo-700 space-y-2">
                      <li className="flex items-center">
                        <CheckCircle className="w-5 h-5 mr-2 text-indigo-600" />
                        Use strong action verbs to start bullet points
                      </li>
                      <li className="flex items-center">
                        <CheckCircle className="w-5 h-5 mr-2 text-indigo-600" />
                        Quantify achievements where possible
                      </li>
                      <li className="flex items-center">
                        <CheckCircle className="w-5 h-5 mr-2 text-indigo-600" />
                        Keep formatting consistent throughout
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* ---- Right Column: Resources and Sidebar ---- */}
            <div className="lg:col-span-1 space-y-8">
              <div className="flex items-center gap-4">
                <h2 className="text-2xl font-bold text-gray-900">Learning Resources</h2>
                <div className="h-px flex-grow bg-gradient-to-r from-indigo-200 to-transparent"></div>
              </div>

              {/* Example resource cards */}
              {[
                {
                  title: 'AI Tutor',
                  description: 'Get 24/7 help with concepts and practice problems',
                  icon: Bot,
                  route: '/ai',
                  bgClass: 'bg-gradient-to-br from-indigo-500 to-blue-600'
                },
                {
                  title: 'Math Quick Tips',
                  description: 'Prepare for case interviews with mental math shortcuts',
                  icon: Calculator,
                  route: '/mentalMath',
                  bgClass: 'bg-gradient-to-br from-purple-500 to-pink-600'
                }
              ].map((resource, index) => (
                <div
                  key={index}
                  className="p-6 bg-white rounded-2xl shadow-lg hover:shadow-xl border border-gray-100 transition-all duration-300 group"
                >
                  <div className="flex items-center space-x-4 mb-4">
                    <div className={`p-3 rounded-xl shadow-lg transform transition-transform group-hover:scale-110 ${resource.bgClass}`}>
                      <resource.icon className="w-6 h-6 text-white" />
                    </div>
                    <h3 className="text-xl font-bold text-gray-900">
                      {resource.title}
                    </h3>
                  </div>
                  <p className="text-gray-600 mb-4 text-lg">{resource.description}</p>
                  <Link
                    to={resource.route}
                    className="text-indigo-600 font-semibold flex items-center hover:text-indigo-800 transition-colors duration-300"
                  >
                    Access Now <ArrowRight className="w-5 h-5 ml-2 group-hover:ml-3 transition-all" />
                  </Link>
                </div>
              ))}

              {/* ---- Quick Stats Block ---- */}
              <div className="bg-gradient-to-br from-indigo-600 to-purple-600 p-8 rounded-2xl text-white shadow-lg relative overflow-hidden">
                {/* Decorative elements */}
                <div className="absolute top-0 right-0 w-32 h-32 bg-white opacity-10 rounded-full -translate-y-1/2 translate-x-1/2"></div>
                <div className="absolute bottom-0 left-0 w-24 h-24 bg-white opacity-10 rounded-full translate-y-1/2 -translate-x-1/2"></div>
                
                <div className="relative">
                  <div className="flex items-center gap-3 mb-4">
                    <TrendingUp className="w-6 h-6 text-white" />
                    <h3 className="text-xl font-bold">Your Progress Summary</h3>
                  </div>
                  
                  <div className="space-y-4">
                    <div>
                      <div className="flex items-center justify-between mb-2">
                        <span className="text-white/90">Framework Sessions</span>
                        <span className="font-bold">
                          {userFrameworkSessions}/{totalFrameworkSessions}
                        </span>
                      </div>
                      <div className="h-2 w-full bg-white/20 rounded-full overflow-hidden">
                        <div 
                          className="h-full bg-white rounded-full"
                          style={{ width: `${frameworksPercent}%` }}
                        ></div>
                      </div>
                    </div>
                    
                    <div>
                      <div className="flex items-center justify-between mb-2">
                        <span className="text-white/90">Case Studies Done</span>
                        <span className="font-bold">
                          {userCaseStudiesDone}/{totalCaseStudies}
                        </span>
                      </div>
                      <div className="h-2 w-full bg-white/20 rounded-full overflow-hidden">
                        <div 
                          className="h-full bg-white rounded-full"
                          style={{ width: `${caseStudiesPercent}%` }}
                        ></div>
                      </div>
                    </div>
                    
                    <div>
                      <div className="flex items-center justify-between mb-2">
                        <span className="text-white/90">Math Questions</span>
                        <span className="font-bold">
                          {userMathQuestionsDone}/{totalMathQuestions}
                        </span>
                      </div>
                      <div className="h-2 w-full bg-white/20 rounded-full overflow-hidden">
                        <div 
                          className="h-full bg-white rounded-full"
                          style={{ width: `${mathQuestionsPercent}%` }}
                        ></div>
                      </div>
                    </div>
                  </div>

                  <Link
                    to="/dashboard"
                    className="mt-8 inline-block w-full py-3.5 px-6 bg-white text-indigo-600 font-medium text-center rounded-xl transition-all duration-200 hover:bg-white/90 shadow-md hover:shadow-lg"
                  >
                    View Full Progress Report
                  </Link>
                </div>
              </div>

              {/* ---- Notes App Section ---- */}
              <div className="bg-white bg-opacity-90 backdrop-blur-lg rounded-2xl shadow-lg p-8 border border-gray-100">
                <div className="flex items-center gap-3 mb-4">
                  <div className="p-3 bg-amber-100 rounded-xl">
                    <FileText className="w-5 h-5 text-amber-600" />
                  </div>
                  <h3 className="text-xl font-bold text-gray-900">Access Notes App</h3>
                </div>
                
                <p className="text-gray-600 mb-6 text-lg">
                  With your subscription, you can access our dedicated notes app to store and review key insights.
                </p>
                
                <Link
                  to="/notes"
                  className="w-full inline-block py-4 px-6 bg-gradient-to-r from-amber-500 to-orange-500 text-white font-medium rounded-xl text-center transition-all duration-200 hover:shadow-lg"
                >
                  Go to Noted
                </Link>
              </div>
                           
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuroraPrepDashboard;