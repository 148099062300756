import React, { useState } from 'react';
import { CheckCircle, Menu as MenuIcon, LayoutDashboard } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import LessonNavigation from '../components/LessonNavigation';
import { useAuth } from '@clerk/clerk-react'; // <-- Clerk React import

const RevenueCostSynergyPage = () => {
  const [buttonState, setButtonState] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const navigate = useNavigate();

  // If you're using Create React App, define REACT_APP_BACK_END_URL in your .env
  // e.g. REACT_APP_BACK_END_URL=https://your-backend.com
  const url = process.env.REACT_APP_BACK_END_URL;

  // Clerk auth helpers (if your backend needs a token)
  const { userId, getToken } = useAuth();

  const activeLessonTitle = "Revenue / Cost Synergy";
  const prevLessonPath = "/growth"; // Adjust as needed
  const nextLessonPath = "/break-even"; // Adjust as needed

  // Handle the POST request to mark this lesson as completed
  const handleMarkCompleted = async () => {
    try {
      // Retrieve Clerk's session token if required by your backend
      const token = await getToken();

      const response = await fetch(`${url}/api/marked-completed`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // if your server requires a Bearer token
        },
        body: JSON.stringify({
          pageTitle: 'Revenue / Cost Synergy', // match your lesson’s title
          clerkUserId: userId,
        }),
      });

      if (!response.ok) {
        throw new Error(`Failed to mark completed: ${response.statusText}`);
      }

      // Mark button as completed in the UI
      setButtonState(true);
      console.log('Lesson successfully marked as completed!');
    } catch (error) {
      console.error('Error marking lesson completed:', error);
      // Optionally handle or display an error message here
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex">
      {/* Sidebar */}
      {isSidebarOpen && (
        <Sidebar 
          isSidebarOpen={isSidebarOpen} 
          setIsSidebarOpen={setIsSidebarOpen} 
          activeLessonTitle={activeLessonTitle}
        />
      )}

      <div className={`flex-1 transition-all duration-300 ${isSidebarOpen ? 'ml-80' : 'ml-0'}`}>
        {/* Top Navigation */}
        <div className="bg-white border-b sticky top-0 z-40">
          <div className="h-16 flex items-center px-6">
            {!isSidebarOpen && (
              <button
                onClick={() => setIsSidebarOpen(true)}
                className="text-gray-500 hover:text-gray-700 mr-4"
              >
                <MenuIcon className="w-6 h-6" />
              </button>
            )}

            <button
              onClick={() => navigate('/main')}
              className="flex items-center space-x-2 px-4 py-2 text-gray-600 hover:text-gray-900 hover:bg-gray-50 rounded-lg transition-colors duration-200"
            >
              <LayoutDashboard className="w-4 h-4" />
              <span>Back to Dashboard</span>
            </button>

            <div className="flex-1"></div>

            <LessonNavigation
              prevLessonPath={prevLessonPath}
              nextLessonPath={nextLessonPath}
            />
          </div>
        </div>

        {/* Page Content */}
        <div className="max-w-4xl mx-auto px-8 py-12">
          {buttonState ? (
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              Revenue & Cost Synergy
              <CheckCircle className="w-6 h-6 text-green-500 inline-block ml-2" />
            </h2>
          ) : (
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              Revenue & Cost Synergy
            </h2>
          )}

          <div className="rounded-xl overflow-hidden shadow-lg mb-6">
            <div className="aspect-w-16 aspect-h-9">
              <video width="100%" height="auto" controls className="object-cover">
                <source src="/images/synergies.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>

          <div className="p-6 rounded-xl mb-12 bg-[#F0F4FA]">
            <h3 className="text-2xl font-bold mb-4">Introduction:</h3>
            <p className="text-gray-700 mb-4">
              In management consulting interviews, synergies cases evaluate your ability to identify 
              and quantify the benefits of a merger or acquisition. By understanding revenue and cost 
              synergies, you'll impress interviewers and showcase your strategic thinking.
            </p>
          </div>

          <div className="mb-12">
            <h3 className="text-2xl font-bold mb-4">Understanding Synergies:</h3>
            <p className="text-gray-700 mb-4">
              Synergies occur when two companies combine and create additional value beyond what they 
              could achieve separately. This value can manifest as increased efficiency, revenue growth, 
              or cost savings.
            </p>
          </div>

          <div className="mb-12">
            <h3 className="text-2xl font-bold mb-4">Types of Synergies:</h3>
            <ul className="list-disc list-inside mb-4 text-gray-700 pl-4">
              <li>
                <strong>Revenue Synergies:</strong> Achieved by cross-selling products, entering new markets, 
                enhancing pricing power, or combining complementary offerings.
              </li>
              <li>
                <strong>Cost Synergies:</strong> Realized by consolidating functions (HR, IT), streamlining the 
                supply chain, reducing facilities, and eliminating duplicate efforts.
              </li>
            </ul>
          </div>

          <div className="mb-12">
            <h3 className="text-2xl font-bold mb-4">Quantifying Synergies:</h3>
            <p className="text-gray-700 mb-4">
              To impress an interviewer, identify and estimate the financial impact of each synergy, 
              determine the time frame for realization, and assess associated risks. This structured 
              approach shows your analytical rigor.
            </p>
          </div>

          <div className="p-6 rounded-xl mb-12 bg-[#F0F4FA]">
            <h3 className="text-2xl font-bold mb-4">Example Case:</h3>
            <p className="text-gray-700 mb-4">
              Consider a scenario where your client, a leading consumer goods company, wants to acquire a 
              smaller competitor with $500 million in revenue and a 10% operating margin. You must assess 
              the value of potential synergies.
            </p>
          </div>

          <div className="mb-12">
            <h3 className="text-2xl font-bold mb-4">Step 1: Identify Potential Revenue Synergies</h3>
            <p className="text-gray-700 mb-4">
              Consider cross-selling opportunities, expanding distribution channels, and improving pricing 
              power. For example:
            </p>
            <strong>Cross-selling & Expanded Distribution:</strong> A 5% revenue increase on $500 million adds 
            $25 million. Enhanced pricing power by 1% adds $5 million, totaling $30 million in revenue synergies.
          </div>

          <div className="mb-12">
            <h3 className="text-2xl font-bold mb-4">Step 2: Identify Potential Cost Synergies</h3>
            <p className="text-gray-700 mb-4">
              Consider manufacturing consolidation, procurement efficiencies, and eliminating redundant 
              corporate functions. For example:
            </p>
            <strong>Cost Synergies:</strong>
            <ul className="list-disc list-inside mb-4 text-gray-700 pl-4">
              <li>Manufacturing consolidation: 5% cost reduction → $15 million savings</li>
              <li>Procurement and supply chain efficiencies: 5% reduction → $10 million savings</li>
              <li>Corporate overhead reduction: 20% cut → $25 million savings</li>
            </ul>
            Total cost synergies = $50 million.
          </div>

          <div className="mb-12">
            <h3 className="text-2xl font-bold mb-4">Step 3: Quantify the Synergies</h3>
            <p className="text-gray-700 mb-4">
              Total annual synergies: $30 million (revenue) + $50 million (cost) = $80 million.
              With an EBITDA multiple of 8x, this could add $640 million in value.
            </p>
          </div>

          <div className="mb-12">
            <h3 className="text-2xl font-bold mb-4">Step 4: Assess Time Frame and Risks</h3>
            <p className="text-gray-700 mb-4">
              Revenue synergies may take 1-2 years, cost synergies may also take 1-2 years to fully realize. 
              Consider cultural challenges, customer attrition, regulatory hurdles, and execution risks.
            </p>
          </div>

          <div className="p-6 rounded-xl mb-12 bg-[#F0F4FA]">
            <h3 className="text-2xl font-bold mb-4">Step 5: Explain the Valuation Impact</h3>
            <p className="text-gray-700 mb-4">
              With $80 million in annual synergies and an 8x EBITDA multiple, you add $640 million in value. 
              This is a rough estimate and should be refined with due diligence. Weigh these synergies 
              against the acquisition cost and integration expenses to ensure a positive ROI.
            </p>
          </div>

          <div className="mb-12">
            <h2 className="text-2xl font-bold mb-4">Conclusion</h2>
            <p className="text-gray-700 mb-4">
              Demonstrating your ability to identify and quantify synergies is crucial in M&A cases. By 
              understanding revenue and cost synergies, following a structured approach, and clearly 
              communicating your analysis, you showcase your strategic thinking and problem-solving skills.
            </p>
          </div>

          <div className="text-center mt-12">
            {buttonState ? (
              <button
                type="button"
                className="px-8 py-3 bg-green-500 text-white rounded-xl shadow-lg flex items-center justify-center space-x-2 mx-auto hover:bg-green-600 transition-colors duration-200"
              >
                <CheckCircle className="w-5 h-5" />
                <span>Completed Session</span>
              </button>
            ) : (
              <button
                type="button"
                onClick={handleMarkCompleted}
                className="px-8 py-3 bg-gradient-to-r from-indigo-500 to-purple-500 text-white rounded-xl shadow-lg flex items-center justify-center space-x-2 mx-auto hover:shadow-xl transition-all duration-200"
              >
                <span>Mark Lesson Completed</span>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RevenueCostSynergyPage;
