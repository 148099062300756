import React, { useState } from 'react';
import { CheckCircle, Menu as MenuIcon, LayoutDashboard } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import LessonNavigation from '../components/LessonNavigation';
import { useAuth } from '@clerk/clerk-react'; // <-- Clerk React import

const SwotAnalysisPage = () => {
  const [buttonState, setButtonState] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const navigate = useNavigate();

  // If you're using Create React App, put REACT_APP_BACK_END_URL in your .env
  // e.g. REACT_APP_BACK_END_URL=https://your-backend.com
  const url = process.env.REACT_APP_BACK_END_URL;

  // Grab the Clerk auth helpers (if needed for the server's auth)
  const { userId, getToken } = useAuth();

  // The active lesson for your Sidebar
  const activeLessonTitle = 'SWOT Analysis';
  const prevLessonPath = '/profit';
  const nextLessonPath = '/3cs';

  // Handle the POST request to mark the lesson as completed
  const handleMarkCompleted = async () => {
    try {
      // Retrieve Clerk's session token if your server expects it
      const token = await getToken();

      // Post to your backend
      const response = await fetch(`${url}/api/marked-completed`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // If required by your server
        },
        body: JSON.stringify({
          pageTitle: 'SWOT Analysis', // Must match your lesson title in the DB
          clerkUserId: userId,       // Only if your server needs the user ID
        }),
      });

      if (!response.ok) {
        throw new Error(`Failed to mark completed: ${response.statusText}`);
      }

      // If the server responds with success, switch buttonState to "completed"
      setButtonState(true);
      console.log('Lesson successfully marked as completed!');
    } catch (error) {
      console.error('Error marking lesson completed:', error);
      // Handle or display an error message if needed
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex">
      {/* Sidebar */}
      {isSidebarOpen && (
        <Sidebar
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
          activeLessonTitle={activeLessonTitle}
        />
      )}

      {/* Main Content Area */}
      <div className={`flex-1 transition-all duration-300 ${isSidebarOpen ? 'ml-80' : 'ml-0'}`}>
        {/* Top Navigation Bar */}
        <div className="bg-white border-b sticky top-0 z-40">
          <div className="h-16 flex items-center px-6">
            {/* Toggle Sidebar button (visible only when sidebar is closed) */}
            {!isSidebarOpen && (
              <button
                onClick={() => setIsSidebarOpen(true)}
                className="text-gray-500 hover:text-gray-700 mr-4"
              >
                <MenuIcon className="w-6 h-6" />
              </button>
            )}

            {/* Dashboard button (far left) */}
            <button
              onClick={() => navigate('/main')}
              className="flex items-center space-x-2 px-4 py-2 text-gray-600 hover:text-gray-900 hover:bg-gray-50 rounded-lg transition-colors duration-200"
            >
              <LayoutDashboard className="w-4 h-4" />
              <span>Back to Dashboard</span>
            </button>

            {/* Spacer to push Prev/Next to the right */}
            <div className="flex-1"></div>

            {/* Previous and Next Lesson buttons on the far right */}
            <LessonNavigation
              prevLessonPath={prevLessonPath}
              nextLessonPath={nextLessonPath}
            />
          </div>
        </div>

        {/* Page Content */}
        <div className="max-w-4xl mx-auto px-8 py-12">
          {/* Heading and Video Section */}
          {buttonState ? (
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              Mastering the SWOT Framework
              <CheckCircle className="w-6 h-6 text-green-500 inline-block ml-2" />
            </h2>
          ) : (
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              Mastering the SWOT Framework
            </h2>
          )}

          <div className="rounded-xl overflow-hidden shadow-lg mb-6">
            <div className="aspect-w-16 aspect-h-9">
              <video width="100%" height="auto" controls className="object-cover">
                <source src="/images/SWOT.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>

          {/* Initial Container (F0F4FA Background) */}
          <div className="p-6 rounded-xl mb-12" style={{ backgroundColor: '#F0F4FA' }}>
            <h3 className="text-2xl font-bold mb-4">
              Mastering the SWOT Framework: Strengths, Weaknesses, Opportunities, and Threats
            </h3>
            <p className="text-gray-700 mb-4">
              As an aspiring consultant, you've likely heard of the SWOT framework – a powerful tool 
              used by businesses and consultants to analyze a company's strategic position. In this 
              article, we'll dive deep into the SWOT framework, exploring its history, components, 
              and application. We'll also provide a detailed case study to demonstrate how you can 
              effectively use SWOT in your case interviews and future consulting career.
            </p>
            <br />
            <h3 className="text-xl font-bold mb-4">The History of SWOT</h3>
            <p className="text-gray-700 mb-4">
              The SWOT framework, which stands for Strengths, Weaknesses, Opportunities, and Threats, 
              has its roots in the 1960s. It was developed by Albert S. Humphrey, a management consultant 
              working at the Stanford Research Institute. Humphrey and his team were tasked with creating 
              a new system for managing change and planning for the future.
            </p>
            <p className="text-gray-700 mb-4">
              Through their research, which involved extensive interviews with Fortune 500 companies, 
              they identified four key factors that contributed to organizational success: what is good 
              in the present (Strengths), what is bad in the present (Weaknesses), what is good in the 
              future (Opportunities), and what is bad in the future (Threats). This laid the foundation 
              for the SWOT framework as we know it today.
            </p>
            <br />
            <h3 className="text-xl font-bold mb-4">Understanding SWOT</h3>
            <p className="text-gray-700 mb-4">
              The SWOT framework is a strategic planning tool that helps businesses identify and analyze 
              the internal and external factors that impact their performance. Let's break down each component:
            </p>
            <ol className="list-decimal list-inside mb-4 text-gray-700">
              <li>
                <strong>Strengths:</strong> Internal attributes and resources that give a company an 
                advantage over its competitors. Examples: strong brand recognition, proprietary technology, 
                talented employees, or financial resources.
              </li>
              <li>
                <strong>Weaknesses:</strong> Internal factors that put a company at a disadvantage. Examples: 
                high employee turnover, outdated technology, limited distribution channels, low brand loyalty, 
                or weak financial performance.
              </li>
              <li>
                <strong>Opportunities:</strong> External factors a company can leverage. These may arise from 
                market growth, changing consumer preferences, new technologies, or regulatory changes. Often, 
                opportunities can be found in competitors' weaknesses or in a firm's own competitive advantage.
              </li>
              <li>
                <strong>Threats:</strong> External factors that could negatively impact performance. Examples: 
                increasing competition, economic downturns, supply chain disruptions, or shifts in consumer behavior. 
                Identifying these threats allows a company to prepare strategies to mitigate these risks.
              </li>
            </ol>
            <p className="text-gray-700 mb-4">
              To apply the SWOT framework, businesses typically create a 2x2 matrix, with Strengths and Weaknesses 
              on one axis and Opportunities and Threats on the other. This visual representation helps companies 
              identify strategic initiatives that capitalize on strengths and opportunities while mitigating 
              weaknesses and threats.
            </p>
            <br />
            <div className="image-container" style={{ width: '100%', maxWidth: '1000px', margin: '0 auto' }}>
              <img 
                src="/images/swot.png" 
                alt="SWOT Matrix" 
                style={{ width: '100%', height: 'auto' }}
              />
            </div>
          </div>

          {/* Case Study Section */}
          <div className="container mb-12">
            <h4 className="text-xl font-bold mb-4">Case Study: Applying SWOT in the Fast-Food Industry</h4>
            <p className="text-gray-700 mb-4">
              Let's consider a case study example. <strong>BurgerTown</strong>, a fast-food chain, is looking 
              to expand its market share in the face of increasing competition.
            </p>
            <h4 className="text-xl font-bold mb-4">Strengths</h4>
            <ul className="list-disc list-inside mb-4 text-gray-700 pl-4">
              <li>Strong brand recognition and customer loyalty</li>
              <li>Efficient supply chain and distribution network</li>
              <li>Consistent product quality across locations</li>
            </ul>
            <h4 className="text-xl font-bold mb-4">Weaknesses</h4>
            <ul className="list-disc list-inside mb-4 text-gray-700 pl-4">
              <li>Limited menu variety compared to competitors</li>
              <li>Higher prices than some rivals</li>
              <li>Aging store designs and outdated technology</li>
            </ul>
            <h4 className="text-xl font-bold mb-4">Opportunities</h4>
            <ul className="list-disc list-inside mb-4 text-gray-700 pl-4">
              <li>Growing demand for healthier fast-food options</li>
              <li>Expansion into international markets</li>
              <li>Partnerships with food delivery services</li>
            </ul>
            <h4 className="text-xl font-bold mb-4">Threats</h4>
            <ul className="list-disc list-inside mb-4 text-gray-700 pl-4">
              <li>Intense competition from established players and new entrants</li>
              <li>Changing consumer preferences towards healthier options</li>
              <li>Rising labor and ingredient costs</li>
            </ul>
            <p className="text-gray-700 mb-4">
              Based on this SWOT analysis, BurgerTown could consider the following strategies:
            </p>
            <ol className="list-decimal list-inside mb-4 text-gray-700 pl-4">
              <li>Introduce healthier menu items to capture the growing demand for nutritious fast-food options.</li>
              <li>Invest in store renovations and technology upgrades to enhance the customer experience.</li>
              <li>Explore international expansion opportunities in high-growth, low-competition markets.</li>
              <li>Develop strategic partnerships with food delivery services to expand convenience.</li>
              <li>Implement cost-saving measures and negotiate better supplier terms to mitigate rising costs.</li>
            </ol>
            <p className="text-gray-700">
              By leveraging its strengths, addressing its weaknesses, seizing opportunities, and mitigating threats, 
              BurgerTown can improve its competitive position and drive long-term growth.
            </p>
          </div>

          {/* Conclusion Section */}
          <div className="p-6 rounded-xl mb-12" style={{ backgroundColor: '#F0F4FA' }}>
            <h3 className="text-xl font-bold mb-4">Conclusion</h3>
            <p className="text-gray-700 mb-4">
              The SWOT framework is a powerful tool for analyzing a company's strategic position and developing 
              effective strategies. By understanding its history and components, and practicing its application 
              through case studies like the BurgerTown example, you can effectively incorporate SWOT into your 
              case interview responses.
            </p>
            <p className="text-gray-700">
              Demonstrating your ability to identify and analyze a company's strengths, weaknesses, opportunities, 
              and threats will showcase your strategic thinking skills and impress your interviewers. As you embark 
              on your consulting career, keep the SWOT framework in your toolkit, and you'll be well-equipped to 
              tackle any business challenge that comes your way.
            </p>
          </div>

          {/* Completion Button */}
          <div className="text-center mt-12">
            {buttonState ? (
              <button
                type="button"
                className="px-8 py-3 bg-green-500 text-white rounded-xl shadow-lg flex items-center justify-center space-x-2 mx-auto hover:bg-green-600 transition-colors duration-200"
              >
                <CheckCircle className="w-5 h-5" />
                <span>Completed Session</span>
              </button>
            ) : (
              <button
                type="button"
                onClick={handleMarkCompleted}
                className="px-8 py-3 bg-gradient-to-r from-indigo-500 to-purple-500 text-white rounded-xl shadow-lg flex items-center justify-center space-x-2 mx-auto hover:shadow-xl transition-all duration-200"
              >
                <span>Mark Lesson Completed</span>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SwotAnalysisPage;
