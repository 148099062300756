import React, { useState, useEffect } from 'react';
import { Timer, Calculator, Check, X, RotateCcw, Play, Pause, Settings } from 'lucide-react';

const ConsultingMathPractice = () => {
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(300); // 5 minutes default
  const [timerDuration, setTimerDuration] = useState(5); // Store duration in minutes
  const [score, setScore] = useState(0);
  const [attempts, setAttempts] = useState(0);
  const [currentAnswer, setCurrentAnswer] = useState('');
  const [currentProblem, setCurrentProblem] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('market-sizing');
  const [showSettings, setShowSettings] = useState(false); // Added missing state
  const [feedback, setFeedback] = useState('');

  const categories = {
    'market-sizing': {
      name: 'Market Sizing',
      description: 'Practice estimating market sizes and population-based calculations'
    },
    'growth-rates': {
      name: 'Growth Rates',
      description: 'Calculate compound growth rates and year-over-year changes'
    },
    'percentages': {
      name: 'Percentages',
      description: 'Work with percentage changes, margins, and ratios'
    },
    'breakeven': {
      name: 'Break-Even Analysis',
      description: 'Calculate break-even points and contribution margins'
    }
  };

  const generateProblem = () => {
    let problem = { question: '', answer: 0, explanation: '' };
    
    switch (selectedCategory) {
      case 'market-sizing':
        const population = Math.floor(Math.random() * 300) + 100;
        const percentage = Math.floor(Math.random() * 40) + 10;
        problem = {
          question: `In a city of ${population}M people, if ${percentage}% use a specific service monthly, how many million users does this represent?`,
          answer: (population * percentage / 100).toFixed(1),
          explanation: `${population}M × ${percentage}% = ${(population * percentage / 100).toFixed(1)}M users`
        };
        break;
      
      case 'growth-rates':
        const initialValue = Math.floor(Math.random() * 100) + 50;
        const growthRate = Math.floor(Math.random() * 30) + 10;
        const finalValue = initialValue * (1 + growthRate/100);
        problem = {
          question: `If a business starts with $${initialValue}M revenue and grows by ${growthRate}%, what's the new revenue in millions?`,
          answer: finalValue.toFixed(1),
          explanation: `$${initialValue}M × (1 + ${growthRate}%) = $${finalValue.toFixed(1)}M`
        };
        break;

      case 'percentages':
        const cost = Math.floor(Math.random() * 80) + 20;
        const price = cost * (1 + (Math.floor(Math.random() * 50) + 30)/100);
        problem = {
          question: `If a product costs $${cost} to make and sells for $${price.toFixed(0)}, what's the profit margin percentage?`,
          answer: ((price - cost) / price * 100).toFixed(1),
          explanation: `Margin = (${price.toFixed(0)} - ${cost}) ÷ ${price.toFixed(0)} × 100 = ${((price - cost) / price * 100).toFixed(1)}%`
        };
        break;

      case 'breakeven':
        const fixedCosts = Math.floor(Math.random() * 100000) + 50000;
        const variableCost = Math.floor(Math.random() * 50) + 20;
        const sellingPrice = variableCost * 2;
        problem = {
          question: `With fixed costs of $${fixedCosts.toLocaleString()}, variable cost per unit of $${variableCost}, and selling price of $${sellingPrice}, how many units to break even?`,
          answer: Math.ceil(fixedCosts / (sellingPrice - variableCost)),
          explanation: `Break-even = ${fixedCosts.toLocaleString()} ÷ (${sellingPrice} - ${variableCost}) = ${Math.ceil(fixedCosts / (sellingPrice - variableCost))} units`
        };
        break;
    }
    
    setCurrentProblem(problem);
    setCurrentAnswer('');
    setFeedback('');
  };

  // Update timer duration handler
  const handleTimerDurationChange = (e) => {
    const minutes = Math.max(1, Math.min(60, parseInt(e.target.value) || 1));
    setTimerDuration(minutes);
    setTimeRemaining(minutes * 60);
  };

  useEffect(() => {
    if (isTimerActive && timeRemaining > 0) {
      const timer = setInterval(() => {
        setTimeRemaining(prev => prev - 1);
      }, 1000);
      return () => clearInterval(timer);
    } else if (timeRemaining === 0) {
      setIsTimerActive(false);
    }
  }, [isTimerActive, timeRemaining]);

  useEffect(() => {
    generateProblem();
  }, [selectedCategory]);

  const handleSubmit = () => {
    const userAnswer = parseFloat(currentAnswer);
    const correctAnswer = parseFloat(currentProblem.answer);
    
    setAttempts(prev => prev + 1);
    if (Math.abs(userAnswer - correctAnswer) < 0.1) {
      setScore(prev => prev + 1);
      setFeedback('Correct! ' + currentProblem.explanation);
    } else {
      setFeedback(`Incorrect. The answer is ${correctAnswer}. ${currentProblem.explanation}`);
    }
  };

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs.toString().padStart(2, '0')}`;
  };

  return (
    <div className="min-h-screen bg-gray-50 p-8">
      <div className="max-w-4xl mx-auto">
        {/* Header */}
        <div className="mb-8">
          <h1 className="text-3xl font-bold bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-transparent bg-clip-text">
            Consulting Math Practice
          </h1>
          <p className="mt-2 text-gray-600">Master the mental math skills needed for consulting interviews</p>
        </div>

        {/* Timer and Score Section */}
        <div className="bg-white rounded-xl shadow-sm p-6 mb-6">
          <div className="flex justify-between items-center">
            <div className="flex items-center space-x-4">
              <div className="flex items-center space-x-2">
                <Timer className="w-5 h-5 text-gray-400" />
                <span className="text-lg font-semibold">{formatTime(timeRemaining)}</span>
              </div>
              <button
                onClick={() => setIsTimerActive(!isTimerActive)}
                className="px-4 py-2 bg-gradient-to-r from-indigo-500 to-purple-500 text-white rounded-lg hover:shadow-lg transition-all duration-200"
              >
                {isTimerActive ? <Pause className="w-4 h-4" /> : <Play className="w-4 h-4" />}
              </button>
              <button
                onClick={() => setShowSettings(!showSettings)}
                className="text-gray-500 hover:text-gray-700"
              >
                <Settings className="w-5 h-5" />
              </button>
            </div>
            <div className="flex items-center space-x-4">
              <span className="text-gray-600">Score: {score}/{attempts}</span>
              <button
                onClick={() => {
                  setScore(0);
                  setAttempts(0);
                  setTimeRemaining(timerDuration * 60);
                  setIsTimerActive(false);
                  generateProblem();
                }}
                className="text-gray-500 hover:text-gray-700"
              >
                <RotateCcw className="w-5 h-5" />
              </button>
            </div>
          </div>

          {showSettings && (
            <div className="mt-4 p-4 bg-gray-50 rounded-lg">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Timer Duration (minutes):
              </label>
              <input
                type="number"
                min="1"
                max="60"
                value={timerDuration}
                onChange={handleTimerDurationChange}
                className="w-20 px-2 py-1 border rounded"
              />
            </div>
          )}
        </div>

        {/* Category Selection */}
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-6">
          {Object.entries(categories).map(([key, category]) => (
            <button
              key={key}
              onClick={() => setSelectedCategory(key)}
              className={`p-4 rounded-xl text-left transition-all duration-200 ${
                selectedCategory === key
                  ? 'bg-gradient-to-r from-indigo-500 to-purple-500 text-white shadow-lg'
                  : 'bg-white hover:shadow-md'
              }`}
            >
              <h3 className="font-semibold">{category.name}</h3>
              <p className={`text-sm mt-1 ${selectedCategory === key ? 'text-white/80' : 'text-gray-500'}`}>
                {category.description}
              </p>
            </button>
          ))}
        </div>

        {/* Problem Section */}
        <div className="bg-white rounded-xl shadow-sm p-6">
          <div className="mb-6">
            <h2 className="text-xl font-semibold text-gray-900 mb-2">Problem</h2>
            <p className="text-gray-700">{currentProblem?.question}</p>
          </div>

          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Your Answer
            </label>
            <div className="flex space-x-4">
              <input
                type="number"
                step="0.1"
                value={currentAnswer}
                onChange={(e) => setCurrentAnswer(e.target.value)}
                className="flex-1 px-4 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                placeholder="Enter your answer..."
              />
              <button
                onClick={handleSubmit}
                className="px-6 py-2 bg-gradient-to-r from-indigo-500 to-purple-500 text-white rounded-lg hover:shadow-lg transition-all duration-200"
              >
                Submit
              </button>
            </div>
          </div>

          {feedback && (
            <div className={`p-4 rounded-lg ${
              feedback.startsWith('Correct') ? 'bg-green-50 text-green-700' : 'bg-red-50 text-red-700'
            }`}>
              {feedback}
            </div>
          )}

          <button
            onClick={generateProblem}
            className="mt-4 px-4 py-2 text-gray-600 hover:text-gray-900 flex items-center space-x-2"
          >
            <RotateCcw className="w-4 h-4" />
            <span>New Problem</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConsultingMathPractice;