import { useUser } from "@clerk/clerk-react";
import { useAuth } from "@clerk/clerk-react";
import React, { useState, useEffect, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  FileUp,
  Search,
  Plus,
  ChevronLeft,
  ChevronRight,
  Edit,
  Trash2,
  Maximize,
  X,
  Paperclip,
  Save,
  AlertTriangle,
} from "lucide-react";
import ChatWidget from "./ChatWidget";
import WelcomeMessage from "./WelcomeMessage";
import Navbar from "../components/Navbar";

const url = process.env.REACT_APP_BACK_END_URL;

const getFileIcon = (filename) => {
  const ext = filename.split(".").pop().toLowerCase();
  switch (ext) {
    case "pdf":
      return "📄";
    case "doc":
    case "docx":
      return "📝";
    case "xls":
    case "xlsx":
      return "📊";
    default:
      return "📎";
  }
};

// Custom Card Components
const Card = ({ children, className = "", expandable = false }) => (
  <div
    className={`bg-white rounded-xl shadow-sm hover:shadow-md transition-all duration-300 ${
      expandable ? "transform hover:scale-[1.01]" : ""
    } ${className}`}
  >
    {children}
  </div>
);

const CardContent = ({ children, className = "" }) => (
  <div className={`p-6 ${className}`}>{children}</div>
);

const IconButton = ({ icon, onClick, className = "", label = "", ...props }) => (
  <button
    onClick={onClick}
    className={`p-2 rounded-full hover:bg-gray-100 transition-all flex items-center justify-center ${className}`}
    {...props}
    title={label}
  >
    {icon}
  </button>
);

// Button component with variants
const Button = ({
  children,
  onClick,
  variant = "primary",
  size = "md",
  icon = null,
  className = "",
  ...props
}) => {
  const baseClasses = "font-medium rounded-lg transition-all duration-200 flex items-center gap-2";

  const variantClasses = {
    primary: "bg-indigo-600 hover:bg-indigo-700 text-white",
    secondary: "bg-gray-50 hover:bg-gray-100 text-gray-700 border border-gray-200",
    danger: "bg-red-50 hover:bg-red-100 text-red-600 border border-red-200",
    ghost: "hover:bg-gray-100 text-gray-600",
  };

  const sizeClasses = {
    sm: "text-sm px-3 py-1.5",
    md: "px-4 py-2",
    lg: "text-lg px-5 py-2.5",
  };

  return (
    <button
      onClick={onClick}
      className={`${baseClasses} ${variantClasses[variant]} ${sizeClasses[size]} ${className}`}
      {...props}
    >
      {icon && icon}
      {children}
    </button>
  );
};

// FileUploadPreview component
const FileUploadPreview = ({ file, onRemove }) => {
  return (
    <div className="flex items-center gap-2 p-3 bg-gray-50 rounded-lg group hover:bg-gray-100 transition-all">
      <span className="text-xl">{getFileIcon(file.name)}</span>
      <span className="flex-1 truncate font-medium text-gray-700">{file.name}</span>
      <span className="text-sm text-gray-500">
        ({(file.size / 1024 / 1024).toFixed(2)} MB)
      </span>
      <button
        onClick={onRemove}
        className="p-1 text-gray-400 hover:text-red-500 opacity-0 group-hover:opacity-100 transition-all"
        aria-label="Remove file"
      >
        <X size={16} />
      </button>
    </div>
  );
};

const NotesAurora = () => {
  // State declarations
  const [pages, setPages] = useState([]);
  const [currentPage, setCurrentPage] = useState("default");
  const [currentNote, setCurrentNote] = useState({
    id: null,
    title: "",
    content: "",
    attachments: [],
  });
  const [isEditing, setIsEditing] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [newPageTitle, setNewPageTitle] = useState("");
  const [isAddingPage, setIsAddingPage] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [editingPageId, setEditingPageId] = useState(null);
  const [expandedCard, setExpandedCard] = useState(null);
  const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 });
  const quillRef = useRef(null);
  const [attachments, setAttachments] = useState([]);
  const fileInputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const { user } = useUser();
  const userId = user ? user.id : null;
  const { getToken } = useAuth();

  // ------------------------------------------
  // Toast Utility (for quick messages)
  // ------------------------------------------
  const toast = (message, type = "info") => {
    // Simple placeholder; replace with your own toast library in production
    alert(`${type.toUpperCase()}: ${message}`);
  };

  // ------------------------------------------
  // Fetch Pages
  // ------------------------------------------
  useEffect(() => {
    fetchPages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchPages = async () => {
    setIsLoading(true);
    try {
      const token = await getToken();
      const response = await fetch(url + "/api/pages", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        // Sort pages by createdAt descending
        const sortedPages = data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setPages(sortedPages);
      } else {
        toast("Failed to fetch pages", "error");
      }
    } catch (error) {
      console.error("Error:", error);
      toast("Error fetching pages", "error");
    } finally {
      setIsLoading(false);
    }
  };

  // Derived data for the current page
  const currentPageData = pages.find((page) => page._id === currentPage) || { notes: [] };

  // Sort and filter notes
  const sortedNotes = currentPageData.notes
    ?.slice()
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  const filteredNotes = sortedNotes?.filter(
    (note) =>
      (note.title && note.title.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (note.content && note.content.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  // ------------------------------------------
  // File Upload Handler
  // ------------------------------------------
  const handleFileUpload = (e) => {
    const files = Array.from(e.target.files);

    const validFiles = files.filter((file) => {
      const ext = file.name.split(".").pop().toLowerCase();
      const validExtensions = ["pdf", "doc", "docx", "xls", "xlsx"];
      const maxSize = 10 * 1024 * 1024; // 10MB limit

      if (!validExtensions.includes(ext)) {
        toast("Invalid file type. Only PDF, Word, and Excel files are allowed.", "error");
        return false;
      }
      if (file.size > maxSize) {
        toast(`File too large (${file.name}). Max size is 10MB.`, "error");
        return false;
      }
      return true;
    });

    setAttachments((prev) => [...prev, ...validFiles]);
  };

  // Remove a file from the new attachments list
  const removeAttachment = (index) => {
    setAttachments((prev) => prev.filter((_, i) => i !== index));
  };

  // ------------------------------------------
  // Delete Note
  // ------------------------------------------
  const handleDeleteNote = async (noteId) => {
    if (!window.confirm("Delete this note?")) return;

    try {
      const token = await getToken();
      const response = await fetch(`${url}/api/notes/${noteId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        // Remove note from local state
        setPages(
          pages.map((page) => {
            if (page._id === currentPage) {
              return {
                ...page,
                notes: page.notes.filter((note) => note._id !== noteId),
              };
            }
            return page;
          })
        );
        toast("Note deleted successfully", "success");
      } else {
        toast("Failed to delete note", "error");
      }
    } catch (error) {
      console.error("Error deleting note:", error);
      toast("Error deleting note", "error");
    }
  };

  // ------------------------------------------
  // Delete Page
  // ------------------------------------------
  const handleDeletePage = async (pageId) => {
    try {
      const token = await getToken();
      const response = await fetch(`${url}/api/pages/${pageId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const updatedPages = pages.filter((page) => page._id !== pageId);
        setPages(updatedPages);

        if (updatedPages.length > 0) {
          setCurrentPage(updatedPages[0]._id);
        } else {
          setCurrentPage("default");
        }
        toast("Page deleted successfully", "success");
      } else {
        toast("Failed to delete page", "error");
      }
    } catch (error) {
      console.error("Error deleting page:", error);
      toast("Error deleting page", "error");
    }
  };

  // ------------------------------------------
  // Add Page
  // ------------------------------------------
  const handleAddPage = async () => {
    if (!newPageTitle.trim()) return;
    setIsLoading(true);

    try {
      const token = await getToken();
      const response = await fetch(`${url}/api/pages`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId, title: newPageTitle }),
      });

      if (response.ok) {
        const newPage = await response.json();
        await fetchPages(); // refresh pages
        setCurrentPage(newPage._id);
        setNewPageTitle("");
        setIsAddingPage(false);
        toast("Page created successfully", "success");
      } else {
        toast("Failed to add page", "error");
      }
    } catch (error) {
      console.error("Error adding page:", error);
      toast("Error adding page", "error");
    } finally {
      setIsLoading(false);
    }
  };

  // ------------------------------------------
  // Rename Page
  // ------------------------------------------
  const handleRenamePage = async (pageId, newTitle) => {
    if (!newTitle.trim()) return;

    try {
      const token = await getToken();
      const response = await fetch(`${url}/api/pages/${pageId}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ title: newTitle }),
      });

      if (response.ok) {
        const updatedPage = await response.json();
        setPages(
          pages.map((page) => (page._id === pageId ? { ...page, title: updatedPage.title } : page))
        );
        setEditingPageId(null);
        toast("Page renamed successfully", "success");
      } else {
        toast("Failed to update page title", "error");
      }
    } catch (error) {
      console.error("Error updating page title:", error);
      toast("Error updating page title", "error");
    }
  };

  // ------------------------------------------
  // Save New Note
  // ------------------------------------------
  const handleSave = async () => {
    if (!currentNote.title || !currentNote.content) {
      toast("Title and content are required.", "error");
      return;
    }

    setIsLoading(true);
    try {
      const token = await getToken();
      const formData = new FormData();
      formData.append("userId", userId);
      formData.append("pageId", currentPage);
      formData.append("title", currentNote.title);
      formData.append("content", currentNote.content);
      formData.append("format", "html");

      if (attachments && attachments.length > 0) {
        attachments.forEach((file) => {
          formData.append("attachments", file);
        });
      }

      const response = await fetch(`${url}/api/notes`, {
        method: "POST",
        headers: { Authorization: `Bearer ${token}` },
        body: formData,
      });

      if (response.ok) {
        const savedNote = await response.json();
        // Add the new note to local state
        setPages(
          pages.map((page) => {
            if (page._id === currentPage) {
              return { ...page, notes: [...page.notes, savedNote] };
            }
            return page;
          })
        );
        setCurrentNote({ id: null, title: "", content: "", attachments: [] });
        setAttachments([]);
        setIsEditing(false);
        toast("Note saved successfully", "success");
      } else {
        const errorData = await response.json();
        toast(`Failed to save note: ${errorData.error || "Unknown error"}`, "error");
      }
    } catch (error) {
      console.error("Error saving note:", error);
      toast("Error saving note. Please try again.", "error");
    } finally {
      setIsLoading(false);
    }
  };

  // ------------------------------------------
  // Update Existing Note
  // ------------------------------------------
  const handleUpdate = async (noteId) => {
    if (!currentNote.title || !currentNote.content) {
      toast("Title and content are required.", "error");
      return;
    }

    setIsLoading(true);
    try {
      const token = await getToken();
      const formData = new FormData();
      formData.append("title", currentNote.title);
      formData.append("content", currentNote.content);
      formData.append("format", "html");

      if (attachments && attachments.length > 0) {
        attachments.forEach((file) => {
          formData.append("attachments", file);
        });
      }

      const response = await fetch(`${url}/api/notes/${noteId}`, {
        method: "PUT",
        headers: { Authorization: `Bearer ${token}` },
        body: formData,
      });

      if (response.ok) {
        const updatedNote = await response.json();
        // Update the existing note in local state
        setPages(
          pages.map((page) => {
            if (page._id === currentPage) {
              return {
                ...page,
                notes: page.notes.map((note) => (note._id === noteId ? updatedNote : note)),
              };
            }
            return page;
          })
        );
        setCurrentNote({ id: null, title: "", content: "", attachments: [] });
        setAttachments([]);
        setIsEditing(false);
        toast("Note updated successfully", "success");
      } else {
        const errorData = await response.json();
        toast(`Failed to update note: ${errorData.error || "Unknown error"}`, "error");
      }
    } catch (error) {
      console.error("Error updating note:", error);
      toast("Error updating note. Please try again.", "error");
    } finally {
      setIsLoading(false);
    }
  };

  // ------------------------------------------
  // Delete Attachment (for existing note)
  // ------------------------------------------
  const handleDeleteAttachment = async (noteId, filename) => {
    if (!window.confirm("Delete this attachment?")) return;

    try {
      const token = await getToken();
      const response = await fetch(`${url}/api/notes/${noteId}/attachments/${filename}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        // Remove attachment from local state
        setPages((prevPages) =>
          prevPages.map((page) => {
            if (page._id === currentPage) {
              return {
                ...page,
                notes: page.notes.map((note) => {
                  if (note._id === noteId) {
                    return {
                      ...note,
                      attachments: note.attachments.filter(
                        (attach) => attach.filename !== filename
                      ),
                    };
                  }
                  return note;
                }),
              };
            }
            return page;
          })
        );

        // If we are currently editing that note, remove from currentNote as well
        if (currentNote._id === noteId) {
          setCurrentNote((prev) => ({
            ...prev,
            attachments: prev.attachments.filter(
              (attachment) => attachment.filename !== filename
            ),
          }));
        }

        toast("Attachment deleted successfully", "success");
      } else {
        const errorData = await response.json();
        toast(`Failed to delete attachment: ${errorData.error || "Unknown error"}`, "error");
      }
    } catch (error) {
      console.error("Error deleting attachment:", error);
      toast("Error deleting attachment. Please try again.", "error");
    }
  };

  // ------------------------------------------
  // Note Content Component
  // ------------------------------------------
  const NoteContent = ({ noteId, content, attachments, onDeleteAttachment }) => {
    const renderAttachments = () => {
      if (!attachments || attachments.length === 0) return null;

      return (
        <div className="mt-4 pt-4 border-t border-gray-200">
          <h3 className="text-sm font-semibold mb-2 text-gray-700 flex items-center gap-2">
            <Paperclip size={14} /> Attachments
          </h3>
          <div className="space-y-2">
            {attachments.map((file, index) => {
              const fileIcon = getFileIcon(file.originalName);
              return (
                <div
                  key={index}
                  className="flex items-center gap-2 p-3 bg-gray-50 rounded-lg hover:bg-gray-100 transition-all group"
                >
                  <span className="text-xl">{fileIcon}</span>
                  <a
                    href={`${url}/uploads/${file.filename}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex-1 text-indigo-600 hover:text-indigo-800 truncate font-medium"
                    download={file.originalName}
                  >
                    {file.originalName}
                  </a>
                  <span className="text-sm text-gray-500">
                    ({(file.size / 1024 / 1024).toFixed(2)} MB)
                  </span>
                  {onDeleteAttachment && (
                    <button
                      onClick={() => onDeleteAttachment(noteId, file.filename)}
                      className="p-1 text-gray-400 hover:text-red-500 transition-colors opacity-0 group-hover:opacity-100"
                    >
                      <Trash2 size={16} />
                    </button>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      );
    };

    return (
      <div>
        <div
          className="prose prose-sm max-w-none
            prose-headings:font-bold prose-headings:text-gray-900
            prose-p:text-gray-700 prose-p:leading-relaxed
            prose-a:text-indigo-600 prose-a:no-underline hover:prose-a:underline
            prose-strong:text-gray-900 prose-strong:font-semibold
            [&_pre]:overflow-x-auto 
            [&_pre]:max-w-full 
            [&_pre]:p-4 
            [&_pre]:bg-gray-50 
            [&_pre]:rounded-lg 
            [&_pre]:my-2 
            [&_code]:text-sm 
            [&_code]:font-mono 
            [&_pre]:border 
            [&_pre]:border-gray-200
            [&_ul]:list-disc 
            [&_ul]:pl-4 
            [&_ol]:list-decimal 
            [&_ol]:pl-4 
            [&_li]:my-1"
          dangerouslySetInnerHTML={{ __html: content }}
        />
        {renderAttachments()}
      </div>
    );
  };

  // ------------------------------------------
  // Loading Spinner
  // ------------------------------------------
  const LoadingSpinner = () => (
    <div className="flex justify-center items-center p-4">
      <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-indigo-600"></div>
    </div>
  );

  // ------------------------------------------
  // Empty State
  // ------------------------------------------
  const EmptyState = () => (
    <div className="flex flex-col items-center justify-center p-8 text-center">
      <div className="bg-indigo-50 p-4 rounded-full mb-4">
        <AlertTriangle size={32} className="text-indigo-600" />
      </div>
      <h3 className="text-lg font-medium text-gray-900 mb-1">No notes found</h3>
      <p className="text-gray-500 mb-6">Get started by creating your first note</p>
      <Button
        onClick={() => {
          setIsEditing(true);
          setCurrentNote({
            id: null,
            title: "",
            content: "",
            attachments: [],
          });
        }}
        icon={<Plus size={16} />}
      >
        Create Note
      </Button>
    </div>
  );

  // ------------------------------------------
  // Main Return
  // ------------------------------------------
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      {/* Navbar */}
      <Navbar />

      <div className="flex flex-1">
        {/* Sidebar toggle button */}
        {!isSidebarOpen && (
          <button
            onClick={() => setIsSidebarOpen(true)}
            className="fixed left-4 top-20 z-20 bg-white p-2 rounded-full shadow-lg hover:bg-gray-50 text-gray-700"
            aria-label="Open sidebar"
          >
            <ChevronRight size={20} />
          </button>
        )}

        {/* Sidebar */}
        <div
          className={`${
            isSidebarOpen ? "w-72" : "w-0"
          } transition-all duration-300 bg-white h-screen border-r border-gray-100 overflow-hidden relative shadow-sm`}
        >
          <div className="p-6 h-full flex flex-col">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-bold text-gray-900">Pages</h2>
              <div className="flex gap-2">
                <IconButton
                  icon={<Plus size={18} />}
                  onClick={() => setIsAddingPage(true)}
                  className="text-gray-700 hover:bg-indigo-50 hover:text-indigo-600"
                  label="Add page"
                />
                <IconButton
                  icon={<ChevronLeft size={18} />}
                  onClick={() => setIsSidebarOpen(false)}
                  className="text-gray-700 hover:bg-indigo-50 hover:text-indigo-600"
                  label="Close sidebar"
                />
              </div>
            </div>

            {/* Chat Widget in Sidebar */}
            <div className="mb-6">
              <ChatWidget />
            </div>

            {isAddingPage && (
              <div className="mb-6 p-4 bg-indigo-50 rounded-xl">
                <input
                  type="text"
                  value={newPageTitle}
                  onChange={(e) => setNewPageTitle(e.target.value)}
                  placeholder="New page name"
                  className="w-full p-2 border rounded-lg mb-3 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 outline-none"
                />
                <div className="flex gap-2">
                  <Button onClick={handleAddPage} size="sm" disabled={isLoading}>
                    {isLoading ? "Adding..." : "Add Page"}
                  </Button>
                  <Button onClick={() => setIsAddingPage(false)} variant="ghost" size="sm">
                    Cancel
                  </Button>
                </div>
              </div>
            )}

            {/* List of Pages */}
            <div className="space-y-1 overflow-y-auto flex-grow">
              {isLoading && pages.length === 0 ? (
                <LoadingSpinner />
              ) : (
                pages?.map((page) => (
                  <div
                    key={page._id}
                    className={`group flex items-center justify-between p-3 rounded-lg cursor-pointer transition-all ${
                      currentPage === page._id
                        ? "bg-indigo-50 text-indigo-700"
                        : "hover:bg-gray-50 text-gray-700"
                    }`}
                    onClick={() => setCurrentPage(page._id)}
                  >
                    <div className="flex items-center gap-3 flex-1 min-w-0">
                      <div
                        className={`w-6 h-6 flex items-center justify-center rounded-md ${
                          currentPage === page._id ? "bg-indigo-100" : "bg-gray-100"
                        }`}
                      >
                        <span className="text-sm">{page.title.charAt(0).toUpperCase()}</span>
                      </div>
                      {editingPageId === page._id ? (
                        <input
                          type="text"
                          defaultValue={page.title}
                          onClick={(e) => e.stopPropagation()}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleRenamePage(page._id, e.target.value);
                            }
                          }}
                          onBlur={(e) => handleRenamePage(page._id, e.target.value)}
                          className="bg-white border rounded px-2 py-1 outline-none focus:ring-2 focus:ring-indigo-500 flex-1 min-w-0"
                          autoFocus
                        />
                      ) : (
                        <span className="flex-1 truncate font-medium">{page.title}</span>
                      )}
                    </div>
                    <div className="flex gap-1 opacity-0 group-hover:opacity-100 transition-opacity">
                      <IconButton
                        icon={<Edit size={14} />}
                        onClick={(e) => {
                          e.stopPropagation();
                          setEditingPageId(page._id);
                        }}
                        className="text-gray-500 hover:text-indigo-600"
                      />
                      {pages.length > 1 && (
                        <IconButton
                          icon={<Trash2 size={14} />}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (window.confirm(`Delete page "${page.title}"?`)) {
                              handleDeletePage(page._id);
                            }
                          }}
                          className="text-gray-500 hover:text-red-600"
                        />
                      )}
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="flex-1 p-6 overflow-y-auto">
          <div className="max-w-6xl mx-auto">
            {currentPage === "default" ? (
              <WelcomeMessage />
            ) : (
              <>
                {/* Header with search and "new note" button */}
                <div className="flex flex-col sm:flex-row sm:items-center justify-between mb-10 gap-4">
                  <h1 className="text-3xl font-bold bg-gradient-to-r from-indigo-600 to-violet-600 bg-clip-text text-transparent">
                    {currentPageData?.title}
                  </h1>
                  <div className="flex gap-3 flex-wrap">
                    <div className="relative flex-grow sm:flex-grow-0 max-w-md">
                      <Search
                        className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                        size={18}
                      />
                      <input
                        type="text"
                        placeholder="Search notes..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="pl-10 pr-10 py-2 w-full rounded-lg border border-gray-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
                      />
                      {searchQuery && (
                        <button
                          onClick={() => setSearchQuery("")}
                          className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
                        >
                          <X size={18} />
                        </button>
                      )}
                    </div>
                    {!isEditing && (
                      <Button
                        onClick={() => {
                          setIsEditing(true);
                          setCurrentNote({
                            id: null,
                            title: "",
                            content: "",
                            attachments: [],
                          });
                        }}
                        icon={<Plus size={18} />}
                      >
                        New Note
                      </Button>
                    )}
                  </div>
                </div>

                {/* If Editing, show the "Add/Edit Note" card */}
                {isEditing && (
                  <Card className="mb-8 border border-gray-100">
                    <CardContent>
                      <input
                        type="text"
                        value={currentNote.title}
                        onChange={(e) =>
                          setCurrentNote({ ...currentNote, title: e.target.value })
                        }
                        placeholder="Note Title"
                        className="w-full text-xl font-semibold mb-4 p-3 border-0 border-b focus:border-indigo-500 focus:ring-0 focus:outline-none bg-transparent"
                      />

                      {/* Editor */}
                      <div className="mb-6 rounded-lg border border-gray-200 overflow-hidden">
                        <ReactQuill
                          ref={quillRef}
                          value={currentNote.content}
                          onChange={(content) =>
                            setCurrentNote({ ...currentNote, content })
                          }
                          modules={{
                            toolbar: [
                              [{ header: [1, 2, false] }],
                              ["bold", "italic", "underline", "strike"],
                              [{ list: "ordered" }, { list: "bullet" }],
                              ["link", "image"],
                              [{ align: [] }],
                              [{ color: [] }, { background: [] }],
                            ],
                            clipboard: {
                              matchVisual: false,
                            },
                          }}
                          formats={[
                            "header",
                            "bold",
                            "italic",
                            "underline",
                            "strike",
                            "list",
                            "bullet",
                            "link",
                            "image",
                            "align",
                            "color",
                            "background",
                          ]}
                          theme="snow"
                          preserveWhitespace={true}
                          className="min-h-[200px]"
                        />
                      </div>

                      {/* Existing Attachments */}
                      {currentNote.attachments && currentNote.attachments.length > 0 && (
                        <div className="mt-6 border-t pt-4">
                          <h3 className="text-sm font-semibold mb-3 flex items-center gap-2 text-gray-700">
                            <Paperclip size={16} /> Existing Attachments
                          </h3>
                          <div className="space-y-2 mt-2">
                            {currentNote.attachments.map((file, index) => (
                              <div
                                key={index}
                                className="flex items-center gap-2 p-3 bg-gray-50 rounded-lg group hover:bg-gray-100 transition-all"
                              >
                                <span className="text-xl">{getFileIcon(file.originalName)}</span>
                                <a
                                  href={`${url}/uploads/${file.filename}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="flex-1 text-indigo-600 hover:text-indigo-800 truncate font-medium"
                                  download={file.originalName}
                                >
                                  {file.originalName}
                                </a>
                                <span className="text-sm text-gray-500">
                                  ({(file.size / 1024 / 1024).toFixed(2)} MB)
                                </span>
                                <button
                                  onClick={() =>
                                    handleDeleteAttachment(currentNote._id, file.filename)
                                  }
                                  className="p-2 text-gray-400 hover:text-red-500 opacity-0 group-hover:opacity-100 transition-all rounded-full hover:bg-gray-200"
                                >
                                  <Trash2 size={16} />
                                </button>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}

                      {/* New Attachments */}
                      <div className="mt-6 border-t pt-4">
                        <div className="flex items-center justify-between mb-3">
                          <h3 className="text-sm font-semibold flex items-center gap-2 text-gray-700">
                            <Paperclip size={16} /> Add Attachments
                          </h3>
                          <Button
                            onClick={() => fileInputRef.current?.click()}
                            variant="secondary"
                            size="sm"
                            icon={<FileUp size={16} />}
                          >
                            Add Files
                          </Button>
                        </div>

                        <input
                          type="file"
                          ref={fileInputRef}
                          onChange={handleFileUpload}
                          multiple
                          accept=".pdf,.doc,.docx,.xls,.xlsx"
                          className="hidden"
                        />

                        {/* File Preview List */}
                        <div className="space-y-2 mt-2">
                          {attachments.length > 0 ? (
                            attachments.map((file, index) => (
                              <FileUploadPreview
                                key={index}
                                file={file}
                                onRemove={() => removeAttachment(index)}
                              />
                            ))
                          ) : (
                            <div className="p-4 bg-gray-50 rounded-lg border border-dashed border-gray-200 text-center">
                              <p className="text-gray-500 text-sm">
                                Drag files here or click "Add Files" to upload
                              </p>
                              <p className="text-gray-400 text-xs mt-1">
                                Supported formats: PDF, Word, Excel (Max: 10MB)
                              </p>
                            </div>
                          )}
                        </div>
                      </div>

                      {/* Buttons */}
                      <div className="flex gap-3 mt-6 border-t pt-6">
                        <Button
                          onClick={() =>
                            currentNote._id ? handleUpdate(currentNote._id) : handleSave()
                          }
                          disabled={isLoading}
                          icon={<Save size={18} />}
                        >
                          {isLoading
                            ? "Saving..."
                            : currentNote._id
                            ? "Update"
                            : "Save"}
                        </Button>
                        <Button
                          onClick={() => {
                            setCurrentNote({ id: null, title: "", content: "", attachments: [] });
                            setIsEditing(false);
                            setAttachments([]);
                          }}
                          variant="secondary"
                        >
                          Cancel
                        </Button>
                      </div>
                    </CardContent>
                  </Card>
                )}

                {/* Show list of notes if not editing */}
                {!isEditing && (
                  <>
                    {isLoading && filteredNotes?.length === 0 ? (
                      <LoadingSpinner />
                    ) : filteredNotes?.length === 0 ? (
                      searchQuery ? (
                        // No matches for the search query
                        <div className="text-center py-16">
                          <div className="inline-flex items-center justify-center w-16 h-16 bg-gray-100 rounded-full mb-4">
                            <Search size={24} className="text-gray-400" />
                          </div>
                          <h3 className="text-lg font-medium text-gray-900 mb-1">
                            No results found
                          </h3>
                          <p className="text-gray-500">Try adjusting your search terms</p>
                        </div>
                      ) : (
                        // No notes at all in this page
                        <EmptyState />
                      )
                    ) : (
                      // Display the notes
                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 relative">
                        {filteredNotes?.map((note) => (
                          <Card
                            key={note._id}
                            className="group hover:shadow-md transition-all border border-gray-100"
                            expandable={true}
                          >
                            <CardContent>
                              <div className="flex justify-between items-start mb-3">
                                <h2 className="text-lg font-semibold text-gray-800 line-clamp-1">
                                  {note.title}
                                </h2>
                                <div className="flex opacity-0 group-hover:opacity-100 transition-opacity">
                                  <IconButton
                                    icon={<Edit size={16} />}
                                    onClick={() => {
                                      setCurrentNote(note);
                                      setIsEditing(true);
                                      setAttachments([]);
                                    }}
                                    className="text-gray-400 hover:text-indigo-600"
                                    label="Edit note"
                                  />
                                  <IconButton
                                    icon={<Trash2 size={16} />}
                                    onClick={() => handleDeleteNote(note._id)}
                                    className="text-gray-400 hover:text-red-600"
                                    label="Delete note"
                                  />
                                  <IconButton
                                    icon={<Maximize size={16} />}
                                    onClick={(e) => {
                                      const rect = e.currentTarget.getBoundingClientRect();
                                      setModalPosition({
                                        x: rect.x,
                                        y: rect.y + window.scrollY,
                                      });
                                      setExpandedCard(note._id);
                                    }}
                                    className="text-gray-400 hover:text-indigo-600"
                                    label="Expand note"
                                  />
                                </div>
                              </div>

                              {/* Metadata & date */}
                              <div className="flex items-center text-xs text-gray-500 mb-3">
                                <span>
                                  {new Date(note.updatedAt || note.createdAt).toLocaleDateString()}
                                </span>
                                {note.attachments?.length > 0 && (
                                  <span className="flex items-center ml-3">
                                    <Paperclip size={12} className="mr-1" />
                                    {note.attachments.length}
                                  </span>
                                )}
                              </div>

                              {/* Preview content with gradient fade */}
                              <div className="relative h-40 overflow-hidden">
                                <NoteContent
                                  noteId={note._id}
                                  content={note.content}
                                  attachments={[]} // Hide attachments in preview
                                />
                                <div className="absolute bottom-0 left-0 right-0 h-16 bg-gradient-to-t from-white to-transparent pointer-events-none" />
                              </div>
                            </CardContent>
                          </Card>
                        ))}
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      {/* Modal for expanded note */}
      {expandedCard && (
        <>
          <div
            className="fixed inset-0 bg-black bg-opacity-30 z-40 backdrop-blur-sm"
            onClick={() => setExpandedCard(null)}
          />
          <div
            className="fixed z-50 bg-white rounded-xl shadow-2xl w-[90vw] max-w-4xl max-h-[90vh] flex flex-col overflow-hidden"
            style={{
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            {/* Modal Header */}
            <div className="px-6 py-4 border-b border-gray-200 flex justify-between items-center flex-shrink-0 bg-gray-50">
              <h2 className="text-xl font-semibold text-gray-800">
                {filteredNotes.find((n) => n._id === expandedCard)?.title}
              </h2>
              <IconButton
                icon={<X size={20} />}
                onClick={() => setExpandedCard(null)}
                className="text-gray-500 hover:text-gray-700"
                label="Close"
              />
            </div>

            {/* Modal Content */}
            <div className="flex-1 overflow-y-auto p-6">
              <div className="max-w-full">
                <NoteContent
                  noteId={expandedCard}
                  content={filteredNotes.find((n) => n._id === expandedCard)?.content}
                  attachments={filteredNotes.find((n) => n._id === expandedCard)?.attachments}
                  onDeleteAttachment={handleDeleteAttachment}
                />
              </div>
            </div>

            {/* Modal Footer */}
            <div className="px-6 py-4 border-t border-gray-200 flex justify-end gap-2 flex-shrink-0 bg-gray-50">
              <Button
                variant="secondary"
                onClick={() => {
                  const note = filteredNotes.find((n) => n._id === expandedCard);
                  setCurrentNote(note);
                  setIsEditing(true);
                  setAttachments([]);
                  setExpandedCard(null);
                }}
                icon={<Edit size={16} />}
              >
                Edit
              </Button>
              <Button variant="ghost" onClick={() => setExpandedCard(null)}>
                Close
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default NotesAurora;