import React from 'react';
import Lottie from 'react-lottie';
import { PlusCircle, FileText, ArrowLeft } from 'lucide-react';

const WelcomeMessage = () => {
  // Enhanced Lottie animation configuration
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: {
      "v": "5.5.7",
      "fr": 60,
      "ip": 0,
      "op": 180,
      "w": 512,
      "h": 512,
      "nm": "Modern Notes Animation",
      "ddd": 0,
      "assets": [],
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          "nm": "Note 1",
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100 },
            "p": { "a": 1, "k": [
              { "t": 0, "s": [256, 256], "h": 1 },
              { "t": 90, "s": [256, 236], "h": 1 },
              { "t": 180, "s": [256, 256] }
            ]},
            "a": { "a": 0, "k": [0, 0, 0] },
            "s": { 
              "a": 1, 
              "k": [
                { "t": 0, "s": [100, 100, 100] },
                { "t": 90, "s": [105, 105, 105] },
                { "t": 180, "s": [100, 100, 100] }
              ]
            }
          },
          "shapes": [{
            "ty": "gr",
            "it": [{
              "ty": "rc",
              "d": 1,
              "s": { "a": 0, "k": [140, 180] },
              "p": { "a": 0, "k": [0, 0] },
              "r": { "a": 0, "k": 12 }
            }, {
              "ty": "fl",
              "c": { "a": 0, "k": [0.39, 0.45, 1, 1] }
            }]
          }]
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          "nm": "Note 2",
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 60 },
            "p": { "a": 1, "k": [
              { "t": 0, "s": [296, 256], "h": 1 },
              { "t": 90, "s": [296, 246], "h": 1 },
              { "t": 180, "s": [296, 256] }
            ]},
            "a": { "a": 0, "k": [0, 0, 0] },
            "s": { "a": 0, "k": [90, 90, 90] }
          },
          "shapes": [{
            "ty": "gr",
            "it": [{
              "ty": "rc",
              "d": 1,
              "s": { "a": 0, "k": [140, 180] },
              "p": { "a": 0, "k": [0, 0] },
              "r": { "a": 0, "k": 12 }
            }, {
              "ty": "fl",
              "c": { "a": 0, "k": [0.58, 0.62, 1, 1] }
            }]
          }]
        }
      ]
    },
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-[60vh] px-4">
      <div className="relative">
        {/* Gradient background blur effect */}
        <div className="absolute inset-0 -z-10 transform">
          <div className="absolute inset-0 blur-3xl opacity-30 bg-gradient-to-r from-blue-400 via-purple-500 to-pink-500 rounded-full" />
        </div>
        
        {/* Main content container */}
        <div className="relative z-10 flex flex-col items-center space-y-8 max-w-2xl mx-auto">
          {/* Animation container */}
          <div className="w-56 h-56">
            <Lottie options={defaultOptions} />
          </div>

          {/* Text content */}
          <div className="text-center space-y-6">
            <h2 className="text-4xl font-bold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
              Welcome to Your Digital Workspace
            </h2>
            
            <p className="text-lg text-gray-600 max-w-md leading-relaxed">
              Transform your ideas into organized notes. Start by creating your first page.
            </p>

            {/* Features grid */}
            <div className="grid grid-cols-2 gap-4 mt-8 max-w-lg mx-auto">
              <div className="p-4 bg-white/50 backdrop-blur-sm rounded-xl border border-gray-100 shadow-sm">
                <FileText className="w-6 h-6 text-blue-500 mb-2" />
                <h3 className="font-medium text-gray-800">Organize</h3>
                <p className="text-sm text-gray-600">Create multiple pages for different topics</p>
              </div>
              <div className="p-4 bg-white/50 backdrop-blur-sm rounded-xl border border-gray-100 shadow-sm">
                <PlusCircle className="w-6 h-6 text-purple-500 mb-2" />
                <h3 className="font-medium text-gray-800">Create</h3>
                <p className="text-sm text-gray-600">Add rich text notes with attachments</p>
              </div>
            </div>

            {/* Start action */}
            <div className="flex items-center justify-center gap-3 mt-8 text-blue-600 group cursor-pointer">
              <ArrowLeft className="w-5 h-5 animate-bounce" />
              <span className="text-sm font-medium group-hover:underline">
                Click the "+" icon to begin
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeMessage;