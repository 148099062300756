import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Brain, PencilRuler, Target, Paperclip } from 'lucide-react';

const MathSidebar = () => {
  const location = useLocation();
  
  const menuItems = [
    {
      title: "Mental Math Shortcuts",
      path: "/mentalMath",
      icon: PencilRuler,
      description: "Quick calculation techniques for case interviews"
    },
    {
      title: "Estimation Guide",
      path: "/estimation",
      icon: Target,
      description: "Master the art of quick estimations"
    },
    {
      title: "Approximation",
      path: "/approximation",
      icon: Brain,
      description: "Learn about margins of error and approximations"
    },
    {
      title: "Conversions",
      path: "/conversions",
      icon: Paperclip,
      description: "Handle fractions, decimals, and percentages"
    }
  ];

  return (
    <aside className="fixed top-0 left-0 h-full w-72 bg-white border-r border-gray-200 pt-16">
      <div className="p-6">
        <div className="flex items-center gap-2 mb-8">
          <Brain className="h-6 w-6 text-violet-600" />
          <h2 className="text-lg font-medium text-violet-600">Math Quick Tips</h2>
        </div>

        <nav className="space-y-2">
          {menuItems.map((item) => {
            const isActive = location.pathname === item.path;
            const ItemIcon = item.icon;
            
            return (
              <Link
                key={item.path}
                to={item.path}
                className={`
                  block p-3 rounded-lg transition-all duration-200
                  ${isActive 
                    ? 'bg-gradient-to-r from-violet-600 to-pink-500 text-white' 
                    : 'hover:bg-gray-50'
                  }
                `}
              >
                <div className="flex items-start gap-3">
                  <div className={`mt-0.5 ${isActive ? 'text-white' : 'text-violet-600'}`}>
                    <ItemIcon className="h-5 w-5" />
                  </div>
                  <div>
                    <div className={`font-medium ${isActive ? 'text-white' : 'text-gray-900'}`}>
                      {item.title}
                    </div>
                    <p className={`text-sm mt-0.5 ${isActive ? 'text-white/80' : 'text-gray-500'}`}>
                      {item.description}
                    </p>
                  </div>
                </div>
              </Link>
            );
          })}
        </nav>
      </div>
    </aside>
  );
};

export default MathSidebar;