// src/pages/CaseStudyDetail.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth, useUser } from '@clerk/clerk-react';
import {
  BookOpen,
  Building,
  Target,
  TrendingUp,
  CheckCircle,
  Sparkles,
} from 'lucide-react';

import Popup from '../components/Popup';
import Navbar from '../components/Navbar';

// Import your ProcessingAnimation component
import ProcessingAnimation from '../components/ProcessingAnimation';

const CaseStudyDetail = () => {
  const { id } = useParams();
  const { getToken } = useAuth();
  const { user } = useUser();

  // States
  const [caseData, setCaseData] = useState(null);
  const [error, setError] = useState(null);
  const [answer, setAnswer] = useState('');
  const [grade, setGrade] = useState('');
  const [feedback, setFeedback] = useState('');
  const [loading, setLoading] = useState(false);       // <--- tracks submission
  const [showFeedback, setShowFeedback] = useState(false);

  // Your backend URL
  const url = process.env.REACT_APP_BACK_END_URL;

  // --- Fetch Case Data ---
  useEffect(() => {
    const fetchCase = async () => {
      try {
        const token = await getToken();
        const response = await fetch(`${url}/api/case/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          const errText = await response.text();
          throw new Error(`Failed to fetch case: ${errText}`);
        }

        const data = await response.json();
        setCaseData(data);
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
    };

    fetchCase();
  }, [id, url, getToken]);

  // --- Handle Submit ---
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true); // show the processing animation

    const formData = new FormData(e.target);
    const currentAnswer = formData.get('answer');
    setAnswer(currentAnswer);

    try {
      const token = await getToken();

      // Prepare the payload
      const payload = {
        userId: user?.id,
        title: caseData?.title,
        area: caseData?.area,
        companyBackground: caseData?.companyBackground,
        problemStatement: caseData?.problemStatement,
        companyFinancialBusinessInformation:
          caseData?.companyFinancialBusinessInformation,
        keyDetailsIndustry: caseData?.keyDetailsIndustry,
        objective: caseData?.objective,
        difficultyLevel: caseData?.difficultyLevel,
        answer: currentAnswer,
      };

      console.log(`${url}/api/submit`)
      const response = await fetch(`${url}/api/submit`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errText = await response.text();
        throw new Error(`Submit failed: ${errText}`);
      }

      const data = await response.json();
      setFeedback(data.feedback || '');
      setGrade(data.grade || '');
      setShowFeedback(true);
    } catch (err) {
      console.error(err);
      setError(err.message);
    } finally {
      // hide the processing animation
      setLoading(false);
    }
  };

  // --- Card component ---
  const Card = ({ children, gradient, shine }) => (
    <div
      className={`rounded-2xl shadow-lg ${
        gradient
          ? 'bg-gradient-to-r from-violet-600 to-pink-500'
          : 'bg-white bg-opacity-90 backdrop-blur-lg'
      } p-8 ${shine ? 'hover:shadow-xl transition-shadow duration-300' : ''}`}
    >
      {children}
    </div>
  );

  // --- Early returns ---
  if (error) {
    return <div className="p-6 text-red-500">Error: {error}</div>;
  }
  if (!caseData) {
    // This is for the *initial* fetch of the case data
    return <div className="p-6">Loading...</div>;
  }

  // --- Render ---
  return (
    <div className="min-h-screen bg-gradient-to-br from-slate-50 to-purple-50 flex flex-col">
      <Navbar />

      {/* Processing Animation Overlay */}
      <ProcessingAnimation
        isLoading={loading}
        onComplete={() => {
          // Optional callback after animation is done
        }}
      />

      <div className="flex-1">
        {/* Header Banner */}
        <div className="w-full bg-white bg-opacity-90 backdrop-blur-lg border-b border-gray-100">
          <div className="max-w-7xl mx-auto py-6 px-6">
            <div className="flex items-center gap-2 text-violet-600 mb-2">
              <Sparkles className="w-5 h-5" />
              <span className="text-sm font-medium">Case Study Explorer</span>
            </div>
            <h1 className="text-5xl font-bold text-gray-900 mb-4">
              {caseData.title}
            </h1>
            <div className="flex gap-3">
              <span className="px-4 py-1.5 rounded-full bg-violet-100 text-violet-700 font-medium text-sm">
                {caseData.area}
              </span>
              <span
                className={`px-4 py-1.5 rounded-full font-medium text-sm
                  ${
                    caseData.difficultyLevel.toLowerCase() === 'easy'
                      ? 'bg-green-100 text-green-700'
                      : ''
                  }
                  ${
                    caseData.difficultyLevel.toLowerCase() === 'medium'
                      ? 'bg-yellow-100 text-yellow-700'
                      : ''
                  }
                  ${
                    caseData.difficultyLevel.toLowerCase() === 'hard'
                      ? 'bg-red-100 text-red-700'
                      : ''
                  }
                `}
              >
                {caseData.difficultyLevel}
              </span>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="max-w-7xl mx-auto px-6 py-12">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
            {/* Left Column */}
            <div className="lg:col-span-2 space-y-8">
              {/* Company Background */}
              <Card shine>
                <div className="flex items-center gap-2 text-violet-600 mb-4">
                  <Building className="w-5 h-5" />
                  <h2 className="text-xl font-semibold">Company Background</h2>
                </div>
                <p className="text-gray-700 leading-relaxed">
                  {caseData.companyBackground}
                </p>
              </Card>

              {/* Problem Statement */}
              <Card gradient>
                <div className="flex items-center gap-2 text-white mb-4">
                  <BookOpen className="w-5 h-5" />
                  <h2 className="text-xl font-semibold">Problem Statement</h2>
                </div>
                <p className="text-white text-opacity-90 leading-relaxed">
                  {caseData.problemStatement}
                </p>
              </Card>

              {/* Financial & Business Info */}
              <Card shine>
                <div className="flex items-center gap-2 text-violet-600 mb-4">
                  <TrendingUp className="w-5 h-5" />
                  <h2 className="text-xl font-semibold">
                    Financial &amp; Business Information
                  </h2>
                </div>
                <p className="text-gray-700 leading-relaxed">
                  {caseData.companyFinancialBusinessInformation}
                </p>
              </Card>

              {/* Industry Details */}
              <Card shine>
                <div className="flex items-center gap-2 text-violet-600 mb-4">
                  <CheckCircle className="w-5 h-5" />
                  <h2 className="text-xl font-semibold">Industry Details</h2>
                </div>
                <p className="text-gray-700 leading-relaxed">
                  {caseData.keyDetailsIndustry}
                </p>
              </Card>
            </div>

            {/* Right Column (Sidebar) */}
            <div>
              <div className="sticky top-6">
                <Card shine>
                  <div className="flex items-center gap-2 text-violet-600 mb-4">
                    <Target className="w-5 h-5" />
                    <h2 className="text-xl font-semibold">Objective</h2>
                  </div>
                  <p className="text-gray-700 mb-8 leading-relaxed">
                    {caseData.objective}
                  </p>

                  {/* Form to Submit an Answer */}
                  <form onSubmit={handleSubmit} className="space-y-6">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Your Answer
                      </label>
                      <textarea
                        className="w-full min-h-[200px] p-4 rounded-xl border border-gray-200 
                                   focus:ring-2 focus:ring-violet-500 focus:border-transparent 
                                   outline-none transition-all duration-200"
                        placeholder="Type your answer here..."
                        required
                        name="answer"
                      />
                    </div>

                    <button
                      type="submit"
                      disabled={loading}
                      className="w-full bg-gradient-to-r from-violet-600 to-pink-500 
                                 hover:from-violet-700 hover:to-pink-600 
                                 text-white font-medium py-3 px-6 rounded-xl 
                                 transition-all duration-200"
                    >
                      {loading ? 'Submitting...' : 'Submit Answer'}
                    </button>
                  </form>

                  {/* Display any error during submission */}
                  {error && <p className="mt-2 text-red-500">{error}</p>}
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Feedback Popup */}
      <Popup
        open={showFeedback}
        onClose={() => setShowFeedback(false)}
        grade={grade}
        feedback={feedback}
      />
    </div>
  );
};

export default CaseStudyDetail;
