import React, { useState, useEffect } from 'react';
import {
  Menu,
  X,
  ChevronRight,
  Sparkles,
  BookOpen,
  Calculator,
  Users,
  Bot,
  ClipboardList,
  BookCopy,
  StickyNote,
  Target,
  CheckCircle2
} from 'lucide-react';
import {
  useClerk,
  useAuth,
} from '@clerk/clerk-react';
import { useNavigate, Link } from 'react-router-dom';
import StorySection from '../components/CombinedSection';
import ReviewsSection from '../components/ReviewsSection';
import ManagementConsultingPrep from '../components/ManagementConsultingPrep';
import { LinkedinIcon, Twitter, Instagram } from 'lucide-react';

/* ------------------ GradientButton Component ------------------ */
<ManagementConsultingPrep />
const GradientButton = ({ children, className = '', ...props }) => (
  <button
    className={`relative group overflow-hidden bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white px-6 py-3 rounded-xl font-medium transition-all duration-300 hover:scale-105 hover:shadow-lg ${className}`}
    {...props}
  >
    <div className="absolute inset-0 bg-white/20 translate-y-full group-hover:translate-y-0 transition-transform duration-300" />
    {children}
  </button>
);

/* ------------------ FeatureCard Component ------------------ */
const FeatureCard = ({ icon: Icon, title, description }) => (
  <div className="group relative bg-white/50 backdrop-blur-lg p-8 rounded-2xl shadow-xl transition-all duration-300 hover:shadow-2xl hover:-translate-y-1 border border-gray-100">
    <div className="absolute inset-0 bg-gradient-to-br from-indigo-50 to-purple-50 rounded-2xl -z-10" />
    <div className="text-indigo-600 mb-4 transform transition-transform duration-300 group-hover:scale-110">
      <Icon className="w-8 h-8" strokeWidth={1.5} />
    </div>
    <h3 className="text-xl font-semibold text-gray-900 mb-2">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

/* ------------------ PricingFeature Component ------------------ */
const PricingFeature = ({ children }) => (
  <li className="flex items-center space-x-3">
    <div className="flex-shrink-0 w-5 h-5 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-full flex items-center justify-center">
      <CheckCircle2 className="w-4 h-4 text-white" />
    </div>
    <span className="text-gray-600">{children}</span>
  </li>
);

const AuroraPrepConsultingLanding = () => {
  // ====================== HOOKS & STATES =======================
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  let { isSignedIn } = useAuth();
  const { openSignIn } = useClerk();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  // Optional, if referencing a backend:
  const url = process.env.REACT_APP_BACK_END_URL;
  console.log('Backend URL:', url);

  // If you want user notes, etc.
  const [notes, setNotes] = useState([]);

  // NEW: State to track monthly vs annual billing
  const [billingCycle, setBillingCycle] = useState('monthly');

  // ====================== HANDLERS =======================
  const handleLoginClick = () => {
    if (isSignedIn) {
      navigate('/consulting-dashboard');
    } else {
      openSignIn();
    }
  };

  const handleGetStarted = () => {
    setLoading(true);
    setError('');

    if (isSignedIn) {
      navigate('/main');
    } else {
      window.location.href =
        'https://auroraprep.lemonsqueezy.com/buy/1949da6c-f4ce-4551-b443-cab7e24871bb';
    }

    setLoading(false);
  };

  // ====================== DATA =======================
  const features = [
    {
      icon: BookOpen,
      title: 'Problem Solving Lessons',
      description:
        'Learn the strategic methods that consultants at top-tier firms leverage daily.'
    },
    {
      icon: Calculator,
      title: 'Quant & Math Drills',
      description:
        'Build confidence with targeted quantitative exercises, shortcuts, and in-depth solution walkthroughs.'
    },
    {
      icon: BookCopy,
      title: 'Practice Case Simulations',
      description:
        'Test your skills and gain instant feedback on how to improve your approach to casing.'
    },
    {
      icon: ClipboardList,
      title: 'Resume & Cover Letter Overhaul',
      description:
        'AI-enhanced and human-reviewed feedback to optimize your professional documents for top consulting firms.'
    },
    {
      icon: Bot,
      title: '24/7 AI Tutor',
      description:
        'Instant guidance, clarifications, and feedback. Perfect for late-night study or quick concept checks.'
    },
    {
      icon: Users,
      title: 'Behavioral Interview Prep',
      description:
        'Structured modules to craft compelling STAR stories and master those tricky “fit” questions.'
    },
    {
      icon: Target,
      title: 'Goal-Focused Roadmaps',
      description:
        'Customized study plans to keep you on track and align your practice with specific target firms.'
    },
    {
      icon: StickyNote,
      title: 'Noted Integration',
      description:
        'Seamless note-taking and organization across all practice modules for continuous reference.'
    }
  ];

  // Trusted schools marquee
  const schoolLogos = [
    { name: 'Wharton',       src: '/images/Wharton.png' },
    { name: 'UCLA',          src: '/images/UCLA.png' },
    { name: 'Northwestern',  src: '/images/Northwestern.png' },
    { name: 'NYU',           src: '/images/NYU.png' },
    { name: 'MIT',           src: '/images/MIT.png' },
    { name: 'Harvard',       src: '/images/Harvard_1.png' },
    { name: 'Chicago Booth', src: '/images/Chicago_1.png' },
    { name: 'OU',            src: '/images/OU.png' }
  ];
  const repeatedSchoolLogos = [...schoolLogos, ...schoolLogos]; // repeat array

  // Pricing values depending on billing cycle
  const displayPrice = billingCycle === 'monthly' ? '45' : '150';
  const priceSuffix = billingCycle === 'monthly' ? '/month' : '/year';

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      {/* ======================== NAVIGATION ======================== */}
      <nav className="fixed w-full bg-white/70 backdrop-blur-lg shadow-sm z-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16 items-center">
            {/* Logo Section */}
            <div className="flex-shrink-0 flex items-center gap-3">
              <img
                src="/images/ap_logo.svg"
                alt="Aurora Prep Logo"
                className="h-8 w-auto"
              />
              <span className="text-2xl font-bold bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-transparent bg-clip-text">
                Aurora Prep
              </span>
            </div>

            {/* Desktop Menu */}
            <div className="hidden md:flex items-center space-x-8">
              <a
                href="#features"
                className="text-gray-600 hover:text-gray-900 transition-colors"
              >
                Features
              </a>
              <a
                href="#pricing"
                className="text-gray-600 hover:text-gray-900 transition-colors"
              >
                Pricing
              </a>
                <a
                  href="#noted-section"
                  className="text-gray-600 hover:text-gray-900 transition-colors flex items-center"
                >
                  <StickyNote className="w-5 h-5 mr-1" />
                  Noted App
                </a>
              {/* If not signed in, show login button */}
              {!isSignedIn && (
                <button
                  onClick={handleLoginClick}
                  className="px-4 py-2 text-gray-600 hover:text-gray-900 transition-colors"
                  disabled={loading}
                >
                  {loading ? 'Processing...' : 'Login'}
                </button>
              )}
              {/* The main call-to-action button changes text based on isSignedIn */}
              <GradientButton onClick={handleGetStarted} disabled={loading}>
                {loading
                  ? 'Processing...'
                  : isSignedIn
                  ? 'Continue'
                  : 'Get Started'
                }
              </GradientButton>
            </div>

            {/* Mobile Menu Toggle */}
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="md:hidden text-gray-600"
            >
              {isMenuOpen ? (
                <X className="h-6 w-6" />
              ) : (
                <Menu className="h-6 w-6" />
              )}
            </button>
          </div>
        </div>

        {/* Mobile Menu */}
        {isMenuOpen && (
          <div className="md:hidden absolute w-full bg-white/90 backdrop-blur-lg">
            <div className="px-4 py-3 space-y-3">
              <a
                href="#features"
                className="block text-gray-600 hover:text-gray-900"
              >
                Features
              </a>
              <a
                href="#pricing"
                className="block text-gray-600 hover:text-gray-900"
              >
                Pricing
              </a>

                <a
                  href="#noted-section"
                  className="block text-gray-600 hover:text-gray-900 flex items-center"
                >
                  <StickyNote className="w-5 h-5 mr-1" />
                  My Notes
                </a>
              {!isSignedIn && (
                <button
                  onClick={handleLoginClick}
                  className="block w-full text-left px-4 py-2 text-gray-600 hover:text-gray-900"
                  disabled={loading}
                >
                  {loading ? 'Processing...' : 'Login'}
                </button>
              )}
              <GradientButton
                onClick={handleGetStarted}
                className="w-full"
                disabled={loading}
              >
                {loading
                  ? 'Processing...'
                  : isSignedIn
                  ? 'Continue'
                  : 'Get Started'
                }
              </GradientButton>
              {error && (
                <p className="text-red-500 text-sm text-center">{error}</p>
              )}
            </div>
          </div>
        )}
      </nav>
      {/* =========================================================== */}

      {/* Main content container (pushes footer to bottom if needed) */}
      <div className="flex-grow pt-16">
        {/* ======================== HERO SECTION ====================== */}
        <div
          className="relative bg-white overflow-hidden"
          style={{
            backgroundImage:
              'url("https://images.unsplash.com/photo-1552664730-d307ca884978?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80")',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        >
          {/* Subtle overlay for brand color & text contrast */}
          <div
            className="absolute inset-0 bg-gradient-to-br from-indigo-50 via-purple-50 to-pink-50"
            style={{ mixBlendMode: 'multiply', opacity: 0.6 }}
          />

          {/* Hero Content Container */}
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-32 pb-24">
            {/* Simple “translucent” dark box */}
            <div className="max-w-3xl mx-auto bg-black/50 rounded-xl p-8 sm:p-12 text-center shadow-2xl border border-white/10">
              <div className="flex items-center justify-center space-x-3 mb-8">
                <Sparkles className="w-6 h-6 text-indigo-200" />
                <span className="text-sm font-semibold text-indigo-100 tracking-wide uppercase">
                  Transform Your Consulting Career
                </span>
              </div>

              <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold text-white mb-4 leading-tight">
                <span className="block">Crack the Interview.</span>
                <span className="bg-gradient-to-r from-indigo-300 via-purple-300 to-pink-300 text-transparent bg-clip-text">
                  Land the Offer.
                </span>
              </h1>

              <p className="max-w-2xl mx-auto text-lg sm:text-xl md:mt-5 text-gray-100">
                Aurora Prep helps aspiring consultants excel with case studies,
                AI-driven resume reviews, and tailored feedback for interview success—
                now with seamless Noted App integration for even more efficient preparation.
              </p>

              <div className="mt-10 flex flex-col sm:flex-row items-center justify-center space-y-4 sm:space-y-0 sm:space-x-4">
                {/* Separate login button */}
                {!isSignedIn && (
                  <button
                    onClick={handleLoginClick}
                    className="w-full sm:w-auto px-6 py-3 bg-white text-gray-700 rounded-xl font-medium border border-gray-200 hover:border-gray-300 transition-colors duration-300"
                    disabled={loading}
                  >
                    {loading ? 'Processing...' : 'Login'}
                  </button>
                )}

                {/* Main CTA changes text based on sign-in status */}
                <GradientButton
                  onClick={handleGetStarted}
                  className="w-full sm:w-auto"
                  disabled={loading}
                >
                  <span className="flex items-center">
                    {loading
                      ? 'Processing...'
                      : isSignedIn
                      ? 'Continue'
                      : 'Get Started'
                    }
                    <ChevronRight className="ml-2 w-4 h-4" />
                  </span>
                </GradientButton>
              </div>

              {error && <p className="text-red-200 text-sm mt-4">{error}</p>}
            </div>
          </div>
        </div>
        {/* =========================================================== */}

        {/* ======================== FEATURES SECTION ================== */}
        <div id="features" className="py-24 relative">
          <div className="absolute inset-0 bg-gradient-to-b from-white to-gray-50" />
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mb-16">
              <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl">
                Build the Skills That Top Firms Demand
              </h2>
              <p className="mt-4 text-xl text-gray-600">
                Our program covers every angle: from quant drills to behavioral mastery.
              </p>
            </div>
            <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4">
              {features.map((feature, index) => (
                <FeatureCard key={index} {...feature} />
              ))}
            </div>
          </div>
        </div>
        {/* =========================================================== */}

        {/* ======================== TRUSTED BY STUDENTS (MARQUEE) ==== */}
        <section className="py-20 bg-white relative overflow-hidden">
          {/* Add a small <style> block for marquee animation */}
          <style>
            {`
              @keyframes marquee {
                0%   { transform: translateX(0); }
                100% { transform: translateX(-50%); }
              }
            `}
          </style>

          <div className="container mx-auto px-4 text-center">
            <p className="text-gray-600 text-xl font-semibold mb-6 tracking-wide">
              TRUSTED BY STUDENTS FROM
            </p>

            {/* Marquee Outer Container */}
            <div className="overflow-hidden relative w-full">
              {/* The actual “moving” container */}
              <div
                className="flex space-x-12 whitespace-nowrap"
                style={{
                  animation: 'marquee 20s linear infinite',
                }}
              >
                {repeatedSchoolLogos.map((school, i) => (
                  <img
                    key={i}
                    src={school.src}
                    alt={school.name}
                    className="h-8 w-auto opacity-80 hover:opacity-100 transition-opacity duration-300"
                  />
                ))}
              </div>
            </div>
          </div>
        </section>
        {/* =========================================================== */}

        {/* ======================== VIDEO DEMO SECTION =============== */}
        <div className="py-24 bg-white relative">
          <div className="absolute inset-0 bg-gradient-to-br from-indigo-50 via-purple-50 to-pink-50 opacity-50" />
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mb-16">
              <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl">
                See Aurora Prep in Action
              </h2>
              <p className="mt-4 text-xl text-gray-600">
                Watch our platform transform the way you approach consulting interviews.
              </p>
            </div>
            <div className="flex justify-center">
              <div
                className="relative"
                style={{ height: '500px', width: '100%' }}
              >
                <video
                  className="absolute top-0 left-0 w-full h-full"
                  controls
                  poster="/images/poster-image.png"
                >
                  <source src="/images/auroraprep.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        </div>
        {/* =========================================================== */}

        {/* ======================== NOTED SECTION (IF SIGNED IN) ===== */}
          <div id="noted-section" className="py-24 relative bg-gradient-to-br from-white to-gray-100">
            <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              {/* Background Layer for Glassmorphism */}
              <div className="absolute inset-0 bg-gradient-to-r from-indigo-100 to-purple-100 opacity-20 pointer-events-none rounded-2xl" />
              <div className="relative bg-white/70 backdrop-blur-md shadow-xl rounded-2xl p-8 md:p-12">
                {/* Two-column layout */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
                  {/* Left Column: Image or Screenshot */}
                  <div className="flex justify-center">
                    <img
                      src="/images/Noted.png"
                      alt="Noted Integration Preview"
                      className="rounded-xl shadow-lg border border-gray-200"
                    />
                  </div>
                  {/* Right Column: Content & Notes Display */}
                  <div>
                    <h2 className="text-3xl font-bold text-gray-800 mb-4">
                      Seamless Notes with <span className="text-indigo-600">Noted</span>
                    </h2>
                    <p className="text-gray-600 text-lg mb-6">
                      Organize all your insights, frameworks, and interview strategies in one place.
                      Access and update your notes in real time without ever leaving the Aurora Prep platform.
                    </p>

                    {notes && notes.length > 0 ? (
                      <div className="space-y-4 max-h-64 overflow-y-auto pr-2">
                        {notes.map((note, idx) => (
                          <div
                            key={note.id || idx}
                            className="p-4 bg-white rounded-lg shadow-sm border border-gray-100"
                          >
                            <h3 className="text-md font-semibold text-gray-800 mb-1">
                              {note.title || `Note #${idx + 1}`}
                            </h3>
                            <p className="text-gray-600 text-sm">
                              {note.content}
                            </p>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="bg-gradient-to-r from-purple-400 to-purple-600 bg-clip-text text-transparent italic">
                        You don’t have any notes yet. Start practicing to create your first note!
                      </div>
                    )}

                  </div>
                </div>
              </div>
            </div>
          </div>
        {/* =========================================================== */}

        {/* ======================== PRICING SECTION =================== */}
        <div id="pricing" className="py-24 bg-white relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-br from-indigo-50 via-purple-50 to-pink-50 opacity-50" />
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mb-16">
              <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl">
                Get Started Today. Prep faster and perform better in interviews with Aurora Prep.
              </h2>
              <p className="mt-4 text-xl text-gray-600">
                Start free, upgrade when you need more personalized guidance.
              </p>
            </div>

            {/* Toggle for Monthly/Yearly */}
            <div className="flex justify-center mb-10">
              <div className="inline-flex items-center space-x-4">
                <span
                  className={`cursor-pointer font-medium ${
                    billingCycle === 'monthly' ? 'text-indigo-600' : 'text-gray-500'
                  }`}
                  onClick={() => setBillingCycle('monthly')}
                >
                  Monthly
                </span>
                {/* Simple toggle switch (replace with a fancy UI if desired) */}
                <div
                  className="relative inline-block w-12 align-middle select-none transition duration-200 ease-in"
                  onClick={() =>
                    setBillingCycle((prev) => (prev === 'monthly' ? 'annual' : 'monthly'))
                  }
                >
                  <input
                    type="checkbox"
                    name="toggle"
                    id="toggle"
                    checked={billingCycle === 'annual'}
                    onChange={() => {}}
                    className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                    style={{
                      top: '0px',
                      left: billingCycle === 'annual' ? 'calc(100% - 1.5rem)' : '0',
                    }}
                  />
                  <label
                    htmlFor="toggle"
                    className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                  />
                </div>
                <span
                  className={`cursor-pointer font-medium ${
                    billingCycle === 'annual' ? 'text-indigo-600' : 'text-gray-500'
                  }`}
                  onClick={() => setBillingCycle('annual')}
                >
                  Yearly
                </span>
              </div>
            </div>

            <div className="max-w-lg mx-auto">
              <div className="relative bg-white rounded-2xl shadow-xl overflow-hidden border border-gray-100">
                <div className="absolute inset-0 bg-gradient-to-br from-indigo-50 to-purple-50 opacity-50" />
                <div className="relative px-6 py-8">
                  <div className="flex items-center justify-center space-x-2">
                    <h3 className="text-2xl font-semibold text-gray-900">
                      Premium Consulting Prep
                    </h3>
                    <span className="px-3 py-1 text-sm font-medium bg-gradient-to-r from-indigo-500 to-purple-500 text-white rounded-full">
                      Most Popular
                    </span>
                  </div>
                  <div className="mt-6 flex items-baseline justify-center">
                    <span className="text-5xl font-extrabold text-gray-900">
                      ${displayPrice}
                    </span>
                    <span className="ml-2 text-gray-500">{priceSuffix}</span>
                  </div>
                  <ul className="mt-8 space-y-4">
                    <PricingFeature>
                      Free Access to Noted App for Aurora Prep Students
                    </PricingFeature>
                    <PricingFeature>
                      Quantitative Practice &amp; Math Tips
                    </PricingFeature>
                    <PricingFeature>
                      Complete Frameworks &amp; Study Guides
                    </PricingFeature>
                    <PricingFeature>
                      Simulated Case Problems &amp; Detailed Feedback
                    </PricingFeature>
                    <PricingFeature>
                      Unlimited Access to the AI Tutor
                    </PricingFeature>
                    <PricingFeature>
                      Resume &amp; Cover Letter Reviewer
                    </PricingFeature>
                  </ul>
                </div>
                <div className="relative px-6 py-8 bg-gray-50 border-t border-gray-100">
                  <div className="text-center">
                    <p className="text-base font-medium text-indigo-600 mb-4">
                      For Enterprise or University pricing please contact support@auroraprep.com!
                    </p>
                    <GradientButton
                      onClick={handleGetStarted}
                      className="w-full"
                      disabled={loading}
                    >
                      {loading
                        ? 'Processing...'
                        : isSignedIn
                        ? 'Continue'
                        : 'Get Started Now'
                      }
                    </GradientButton>
                    {error && (
                      <p className="text-red-500 text-sm mt-2">{error}</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Custom Sections */}
        <StorySection />
        <ReviewsSection />

        {/* ======================== FINAL CTA SECTION =============== */}
        <div className="relative">
          <div className="absolute inset-0 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500" />
          <div className="relative max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
            <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
              <span className="block mb-1">Ready to Accelerate Your Career?</span>
              <span className="block">Start your free trial today.</span>
            </h2>
            <p className="mt-4 text-lg text-indigo-100">
             Try Today! Cancel anytime.
            </p>

            <GradientButton
              onClick={handleGetStarted}
              className="mt-8 bg-white text-gray-900 px-8 py-3 rounded-xl font-medium hover:bg-gray-50 transition-colors duration-300"
              disabled={loading}
            >
              {loading
                ? 'Processing...'
                : isSignedIn
                ? 'Continue'
                : 'Sign Up Today!'
              }
            </GradientButton>

            {error && (
              <p className="text-red-500 text-sm mt-2">{error}</p>
            )}

            {/* Social Media Icons */}
            <div className="mt-8 flex justify-center space-x-6">
              <a
                href="https://www.linkedin.com/company/aurora-prep/"
                className="text-white hover:text-indigo-100"
              >
                <LinkedinIcon className="w-6 h-6" />
              </a>
              <a
                href="https://x.com/Aurora_Prep"
                className="text-white hover:text-indigo-100"
              >
                <Twitter className="w-6 h-6" />
              </a>
              <a
                href="https://www.instagram.com/auroraprep/"
                className="text-white hover:text-indigo-100"
              >
                <Instagram className="w-6 h-6" />
              </a>
            </div>

            {/* Copyright Notice */}
            <div className="mt-6 text-sm text-white">
              © Aurora Prep 2025. All rights reserved.
            </div>
          </div>
        </div>
      </div>

      {/* ======================== FOOTER WITH LINKS =============== */}
      <footer className="bg-gray-900 py-4">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col sm:flex-row justify-center sm:justify-between items-center text-gray-400 text-sm space-y-3 sm:space-y-0">
          <div className="flex space-x-4">
            {/* Link to Terms of Conditions */}
            <Link to="/terms" className="hover:text-white">
              Terms &amp; Conditions
            </Link>
            {/* Link to Privacy Policy */}
            <Link to="/privacy" className="hover:text-white">
              Privacy Policy
            </Link>
          </div>
          <div>
            <span>© 2025 Aurora Prep</span>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default AuroraPrepConsultingLanding;
