import React, { useState } from 'react';
import { CheckCircle, Menu as MenuIcon, LayoutDashboard } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import LessonNavigation from '../components/LessonNavigation';
import { useAuth } from '@clerk/clerk-react'; // Clerk React import

const ProfitabilityAnalysisPage = () => {
  const [buttonState, setButtonState] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const navigate = useNavigate();

  // If you're using Create React App, put REACT_APP_BACK_END_URL in your .env
  // e.g. REACT_APP_BACK_END_URL=https://your-backend.com
  const url = process.env.REACT_APP_BACK_END_URL;
  console.log('Backend URL =>', `${url}/api/marked-completed`);

  // Grab the Clerk auth helpers
  const { userId, getToken } = useAuth();

  const activeLessonTitle = 'Profitability Analysis';
  const prevLessonPath = '/intro';
  const nextLessonPath = '/swot';

  // Handle the POST request to mark the lesson as completed
  const handleMarkCompleted = async () => {
    try {
      // Retrieve Clerk's session token
      const token = await getToken();
      // Post to your backend with the token if your server needs it
      // (some setups use Bearer tokens in the "Authorization" header)
      const response = await fetch(`${url}/api/marked-completed`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Example of passing the token via an Authorization header:
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          pageTitle: 'Profitability Analysis',
          clerkUserId: userId, // or userId || ''
        }),
      });

      if (!response.ok) {
        throw new Error(`Failed to mark completed: ${response.statusText}`);
      }

      // If the server responds with success, set the button state to "completed"
      setButtonState(true);
      console.log('Lesson successfully marked as completed!');
    } catch (error) {
      console.error('Error marking lesson completed:', error);
      // Handle error or display a message to the user
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex">
      {/* Sidebar */}
      {isSidebarOpen && (
        <Sidebar
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
          activeLessonTitle={activeLessonTitle}
        />
      )}

      {/* Main Content */}
      <div className={`flex-1 transition-all duration-300 ${isSidebarOpen ? 'ml-80' : 'ml-0'}`}>
        {/* Top Navigation Bar */}
        <div className="bg-white border-b sticky top-0 z-40">
          <div className="h-16 flex items-center px-6">
            {/* Toggle Sidebar button (visible only when sidebar is closed) */}
            {!isSidebarOpen && (
              <button
                onClick={() => setIsSidebarOpen(true)}
                className="text-gray-500 hover:text-gray-700 mr-4"
              >
                <MenuIcon className="w-6 h-6" />
              </button>
            )}

            {/* Dashboard button on the far left */}
            <button
              onClick={() => navigate('/main')}
              className="flex items-center space-x-2 px-4 py-2 text-gray-600 hover:text-gray-900 hover:bg-gray-50 rounded-lg transition-colors duration-200"
            >
              <LayoutDashboard className="w-4 h-4" />
              <span>Back to Dashboard</span>
            </button>

            {/* Spacer to push navigation buttons to the right */}
            <div className="flex-1"></div>

            {/* Previous and Next Lesson buttons */}
            <LessonNavigation prevLessonPath={prevLessonPath} nextLessonPath={nextLessonPath} />
          </div>
        </div>

        {/* Page Content */}
        <div className="max-w-4xl mx-auto px-8 py-12">
          {/* Heading and Video Section */}
          {buttonState ? (
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              Demystifying Profitability Cases
              <CheckCircle className="w-6 h-6 text-green-500 inline-block ml-2" />
            </h2>
          ) : (
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              Demystifying Profitability Cases
            </h2>
          )}

          <div className="rounded-xl overflow-hidden shadow-lg mb-6">
            <div className="aspect-w-16 aspect-h-9">
              <video width="100%" height="auto" controls className="object-cover">
                <source src="/images/Profitability.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>

          <p className="text-gray-700 mb-6">Financial Acumen for Aspiring Consultants</p>

          <div className="p-6 rounded-xl mb-12" style={{ backgroundColor: '#F0F4FA' }}>
            <h2 className="text-2xl font-bold mb-4">Profitability Analysis</h2>
            <p className="text-gray-700 mb-4">
              Now, we will dig into the well-known profitability analysis. This is one of the most
              common scenarios presented in case interviews across the industry whether you are
              applying for a role with MBB, the Big Four, or any consulting firm. As a consultant,
              you will be expected to have a strong grasp of what impacts profitability for a
              business - and this may be a surprise for you at this point - but it's not only
              revenue growth. This is the trick you will be expected to fall for, but since you
              have been preparing diligently with Aurora Prep, you'll be well aware of this trap!
            </p>
            <p className="text-gray-700 mb-4">
              While revenues is a large factor for profitability, you will be presented with
              case studies requiring you to analyze various aspects before making a recommendation.
              To do this effectively, efficiently, and cohesively, there is a tried and true framework
              you can always fall back on:
            </p>
            <p className="text-gray-700 mb-4"><strong>Profits = Revenue - Expenses</strong></p>
            <p className="text-gray-700 mb-4">
              Revenues can be broken down into deeper components depending on the industry. The most
              common way to break revenue into components is: Price x Quantity (Volume). This will
              work for many retail and/or industries where goods are sold by varying quantities.
              However, it's important to note that other businesses could have differing revenue
              structures, such as in asset management where firms charge a percentage based on
              assets under management. Many firms though will fit into this basic component structure.
              Take for example, a SaaS company selling their software for a $25 licensing fee per user.
              The revenues there would be derived from doing:
            </p>
            <p className="text-gray-700 mb-4"><strong>Profits = $25 x # Users on Platform</strong></p>
            <p className="text-gray-700 mb-4">
              A key concept to note is that while expenses may seem straightforward, they can often
              become much more complex the further you dig. This is because expenses can be broken
              into more granular components: fixed expenses and variable expenses.
            </p>
            <p className="text-gray-700">
              Fixed expenses are those which typically do not change over a business cycle, such as:
              rent or lease payments, salaries, or loan repayments. Variable expenses/costs on the
              other hand fluctuate as more production or sales are generated. Therefore, as a
              business becomes more productive it may actually be incurring greater costs with
              increased sales. It is important to understand a business's cost structure in order
              to fully analyze how profitability is impacted.
            </p>
          </div>

          <div className="mb-12">
            <h2 className="text-2xl font-bold mb-4">A Practice Case Example with Widgets LLC</h2>
            <p className="text-gray-700 mb-4">
              Let's look at a brief case study example. Widgets LLC's CFO has hired your firm because
              it has been struggling with profitability over the last 3 years and feels that it is
              losing ground to competitors. Widgets has mentioned that their brand loyalty is higher
              than ever and they have increased marketing spend around the holidays by $1 million
              over the last year, so they are unsure why their profits are struggling.
            </p>
            <p className="text-gray-700 mb-4">
              <strong>Aurora Consultant:</strong> Hmm, this is interesting. Do you mind if I ask a few
              clarifying questions?
            </p>
            <p className="text-gray-700 mb-4">
              <strong>Widget's CFO:</strong> Not at all. Ask whatever you need.
            </p>
            <p className="text-gray-700 mb-4">
              <strong>Aurora Consultant:</strong> I understand you have been increasing marketing
              spend. Has that impacted your revenues in a meaningful way?
            </p>
            <p className="text-gray-700 mb-4">
              <strong>Widget's CFO:</strong> Actually it has. That's part of our confusion. Our
              revenues are up an additional 20% from last year to $10 million for the year. However,
              our net profits are down 10%.
            </p>
            <p className="text-gray-700 mb-4">
              <strong>Aurora Consultant:</strong> Okay, do you mind providing me some context around
              the non-marketing expenses for the business? I would like to understand the fixed
              costs for the business.
            </p>
            <p className="text-gray-700 mb-4">
              <strong>Widget's CFO:</strong> Well really our biggest fixed cost is our rental
              property for the manufacturing plant in Ohio. That has not changed at all for the
              past 3 years given we are on a fixed contract for 5 years, and still have 2 years
              remaining. So our spend there is 30% of the overall expenses at $3 million/year.
            </p>
            <p className="text-gray-700 mb-4">
              <strong>Aurora Consultant:</strong> Interesting. Can you explain how variable costs
              work for your business?
            </p>
            <p className="text-gray-700 mb-4">
              <strong>Widget's CFO:</strong> Definitely. Our largest variable costs are our
              contractors. Given the simplicity of our widget construction, we run a small full-time
              shop but ramp up when demand increases by hiring contractors. Our second largest
              expense is aluminum. Most of our components are made of aluminum because of its
              malleability and relative cheapness compared to other metals. It makes up about 40% of
              the COGS for each widget.
            </p>
            <p className="text-gray-700 mb-4">
              <strong>Aurora Consultant:</strong> Can you provide me a break down over the last 5 years
              for your contractor expense, aluminum expense, and revenues over the same period?
            </p>
            <p className="text-gray-700 mb-4">
              <strong>Widget's CFO:</strong> Of course. Here you are...[at this point your
              interviewer will have provided some detailed charts and other resources that are
              important to the case]
            </p>
            <p className="text-gray-700 mb-4">
              <strong>Aurora Consultant:</strong> Okay, after analyzing these figures, I actually found
              that there is a direct trend between your sales and aluminum costs. Whenever your
              sales increase substantially within the holiday seasons, it looks as though you are
              hiring 1/3 more staff, but also your aluminum costs also increased substantially to
              being nearly 60% of the COGS for each product. May I ask, are these contractors
              regulars that are familiar with your products?
            </p>
            <p className="text-gray-700 mb-4">
              <strong>Widget's CFO:</strong> Now that you mention it, we have had issues with our
              contracting company. We frequently have to hire more during the holiday seasons because
              of increased demand, but the factory managers have complained that they take terribly
              long to train and scrap some of our aluminum in the learning process. I would not have
              anticipated they were scrapping a significant amount of material...
            </p>
            <p className="text-gray-700">
              <strong>Aurora Consultant:</strong> I believe the next area worth analyzing would be how
              much aluminum is typically going to waste when you ramp up during the holiday
              seasons... My initial thesis is that the firm supplying your contractors is not doing a
              diligent job of sending you regulars that are familiar with your building process, and
              this skills gap is leading to an excess in wasted aluminum which is increasing your
              expenses and reducing profits when you need efficiency the most in ramping up to meet
              increased sales from your marketing efforts during the holidays.
            </p>
          </div>

          <div className="p-6 rounded-xl mb-12" style={{ backgroundColor: '#F0F4FA' }}>
            <h2 className="text-2xl font-bold mb-4">Final Thoughts</h2>
            <p className="text-gray-700 mb-4">
              Now this was of course a very small and concise example of a potential case study you
              may encounter. However, the way that our consultant handled the case by asking
              detailed questions is what you will want to do. Ensure that you ask for multiple
              years' worth of trends so that you can spot if there are discrepancies or any areas
              that could spark deeper need for analysis.
            </p>
            <p className="text-gray-700 mb-4">
              In the end, in this case, it appeared that the issue was actually not from a lack of
              sales. The business was doing well with revenues up 20% for the year. Their gap
              actually was in an operational inefficiency which was driving up their variable cost
              - meaning that as the company became more successful, they were actually incurring
              more costs from a lack of skilled labor and wasted materials cost.
            </p>
            <p className="text-gray-700">
              This case could have easily also been about a revenue problem, except the CFO queued us
              in that this was an expense issue early on given the firm was seeing a 20% growth in
              revenues as a result of their marketing plan. Be sure to keep an inquisitive mindset
              when you are in the case to help you think about where you should investigate and as
              you dig deeper, you will surely find more clues to the case.
            </p>
          </div>

          {/* Completion Button */}
          <div className="text-center mt-12">
            {buttonState ? (
              <button
                type="button"
                className="px-8 py-3 bg-green-500 text-white rounded-xl shadow-lg flex items-center justify-center space-x-2 mx-auto hover:bg-green-600 transition-colors duration-200"
              >
                <CheckCircle className="w-5 h-5" />
                <span>Completed Session</span>
              </button>
            ) : (
              <button
                type="button"
                onClick={handleMarkCompleted}
                className="px-8 py-3 bg-gradient-to-r from-indigo-500 to-purple-500 text-white rounded-xl shadow-lg flex items-center justify-center space-x-2 mx-auto hover:shadow-xl transition-all duration-200"
              >
                <span>Mark Lesson Completed</span>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfitabilityAnalysisPage;
