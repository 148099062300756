import React, { useState, useEffect } from 'react';

const CombinedSection = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="relative overflow-hidden bg-gradient-to-br from-slate-50 to-white">
      {/* Animated Background Elements */}
      <div 
        className="absolute inset-0 bg-gradient-to-br from-indigo-50/70 via-purple-50/70 to-pink-50/70"
        style={{
          transform: `translateY(${scrollPosition * 0.1}px)`
        }}
      />
      <div 
        className="absolute top-0 right-0 w-96 h-96 bg-purple-100/30 rounded-full blur-3xl -z-10"
        style={{
          transform: `translate(${50 + scrollPosition * 0.05}%, ${-50 - scrollPosition * 0.05}%)`
        }}
      />
      <div 
        className="absolute bottom-0 left-0 w-96 h-96 bg-indigo-100/30 rounded-full blur-3xl -z-10"
        style={{
          transform: `translate(${-50 - scrollPosition * 0.05}%, ${50 + scrollPosition * 0.05}%)`
        }}
      />

      {/* Advisor Section */}
      <section className="py-24 relative">
        <div className="container mx-auto px-4">
          <div className="relative max-w-7xl mx-auto">
            <div className="flex flex-col lg:flex-row gap-16 items-start">
              {/* Left Column - Title and Quote */}
              {/* Removed lg:sticky to avoid layout issues */}
              <div className="lg:w-1/2 pt-10">
                <h2
                  className="text-6xl lg:text-7xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-indigo-600 to-purple-600 mb-8"
                >
                  Our Advisor
                </h2>
                <div className="mt-8 border-l-4 border-indigo-400 pl-6">
                  <p className="text-2xl text-gray-700 italic font-light">
                    "We’re looking forward to having Natalie as part of the Aurora Prep team as we continue to make our platform the go-to choice for aspiring consultants."
                  </p>
                </div>
                
                <div className="mt-12 p-6 bg-white/80 backdrop-blur-sm rounded-2xl shadow-lg">
                  <img
                    src="./images/CorpNataliexAuroraPrep.png"
                    alt="Corporate Natalie - Aurora Prep Advisor"
                    className="rounded-xl shadow-lg w-full h-auto object-cover mb-6 transform transition-transform hover:scale-105"
                  />
                  <h3 className="text-xl font-medium text-gray-900">Corporate Natalie</h3>
                  <p className="text-indigo-600 font-medium">Strategic Advisor for Aurora Prep</p>
                </div>
              </div>

              {/* Right Column - Content */}
              <div className="lg:w-1/2 space-y-16" style={{ perspective: '1000px' }}>
                {/* Introduction */}
                <div 
                  className="bg-white/80 backdrop-blur-sm p-8 rounded-3xl shadow-xl"
                  style={{
                    transform: `translateY(${scrollPosition * 0.1}px) rotateY(${scrollPosition * 0.02}deg)`,
                    opacity: Math.min(1, 1 - (scrollPosition * 0.001))
                  }}
                >
                  <h3 className="text-xl font-semibold text-gray-900 mb-4">Our Partnership</h3>
                  <p className="text-xl font-light text-gray-800 leading-relaxed">
                  We have teamed up with one of our favorite influencers, Corporate Natalie. As a former Deloitte consultant and respected thought leader, Natalie serves as a Strategic Advisor to our team, helping us transform how students at all levels prepare for interviews.
                  </p>
                </div>

                {/* Impact */}
                <div className="relative">
                  <div className="absolute left-0 w-1 h-full bg-gradient-to-b from-indigo-400 to-purple-400 rounded-full" />
                  <div className="pl-8">
                    <h3 className="text-xl font-semibold text-gray-900 mb-4">Our Partnership</h3>
                    <p className="text-lg text-gray-700 leading-relaxed">
                    We have teamed up with one of our favorite influencers, Corporate Natalie. As a former Deloitte consultant and respected thought leader, Natalie serves as a Strategic Advisor to our team, helping us transform how students at all levels prepare for interviews.
                    </p>
                  </div>
                </div>

                {/* Vision */}
                <div className="relative overflow-hidden rounded-3xl">
                  <div className="absolute inset-0 bg-indigo-600 opacity-90" />
                  <div className="relative p-8">
                    <h3 className="text-xl font-semibold text-white mb-4">Our Goal</h3>
                    <p className="text-lg text-white leading-relaxed">
                      Together with Natalie, we're building a future where quality interview preparation 
                      is accessible to everyone. Her guidance helps ensure our platform meets the real 
                      needs of aspiring consultants.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Story Section */}
      <section className="relative py-20">
  {/* Decorative background elements */}
  <div className="absolute inset-0 bg-gradient-to-br from-purple-50 to-pink-50 opacity-50" />
  <div className="absolute right-0 top-20 w-1/3 h-96 bg-purple-200/20 blur-3xl rounded-full" />
  <div className="absolute left-0 bottom-20 w-1/3 h-96 bg-pink-200/20 blur-3xl rounded-full" />

  <div className="container mx-auto px-4">
    <div className="relative max-w-7xl mx-auto">
      <div className="flex flex-col lg:flex-row gap-16 items-start">
        {/* Left Column - Title and Quote */}
        <div className="lg:w-1/2 pt-10">
          <h2 className="text-6xl lg:text-7xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-pink-600 mb-8">
            Our Story
          </h2>
          <div className="mt-8 border-l-4 border-purple-400 pl-6">
            <p className="text-2xl text-gray-700 italic font-light">
              "Transforming consulting interview preparation through innovation and accessibility."
            </p>
          </div>
          
          {/* Updated Image Block */}
          <div className="mt-12 flex items-center">
            <img
              src="./images/syd_p.png"
              alt="Sydney Henriques"
              className="w-28 h-28 rounded-full object-cover border-2 border-purple-300 shadow-lg hover:shadow-xl transition-shadow duration-300"
            />
            <div className="ml-4">
              <h3 className="text-2xl font-semibold text-gray-900">Sydney Henriques</h3>
              <p className="text-purple-600 font-medium">CEO & Co-Founder</p>
            </div>
          </div>
        </div>

        {/* Right Column - Story Content */}
        <div className="lg:w-1/2 space-y-16">
          {/* Mission Statement */}
          <div className="bg-white/80 backdrop-blur-sm p-8 rounded-3xl shadow-xl">
            <p className="text-2xl font-light text-gray-800 leading-relaxed">
              At Aurora Prep, we're redefining consulting interview preparation. As consultants ourselves, 
              we understand the need for affordable and effective resources.
            </p>
          </div>

          {/* Journey & Mission Combined */}
          <div className="relative">
            <div className="absolute left-0 w-1 h-full bg-gradient-to-b from-purple-400 to-pink-400 rounded-full" />
            <div className="pl-8">
              <h3 className="text-xl font-semibold text-gray-900 mb-4">Our Journey & Mission</h3>
              <p className="text-lg text-gray-700 leading-relaxed">
              We built Aurora Prep by combining industry expertise with cutting-edge technology to revolutionize interview preparation. Our mission is to make it accessible, affordable, and highly effective—cutting preparation time from 80–100 hours to just 40 through interactive learning and real-time feedback, empowering you to excel and stand out.
              </p>
            </div>
          </div>

          {/* Vision */}
          <div className="relative overflow-hidden rounded-3xl">
            <div className="absolute inset-0 bg-purple-600 opacity-90" />
            <div className="relative p-8">
              <h3 className="text-xl font-semibold text-white mb-4">Our Vision</h3>
              <p className="text-lg text-white leading-relaxed">
              Whether your goal is to join a top-tier global consulting firm or a small boutique, Aurora Prep was made to be the differentiator for you in interviews. We'll be with you every step of the way.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

    </div>
  );
};

export default CombinedSection;
