import React, { useState } from 'react';
import { 
  MenuIcon,
  X,
  ChevronRight, 
  CheckCircle,
  Lock,
  ArrowLeft,
  ArrowRight,
  BookOpen,
  LayoutDashboard
} from 'lucide-react';

const LessonPage = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const lessons = [
    { title: "Introduction", isCompleted: true, isActive: false },
    { title: "Profitability Analysis", isCompleted: false, isActive: true },
    { title: "SWOT Analysis", isCompleted: false, isLocked: false },
    { title: "The 3 C's Model", isCompleted: false, isLocked: true },
    { title: "4 Ps of Marketing", isCompleted: false, isLocked: true },
    { title: "Market Sizing", isCompleted: false, isLocked: true },
    { title: "Porter's 5 Forces", isCompleted: false, isLocked: true },
    { title: "Growth Strategy", isCompleted: false, isLocked: true },
    { title: "Revenue / Cost Synergy", isCompleted: false, isLocked: true },
    { title: "Break-Even Analysis", isCompleted: false, isLocked: true },
    { title: "BCG Matrix", isCompleted: false, isLocked: true }
  ];

  return (
    <div className="min-h-screen bg-gray-50 flex">
      {/* Sidebar */}
      <div className={`fixed inset-y-0 left-0 z-50 bg-white w-80 transform transition-transform duration-300 ease-in-out ${
        isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
      }`}>
        <div className="h-full flex flex-col">
          <div className="h-16 p-4 flex items-center justify-between">
            <h2 className="font-semibold bg-gradient-to-r from-indigo-500 to-purple-500 text-transparent bg-clip-text">
              CAST Framework
            </h2>
            <button onClick={() => setIsSidebarOpen(false)} className="text-gray-500 hover:text-gray-700">
              <X className="w-5 h-5" />
            </button>
          </div>
          <div className="border-b"></div>
          <div className="flex-1 overflow-y-auto p-4 space-y-2">
            {lessons.map((lesson, index) => (
              <div key={index} className={`p-3 rounded-lg transition-all duration-200 ${
                lesson.isActive ? 'bg-gradient-to-r from-indigo-500 to-purple-500 text-white' :
                lesson.isLocked ? 'text-gray-400' : 'hover:bg-gray-50 text-gray-700'
              }`}>
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-3">
                    {lesson.isCompleted ? (
                      <CheckCircle className="w-5 h-5 text-green-500" />
                    ) : lesson.isLocked ? (
                      <Lock className="w-5 h-5" />
                    ) : (
                      <BookOpen className="w-5 h-5" />
                    )}
                    <span className="font-medium">{lesson.title}</span>
                  </div>
                  {lesson.isActive && <ChevronRight className="w-5 h-5" />}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className={`flex-1 transition-all duration-300 ${isSidebarOpen ? 'ml-80' : 'ml-0'}`}>
        {/* Top Navigation */}
        <div className="bg-white border-b sticky top-0 z-40">
          <div className="h-16 flex items-center justify-between px-6">
            <div className="flex items-center space-x-4">
              {!isSidebarOpen && (
                <button onClick={() => setIsSidebarOpen(true)} className="text-gray-500 hover:text-gray-700">
                  <MenuIcon className="w-6 h-6" />
                </button>
              )}
              <button 
                onClick={() => console.log('Navigate to dashboard')} 
                className="flex items-center space-x-2 px-4 py-2 text-gray-600 hover:text-gray-900 hover:bg-gray-50 rounded-lg transition-colors duration-200"
              >
                <LayoutDashboard className="w-4 h-4" />
                <span>Back to Dashboard</span>
              </button>
            </div>
            <div className="flex items-center space-x-4">
              <button className="flex items-center space-x-2 px-4 py-2 text-gray-600 hover:text-gray-900">
                <ArrowLeft className="w-4 h-4" />
                <span>Previous</span>
              </button>
              <button className="flex items-center space-x-2 px-4 py-2 bg-gradient-to-r from-indigo-500 to-purple-500 text-white rounded-lg hover:shadow-lg transition-all duration-200">
                <span>Next Lesson</span>
                <ArrowRight className="w-4 h-4" />
              </button>
            </div>
          </div>
        </div>

        {/* Lesson Content */}
        <div className="max-w-4xl mx-auto px-8 py-12">
          <h1 className="text-3xl font-bold bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-transparent bg-clip-text mb-6">
            Profitability Analysis
          </h1>
          
          <div className="prose max-w-none">
            <p className="text-lg text-gray-600 mb-6">
              Profitability analysis is a crucial framework used in consulting to evaluate a company's financial health and performance. In this lesson, we'll explore the key components and methodologies used to assess profitability.
            </p>
            
            <h2 className="text-xl font-semibold text-gray-900 mb-4">Key Components of Profitability Analysis</h2>
            
            <div className="bg-white rounded-xl p-6 shadow-sm mb-6">
              <ul className="space-y-4">
                <li className="flex items-start space-x-3">
                  <div className="p-1 bg-indigo-100 rounded-full mt-1">
                    <div className="w-2 h-2 rounded-full bg-indigo-500"></div>
                  </div>
                  <span className="text-gray-700">Revenue Analysis</span>
                </li>
                <li className="flex items-start space-x-3">
                  <div className="p-1 bg-indigo-100 rounded-full mt-1">
                    <div className="w-2 h-2 rounded-full bg-indigo-500"></div>
                  </div>
                  <span className="text-gray-700">Cost Structure Evaluation</span>
                </li>
                <li className="flex items-start space-x-3">
                  <div className="p-1 bg-indigo-100 rounded-full mt-1">
                    <div className="w-2 h-2 rounded-full bg-indigo-500"></div>
                  </div>
                  <span className="text-gray-700">Margin Assessment</span>
                </li>
              </ul>
            </div>

            <p className="text-gray-600 mb-6">
              We'll dive deep into each of these components and learn how to apply them in real consulting scenarios.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LessonPage;