import React, { useState } from 'react';
import { CheckCircle, Menu as MenuIcon, LayoutDashboard } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import LessonNavigation from '../components/LessonNavigation';
import { useAuth } from '@clerk/clerk-react'; // <-- Clerk React import

const GrowthStrategyPage = () => {
  const [buttonState, setButtonState] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const navigate = useNavigate();

  // If you're using Create React App, put REACT_APP_BACK_END_URL in your .env
  // e.g. REACT_APP_BACK_END_URL=https://your-backend.com
  const url = process.env.REACT_APP_BACK_END_URL;

  // Clerk auth helpers (if your backend requires a token)
  const { userId, getToken } = useAuth();

  const activeLessonTitle = "Growth Strategy";
  const prevLessonPath = "/porters"; // Adjust as needed
  const nextLessonPath = "/revcost"; // Adjust as needed

  // Handle the POST request to mark the lesson as completed
  const handleMarkCompleted = async () => {
    try {
      // Retrieve Clerk's session token if your server needs it
      const token = await getToken();

      // Post to your backend
      const response = await fetch(`${url}/api/marked-completed`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // if required
        },
        body: JSON.stringify({
          pageTitle: 'Growth Strategy', // Must match the lesson's title in your Sidebar
          clerkUserId: userId,
        }),
      });

      if (!response.ok) {
        throw new Error(`Failed to mark completed: ${response.statusText}`);
      }

      // If the server responds with success, update button state
      setButtonState(true);
      console.log('Lesson successfully marked as completed!');
    } catch (error) {
      console.error('Error marking lesson completed:', error);
      // Optionally show an error message here
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex">
      {/* Sidebar */}
      {isSidebarOpen && (
        <Sidebar 
          isSidebarOpen={isSidebarOpen} 
          setIsSidebarOpen={setIsSidebarOpen} 
          activeLessonTitle={activeLessonTitle}
        />
      )}

      <div className={`flex-1 transition-all duration-300 ${isSidebarOpen ? 'ml-80' : 'ml-0'}`}>
        {/* Top Navigation */}
        <div className="bg-white border-b sticky top-0 z-40">
          <div className="h-16 flex items-center px-6">
            {!isSidebarOpen && (
              <button 
                onClick={() => setIsSidebarOpen(true)} 
                className="text-gray-500 hover:text-gray-700 mr-4"
              >
                <MenuIcon className="w-6 h-6" />
              </button>
            )}

            <button 
              onClick={() => navigate('/main')}
              className="flex items-center space-x-2 px-4 py-2 text-gray-600 hover:text-gray-900 hover:bg-gray-50 rounded-lg transition-colors duration-200"
            >
              <LayoutDashboard className="w-4 h-4" />
              <span>Back to Dashboard</span>
            </button>

            <div className="flex-1"></div>

            <LessonNavigation
              prevLessonPath={prevLessonPath}
              nextLessonPath={nextLessonPath}
            />
          </div>
        </div>

        {/* Page Content */}
        <div className="max-w-4xl mx-auto px-8 py-12">
          {buttonState ? (
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              Growth Strategy Framework
              <CheckCircle className="w-6 h-6 text-green-500 inline-block ml-2" />
            </h2>
          ) : (
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              Growth Strategy Framework
            </h2>
          )}

          <div className="rounded-xl overflow-hidden shadow-lg mb-6">
            <div className="aspect-w-16 aspect-h-9">
              <video width="100%" height="auto" controls className="object-cover">
                <source src="/images/growth.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>

          <div className="p-6 rounded-xl mb-12" style={{ backgroundColor: '#F0F4FA' }}>
            <h3 className="text-2xl font-bold mb-4">Identifying and Acing the Growth Strategy Case Interview</h3>
            <p className="text-gray-700 mb-4">
              Growth strategy cases challenge your ability to analyze a company's current situation and develop 
              strategies for sustainable growth. This often involves using multiple frameworks we've discussed so far.
            </p>
            <br />
            <h3 className="text-xl font-bold mb-4">Understanding the Objective of the Growth Strategy Case:</h3>
            <p className="text-gray-700 mb-4">
              Growth strategy cases often involve a company looking to expand, enter new markets, or increase market 
              share. Your task is to identify growth opportunities and propose a feasible strategy.
            </p>
            <ul className="list-disc list-inside mb-4 text-gray-700 pl-4">
              <li><strong>Market Sizing:</strong> Estimate market potential.</li>
              <li><strong>Competitive Landscape:</strong> Analyze competitors' strengths and weaknesses.</li>
              <li><strong>Customer Segmentation:</strong> Understand different segments and their needs.</li>
              <li><strong>Value Proposition:</strong> Articulate the unique benefits the company offers.</li>
              <li><strong>Profitability:</strong> Assess financial viability of the growth strategy.</li>
            </ul>
            <p className="text-gray-700 mb-4">
              Not all areas require deep analysis; focus on what's most relevant based on the initial information and 
              clarifying questions. For example, if the firm struggles against competitors, investigate why and adapt 
              your approach accordingly.
            </p>
            <p className="text-gray-700 mb-4">
              By clarifying the value proposition, understanding the competitive landscape, and identifying the right 
              customer segments, you can pinpoint growth opportunities. You might use Porter's Five Forces or other 
              frameworks to structure your analysis.
            </p>
            <p className="text-gray-700 mb-4">
              Below is a five-step approach to guide you at the start of the case, helping you feel confident and 
              structured from the get-go.
            </p>
          </div>

          <div className="mb-12">
            <h3 className="text-2xl font-bold mb-4">5-Step Problem Solving Approach:</h3>
            <ol className="list-decimal list-inside mb-4 text-gray-700 pl-4">
              <li><strong>Clarify the Objective:</strong> Understand the company's goals and the specific question.</li>
              <li><strong>Identify Key Drivers:</strong> Determine factors that drive growth, like market trends or technology.</li>
              <li><strong>Develop a Framework:</strong> Structure your analysis with elements like market attractiveness, competitive advantage, and internal capabilities.</li>
              <li><strong>Analyze and Evaluate:</strong> Use your framework to assess growth options qualitatively and quantitatively.</li>
              <li><strong>Recommend and Justify:</strong> Present a growth strategy backed by data-driven insights.</li>
            </ol>
            <p className="text-gray-700 mb-4">
              Additionally, remember to think out loud, show confidence, and build rapport with the interviewer.
            </p>
          </div>

          <div className="p-6 rounded-xl mb-12" style={{ backgroundColor: '#F0F4FA' }}>
            <h3 className="text-2xl font-bold mb-4">Behavioral Tips for Acing the Interview:</h3>
            <ul className="list-disc list-inside mb-4 text-gray-700 pl-4">
              <li><strong>Think Out Loud:</strong> Explain your assumptions and reasoning.</li>
              <li><strong>Be Creative Yet Practical:</strong> Offer innovative, feasible solutions.</li>
              <li><strong>Prioritize and Focus:</strong> Identify the most critical issues first.</li>
              <li><strong>Engage with the Interviewer:</strong> Ask clarifying questions and treat it as a collaborative exercise.</li>
              <li><strong>Practice:</strong> Familiarize yourself with growth strategy frameworks and practice case interviews.</li>
            </ul>
            <br />
            <h3 className="text-xl font-bold mb-4">Sample Growth Strategy Frameworks:</h3>
            <ul className="list-disc list-inside mb-4 text-gray-700 pl-4">
              <li><strong>Ansoff Matrix:</strong> Market penetration, product development, market development, diversification.</li>
              <li><strong>Porter's Five Forces:</strong> Assess industry competitiveness and identify growth areas.</li>
              <li><strong>Blue Ocean Strategy:</strong> Find uncontested markets and create new demand.</li>
              <li><strong>Value Chain Analysis:</strong> Identify growth opportunities along the company's value chain.</li>
            </ul>
            <p className="text-gray-700 mb-4">
              Let's consider an example dialogue to illustrate how to handle a growth strategy case.
            </p>
          </div>

          <div className="p-6 rounded-xl mb-12" style={{ backgroundColor: '#F0F4FA' }}>
            <h3 className="text-2xl font-bold mb-4">Example Case</h3>
            <p className="text-gray-700 mb-4">
              Fit & Fast, a workout equipment manufacturer, faces stagnant growth. The CEO wants to boost revenue and 
              market share. They focus on high-end home gym equipment in the US and need a growth strategy.
            </p>
            <p className="text-gray-700 mb-4"><strong>Interviewer:</strong> How would you approach this growth strategy case?</p>
            <p className="text-gray-700 mb-4"><strong>Aurora Prep Consultant:</strong> First, clarify the objective—20% revenue growth over 3 years. I'd then explore market opportunities, competitive landscape, and internal capabilities.</p>
            <p className="text-gray-700 mb-4">
              Assess market opportunities (segments, untapped customers), understand why they're struggling against 
              competitors, and refine the value proposition. For competitors, identify their positioning and what 
              differentiates Fit & Fast.
            </p>
            <p className="text-gray-700 mb-4">
              After analyzing trends and consumer data, propose a three-pronged strategy:  
            </p>
            <ol className="list-decimal list-inside mb-4 text-gray-700 pl-4">
              <li><strong>Consumer Segmentation Focus:</strong> Target the most successful age range with tailored marketing.</li>
              <li><strong>Product Strategy:</strong> Offer bundles and leverage economies of scale.</li>
              <li><strong>Partnership:</strong> Collaborate with well-known at-home fitness brands for co-branded products.</li>
            </ol>
            <p className="text-gray-700 mb-4">
              Ensure profitability via financial analysis, optimizing costs, and testing marketing strategies.
            </p>
            <p className="text-gray-700">
              <strong>Interviewer:</strong> Excellent. This approach is structured, and you've considered viability and profitability.
            </p>
          </div>

          <div className="mb-12">
            <h3 className="text-2xl font-bold mb-4">Conclusion</h3>
            <p className="text-gray-700 mb-4">
              Mastering growth strategy cases involves analytical skills, business sense, and structured problem-solving. 
              Understand key concepts, apply frameworks, and practice regularly. Stay confident, think creatively, and 
              communicate effectively to impress your interviewer and move closer to your dream consulting role.
            </p>
          </div>

          {/* Completion Button */}
          <div className="text-center mt-12">
            {buttonState ? (
              <button
                type="button"
                className="px-8 py-3 bg-green-500 text-white rounded-xl shadow-lg flex items-center justify-center space-x-2 mx-auto hover:bg-green-600 transition-colors duration-200"
              >
                <CheckCircle className="w-5 h-5" />
                <span>Completed Session</span>
              </button>
            ) : (
              <button
                type="button"
                onClick={handleMarkCompleted}
                className="px-8 py-3 bg-gradient-to-r from-indigo-500 to-purple-500 text-white rounded-xl shadow-lg flex items-center justify-center space-x-2 mx-auto hover:shadow-xl transition-all duration-200"
              >
                <span>Mark Lesson Completed</span>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GrowthStrategyPage;
