import React, { useState } from 'react';
import { CheckCircle, Menu as MenuIcon, LayoutDashboard } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import LessonNavigation from '../components/LessonNavigation';
import { useAuth } from '@clerk/clerk-react'; // <-- Clerk React import

const BreakEvenAnalysisPage = () => {
  const [buttonState, setButtonState] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const navigate = useNavigate();

  // If you're using Create React App, define REACT_APP_BACK_END_URL in your .env
  // e.g. REACT_APP_BACK_END_URL=https://your-backend.com
  const url = process.env.REACT_APP_BACK_END_URL;

  // Clerk auth helpers (if your backend requires a token)
  const { userId, getToken } = useAuth();

  const activeLessonTitle = 'Break-Even Analysis';
  const prevLessonPath = '/revcost'; // Adjust as needed
  const nextLessonPath = '/bcg';     // Adjust as needed

  // Handle the POST request to mark this lesson as completed
  const handleMarkCompleted = async () => {
    try {
      // Retrieve Clerk's session token if required by your backend
      const token = await getToken();

      // Make the POST request to your backend
      const response = await fetch(`${url}/api/marked-completed`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // if your server requires Bearer token
        },
        body: JSON.stringify({
          pageTitle: 'Break-Even Analysis', // Must match what's in your lessons array (sidebar)
          clerkUserId: userId,
        }),
      });

      if (!response.ok) {
        throw new Error(`Failed to mark completed: ${response.statusText}`);
      }

      // If the server responds with success, show the "Completed Session" button
      setButtonState(true);
      console.log('Lesson successfully marked as completed!');
    } catch (error) {
      console.error('Error marking lesson completed:', error);
      // Optionally handle or display an error message here
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex">
      {/* Sidebar */}
      {isSidebarOpen && (
        <Sidebar
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
          activeLessonTitle={activeLessonTitle}
        />
      )}

      <div className={`flex-1 transition-all duration-300 ${isSidebarOpen ? 'ml-80' : 'ml-0'}`}>
        {/* Top Navigation */}
        <div className="bg-white border-b sticky top-0 z-40">
          <div className="h-16 flex items-center px-6">
            {!isSidebarOpen && (
              <button
                onClick={() => setIsSidebarOpen(true)}
                className="text-gray-500 hover:text-gray-700 mr-4"
              >
                <MenuIcon className="w-6 h-6" />
              </button>
            )}

            <button
              onClick={() => navigate('/main')}
              className="flex items-center space-x-2 px-4 py-2 text-gray-600 hover:text-gray-900 hover:bg-gray-50 rounded-lg transition-colors duration-200"
            >
              <LayoutDashboard className="w-4 h-4" />
              <span>Back to Dashboard</span>
            </button>

            <div className="flex-1"></div>

            <LessonNavigation
              prevLessonPath={prevLessonPath}
              nextLessonPath={nextLessonPath}
            />
          </div>
        </div>

        {/* Page Content */}
        <div className="max-w-4xl mx-auto px-8 py-12">
          {buttonState ? (
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              Break-Even Analysis
              <CheckCircle className="w-6 h-6 text-green-500 inline-block ml-2" />
            </h2>
          ) : (
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              Break-Even Analysis
            </h2>
          )}

          <div className="rounded-xl overflow-hidden shadow-lg mb-6">
            <div className="aspect-w-16 aspect-h-9">
              <video width="100%" height="auto" controls className="object-cover">
                <source src="/images/breakeven.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>

          <div className="p-6 rounded-xl mb-12 bg-[#F0F4FA]">
            <p className="text-gray-700 mb-4">
              Break-even analysis determines the point at which a company's revenue equals its total costs, 
              resulting in neither profit nor loss. In consulting case interviews, you may be asked to 
              perform this analysis. Follow these steps and examples to confidently handle break-even scenarios.
            </p>
          </div>

          <h3 className="text-2xl font-bold mb-4">Step 1: Understand the problem and gather relevant information</h3>
          <p className="text-gray-700 mb-4">
            Clarify the problem and identify data like fixed costs, variable costs, and selling price per unit.
          </p>
          <strong>Example:</strong>
          <p className="text-gray-700 mb-4">
            A smartphone manufacturer’s fixed costs: $500,000. Variable cost/unit: $200. Selling price/unit: $600. 
            Find the break-even quantity.
          </p>

          <div className="p-6 rounded-xl mb-12 bg-[#F0F4FA]">
            <h3 className="text-2xl font-bold mb-4">Step 2: Identify key components of the breakeven formula</h3>
            <ul className="list-disc list-inside mb-4 text-gray-700 pl-4">
              <li><strong>Fixed costs:</strong> constant costs (rent, salaries)</li>
              <li><strong>Variable costs:</strong> costs per unit (materials, labor)</li>
              <li><strong>Contribution margin per unit:</strong> selling price - variable cost</li>
            </ul>
          </div>

          <h3 className="text-2xl font-bold mb-4">Step 3: Calculate the contribution margin per unit</h3>
          <p className="text-gray-700 mb-4">
            Contribution margin = $600 - $200 = $400/unit
          </p>

          <div className="p-6 rounded-xl mb-12 bg-[#F0F4FA]">
            <h3 className="text-2xl font-bold mb-4">Step 4: Apply the breakeven formula</h3>
            <p className="text-gray-700 mb-4">
              Breakeven quantity = Fixed costs ÷ Contribution margin/unit = $500,000 ÷ $400 = 1,250 units.
            </p>
            <p className="text-gray-700 mb-4">
              Thus, sell 1,250 units to break even.
            </p>
          </div>

          <h3 className="text-2xl font-bold mb-4">Step 5: Interpret results and provide insights</h3>
          <p className="text-gray-700 mb-4">
            With a 1,250-unit breakeven point, consider market demand, competition, and capacity. Suggest 
            strategies (marketing, cost reduction) to improve margins and profitability.
          </p>

          <div className="p-6 rounded-xl mb-12 bg-[#F0F4FA]">
            <h3 className="text-2xl font-bold mb-4">Step 6: Consider scenarios and sensitivity analysis</h3>
            <p className="text-gray-700 mb-4">
              Adjust variables to see their impact:
            </p>
            <p className="text-gray-700 mb-4">
              If selling price = $700, breakeven = $500,000 ÷ ($700 - $200) = 1,000 units.
            </p>
            <p className="text-gray-700 mb-4">
              If variable cost = $180, breakeven = $500,000 ÷ ($600 - $180) = 1,190 units.
            </p>
            <p className="text-gray-700 mb-4">
              This shows how pricing or cost changes affect the breakeven point.
            </p>
          </div>

          <h2 className="text-2xl font-bold mb-4">Conclusion</h2>
          <p className="text-gray-700 mb-4">
            Mastering break-even analysis is crucial for case interviews and real business scenarios. 
            By following these steps, practicing different assumptions, and communicating clearly, 
            you'll impress interviewers and provide actionable recommendations.
          </p>

          <div className="text-center mt-12">
            {buttonState ? (
              <button
                type="button"
                className="px-8 py-3 bg-green-500 text-white rounded-xl shadow-lg flex items-center justify-center space-x-2 mx-auto hover:bg-green-600 transition-colors duration-200"
              >
                <CheckCircle className="w-5 h-5" />
                <span>Completed Session</span>
              </button>
            ) : (
              <button
                type="button"
                onClick={handleMarkCompleted}
                className="px-8 py-3 bg-gradient-to-r from-indigo-500 to-purple-500 text-white rounded-xl shadow-lg flex items-center justify-center space-x-2 mx-auto hover:shadow-xl transition-all duration-200"
              >
                <span>Mark Lesson Completed</span>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BreakEvenAnalysisPage;
