import React, { useEffect, useState } from 'react';
import { X, Check } from 'lucide-react';
import LessonsList from './LessonList';
// Optionally, if you need Clerk auth token for the GET request, import useAuth
import { useAuth } from '@clerk/clerk-react'; 

const Sidebar = ({ isSidebarOpen, setIsSidebarOpen, activeLessonTitle }) => {
  const [completedTitles, setCompletedTitles] = useState([]);
  
  // If your server needs an auth token, you can retrieve it from Clerk:
  const { getToken } = useAuth();

  const url = process.env.REACT_APP_BACK_END_URL;

  // All the lessons in your sidebar
  const lessons = [
    { title: "Introduction", path: "/intro" },
    { title: "Profitability Analysis", path: "/profit" },
    { title: "SWOT Analysis", path: "/swot" },
    { title: "The 3 C's Model", path: "/3cs" },
    { title: "4 Ps of Marketing", path: "/4ps" },
    { title: "Market Sizing", path: "/market" },
    { title: "Porter's 5 Forces", path: "/porters" },
    { title: "Growth Strategy", path: "/growth" },
    { title: "Revenue / Cost Synergy", path: "/revcost" },
    { title: "Break-Even Analysis", path: "/break-even" },
    { title: "BCG Matrix", path: "/bcg" }
  ];

  // 1) On component mount, fetch the user’s completed lessons
  useEffect(() => {
    const fetchCompletedLessons = async () => {
      try {
        // If your server expects an auth token in the Authorization header:
        const token = await getToken();
        console.log('Token:', token);

        console.log("url: " + `${url}/api/marked-completed`)

        // Make the GET request to your server
        const response = await fetch(`${url}/api/marked-completed`, {
          method: 'GET',
          // If needed for secured endpoints:
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch completed lessons');
        }

        const data = await response.json(); // { completedTitles: ["Introduction", ...] }
        if (data.completedTitles) {
          setCompletedTitles(data.completedTitles);
        }
      } catch (err) {
        console.error('Error fetching completed sessions:', err);
      }
    };

    fetchCompletedLessons();
  }, []);

  // 2) Mark the active lesson & completed lessons
  const updatedLessons = lessons.map(lesson => ({
    ...lesson,
    isActive: lesson.title === activeLessonTitle,
    // isCompleted if the user has completed that lesson
    isCompleted: completedTitles.includes(lesson.title),
  }));

  return (
    <div 
      className={`fixed inset-y-0 left-0 z-50 bg-white w-80 transform transition-transform duration-300 ease-in-out ${
        isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
      }`}
    >
      <div className="h-full flex flex-col">
        <div className="h-16 p-4 flex items-center justify-between">
          <h2 className="font-semibold bg-gradient-to-r from-indigo-500 to-purple-500 text-transparent bg-clip-text">
            Strategy Framework
          </h2>
          <button 
            onClick={() => setIsSidebarOpen(false)} 
            className="text-gray-500 hover:text-gray-700"
          >
            <X className="w-5 h-5" />
          </button>
        </div>
        <div className="border-b"></div>
        {/* Render your updated lessons (with isCompleted) */}
        <LessonsList lessons={updatedLessons} />
      </div>
    </div>
  );
};

export default Sidebar;
