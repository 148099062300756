import React, { useState } from 'react';
import {
  ChevronDown,
  ChevronUp,
  Calculator,
  Scale,
  Split,
  Repeat,
  PieChart,
  CheckCircle
} from 'lucide-react';
import Navbar from '../components/Navbar';
import MathSideBar from '../components/MathSideBar';

const EstimationGuide = () => {
  const [openSections, setOpenSections] = useState({
    rounding: true,
    bracketing: false,
    breakdown: false,
    analogies: false,
    pareto: false,
    sanity: false,
  });

  const toggleSection = (section) => {
    setOpenSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const Section = ({ title, children, isOpen, onToggle, icon: Icon }) => (
    <div className="mb-8 rounded-lg bg-white shadow-lg overflow-hidden">
      <button
        onClick={onToggle}
        className="w-full px-6 py-4 flex items-center justify-between bg-gradient-to-r from-violet-600 to-pink-500 text-white"
      >
        <div className="flex items-center gap-3">
          <Icon size={24} />
          <h2 className="text-xl font-semibold">{title}</h2>
        </div>
        {isOpen ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
      </button>
      {isOpen && (
        <div className="p-6 bg-violet-50">
          {children}
        </div>
      )}
    </div>
  );

  const Example = ({ title, children }) => (
    <div className="mt-4 p-4 bg-white rounded-lg shadow-sm border border-violet-100">
      <h4 className="font-semibold text-lg mb-2">{title}</h4>
      {children}
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />
      <MathSideBar />

      {/* Main content area to accommodate sidebar width */}
      <div className="lg:ml-72">
        <main className="pt-20 px-4 pb-8 max-w-4xl mx-auto">
          <div className="text-center mb-12">
            <div className="inline-block p-3 rounded-full bg-gradient-to-br from-violet-600 to-pink-500 mb-6">
              <Calculator className="w-12 h-12 text-white" />
            </div>
            <h1 className="text-4xl font-bold mb-4 bg-gradient-to-r from-violet-600 to-pink-500 bg-clip-text text-transparent">
              Math Estimation Techniques
            </h1>
            <p className="text-gray-600 text-lg max-w-2xl mx-auto">
              Master essential estimation techniques for case interviews and consulting work
            </p>
          </div>

          <div className="bg-white rounded-xl p-6 shadow-lg mb-8">
            <p className="text-gray-600 leading-relaxed">
              In the world of management consulting, the ability to make quick and accurate estimates is a
              crucial skill. Whether you're faced with a case interview question or need to make a data-driven
              decision on the job, estimation techniques can help you arrive at reasonable answers without
              getting bogged down in precise calculations.
            </p>
          </div>

          <Section
            title="Rounding"
            isOpen={openSections.rounding}
            onToggle={() => toggleSection('rounding')}
            icon={Calculator}
          >
            <p className="text-gray-600 mb-4">
              One of the simplest estimation techniques is rounding numbers to the nearest easy-to-work-with
              value. When dealing with large or complex numbers, round them to the nearest power of 10, such as
              10, 100, or 1,000.
            </p>
            <Example title="Example: Product Estimation">
              <p className="text-gray-600">Estimate the product of 223 and 48:</p>
              <ul className="list-disc ml-6 mt-2 space-y-1 text-gray-600">
                <li>Round 223 to 220</li>
                <li>Round 48 to 50</li>
                <li>Calculate: 220 × 50 = 11,000</li>
              </ul>
            </Example>
          </Section>

          <Section
            title="Bracketing"
            isOpen={openSections.bracketing}
            onToggle={() => toggleSection('bracketing')}
            icon={Scale}
          >
            <p className="text-gray-600 mb-4">
              Bracketing involves estimating a range of values within which the actual answer likely falls. This
              technique is useful when you're unsure of the exact input values or when the problem involves
              uncertainty.
            </p>
            <Example title="Example: Gas Stations in a City">
              <p className="text-gray-600">
                Estimate the number of gas stations in a city with 500,000 people:
              </p>
              <ul className="list-disc ml-6 mt-2 space-y-2 text-gray-600">
                <li>
                  <strong>Lower bound:</strong> 1 station per 10,000 people = 50 stations
                </li>
                <li>
                  <strong>Upper bound:</strong> 1 station per 5,000 people = 100 stations
                </li>
                <li>
                  <strong>Estimate range:</strong> 50-100 gas stations
                </li>
              </ul>
            </Example>
          </Section>

          <Section
            title="Breaking Down Problems"
            isOpen={openSections.breakdown}
            onToggle={() => toggleSection('breakdown')}
            icon={Split}
          >
            <p className="text-gray-600 mb-4">
              When faced with a complex estimation problem, break it down into smaller, more manageable
              components. Estimate each component separately and then combine the results.
            </p>
            <Example title="Example: NYC Pizza Consumption">
              <p className="text-gray-600">Estimate daily pizza slice consumption in NYC:</p>
              <ul className="list-disc ml-6 mt-2 space-y-2 text-gray-600">
                <li>NYC population: ~8.4 million</li>
                <li>1 in 10 eat pizza daily: 840,000 people</li>
                <li>Average 2 slices per person</li>
                <li>Total: 1.68 million slices daily</li>
              </ul>
            </Example>
          </Section>

          <Section
            title="Analogies and Comparisons"
            isOpen={openSections.analogies}
            onToggle={() => toggleSection('analogies')}
            icon={Repeat}
          >
            <p className="text-gray-600 mb-4">
              Drawing analogies and making comparisons to familiar quantities can help you make better estimates.
              When estimating an unfamiliar quantity, try to relate it to something you know.
            </p>
            <Example title="Example: Movie Theater Capacity">
              <p className="text-gray-600">
                Estimate movie theater seats using school assembly hall comparison:
              </p>
              <ul className="list-disc ml-6 mt-2 space-y-1 text-gray-600">
                <li>School hall capacity: 500 seats</li>
                <li>Theater is ~1.5 times larger</li>
                <li>Estimate: 500 × 1.5 = 750 seats</li>
              </ul>
            </Example>
          </Section>

          <Section
            title="80/20 Rule (Pareto Principle)"
            isOpen={openSections.pareto}
            onToggle={() => toggleSection('pareto')}
            icon={PieChart}
          >
            <p className="text-gray-600 mb-4">
              The Pareto principle states that roughly 80% of effects come from 20% of causes. Focus on the most
              significant factors that contribute to the answer and make reasonable assumptions about the rest.
            </p>
            <Example title="Example: Restaurant Revenue">
              <p className="text-gray-600">Estimate annual restaurant revenue:</p>
              <ul className="list-disc ml-6 mt-2 space-y-2 text-gray-600">
                <li>Dinner sales: 80% of revenue</li>
                <li>Lunch sales: 20% of revenue</li>
                <li>Focus on dinner revenue and add 25% for lunch</li>
              </ul>
            </Example>
          </Section>

          <Section
            title="Sanity Checking"
            isOpen={openSections.sanity}
            onToggle={() => toggleSection('sanity')}
            icon={CheckCircle}
          >
            <p className="text-gray-600 mb-4">
              After making an estimate, always take a step back and perform a sanity check. Ask yourself if the
              estimate makes sense given the context and any known facts.
            </p>
            <Example title="Example: Parking Lot Capacity">
              <p className="text-gray-600">When estimating parking lot capacity:</p>
              <ul className="list-disc ml-6 mt-2 space-y-1 text-gray-600">
                <li>Count rows and columns</li>
                <li>Compare to similar lots</li>
                <li>Verify if estimate aligns with visual impression</li>
                <li>Adjust if estimate seems unreasonable</li>
              </ul>
            </Example>
          </Section>

          <div className="mt-8 p-6 bg-violet-50 rounded-lg border border-violet-100">
            <h2 className="text-xl font-semibold mb-4">Keep Practicing!</h2>
            <p className="text-gray-600">
              By incorporating these estimation techniques into your problem-solving approach and practicing with
              various examples, you'll be able to make quick and reasonably accurate estimates in case interviews
              and on the job. Remember, the goal of estimation is to demonstrate your ability to think logically,
              make reasonable assumptions, and solve problems efficiently.
            </p>
          </div>
        </main>
      </div>
    </div>
  );
};

export default EstimationGuide;