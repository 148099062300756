// src/components/ProcessingAnimation.js
import React, { useState, useEffect } from 'react';
import { Sparkles, Zap } from 'lucide-react';

const ProcessingAnimation = ({ isLoading, onComplete }) => {
  const [progress, setProgress] = useState(0);
  const [showFastProgress, setShowFastProgress] = useState(true);

  useEffect(() => {
    let progressInterval;
    
    if (isLoading && showFastProgress) {
      // Fast initial progress
      progressInterval = setInterval(() => {
        setProgress((prev) => {
          if (prev >= 70) {
            setShowFastProgress(false);
            return 70;
          }
          return prev + 5;
        });
      }, 50);
    } else if (isLoading) {
      // Slow progress after 70%
      progressInterval = setInterval(() => {
        setProgress((prev) => {
          if (prev >= 95) return 95;
          return prev + 0.5;
        });
      }, 100);
    }

    return () => {
      if (progressInterval) clearInterval(progressInterval);
    };
  }, [isLoading, showFastProgress]);

  // Handle completion
  useEffect(() => {
    if (!isLoading && progress > 0) {
      setProgress(100);
      const timeout = setTimeout(() => {
        setProgress(0);
        setShowFastProgress(true);
        if (onComplete) onComplete();
      }, 500);
      return () => clearTimeout(timeout);
    }
  }, [isLoading, progress, onComplete]);

  // If not loading and no progress, don't render
  if (progress === 0) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="bg-white rounded-2xl p-8 w-96 shadow-xl">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center gap-2">
            <Sparkles className="w-5 h-5 text-violet-600 animate-pulse" />
            <span className="font-semibold text-gray-900">Processing</span>
          </div>
          <Zap
            className={`w-5 h-5 text-yellow-500 ${
              progress < 100 ? 'animate-bounce' : ''
            }`}
          />
        </div>
        
        {/* Progress bar container */}
        <div className="h-3 bg-gray-100 rounded-full overflow-hidden">
          {/* Animated progress bar */}
          <div 
            className="h-full bg-gradient-to-r from-violet-600 to-pink-500 transition-all duration-300 ease-out"
            style={{ width: `${progress}%` }}
          />
        </div>
        
        {/* Processing stages */}
        <div className="mt-6 space-y-3">
          <div
            className={`flex items-center gap-2 transition-opacity duration-300 ${
              progress > 30 ? 'opacity-100' : 'opacity-40'
            }`}
          >
            <div
              className={`w-2 h-2 rounded-full ${
                progress > 30 ? 'bg-green-500' : 'bg-gray-300'
              }`}
            />
            <span className="text-sm text-gray-600">Analyzing response...</span>
          </div>
          <div
            className={`flex items-center gap-2 transition-opacity duration-300 ${
              progress > 60 ? 'opacity-100' : 'opacity-40'
            }`}
          >
            <div
              className={`w-2 h-2 rounded-full ${
                progress > 60 ? 'bg-green-500' : 'bg-gray-300'
              }`}
            />
            <span className="text-sm text-gray-600">Generating feedback...</span>
          </div>
          <div
            className={`flex items-center gap-2 transition-opacity duration-300 ${
              progress > 90 ? 'opacity-100' : 'opacity-40'
            }`}
          >
            <div
              className={`w-2 h-2 rounded-full ${
                progress > 90 ? 'bg-green-500' : 'bg-gray-300'
              }`}
            />
            <span className="text-sm text-gray-600">Preparing results...</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProcessingAnimation;
