import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Pencil,
  ChevronRight,
  Lock,
  Sun,
  Moon,
  ArrowRight,
  Shield,
  Zap,
  Star,
  Check,
  X,
  PenTool,
  Sparkles,
  FileText,
  Users,
  Layers,
  Activity,
} from 'lucide-react';

import {
  useClerk,
  useAuth,
  SignInButton,
} from '@clerk/clerk-react';
import { useNavigate } from 'react-router-dom';
import { LinkedinIcon, Twitter, Instagram } from 'lucide-react';

// 3D Button with hover effect
const Button3D = ({ children, primary = false, secondary = false, className = '', disabled, ...props }) => {
  const baseClasses = "relative font-medium rounded-xl transition-all duration-300 transform active:scale-95 active:translate-y-1 overflow-hidden";
  const primaryClasses = "bg-gradient-to-br from-violet-600 to-indigo-600 text-white shadow-[0_8px_0_rgb(76,29,149)] active:shadow-[0_4px_0_rgb(76,29,149)]";
  const secondaryClasses = "bg-white dark:bg-gray-800 text-gray-800 dark:text-white shadow-[0_8px_0_rgb(229,231,235)] dark:shadow-[0_8px_0_rgb(31,41,55)] active:shadow-[0_4px_0_rgb(229,231,235)] dark:active:shadow-[0_4px_0_rgb(31,41,55)]";
  const glassClasses = "backdrop-blur-md bg-white/10 border border-white/20 text-white shadow-[0_8px_0_rgba(255,255,255,0.1)] active:shadow-[0_4px_0_rgba(255,255,255,0.1)]";
  
  let variantClasses = "";
  if (primary) variantClasses = primaryClasses;
  else if (secondary) variantClasses = secondaryClasses;
  else variantClasses = glassClasses;
  
  return (
    <button
      disabled={disabled}
      className={`${baseClasses} ${variantClasses} px-6 py-3 ${className} ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
      {...props}
    >
      <div className="absolute inset-0 bg-white/20 opacity-0 hover:opacity-100 transition-opacity duration-300"></div>
      <div className="relative z-10 flex items-center justify-center">{children}</div>
    </button>
  );
};

// Feature Card with 3D tilt effect and gradient hover
const FeatureCard = ({ icon: Icon, title, description }) => {
  const cardRef = useRef(null);
  
  const handleMouseMove = (e) => {
    const card = cardRef.current;
    if (!card) return;
    
    const rect = card.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    
    const centerX = rect.width / 2;
    const centerY = rect.height / 2;
    
    const rotateX = (y - centerY) / 10;
    const rotateY = (centerX - x) / 10;
    
    card.style.transform = `perspective(1000px) rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;
  };
  
  const handleMouseLeave = () => {
    const card = cardRef.current;
    if (!card) return;
    
    card.style.transform = 'perspective(1000px) rotateX(0) rotateY(0)';
  };
  
  return (
    <div
      ref={cardRef}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      className="group relative bg-white/5 backdrop-blur-xl border border-white/10 rounded-2xl overflow-hidden transition-all duration-500 dark:hover:border-violet-500/50 hover:border-violet-500/50 hover:shadow-[0_0_30px_rgba(139,92,246,0.3)]"
      style={{ transition: 'transform 0.2s ease-out' }}
    >
      <div className="absolute inset-0 bg-gradient-to-br from-violet-600/5 to-indigo-600/5 opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>
      <div className="absolute -inset-1 bg-gradient-to-br from-violet-600/20 to-indigo-600/20 rounded-xl blur-xl opacity-0 group-hover:opacity-70 transition-opacity duration-500 -z-10"></div>
      
      <div className="relative p-8">
        <div className="w-14 h-14 rounded-2xl bg-gradient-to-br from-violet-500/10 to-indigo-500/10 flex items-center justify-center mb-6 transition-all duration-500 group-hover:bg-gradient-to-br group-hover:from-violet-500 group-hover:to-indigo-500 group-hover:scale-110 group-hover:rotate-3">
          <Icon className="w-7 h-7 text-violet-500 group-hover:text-white transition-colors duration-500" strokeWidth={1.5} />
        </div>
        
        <h3 className="text-xl font-bold text-white mb-3 transition-colors duration-500 group-hover:text-violet-400">{title}</h3>
        <p className="text-gray-400 leading-relaxed transition-colors duration-500 group-hover:text-gray-300">{description}</p>
      </div>
    </div>
  );
};

// Glass Morphism Pricing Card
const PricingCard = ({ popular, title, price, yearlyPrice, isYearly, features, ctaText, onClick, loading }) => {
  return (
    <div className={`relative rounded-2xl backdrop-blur-xl border ${popular ? 'border-violet-500 bg-violet-900/20' : 'border-white/10 bg-white/5'} overflow-hidden transition-all duration-500 hover:shadow-[0_0_30px_rgba(139,92,246,0.3)] transform hover:-translate-y-2`}>
      {popular && (
        <div className="absolute top-0 right-0 -mt-2 -mr-2">
          <div className="relative">
            <div className="absolute inset-0 bg-violet-600 blur-md opacity-70"></div>
            <div className="relative bg-gradient-to-r from-violet-600 to-indigo-600 text-white text-xs font-bold px-4 py-1 rounded-full uppercase">
              Most Popular
            </div>
          </div>
        </div>
      )}
      
      <div className="p-8">
        <h3 className="text-2xl font-bold text-white mb-4">{title}</h3>
        <div className="flex items-baseline mb-6">
          <span className="text-5xl font-extrabold text-white">${isYearly ? yearlyPrice : price}</span>
          <span className="ml-2 text-lg text-gray-400">/{isYearly ? 'year' : 'month'}</span>
        </div>
        
        <div className="mt-8 mb-8">
          <ul className="space-y-4">
            {features.map((feature, index) => (
              <li key={index} className="flex items-start">
                <div className="flex-shrink-0 mt-1">
                  <div className="w-5 h-5 rounded-full bg-gradient-to-r from-violet-500 to-indigo-500 flex items-center justify-center">
                    <Check className="w-3 h-3 text-white" />
                  </div>
                </div>
                <span className="ml-3 text-gray-300">{feature}</span>
              </li>
            ))}
          </ul>
        </div>
        
        <Button3D
          primary={popular}
          secondary={!popular}
          className="w-full py-4"
          onClick={onClick}
          disabled={loading}
        >
          {loading ? (
            <span className="flex items-center justify-center">
              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Processing...
            </span>
          ) : (
            ctaText
          )}
        </Button3D>
      </div>
    </div>
  );
};

// Animated background wave component
const WavyBackground = () => (
  <div className="absolute inset-0 -z-10 overflow-hidden">
    <svg className="absolute top-0 left-0 min-w-full min-h-full" width="100%" height="100%" preserveAspectRatio="none" viewBox="0 0 1440 800">
      <defs>
        <radialGradient id="radialGradient" cx="50%" cy="0%" r="50%" fx="50%" fy="0%">
          <stop offset="0%" stopColor="rgba(124, 58, 237, 0.5)" />
          <stop offset="100%" stopColor="rgba(79, 70, 229, 0)" />
        </radialGradient>
      </defs>
      <rect width="100%" height="100%" fill="url(#radialGradient)" />
      <g className="waves" clipPath="url(#clip)">
        <path className="wave1" 
          d="M-100,300 C140,100 400,100 700,300 C1000,500 1300,500 1540,300 L1540,800 L-100,800 Z" 
          fill="rgba(124, 58, 237, 0.03)" />
        <path className="wave2" 
          d="M-100,400 C140,200 400,200 700,400 C1000,600 1300,600 1540,400 L1540,800 L-100,800 Z" 
          fill="rgba(139, 92, 246, 0.05)" />
        <path className="wave3" 
          d="M-100,500 C140,300 400,300 700,500 C1000,700 1300,700 1540,500 L1540,800 L-100,800 Z" 
          fill="rgba(167, 139, 250, 0.07)" />
      </g>
    </svg>
    <style jsx>{`
      .waves {
        animation: waves 25s linear infinite;
      }
      @keyframes waves {
        0% {
          transform: translateX(0) translateZ(0) scaleY(1);
        }
        50% {
          transform: translateX(-25%) translateZ(0) scaleY(0.8);
        }
        100% {
          transform: translateX(-50%) translateZ(0) scaleY(1);
        }
      }
      .wave1 {
        animation: wave 20s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
        transform-origin: 50% 0%;
      }
      .wave2 {
        animation: wave 25s cubic-bezier(0.36, 0.45, 0.63, 0.53) -.125s infinite;
        transform-origin: 50% 0%;
      }
      .wave3 {
        animation: wave 30s cubic-bezier(0.36, 0.45, 0.63, 0.53) -.25s infinite;
        transform-origin: 50% 0%;
      }
      @keyframes wave {
        0% {
          transform: translateX(0) translateZ(0) scaleY(1);
        }
        50% {
          transform: translateX(-25%) translateZ(0) scaleY(0.8);
        }
        100% {
          transform: translateX(-50%) translateZ(0) scaleY(1);
        }
      }
    `}</style>
  </div>
);

// Floating orbs animation
const FloatingOrbs = () => (
  <div className="absolute inset-0 overflow-hidden pointer-events-none -z-10">
    <div className="absolute top-1/4 left-1/5 w-64 h-64 bg-violet-600/20 rounded-full blur-3xl animate-float-slow"></div>
    <div className="absolute top-1/3 right-1/4 w-80 h-80 bg-indigo-600/20 rounded-full blur-3xl animate-float-medium"></div>
    <div className="absolute bottom-1/4 left-1/3 w-72 h-72 bg-fuchsia-600/20 rounded-full blur-3xl animate-float-fast"></div>
    <style jsx>{`
      @keyframes float-slow {
        0%, 100% { transform: translate(0, 0) rotate(0deg); }
        33% { transform: translate(30px, -50px) rotate(10deg); }
        66% { transform: translate(-20px, 40px) rotate(-5deg); }
      }
      @keyframes float-medium {
        0%, 100% { transform: translate(0, 0) rotate(0deg); }
        33% { transform: translate(-40px, 40px) rotate(-10deg); }
        66% { transform: translate(30px, -30px) rotate(5deg); }
      }
      @keyframes float-fast {
        0%, 100% { transform: translate(0, 0) rotate(0deg); }
        33% { transform: translate(20px, 30px) rotate(5deg); }
        66% { transform: translate(-40px, -20px) rotate(-10deg); }
      }
      .animate-float-slow {
        animation: float-slow 20s ease-in-out infinite;
      }
      .animate-float-medium {
        animation: float-medium 15s ease-in-out infinite;
      }
      .animate-float-fast {
        animation: float-fast 12s ease-in-out infinite;
      }
    `}</style>
  </div>
);

// Shooting stars animation
const ShootingStars = () => (
  <div className="absolute inset-0 overflow-hidden pointer-events-none -z-10">
    <div className="shooting-stars">
      {[...Array(10)].map((_, i) => (
        <div key={i} className="shooting-star" style={{ 
          top: `${Math.random() * 100}%`, 
          left: `${Math.random() * 100}%`,
          animationDelay: `${Math.random() * 10}s`
        }}></div>
      ))}
    </div>
    <style jsx>{`
      .shooting-stars {
        position: absolute;
        width: 100%;
        height: 100%;
      }
      .shooting-star {
        position: absolute;
        width: 100px;
        height: 1px;
        background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 50%, rgba(255,255,255,0) 100%);
        opacity: 0;
        transform: rotate(-45deg);
        animation: shooting-star 10s linear infinite;
      }
      @keyframes shooting-star {
        0% {
          transform: translateX(0) translateY(0) rotate(-45deg);
          opacity: 0;
        }
        2% {
          opacity: 1;
        }
        4% {
          transform: translateX(-100px) translateY(100px) rotate(-45deg);
          opacity: 0;
        }
        100% {
          opacity: 0;
        }
      }
    `}</style>
  </div>
);

// Animated cursor glow effect (follows mouse)
const CursorGlow = () => {
  const cursorRef = useRef(null);
  
  useEffect(() => {
    const handleMouseMove = (e) => {
      if (!cursorRef.current) return;
      cursorRef.current.style.left = `${e.clientX}px`;
      cursorRef.current.style.top = `${e.clientY}px`;
    };
    
    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);
  
  return (
    <div 
      ref={cursorRef}
      className="fixed w-64 h-64 rounded-full bg-violet-600/20 blur-3xl pointer-events-none mix-blend-screen -z-5"
      style={{ 
        transform: 'translate(-50%, -50%)',
        transition: 'left 0.2s ease-out, top 0.2s ease-out'
      }}
    ></div>
  );
};

const UltraModernLandingPage = () => {
  // State hooks
  const [darkMode, setDarkMode] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [isYearly, setIsYearly] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  
  // Auth & navigation hooks
  const { isSignedIn, getToken, userId } = useAuth();
  const { openSignIn } = useClerk();
  const navigate = useNavigate();
  const url = process.env.REACT_APP_BACK_END_URL;
  
  // Scroll animations & visibility
  useEffect(() => {
    setIsVisible(true);
    
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
      
      const elements = document.querySelectorAll('.animate-on-scroll');
      elements.forEach((el) => {
        const rect = el.getBoundingClientRect();
        const isInView = rect.top <= window.innerHeight * 0.8;
        if (isInView) {
          el.classList.add('animate-in');
        }
      });
    };
    
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  
  // Parallax effect
  const parallaxStyle = useCallback(
    (layer) => {
      const factor = layer * 0.03;
      return {
        transform: `translateY(${scrollPosition * factor}px)`,
      };
    },
    [scrollPosition]
  );
  
  // Auth handlers
  const handleLoginClick = () => {
    if (isSignedIn) {
      navigate('/consulting-dashboard');
    } else {
      openSignIn();
    }
  };
  
  const handleGetStarted = async () => {
    try {
      setLoading(true);
      setError('');
      
      if (!isSignedIn) {
        openSignIn();
        return;
      }
      
      const token = await getToken();
      const response = await fetch(`${url}/api/offerings`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      
      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }
      
      const data = await response.json();
      if (data.payingCustomer === true) {
        navigate('/main');
      } else {
        window.location.href =
          'https://auroraprep.lemonsqueezy.com/buy/11755dd1-377b-42ef-b1a8-e0d99615a807';
      }
    } catch (err) {
      console.error('Error in handleGetStarted:', err);
      setError('Something went wrong. Please try again later.');
    } finally {
      setLoading(false);
    }
  };
  
  // Content data
  const features = [
    {
      icon: PenTool,
      title: "AI-Powered Writing",
      description: "Smart suggestions and auto-formatting that adapts to your writing style, helping you create beautiful notes effortlessly."
    },
    {
      icon: Sparkles,
      title: "Intelligent Organization",
      description: "Our AI automatically categorizes and tags your notes, creating a personalized knowledge system that evolves with you."
    },
    {
      icon: FileText,
      title: "Rich Media Support",
      description: "Seamlessly embed images, videos, code snippets, and interactive elements that bring your notes to life."
    },
    {
      icon: Users,
      title: "Real-time Collaboration",
      description: "Work together with teammates in perfect sync with instant updates and smart conflict resolution."
    },
    {
      icon: Layers,
      title: "Multi-device Sync",
      description: "Your notes follow you everywhere with real-time syncing across all devices and instant access from anywhere."
    },
    {
      icon: Activity,
      title: "Version History",
      description: "Travel back in time with comprehensive version history that tracks every change to your notes."
    }
  ];
  
  const basicFeatures = [
    "Up to 5 notebooks",
    "Basic formatting tools",
    "Mobile app access",
    "Up to 100MB storage"
  ];
  
  const premiumFeatures = [
    "Unlimited notebooks",
    "Advanced AI tools & suggestions",
    "Real-time collaboration",
    "Version history & backups",
    "Priority support",
    "5GB storage space"
  ];

  return (
    <div className={`bg-gray-950 text-white overflow-hidden`}>
      {/* Custom CSS for animations and effects */}
      <style jsx>{`
        @keyframes fade-in-up {
          from {
            opacity: 0;
            transform: translateY(20px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }
        
        .animate-on-scroll {
          opacity: 0;
          transform: translateY(30px);
          transition: opacity 0.8s ease-out, transform 0.8s ease-out;
        }
        
        .animate-in {
          opacity: 1;
          transform: translateY(0);
        }
        
        .stagger-1 { transition-delay: 0.1s; }
        .stagger-2 { transition-delay: 0.2s; }
        .stagger-3 { transition-delay: 0.3s; }
        
        @keyframes float {
          0%, 100% { transform: translateY(0); }
          50% { transform: translateY(-20px); }
        }
        
        .animate-float {
          animation: float 6s ease-in-out infinite;
        }
        
        .text-gradient {
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-image: linear-gradient(to right, #8b5cf6, #ec4899, #8b5cf6);
          background-size: 200% auto;
          animation: text-shine 5s linear infinite;
        }
        
        @keyframes text-shine {
          to {
            background-position: 200% center;
          }
        }
      `}</style>
      
      {/* Background Effects */}
      <CursorGlow />
      <WavyBackground />
      <FloatingOrbs />
      <ShootingStars />
      
      {/* Navigation Bar */}
      <nav className={`fixed w-full z-50 transition-all duration-500 ${
        scrollPosition > 50 ? 'backdrop-blur-2xl bg-black/30 border-b border-white/5' : ''
      }`}>
        <div className="container mx-auto px-6 py-4">
          <div className="flex justify-between items-center">
            {/* Logo */}
            <div className="flex items-center space-x-3">
              <div className="group relative">
                <div className="absolute -inset-2 bg-gradient-to-r from-violet-600 to-indigo-600 rounded-full blur-md opacity-75 group-hover:opacity-100 transition-opacity duration-300"></div>
                <div className="relative bg-black rounded-full p-2">
                  <Pencil className="h-6 w-6 text-violet-400 transition-all duration-300 group-hover:rotate-12" />
                </div>
              </div>
              <span className="text-2xl font-bold tracking-tight">Noted</span>
            </div>

            {/* Desktop Nav Links */}
            <div className="hidden md:flex items-center space-x-8">
              <a
                href="#features"
                className="relative text-sm font-medium text-gray-300 hover:text-white transition-colors duration-300 group"
              >
                Features
                <span className="absolute -bottom-1 left-0 w-0 h-0.5 bg-gradient-to-r from-violet-500 to-indigo-500 transition-all duration-300 group-hover:w-full"></span>
              </a>
              <a
                href="#pricing"
                className="relative text-sm font-medium text-gray-300 hover:text-white transition-colors duration-300 group"
              >
                Pricing
                <span className="absolute -bottom-1 left-0 w-0 h-0.5 bg-gradient-to-r from-violet-500 to-indigo-500 transition-all duration-300 group-hover:w-full"></span>
              </a>
              
              <Button3D
                secondary
                className="py-2 px-4"
                onClick={() => window.open('#download', '_blank')}
              >
                <span className="flex items-center">
                  Download
                </span>
              </Button3D>
              
              {!isSignedIn && (
                <button
                  onClick={handleLoginClick}
                  className="relative text-sm font-medium text-gray-300 hover:text-white transition-colors duration-300 group"
                  disabled={loading}
                >
                  {loading ? 'Processing...' : 'Login'}
                  <span className="absolute -bottom-1 left-0 w-0 h-0.5 bg-gradient-to-r from-violet-500 to-indigo-500 transition-all duration-300 group-hover:w-full"></span>
                </button>
              )}
              
              <Button3D
                primary
                onClick={handleGetStarted}
                disabled={loading}
                className="py-2"
              >
                <span className="flex items-center">
                  {loading ? 'Processing...' : 'Get Started'}
                  <ChevronRight className="ml-1 w-4 h-4" />
                </span>
              </Button3D>
              
              <button
                onClick={() => setDarkMode(!darkMode)}
                className="relative p-2 rounded-full bg-gray-800/50 backdrop-blur-md border border-white/5 transition-all duration-300 hover:border-violet-500/50 group"
                aria-label="Toggle Dark Mode"
              >
                <div className="absolute inset-0 rounded-full bg-violet-500/10 opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
                {darkMode ? (
                  <Sun className="h-4 w-4 text-yellow-300 transition-transform duration-500 group-hover:rotate-90" />
                ) : (
                  <Moon className="h-4 w-4 text-violet-400 transition-transform duration-500 group-hover:-rotate-90" />
                )}
              </button>
            </div>
            
            {/* Mobile Menu Button */}
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="md:hidden text-gray-300 hover:text-white transition-colors duration-300"
            >
              {isMenuOpen ? (
                <X className="h-6 w-6" />
              ) : (
                <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              )}
            </button>
          </div>
        </div>
        
        {/* Mobile Menu */}
        {isMenuOpen && (
          <div className="md:hidden absolute w-full backdrop-blur-2xl bg-black/90 border-b border-white/5">
            <div className="px-6 py-6 space-y-4">
              <a
                href="#features"
                className="block text-gray-300 hover:text-white transition-colors duration-300"
                onClick={() => setIsMenuOpen(false)}
              >
                Features
              </a>
              <a
                href="#pricing"
                className="block text-gray-300 hover:text-white transition-colors duration-300"
                onClick={() => setIsMenuOpen(false)}
              >
                Pricing
              </a>
              <button
                onClick={() => {
                  setIsMenuOpen(false);
                  window.open('#download', '_blank');
                }}
                className="block w-full text-left text-gray-300 hover:text-white transition-colors duration-300"
              >
                Download
              </button>
              <button
                onClick={() => {
                  setIsMenuOpen(false);
                  handleLoginClick();
                }}
                className="block w-full text-left text-gray-300 hover:text-white transition-colors duration-300"
                disabled={loading}
              >
                {loading ? 'Processing...' : 'Login'}
              </button>
              <Button3D
                primary
                onClick={() => {
                  setIsMenuOpen(false);
                  handleGetStarted();
                }}
                className="w-full"
                disabled={loading}
              >
                <span className="flex items-center justify-center">
                  {loading ? 'Processing...' : 'Get Started'}
                  <ChevronRight className="ml-1 w-4 h-4" />
                </span>
              </Button3D>
              {error && (
                <p className="text-red-400 text-sm mt-2">{error}</p>
              )}
            </div>
          </div>
        )}
      </nav>

      {/* Hero Section */}
      <div className="relative min-h-screen overflow-hidden flex items-center">
        {/* Hero Content */}
        <div className="container mx-auto px-6 pt-40 pb-32 relative">
          <div className="max-w-5xl mx-auto text-center">
            <div
              className={`transition-all duration-1000 ${
                isVisible ? 'opacity-100' : 'opacity-0 transform translate-y-10'
              }`}
            >
              <div className="inline-flex items-center bg-white/5 backdrop-blur-md border border-white/10 rounded-full px-4 py-2 mb-6">
                <Sparkles className="h-4 w-4 mr-2 text-violet-400" />
                <span className="text-sm font-medium text-gray-300">Redefining note-taking for 2025</span>
              </div>
              
              <h1 className="text-5xl sm:text-7xl font-bold mb-8 tracking-tight leading-tight">
                <span className="block mb-2">Capture brilliance.</span>
                <span className="relative inline-block text-gradient">
                  Effortlessly.
                </span>
              </h1>
              
              <p className="text-xl md:text-2xl mb-12 leading-relaxed max-w-3xl mx-auto text-gray-300">
                Experience a note-taking app designed for the way your mind works.
                <br className="hidden md:block" />
                <span className="font-medium">Fast. Beautiful. Intelligent.</span>
              </p>

              <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-6">
                <Button3D
                  primary
                  onClick={handleGetStarted}
                  disabled={loading}
                  className="group"
                >
                  <span className="flex items-center">
                    {loading ? 'Processing...' : 'Try Noted free'}
                    <ChevronRight className="ml-2 h-5 w-5 transition-transform duration-300 group-hover:translate-x-1" />
                  </span>
                </Button3D>

                <Button3D
                  secondary
                  className="group"
                >
                  <span className="flex items-center">
                    Learn more
                    <ArrowRight className="ml-2 h-5 w-5 transition-transform duration-300 group-hover:translate-x-1" />
                  </span>
                </Button3D>
              </div>
              
              {error && (
                <p className="text-red-400 text-sm mt-4">{error}</p>
              )}
            </div>
          </div>
        </div>
        
        {/* Floating App Demo (Appears on scroll) */}
        <div 
          className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-full max-w-4xl pb-10 opacity-0 animate-on-scroll"
          style={{ 
            transitionDelay: '0.5s',
            animationDelay: '0.5s'
          }}
        >
          <div className="relative mx-auto w-11/12">
            <div className="absolute -inset-2 bg-gradient-to-r from-violet-600/30 to-indigo-600/30 rounded-xl blur-xl"></div>
            <div className="relative bg-black/40 backdrop-blur-xl border border-white/10 rounded-xl overflow-hidden shadow-2xl">
              <div className="h-8 bg-black/60 border-b border-white/10 flex items-center px-4">
                <div className="flex space-x-2">
                  <div className="w-3 h-3 rounded-full bg-red-400"></div>
                  <div className="w-3 h-3 rounded-full bg-yellow-400"></div>
                  <div className="w-3 h-3 rounded-full bg-green-400"></div>
                </div>
              </div>
              <div className="grid grid-cols-5 h-64 md:h-80">
                <div className="col-span-1 border-r border-white/5 p-4">
                  <div className="h-8 w-full bg-white/5 rounded-lg mb-4"></div>
                  <div className="h-4 w-3/4 bg-white/5 rounded mb-3"></div>
                  <div className="h-4 w-5/6 bg-white/5 rounded mb-3"></div>
                  <div className="h-4 w-4/5 bg-white/5 rounded mb-3"></div>
                </div>
                <div className="col-span-4 p-6">
                  <div className="h-8 w-1/2 bg-white/5 rounded-lg mb-6"></div>
                  <div className="h-4 w-full bg-white/5 rounded mb-3"></div>
                  <div className="h-4 w-full bg-white/5 rounded mb-3"></div>
                  <div className="h-4 w-3/4 bg-white/5 rounded mb-6"></div>
                  <div className="h-32 w-full bg-gradient-to-br from-violet-500/10 to-indigo-500/10 rounded-lg flex items-center justify-center">
                    <Sparkles className="text-violet-400 w-10 h-10 opacity-50" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div id="features" className="py-32 relative overflow-hidden">
        <div className="container mx-auto px-6">
          <div className="text-center mb-20 animate-on-scroll">
            <h2 className="text-4xl md:text-5xl font-bold mb-6">
              Features that
              <span className="text-gradient ml-3">elevate</span> your ideas
            </h2>
            <p className="text-xl text-gray-300 max-w-3xl mx-auto">
              Every detail designed to help you capture, organize, and develop your thoughts with unprecedented ease.
            </p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto">
            {features.map((feature, index) => (
              <div key={index} className="animate-on-scroll" style={{ transitionDelay: `${(index % 3) * 0.1}s` }}>
                <FeatureCard {...feature} />
              </div>
            ))}
          </div>
          
          {/* Security Feature Highlight */}
          <div className="mt-32 max-w-4xl mx-auto animate-on-scroll">
            <div className="text-center mb-16">
              <div className="inline-block relative mb-8">
                <div className="absolute -inset-6 bg-violet-500/20 rounded-full blur-xl animate-pulse"></div>
                <Lock className="h-16 w-16 text-violet-400 relative" />
              </div>
              <h2 className="text-4xl md:text-5xl font-bold mb-6">
                Privacy that's
                <span className="text-gradient ml-3">uncompromising</span>
              </h2>
              <p className="text-xl text-gray-300 max-w-3xl mx-auto">
                End-to-end encryption with zero-knowledge architecture. Your notes belong only to you — not even we can access them.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Pricing Section */}
      <div id="pricing" className="py-32 relative overflow-hidden">
        <div className="container mx-auto px-6">
          {/* Toggle for Monthly / Yearly */}
          <div className="flex flex-col items-center mb-20 animate-on-scroll">
            <h2 className="text-4xl md:text-5xl font-bold mb-6">
              Simple,
              <span className="text-gradient ml-3">transparent</span> pricing
            </h2>
            <p className="text-xl text-gray-300 max-w-3xl mx-auto text-center mb-10">
              Choose a plan that fits your needs with no hidden fees or complicated tiers.
            </p>
            
            <div className="flex items-center space-x-3 bg-white/5 backdrop-blur-md border border-white/10 rounded-full p-1">
              <button
                className={`px-6 py-2 rounded-full transition-all duration-300 ${
                  !isYearly ? 'bg-gradient-to-r from-violet-600 to-indigo-600 text-white' : 'text-gray-300'
                }`}
                onClick={() => setIsYearly(false)}
              >
                Monthly
              </button>
              <button
                className={`px-6 py-2 rounded-full transition-all duration-300 ${
                  isYearly ? 'bg-gradient-to-r from-violet-600 to-indigo-600 text-white' : 'text-gray-300'
                }`}
                onClick={() => setIsYearly(true)}
              >
                Yearly
                <span className="ml-2 bg-violet-400/20 text-violet-300 text-xs px-2 py-0.5 rounded-full">
                  Save 30%
                </span>
              </button>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-4xl mx-auto">
            {/* Basic Plan */}
            <div className="animate-on-scroll" style={{ transitionDelay: '0.1s' }}>
              <PricingCard
                title="Basic"
                price="0"
                yearlyPrice="0"
                isYearly={isYearly}
                features={basicFeatures}
                ctaText="Get Started"
                onClick={handleGetStarted}
                loading={loading}
              />
            </div>
            
            {/* Premium Plan */}
            <div className="animate-on-scroll" style={{ transitionDelay: '0.2s' }}>
              <PricingCard
                popular
                title="Premium"
                price="8.95"
                yearlyPrice="59.95"
                isYearly={isYearly}
                features={premiumFeatures}
                ctaText="Upgrade Now"
                onClick={handleGetStarted}
                loading={loading}
              />
            </div>
          </div>
          
          {/* Money Back Guarantee */}
          <div className="text-center mt-12 animate-on-scroll">
            <p className="text-gray-400">
              All plans come with a 30-day money-back guarantee. No questions asked.
            </p>
          </div>
        </div>
      </div>

      {/* Stats Section */}
      <div className="py-20 relative overflow-hidden">
        <div className="container mx-auto px-6">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-4xl mx-auto">
            <div className="animate-on-scroll">
              <div className="text-center">
                <h3 className="text-5xl font-bold mb-2 text-gradient">500K+</h3>
                <p className="text-gray-400">Happy Users</p>
              </div>
            </div>
            <div className="animate-on-scroll" style={{ transitionDelay: '0.1s' }}>
              <div className="text-center">
                <h3 className="text-5xl font-bold mb-2 text-gradient">50M+</h3>
                <p className="text-gray-400">Notes Created</p>
              </div>
            </div>
            <div className="animate-on-scroll" style={{ transitionDelay: '0.2s' }}>
              <div className="text-center">
                <h3 className="text-5xl font-bold mb-2 text-gradient">99.9%</h3>
                <p className="text-gray-400">Uptime</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="relative py-32 overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-r from-violet-600/20 to-indigo-600/20"></div>
        <div className="container mx-auto px-6 relative z-10">
          <div className="max-w-4xl mx-auto text-center animate-on-scroll">
            <h2 className="text-4xl md:text-5xl font-bold mb-6">
              Ready to revolutionize your note-taking?
            </h2>
            <p className="text-xl text-gray-300 mb-10 max-w-3xl mx-auto">
              Join thousands of thinkers, creators, and professionals who've transformed their digital workflow with Noted.
            </p>
            
            <Button3D
              primary
              onClick={handleGetStarted}
              disabled={loading}
              className="text-lg px-10 py-4 group"
            >
              <span className="flex items-center">
                {loading ? (
                  <span className="flex items-center justify-center">
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Processing...
                  </span>
                ) : (
                  <>
                    Start Your Free Trial
                    <ChevronRight className="ml-2 h-5 w-5 transition-transform duration-300 group-hover:translate-x-1" />
                  </>
                )}
              </span>
            </Button3D>
            
            <p className="mt-4 text-gray-400">No credit card required. Cancel anytime.</p>
            
            {error && (
              <p className="text-red-400 text-sm mt-2">{error}</p>
            )}
          </div>
        </div>
      </div>

      {/* Footer */}
      <footer className="bg-black/50 backdrop-blur-lg border-t border-white/5 py-16">
        <div className="container mx-auto px-6">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-10">
            <div className="col-span-1 md:col-span-2">
              <div className="flex items-center space-x-3 mb-4">
                <div className="relative">
                  <div className="absolute -inset-2 bg-gradient-to-r from-violet-600 to-indigo-600 rounded-full blur-md opacity-75"></div>
                  <div className="relative bg-black rounded-full p-2">
                    <Pencil className="h-5 w-5 text-violet-400" />
                  </div>
                </div>
                <span className="text-xl font-bold">Noted</span>
              </div>
              <p className="text-gray-400 mb-6 max-w-xs">
                Redefining the way you capture, organize, and develop your ideas in the digital age.
              </p>
              
              <div className="flex space-x-4">
                <a href="#" className="text-gray-400 hover:text-white transition-colors">
                  <Twitter className="w-5 h-5" />
                </a>
                <a href="#" className="text-gray-400 hover:text-white transition-colors">
                  <Instagram className="w-5 h-5" />
                </a>
                <a href="#" className="text-gray-400 hover:text-white transition-colors">
                  <LinkedinIcon className="w-5 h-5" />
                </a>
              </div>
            </div>
            
            <div>
              <h3 className="text-lg font-medium mb-4">Product</h3>
              <ul className="space-y-2 text-gray-400">
                <li><a href="#" className="hover:text-white transition-colors">Features</a></li>
                <li><a href="#" className="hover:text-white transition-colors">Pricing</a></li>
                <li><a href="#" className="hover:text-white transition-colors">Download</a></li>
                <li><a href="#" className="hover:text-white transition-colors">What's New</a></li>
              </ul>
            </div>
            
            <div>
              <h3 className="text-lg font-medium mb-4">Company</h3>
              <ul className="space-y-2 text-gray-400">
                <li><a href="#" className="hover:text-white transition-colors">About</a></li>
                <li><a href="#" className="hover:text-white transition-colors">Careers</a></li>
                <li><a href="#" className="hover:text-white transition-colors">Blog</a></li>
                <li><a href="#" className="hover:text-white transition-colors">Contact</a></li>
              </ul>
            </div>
          </div>
          
          <div className="border-t border-white/5 mt-12 pt-8 flex flex-col md:flex-row justify-between items-center">
            <p className="text-gray-500 text-sm">© 2025 Noted. All rights reserved.</p>
            <div className="flex space-x-6 mt-4 md:mt-0">
              <a href="#" className="text-gray-500 hover:text-white text-sm transition-colors">Privacy Policy</a>
              <a href="#" className="text-gray-500 hover:text-white text-sm transition-colors">Terms of Service</a>
              <a href="#" className="text-gray-500 hover:text-white text-sm transition-colors">Cookie Policy</a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default UltraModernLandingPage;