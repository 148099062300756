import React, { useState, useEffect } from 'react';
import {
  Timer, Calculator, Check, X, RotateCcw, Play, Pause, Settings
} from 'lucide-react';
import { useAuth, useUser } from '@clerk/clerk-react';
import Navbar from '../components/Navbar'; // Adjust path as needed

const MathFundamentals = () => {
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(300);
  const [timerDuration, setTimerDuration] = useState(5);
  const [score, setScore] = useState(0);
  const [attempts, setAttempts] = useState(0);
  const [currentAnswer, setCurrentAnswer] = useState('');
  const [currentProblem, setCurrentProblem] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('multiplication');
  const [showSettings, setShowSettings] = useState(false);
  const [feedback, setFeedback] = useState('');

  // Performance data from the GET /api/math-performance endpoint
  const [performanceData, setPerformanceData] = useState(null);

  // Clerk hooks for auth
  const { isSignedIn } = useUser();
  const auth = useAuth();

  // If you have a .env with REACT_APP_BACK_END_URL, use it:
  const baseUrl = process.env.REACT_APP_BACK_END_URL || 'http://localhost:8080';

  /* --------------------------------
     1) GET /api/math-performance
     -------------------------------- */
  const fetchMathPerformance = async () => {
    try {
      if (!isSignedIn) return;

      const token = await auth.getToken();
      if (!token) {
        console.error('No token found');
        return;
      }

      const response = await fetch(`${baseUrl}/api/math-performance`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Error fetching math performance');
      }

      const data = await response.json();
      if (data.performanceData) {
        setPerformanceData(data.performanceData);
      }
    } catch (error) {
      console.error('Error fetching math performance:', error);
    }
  };

  /* --------------------------------
     2) POST /submit-answer
     -------------------------------- */
  const submitAnswerToServer = async (isCorrect) => {
    try {
      if (!isSignedIn) return;

      const token = await auth.getToken();
      if (!token) {
        console.error('No token found');
        return;
      }

      const response = await fetch(`${baseUrl}/api/submit-answer`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ isCorrect }),
      });

      if (!response.ok) {
        throw new Error('Error submitting answer');
      }

      const data = await response.json();
      console.log('submitAnswer response:', data);
      
      // (Optional) Re-fetch performance data after submitting answer
      fetchMathPerformance();
    } catch (error) {
      console.error('Error submitting answer to server:', error);
    }
  };

  // Helper to generate random numbers
  const getRandomNumber = (min, max) =>
    Math.floor(Math.random() * (max - min + 1)) + min;

  // Categories for the practice
  const categories = {
    multiplication: {
      name: 'Multiplication',
      description: 'Practice multiplying numbers quickly',
    },
    division: {
      name: 'Division',
      description: 'Master quick division calculations',
    },
    percentages: {
      name: 'Percentages',
      description: 'Calculate percentage changes and portions',
    },
    mixed: {
      name: 'Mixed Practice',
      description: 'Combination of all fundamental operations',
    },
  };

  const generateProblem = () => {
    let problem = { question: '', answer: 0, explanation: '' };

    switch (selectedCategory) {
      case 'multiplication': {
        const num1 = getRandomNumber(5, 95);
        const num2 = getRandomNumber(5, 95);
        problem = {
          question: `What is ${num1} × ${num2}?`,
          answer: num1 * num2,
          explanation: `${num1} × ${num2} = ${num1 * num2}`,
        };
        break;
      }
      case 'division': {
        const dividend = getRandomNumber(100, 1000);
        const divisor = getRandomNumber(2, 50);
        problem = {
          question: `What is ${dividend} ÷ ${divisor}? (Round to 1 decimal)`,
          answer: (dividend / divisor).toFixed(1),
          explanation: `${dividend} ÷ ${divisor} = ${(dividend / divisor).toFixed(1)}`,
        };
        break;
      }
      case 'percentages': {
        const baseNumber = getRandomNumber(50, 500);
        const percentage = getRandomNumber(5, 75);
        problem = {
          question: `What is ${percentage}% of ${baseNumber}?`,
          answer: (baseNumber * percentage / 100).toFixed(1),
          explanation: `${percentage}% of ${baseNumber} = ${(baseNumber * percentage / 100).toFixed(1)}`,
        };
        break;
      }
      case 'mixed': {
        const operationType = getRandomNumber(1, 3);
        switch (operationType) {
          case 1: {
            // Multiplication
            const m1 = getRandomNumber(5, 95);
            const m2 = getRandomNumber(5, 95);
            problem = {
              question: `What is ${m1} × ${m2}?`,
              answer: m1 * m2,
              explanation: `${m1} × ${m2} = ${m1 * m2}`,
            };
            break;
          }
          case 2: {
            // Division
            const d1 = getRandomNumber(100, 1000);
            const d2 = getRandomNumber(2, 50);
            problem = {
              question: `What is ${d1} ÷ ${d2}? (Round to 1 decimal)`,
              answer: (d1 / d2).toFixed(1),
              explanation: `${d1} ÷ ${d2} = ${(d1 / d2).toFixed(1)}`,
            };
            break;
          }
          case 3: {
            // Percentage
            const b = getRandomNumber(50, 500);
            const p = getRandomNumber(5, 75);
            problem = {
              question: `What is ${p}% of ${b}?`,
              answer: (b * p / 100).toFixed(1),
              explanation: `${p}% of ${b} = ${(b * p / 100).toFixed(1)}`,
            };
            break;
          }
          default:
            break;
        }
        break;
      }
      default:
        break;
    }

    setCurrentProblem(problem);
    setCurrentAnswer('');
    setFeedback('');
  };

  // Timer side effect
  useEffect(() => {
    if (isTimerActive && timeRemaining > 0) {
      const timer = setInterval(() => {
        setTimeRemaining((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(timer);
    } else if (timeRemaining === 0) {
      setIsTimerActive(false);
    }
  }, [isTimerActive, timeRemaining]);

  // On first render, or whenever selectedCategory changes, generate a new problem
  useEffect(() => {
    generateProblem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory]);

  // Fetch performance data once user is signed in
  useEffect(() => {
    if (isSignedIn) {
      fetchMathPerformance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSignedIn]);

  const handleTimerDurationChange = (e) => {
    const minutes = Math.max(1, Math.min(60, parseInt(e.target.value) || 1));
    setTimerDuration(minutes);
    setTimeRemaining(minutes * 60);
  };

  const handleSubmit = async () => {
    const userAnswer = parseFloat(currentAnswer);
    const correctAnswer = parseFloat(currentProblem.answer);

    // Always increment attempts
    setAttempts((prev) => prev + 1);

    // Check if answer is within 10% range
    const percentDiff = Math.abs((userAnswer - correctAnswer) / correctAnswer) * 100;
    const isCorrect = percentDiff <= 10;

    // If correct, update local score + feedback
    if (isCorrect) {
      setScore((prev) => prev + 1);
      setFeedback(
        `Correct! Your estimate is within 10% of the actual answer. ${currentProblem.explanation}`
      );
    } else {
      setFeedback(
        `Try again. Your answer was off by ${percentDiff.toFixed(
          1
        )}%. The exact answer is ${correctAnswer}. ${currentProblem.explanation}`
      );
    }

    // 3) Submit the answer to the server, passing isCorrect
    await submitAnswerToServer(isCorrect);
  };

  // Format time for display
  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs.toString().padStart(2, '0')}`;
  };

  return (
    <>
      <Navbar />

      <div className="min-h-screen bg-gray-50 p-8">
        <div className="max-w-4xl mx-auto">
          <div className="mb-8">
            <h1 className="text-3xl font-bold bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-transparent bg-clip-text">
              Consulting Math Fundamentals
            </h1>
            <p className="mt-2 text-gray-600">
              Master the core calculations needed for consulting — estimates within 10% are considered correct!
            </p>
          </div>

          {/* Modern Performance Stats */}
          {performanceData && (
            <div className="mb-8">
              <h2 className="text-xl font-semibold text-gray-800 mb-4">
                Your Overall Performance
              </h2>

              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                {/* Average Accuracy */}
                <div className="bg-white rounded-xl shadow p-6 flex flex-col items-center">
                  <span className="text-sm font-medium text-gray-500">
                    Average Accuracy
                  </span>
                  <span className="text-3xl font-bold text-indigo-600 mt-2">
                    {performanceData.average.toFixed(1)}%
                  </span>
                  <div className="w-full mt-4">
                    <div className="h-2 bg-gray-200 rounded-full">
                      <div
                        className="h-2 bg-indigo-600 rounded-full"
                        style={{ width: `${performanceData.average}%` }}
                      ></div>
                    </div>
                  </div>
                </div>

                {/* Correct Answers */}
                <div className="bg-white rounded-xl shadow p-6 flex flex-col items-center">
                  <span className="text-sm font-medium text-gray-500">
                    Correct Answers
                  </span>
                  <span className="text-3xl font-bold text-green-600 mt-2">
                    {performanceData.correct}
                  </span>
                </div>

                {/* Incorrect Answers */}
                <div className="bg-white rounded-xl shadow p-6 flex flex-col items-center">
                  <span className="text-sm font-medium text-gray-500">
                    Incorrect Answers
                  </span>
                  <span className="text-3xl font-bold text-red-600 mt-2">
                    {performanceData.incorrect}
                  </span>
                </div>
              </div>
            </div>
          )}

          <div className="bg-white rounded-xl shadow-sm p-6 mb-6">
            <div className="flex justify-between items-center">
              <div className="flex items-center space-x-4">
                <div className="flex items-center space-x-2">
                  <Timer className="w-5 h-5 text-gray-400" />
                  <span className="text-lg font-semibold">{formatTime(timeRemaining)}</span>
                </div>
                <button
                  onClick={() => setIsTimerActive(!isTimerActive)}
                  className="px-4 py-2 bg-gradient-to-r from-indigo-500 to-purple-500 text-white rounded-lg hover:shadow-lg transition-all duration-200"
                >
                  {isTimerActive ? <Pause className="w-4 h-4" /> : <Play className="w-4 h-4" />}
                </button>
                <button
                  onClick={() => setShowSettings(!showSettings)}
                  className="text-gray-500 hover:text-gray-700"
                >
                  <Settings className="w-5 h-5" />
                </button>
              </div>
              <div className="flex items-center space-x-4">
                <span className="text-gray-600">Score: {score}/{attempts}</span>
                <button
                  onClick={() => {
                    setScore(0);
                    setAttempts(0);
                    setTimeRemaining(timerDuration * 60);
                    setIsTimerActive(false);
                    generateProblem();
                  }}
                  className="text-gray-500 hover:text-gray-700"
                >
                  <RotateCcw className="w-5 h-5" />
                </button>
              </div>
            </div>

            {showSettings && (
              <div className="mt-4 p-4 bg-gray-50 rounded-lg">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Timer Duration (minutes):
                </label>
                <input
                  type="number"
                  min="1"
                  max="60"
                  value={timerDuration}
                  onChange={handleTimerDurationChange}
                  className="w-20 px-2 py-1 border rounded"
                />
              </div>
            )}
          </div>

          {/* Category Selector */}
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-6">
            {Object.entries(categories).map(([key, category]) => (
              <button
                key={key}
                onClick={() => setSelectedCategory(key)}
                className={`p-4 rounded-xl text-left transition-all duration-200 ${
                  selectedCategory === key
                    ? 'bg-gradient-to-r from-indigo-500 to-purple-500 text-white shadow-lg'
                    : 'bg-white hover:shadow-md'
                }`}
              >
                <h3 className="font-semibold">{category.name}</h3>
                <p
                  className={`text-sm mt-1 ${
                    selectedCategory === key ? 'text-white/80' : 'text-gray-500'
                  }`}
                >
                  {category.description}
                </p>
              </button>
            ))}
          </div>

          {/* Problem & Answer Submission */}
          <div className="bg-white rounded-xl shadow-sm p-6">
            <div className="mb-6">
              <h2 className="text-xl font-semibold text-gray-900 mb-2">Problem</h2>
              <p className="text-gray-700">{currentProblem?.question}</p>
            </div>

            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Your Answer
              </label>
              <div className="flex space-x-4">
                <input
                  type="number"
                  step="0.1"
                  value={currentAnswer}
                  onChange={(e) => setCurrentAnswer(e.target.value)}
                  className="flex-1 px-4 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                  placeholder="Enter your answer..."
                />
                <button
                  onClick={handleSubmit}
                  className="px-6 py-2 bg-gradient-to-r from-indigo-500 to-purple-500 text-white rounded-lg hover:shadow-lg transition-all duration-200"
                >
                  Submit
                </button>
              </div>
            </div>

            {/* Feedback after submission */}
            {feedback && (
              <div
                className={`p-4 rounded-lg ${
                  feedback.startsWith('Correct')
                    ? 'bg-green-50 text-green-700'
                    : 'bg-red-50 text-red-700'
                }`}
              >
                {feedback}
              </div>
            )}

            <button
              onClick={generateProblem}
              className="mt-4 px-4 py-2 text-gray-600 hover:text-gray-900 flex items-center space-x-2"
            >
              <RotateCcw className="w-4 h-4" />
              <span>New Problem</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default MathFundamentals;
