import React from 'react';
import { Helmet } from 'react-helmet';

const TermsAndConditions = () => {
  return (
    <>
      <Helmet>
        {/* Google Tag Manager (gtag.js) */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-6YQYMBQQNY"
        />
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-6YQYMBQQNY');
          `}
        </script>

        {/* Bing Ads / UET */}
        <script>
          {`
            (function(w,d,t,r,u) {
              var f,n,i;
              w[u]=w[u]||[];
              f=function() {
                var o={ti:"97123631", enableAutoSpaTracking:true};
                o.q=w[u];
                w[u]=new UET(o);
                w[u].push("pageLoad");
              },
              n=d.createElement(t);
              n.src=r;
              n.async=1;
              n.onload=n.onreadystatechange=function() {
                var s=this.readyState;
                if(!s || s==="loaded" || s==="complete") {
                  f();
                  n.onload = n.onreadystatechange = null;
                }
              };
              i=d.getElementsByTagName(t)[0];
              i.parentNode.insertBefore(n,i);
            })(window,document,"script","//bat.bing.com/bat.js","uetq");

            // If you have no dynamic revenue to report, just use 0 or another static number:
            window.uetq = window.uetq || [];
            window.uetq.push('event', '', {
              "revenue_value": 0,
              "currency": "USD"
            });
          `}
        </script>

        {/* Basic Meta Tags */}
        <meta charSet="UTF-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0"
        />
        <meta
          name="description"
          content="Aurora Prep, LLC offers top-notch consulting prep, consulting case prep, and consulting interview prep. Discover our subscription packages for tailored consulting preparation."
        />
        <meta
          name="keywords"
          content="consulting prep, consulting case prep, consulting interview prep, subscription packages, consulting preparation, AI-driven tutor, interactive cases, insights dashboard"
        />
        <title>Terms and Conditions for Aurora Prep LLC</title>
      </Helmet>

      {/* Page Content */}
      <div className="min-h-screen bg-gray-100 py-10 px-4 md:px-0">
        <div className="max-w-3xl mx-auto bg-white shadow-lg rounded-lg p-6 md:p-12 text-gray-800 font-sans">
          <h1 className="text-3xl md:text-4xl font-bold mb-6 text-gray-900">
            Terms and Conditions for Aurora Prep LLC
          </h1>

          {/* Section 1 */}
          <h2 className="text-2xl font-semibold mt-8 mb-4 text-gray-900">
            1. Subscription and Payment
          </h2>
          <p className="mb-4">
            <strong>1.1</strong> Users can access the Aurora Prep LLC consulting
            prep training program by subscribing to either a monthly, yearly, or lifetime
            plan.
          </p>
          <p className="mb-4">
            <strong>1.2</strong> Payment for the subscription is due upfront and
            is refundable for up to 72 hours if less than 10% of the course
            material has been reviewed.
          </p>
          <p className="mb-4">
            <strong>1.3</strong> Subscriptions will not automatically renew at
            the end of a subscription term.
          </p>
          <p className="mb-4">
            <strong>1.4</strong> Aurora Prep LLC reserves the right to modify
            the subscription fees without prior notice to users. If users
            subscribe to a new term after the completion of a current term, they
            may experience changes to pricing.
          </p>

          {/* Section 2 */}
          <h2 className="text-2xl font-semibold mt-8 mb-4 text-gray-900">
            2. Access to Platform and Learning Modules
          </h2>
          <p className="mb-4">
            <strong>2.1</strong> Upon successful subscription, users will gain
            access to the Aurora Prep LLC platform, our content, learning
            modules, case trainings, math practice, and personalized performance
            dashboards.
          </p>
          <p className="mb-4">
            <strong>2.2</strong> Access is granted for the duration of the
            subscription period and will be revoked upon expiration or
            cancellation of the subscription.
          </p>
          <p className="mb-4">
            <strong>2.3</strong> Users are responsible for maintaining the
            confidentiality of their login credentials and are liable for any
            activities conducted under their account.
          </p>

          {/* Section 3 */}
          <h2 className="text-2xl font-semibold mt-8 mb-4 text-gray-900">
            3. Intellectual Property
          </h2>
          <p className="mb-4">
            <strong>3.1</strong> All content, including learning modules,
            training materials, and software, is the intellectual property of
            Aurora Prep LLC and is protected by copyright laws.
          </p>
          <p className="mb-4">
            <strong>3.2</strong> Users are granted a limited, non-transferable,
            and non-exclusive license to access and use the content for
            personal, non-commercial purposes.
          </p>
          <p className="mb-4">
            <strong>3.3</strong> Users may not reproduce, distribute, modify, or
            create derivative works of the content without prior written consent
            from Aurora Prep LLC.
          </p>

          {/* Section 4 */}
          <h2 className="text-2xl font-semibold mt-8 mb-4 text-gray-900">
            4. User Data and Privacy
          </h2>
          <p className="mb-4">
            <strong>4.1</strong> Aurora Prep LLC collects and stores user data,
            including test scores and performance metrics, to provide
            personalized dashboards and track progress over time.
          </p>
          <p className="mb-4">
            <strong>4.2</strong> Aurora Prep LLC may use aggregate, anonymized
            data for research, analytics, and improvement of its services.
          </p>
          <p className="mb-4">
            <strong>4.3</strong> Users have the right to access, correct, and
            delete their personal data in accordance with applicable data
            protection laws.
          </p>

          {/* Section 5 */}
          <h2 className="text-2xl font-semibold mt-8 mb-4 text-gray-900">
            5. User Conduct
          </h2>
          <p className="mb-4">
            <strong>5.1</strong> Users agree to use the platform and learning
            modules for lawful purposes only and in compliance with these terms
            and conditions.
          </p>
          <p className="mb-4">
            <strong>5.2</strong> Users are prohibited from engaging in any
            activity that may disrupt or interfere with the functioning of the
            platform or compromise the integrity of the learning modules and
            assessments.
          </p>
          <p className="mb-4">
            <strong>5.3</strong> Aurora Prep LLC reserves the right to suspend
            or terminate a user's account for any violation of these terms and
            conditions.
          </p>

          {/* Section 6 */}
          <h2 className="text-2xl font-semibold mt-8 mb-4 text-gray-900">
            6. Limitation of Liability
          </h2>
          <p className="mb-4">
            <strong>6.1</strong> Aurora Prep LLC strives to provide accurate and
            up-to-date content but does not warrant the completeness, accuracy,
            or reliability of the information provided.
          </p>
          <p className="mb-4">
            <strong>6.2</strong> Aurora Prep LLC shall not be liable for any
            direct, indirect, incidental, consequential, or punitive damages
            arising out of the use or inability to use the platform or learning
            modules.
          </p>

          {/* Section 7 */}
          <h2 className="text-2xl font-semibold mt-8 mb-4 text-gray-900">
            7. Modifications and Termination
          </h2>
          <p className="mb-4">
            <strong>7.1</strong> Aurora Prep LLC reserves the right to modify,
            suspend, or terminate the platform, learning modules, or any part
            thereof at any time without prior notice.
          </p>
          <p className="mb-4">
            <strong>7.2</strong> Aurora Prep LLC may update these terms and
            conditions periodically, and the continued use of the platform after
            such modifications constitutes acceptance of the revised terms.
          </p>

          {/* Section 8 */}
          <h2 className="text-2xl font-semibold mt-8 mb-4 text-gray-900">
            8. Governing Law and Jurisdiction
          </h2>
          <p className="mb-4">
            <strong>8.1</strong> These terms and conditions shall be governed by
            and construed in accordance with the laws of the State of Delaware,
            United States.
          </p>
          <p className="mb-4">
            <strong>8.2</strong> Any disputes arising out of or in connection
            with these terms and conditions shall be subject to the exclusive
            jurisdiction of the courts of the State of Delaware, United States.
          </p>

          {/* Section 9 */}
          <h2 className="text-2xl font-semibold mt-8 mb-4 text-gray-900">
            9. Promotional Communications
          </h2>
          <p className="mb-4">
            <strong>9.1</strong> By creating an account with Aurora Prep LLC,
            users consent to receive promotional communications and special
            offers from Aurora Prep LLC.
          </p>
          <p className="mb-4">
            <strong>9.2</strong> Users may opt out of receiving promotional
            communications at any time by following the unsubscribe instructions
            included in these communications or by contacting customer support.
          </p>

          {/* Final Acknowledgment */}
          <p className="mt-8">
            By subscribing to the Aurora Prep LLC consulting prep training
            program, users acknowledge that they have read, understood, and
            agree to be bound by these terms and conditions.
          </p>
          <p className="mt-3">
            Aurora Prep LLC is a Delaware corporation.
          </p>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;
