import React, { useState, useRef, useEffect } from 'react';
import { useAuth } from '@clerk/clerk-react';
import { useNavigate } from 'react-router-dom';
import { 
  Send,
  Briefcase,
  Users,
  Brain,
  MessageCircle,
  BookMarked,
  AlertCircle,
  Rocket,
  User,
  Copy,
  RotateCcw,
  ThumbsUp,
  ThumbsDown,
  BookOpen,
  RefreshCw,
  ChevronLeft,
  Menu,
  X
} from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Navbar from '../components/Navbar';

const AiTutor = () => {
  // Initial AI message
  const [messages, setMessages] = useState([
    {
      content:
        "👋 Hello! I'm your personal management consulting interview coach.\n\n" + 
        "Choose a topic from the menu or ask me anything to start preparing for your dream consulting role!",
      sender: "ai",
      timestamp: new Date().toISOString()
    }
  ]);

  const [inputMessage, setInputMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPrompt, setSelectedPrompt] = useState('');
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  // Refs
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);
  const firstRenderRef = useRef(true);

  // Clerk Auth
  const { getToken } = useAuth();
  const url = process.env.REACT_APP_BACK_END_URL;

  // Navigation
  const navigate = useNavigate();

  // Auto prompts array
  const autoPrompts = [
    {
      title: "Firm Backgrounds",
      icon: <Briefcase className="w-4 h-4" />,
      description: "Learn about top consulting firms",
      color: "from-blue-500 to-cyan-400",
      prompt:
        "Tell me about the MBB consulting firms, the big 4 and Accenture. Give me details on how each firm started and what they specialize in, as well as the size of their companies and other useful information I should know when interviewing."
    },
    {
      title: "Behavioral Interview Help",
      icon: <Users className="w-4 h-4" />,
      description: "Master behavioral questions",
      color: "from-purple-500 to-indigo-400",
      prompt: "Work with me to practice my behavioral interview skills for a consulting interview"
    },
    {
      title: "Mock Case",
      icon: <Brain className="w-4 h-4" />,
      description: "Practice case interviews",
      color: "from-pink-500 to-rose-400",
      prompt:
        "Can you give me a mock case interview for management consulting. And act along with me as if you were the interviewer so i can practice in real time? Give me a question, and then wait for me to respond before you ask me a follow up. If I say something that is not really logical, give me feedback politely on how I could improve my approach."
    },
    {
      title: "Storytelling",
      icon: <MessageCircle className="w-4 h-4" />,
      description: "Craft compelling narratives",
      color: "from-amber-500 to-yellow-400",
      prompt:
        "Ask me to provide information about my background and explain how it would be useful in consulting. Then I want you to tell me how effective my story was in a polite way, but offer recommendations on where I could improve my storytelling about myself to be more appealing for the interviewer at a management consulting firm."
    },
    {
      title: "Framework Help",
      icon: <BookMarked className="w-4 h-4" />,
      description: "Learn strategic frameworks",
      color: "from-emerald-500 to-teal-400",
      prompt:
        "Ask me which consulting strategy frameworks I need help understanding today, and then elaborate in detail on how each framework should be used after i respond."
    },
    {
      title: "What's your weakness?",
      icon: <AlertCircle className="w-4 h-4" />,
      description: "Answer challenging questions",
      color: "from-red-500 to-orange-400",
      prompt:
        "Ask me what my biggest weakness is. Then give me advice on how to better prepare for this interview question for management consulting."
    }
  ];

  // Scrolling logic
  const scrollToBottom = (smooth = true) => {
    messagesEndRef.current?.scrollIntoView({
      behavior: smooth ? 'smooth' : 'auto'
    });
  };

  useEffect(() => {
    // If there's only the welcome message, don't bother scrolling
    if (messages.length <= 1) return;

    if (firstRenderRef.current) {
      scrollToBottom(false);
      firstRenderRef.current = false;
    } else {
      scrollToBottom(true);
    }
  }, [messages]);

  // Like/Dislike states
  const [messageRatings, setMessageRatings] = useState({});
  const handleThumbsUp = (index) => {
    setMessageRatings((prev) => ({
      ...prev,
      [index]: prev[index] === 'up' ? undefined : 'up'
    }));
  };
  const handleThumbsDown = (index) => {
    setMessageRatings((prev) => ({
      ...prev,
      [index]: prev[index] === 'down' ? undefined : 'down'
    }));
  };

  // Copy functionality
  const [copiedMessageIndex, setCopiedMessageIndex] = useState(null);
  const handleCopy = (index) => {
    const textToCopy = messages[index]?.content || '';
    navigator.clipboard.writeText(textToCopy).then(() => {
      setCopiedMessageIndex(index);
      setTimeout(() => setCopiedMessageIndex(null), 1500);
    });
  };

  // Regenerate (re-run the API call)
  const handleRegenerate = (index) => {
    const aiMsg = messages[index];
    if (!aiMsg || aiMsg.sender !== 'ai' || !aiMsg.relatedUserMessage) return;

    // Remove the old AI message
    const newMessages = [...messages];
    newMessages.splice(index, 1);
    setMessages(newMessages);

    // Re-run handleSubmit with the original user input & prompt
    handleSubmit(null, aiMsg.relatedUserMessage, aiMsg.promptUsed);
  };

  // Focus on input when prompt is selected
  useEffect(() => {
    if (selectedPrompt) {
      inputRef.current?.focus();
    }
  }, [selectedPrompt]);

  // handleSubmit
  const handleSubmit = async (e = null, forcedMsg = '', forcedPromptType = '') => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    const finalMessage = forcedMsg || inputMessage;
    const finalPromptType = forcedPromptType || selectedPrompt;

    if (!finalMessage.trim()) return;

    // Add user message
    const newMessage = {
      content: finalMessage,
      sender: 'user',
      timestamp: new Date().toISOString()
    };
    setMessages((prev) => [...prev, newMessage]);
    setInputMessage('');
    setIsLoading(true);
    
    // On mobile, close sidebar when message is sent
    if (window.innerWidth < 768) {
      setIsSidebarOpen(false);
    }

    try {
      const token = await getToken();
      const response = await fetch(`${url}/api/chat`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          input: finalMessage,
          sessionId: 'default',
          promptType: finalPromptType
        })
      });

      if (!response.ok) {
        const errText = await response.text();
        console.error('Error fetching chat response:', errText);
        throw new Error('Failed to get response');
      }

      const data = await response.json();
      setMessages((prev) => [
        ...prev,
        {
          content: data.response,
          sender: 'ai',
          timestamp: new Date().toISOString(),
          relatedUserMessage: finalMessage,
          promptUsed: finalPromptType
        }
      ]);
    } catch (error) {
      console.error('Error:', error);
      // Add error message to chat
      setMessages((prev) => [
        ...prev,
        {
          content: "I'm sorry, I encountered an error while processing your request. Please try again.",
          sender: 'ai',
          timestamp: new Date().toISOString(),
          isError: true
        }
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  // Auto-prompt
  const handleAutoPromptSelect = (promptObj) => {
    setSelectedPrompt(promptObj.title);
    handleSubmit(null, promptObj.prompt, promptObj.title);
  };

  // Format timestamp 
  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  return (
    <div className="h-screen flex flex-col bg-gradient-to-br from-slate-50 to-purple-50">
      <Navbar />
      
      {/* Main container: full viewport height minus navbar */}
      <div className="flex-1 flex overflow-hidden">
        
        {/* Mobile Menu Toggle */}
        <button 
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          className="md:hidden fixed top-16 left-4 z-20 p-2 bg-white rounded-full shadow-lg"
        >
          {isSidebarOpen ? <X size={20} /> : <Menu size={20} />}
        </button>
        
        {/* Sidebar */}
        <div className={`fixed md:static inset-y-0 left-0 z-10 mt-14 md:mt-0 w-72 bg-white shadow-xl transform transition-transform duration-300 ease-in-out ${
          isSidebarOpen ? 'translate-x-0' : '-translate-x-full md:translate-x-0'
        }`}>
          <div className="h-full flex flex-col p-6 overflow-y-auto">
            <div className="mb-1 flex items-center justify-between">
              <h2 className="text-xl font-bold text-gray-900">Consulting Coach</h2>
              <button 
                onClick={() => setIsSidebarOpen(false)}
                className="md:hidden p-1.5 rounded-full hover:bg-gray-100"
              >
                <ChevronLeft size={18} />
              </button>
            </div>
            
            <p className="text-sm text-gray-500 mb-6">Select a topic or create your own question</p>
            
            <div className="space-y-2">
              {autoPrompts.map((prompt) => (
                <button
                  key={prompt.title}
                  onClick={() => handleAutoPromptSelect(prompt)}
                  className={`w-full px-4 py-2 rounded-xl text-left transition-all duration-200 flex items-center gap-2 ${
                    selectedPrompt === prompt.title
                      ? 'bg-violet-100 text-violet-700'
                      : 'hover:bg-gray-50 text-gray-700'
                  }`}
                >
                  {prompt.icon}
                  <span>{prompt.title}</span>
                </button>
              ))}
            </div>

            {/* Quick Actions */}
            <div className="mt-auto pt-4 border-t border-gray-100">
              <h3 className="text-sm font-medium text-gray-900 mb-3">Quick Actions</h3>
              <div className="space-y-2">
                <button
                  className="w-full px-4 py-2 rounded-xl text-left hover:bg-gray-50 text-gray-700 flex items-center gap-2"
                  onClick={() => {
                    setMessages([
                      {
                        content:
                          "👋 Hello! I'm your personal management consulting interview coach.\n\n" + 
                          "Choose a topic from the menu or ask me anything to start preparing for your dream consulting role!",
                        sender: "ai",
                        timestamp: new Date().toISOString()
                      }
                    ]);
                    setSelectedPrompt('');
                  }}
                >
                  <RefreshCw className="w-4 h-4" />
                  <span>New Session</span>
                </button>
                <button
                  className="w-full px-4 py-2 rounded-xl text-left hover:bg-gray-50 text-gray-700 flex items-center gap-2"
                  onClick={() => navigate('/intro')}
                >
                  <BookOpen className="w-4 h-4" />
                  <span>View Resources</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Main chat area */}
        <div className="flex-1 flex flex-col relative overflow-hidden">
          
          {/* Semi-transparent overlay when sidebar is open on mobile */}
          {isSidebarOpen && (
            <div 
              className="absolute inset-0 bg-black bg-opacity-20 z-10 md:hidden"
              onClick={() => setIsSidebarOpen(false)}
            />
          )}
          
          {/* Messages container */}
          <div className="flex-1 overflow-y-auto p-6">
            <div className="max-w-4xl mx-auto">
              {messages.map((message, index) => {
                const isAi = message.sender === 'ai';
                const isUser = message.sender === 'user';
                return (
                  <div key={index} className="mb-8 animate-fadeIn">
                    <div className="flex items-start space-x-4 group">
                      {/* Avatar */}
                      <div
                        className={`w-8 h-8 rounded-xl flex items-center justify-center flex-shrink-0 
                          ${
                            isUser
                              ? 'bg-gradient-to-r from-indigo-600 to-pink-500'
                              : message.isError 
                                ? 'bg-red-100' 
                                : 'bg-gradient-to-r from-indigo-100 to-pink-100'
                          }`}
                      >
                        {isUser ? (
                          <User className="w-4 h-4 text-white" />
                        ) : (
                          <Rocket className={`w-4 h-4 ${message.isError ? 'text-red-500' : 'text-indigo-600'}`} />
                        )}
                      </div>

                      {/* Message Content */}
                      <div className="flex-1 min-w-0">
                        <div className="text-sm text-gray-500 mb-1 flex items-center space-x-2">
                          <span>{isUser ? 'You' : 'Consulting Coach'}</span>
                          <span className="text-gray-300">•</span>
                          <span className="text-xs">{formatTime(message.timestamp)}</span>
                        </div>
                        <div className={`prose prose-sm md:prose-base leading-relaxed ${isUser ? 'text-gray-800' : message.isError ? 'text-red-700' : 'text-gray-800'}`}>
                          {isAi ? (
                            <ReactMarkdown 
                              remarkPlugins={[remarkGfm]}
                              components={{
                                p: ({node, ...props}) => <p className="mb-3 last:mb-0" {...props} />,
                                h1: ({node, ...props}) => <h1 className="text-xl font-bold mb-3 mt-4" {...props} />,
                                h2: ({node, ...props}) => <h2 className="text-lg font-bold mb-2 mt-4" {...props} />,
                                h3: ({node, ...props}) => <h3 className="text-md font-bold mb-2 mt-3" {...props} />,
                                ul: ({node, ...props}) => <ul className="mb-3 pl-6 list-disc" {...props} />,
                                ol: ({node, ...props}) => <ol className="mb-3 pl-6 list-decimal" {...props} />,
                                li: ({node, ...props}) => <li className="mb-1" {...props} />
                              }}
                            >
                              {message.content}
                            </ReactMarkdown>
                          ) : (
                            message.content
                          )}
                        </div>

                        {/* AI Message Buttons */}
                        {isAi && (
                          <div className="flex items-center space-x-2 mt-3 opacity-0 group-hover:opacity-100 transition-opacity">
                            {/* Copy button */}
                            <button
                              onClick={() => handleCopy(index)}
                              className="p-1.5 hover:bg-gray-100 rounded-md transition-all relative
                                         text-gray-500 hover:text-gray-700"
                            >
                              <Copy className="w-4 h-4" />
                              {copiedMessageIndex === index && (
                                <span className="absolute left-full ml-2 text-xs text-green-600">
                                  Copied!
                                </span>
                              )}
                            </button>

                            {/* Regenerate button */}
                            {message.relatedUserMessage && (
                              <button
                                onClick={() => handleRegenerate(index)}
                                className="p-1.5 hover:bg-gray-100 rounded-md transition-all text-gray-500 hover:text-gray-700"
                              >
                                <RotateCcw className="w-4 h-4" />
                              </button>
                            )}

                            <div className="h-4 w-px bg-gray-200 mx-1" />

                            {/* Thumbs Up */}
                            <button
                              onClick={() => handleThumbsUp(index)}
                              className={`p-1.5 hover:bg-gray-100 rounded-md transition-all 
                                ${
                                  messageRatings[index] === 'up'
                                    ? 'text-green-600 scale-110'
                                    : 'text-gray-500 hover:text-gray-700'
                                }`}
                            >
                              <ThumbsUp className="w-4 h-4" />
                            </button>

                            {/* Thumbs Down */}
                            <button
                              onClick={() => handleThumbsDown(index)}
                              className={`p-1.5 hover:bg-gray-100 rounded-md transition-all 
                                ${
                                  messageRatings[index] === 'down'
                                    ? 'text-red-600 scale-110'
                                    : 'text-gray-500 hover:text-gray-700'
                                }`}
                            >
                              <ThumbsDown className="w-4 h-4" />
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}

              {/* Typing / Loading Indicator */}
              {isLoading && (
                <div className="mb-8 animate-fadeIn">
                  <div className="flex items-start space-x-4 group">
                    <div className="w-8 h-8 rounded-xl flex items-center justify-center flex-shrink-0 bg-gradient-to-r from-indigo-100 to-pink-100">
                      <Rocket className="w-4 h-4 text-indigo-600" />
                    </div>
                    <div className="flex-1 min-w-0">
                      <div className="text-sm text-gray-500 mb-1 flex items-center space-x-2">
                        <span>Consulting Coach</span>
                        <span className="text-gray-300">•</span>
                        <span className="text-xs">Thinking...</span>
                      </div>
                      <div className="flex items-center gap-1">
                        <span className="animate-pulse h-2 w-2 rounded-full bg-indigo-500"></span>
                        <span className="animate-pulse delay-100 h-2 w-2 rounded-full bg-indigo-400"></span>
                        <span className="animate-pulse delay-200 h-2 w-2 rounded-full bg-indigo-300"></span>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* Scroll anchor */}
              <div ref={messagesEndRef} />
            </div>
          </div>

          {/* Input Area */}
          <div className="sticky bottom-0 left-0 w-full px-6 pb-6">
            <div className="mx-auto w-full max-w-4xl bg-white/80 backdrop-blur-md shadow-xl rounded-lg p-4 border border-gray-200">
              <form onSubmit={handleSubmit} className="flex gap-3">
                <input
                  ref={inputRef}
                  type="text"
                  value={inputMessage}
                  onChange={(e) => setInputMessage(e.target.value)}
                  placeholder={selectedPrompt ? "Ask follow-up questions..." : "Ask me anything about consulting interviews..."}
                  className="flex-1 px-4 py-3 rounded-lg border border-gray-200 
                             focus:ring-2 focus:ring-violet-500 focus:border-transparent 
                             transition-all duration-200 text-gray-900 text-sm md:text-base"
                />
                <button
                  type="submit"
                  className="inline-flex items-center gap-2 px-4 py-3 bg-gradient-to-r from-violet-600 to-pink-500 
                             hover:from-violet-700 hover:to-pink-600 text-white font-medium 
                             rounded-lg transition-all duration-200 disabled:opacity-70"
                  disabled={isLoading || !inputMessage.trim()}
                >
                  <Send className="w-4 h-4" />
                  <span className="hidden sm:inline">Send</span>
                </button>
              </form>
              
              {/* Selected topic indicator */}
              {selectedPrompt && (
                <div className="flex items-center mt-2 text-xs text-gray-500">
                  <span>Topic: </span>
                  <span className="ml-1 px-2 py-0.5 bg-violet-50 text-violet-700 rounded-full font-medium">
                    {selectedPrompt}
                  </span>
                  <button 
                    onClick={() => setSelectedPrompt('')}
                    className="ml-1 text-gray-400 hover:text-gray-600"
                  >
                    <X size={12} />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AiTutor;