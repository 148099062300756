import React, { useState } from 'react';
import {
  ChevronDown,
  ChevronUp,
  Divide,
  Calculator,
  ArrowLeftRight,
  Plus,
  Ruler,
  Brain
} from 'lucide-react';
import Navbar from '../components/Navbar';
import MathSideBar from '../components/MathSideBar';

const ConversionsGuide = () => {
  const [openSections, setOpenSections] = useState({
    fractions: true,
    decimals: false,
    percentages: false,
    calculating: false,
    estimating: false,
    combining: false,
  });

  const toggleSection = (section) => {
    setOpenSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const Section = ({ title, children, isOpen, onToggle, icon: Icon }) => (
    <div className="mb-8 rounded-lg bg-white shadow-lg overflow-hidden">
      <button
        onClick={onToggle}
        className="w-full px-6 py-4 flex items-center justify-between bg-gradient-to-r from-violet-600 to-pink-500 text-white"
      >
        <div className="flex items-center gap-3">
          <Icon size={24} />
          <h2 className="text-xl font-semibold">{title}</h2>
        </div>
        {isOpen ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
      </button>
      {isOpen && (
        <div className="p-6 bg-violet-50">
          {children}
        </div>
      )}
    </div>
  );

  const Example = ({ title, children }) => (
    <div className="mt-4 p-4 bg-white rounded-lg shadow-sm border border-violet-100">
      <h4 className="font-semibold text-lg mb-2">{title}</h4>
      <div className="text-gray-600">{children}</div>
    </div>
  );

  const ConversionExample = ({ from, to, steps }) => (
    <div className="flex items-center gap-3 text-gray-600">
      <div className="font-medium">{from}</div>
      <ArrowLeftRight className="text-violet-500" size={20} />
      <div className="font-medium">{to}</div>
      <div className="ml-4 text-gray-500">→ {steps}</div>
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />
      <MathSideBar />

      {/* Main content area accounting for sidebar width */}
      <div className="lg:ml-72">
        {/* Page content container */}
        <main className="pt-20 px-4 pb-8 max-w-4xl mx-auto">
          <div className="text-center mb-12">
            <div className="inline-block p-3 rounded-full bg-gradient-to-br from-violet-600 to-pink-500 mb-6">
              <Calculator className="w-12 h-12 text-white" />
            </div>
            <h1 className="text-4xl font-bold mb-4 bg-gradient-to-r from-violet-600 to-pink-500 bg-clip-text text-transparent">
              Fractions, Decimals, and Percentages
            </h1>
            <p className="text-gray-600 text-lg max-w-2xl mx-auto">
              Master quick conversions and calculations for business analysis
            </p>
          </div>

          <div className="bg-white rounded-xl p-6 shadow-lg mb-8">
            <p className="text-gray-600 leading-relaxed">
              In management consulting and business settings, being able to quickly perform calculations involving
              fractions, decimals, and percentages is crucial. These fundamental concepts are key to analyzing
              financial statements and making data-driven decisions.
            </p>
          </div>

          <Section
            title="Converting Fractions to Decimals"
            isOpen={openSections.fractions}
            onToggle={() => toggleSection('fractions')}
            icon={Divide}
          >
            <p className="text-gray-600 mb-4">
              To convert a fraction to a decimal, divide the numerator by the denominator. For simple fractions,
              you can perform this division mentally.
            </p>
            <Example title="Simple Fraction Conversion">
              <div className="space-y-3">
                <ConversionExample
                  from="3/4"
                  to="0.75"
                  steps="3 ÷ 4 = 0.75"
                />
                <ConversionExample
                  from="7/13"
                  to="~0.54"
                  steps="Slightly more than 1/2 (0.5)"
                />
              </div>
            </Example>
          </Section>

          <Section
            title="Converting Decimals to Fractions"
            isOpen={openSections.decimals}
            onToggle={() => toggleSection('decimals')}
            icon={Calculator}
          >
            <p className="text-gray-600 mb-4">
              Identify the place value of the last digit for the denominator, then write the numerator as the digits
              without the decimal point.
            </p>
            <Example title="Decimal to Fraction Conversion">
              <div className="space-y-4">
                <ConversionExample
                  from="0.625"
                  to="5/8"
                  steps="625/1000 simplified"
                />
                <div className="bg-violet-50 p-3 rounded-lg">
                  <p className="font-medium text-violet-700">Steps:</p>
                  <ol className="list-decimal ml-5 mt-2 space-y-1">
                    <li>Identify thousandths place (1000)</li>
                    <li>Write as 625/1000</li>
                    <li>Simplify to 5/8</li>
                  </ol>
                </div>
              </div>
            </Example>
          </Section>

          <Section
            title="Converting Percentages"
            isOpen={openSections.percentages}
            onToggle={() => toggleSection('percentages')}
            icon={ArrowLeftRight}
          >
            <div className="space-y-4">
              <div>
                <h3 className="font-medium mb-2">Percentage to Fraction:</h3>
                <Example title="Converting 45%">
                  <div className="space-y-2">
                    <ConversionExample
                      from="45%"
                      to="9/20"
                      steps="45/100 simplified"
                    />
                  </div>
                </Example>
              </div>
              <div>
                <h3 className="font-medium mb-2">Percentage to Decimal:</h3>
                <Example title="Converting 18%">
                  <div className="space-y-2">
                    <ConversionExample
                      from="18%"
                      to="0.18"
                      steps="18 ÷ 100"
                    />
                  </div>
                </Example>
              </div>
            </div>
          </Section>

          <Section
            title="Calculating Percentages"
            isOpen={openSections.calculating}
            onToggle={() => toggleSection('calculating')}
            icon={Plus}
          >
            <p className="text-gray-600 mb-4">
              Convert the percentage to a decimal and multiply, or use mental shortcuts for common percentages.
            </p>
            <Example title="Quick Percentage Calculations">
              <div className="space-y-4">
                <div className="p-3 bg-violet-50 rounded-lg">
                  <p className="font-medium">15% of 80:</p>
                  <ol className="list-decimal ml-5 mt-2">
                    <li>15% = 0.15</li>
                    <li>0.15 × 80 = 12</li>
                  </ol>
                </div>
                <div className="p-3 bg-violet-50 rounded-lg">
                  <p className="font-medium">25% of 120:</p>
                  <ol className="list-decimal ml-5 mt-2">
                    <li>25% = 1/4</li>
                    <li>120 ÷ 4 = 30</li>
                  </ol>
                </div>
              </div>
            </Example>
          </Section>

          <Section
            title="Estimating Percentages"
            isOpen={openSections.estimating}
            onToggle={() => toggleSection('estimating')}
            icon={Ruler}
          >
            <p className="text-gray-600 mb-4">
              Use benchmark values and rounding techniques for quick estimates.
            </p>
            <Example title="Percentage Estimation">
              <div className="space-y-3">
                <p>Estimate 27 out of 85:</p>
                <div className="bg-violet-50 p-3 rounded-lg">
                  <ol className="list-decimal ml-5">
                    <li>Round to 30 out of 100</li>
                    <li>Equals approximately 30%</li>
                    <li>Actual value: 31.8%</li>
                  </ol>
                </div>
              </div>
            </Example>
          </Section>

          <Section
            title="Combining Conversions"
            isOpen={openSections.combining}
            onToggle={() => toggleSection('combining')}
            icon={Brain}
          >
            <p className="text-gray-600 mb-4">
              Convert all values to a common format (usually decimals) before calculating.
            </p>
            <Example title="Complex Calculation Example">
              <div className="space-y-3">
                <p className="font-medium">Calculate: 2/5 of 60% of 250</p>
                <div className="bg-violet-50 p-3 rounded-lg">
                  <ol className="list-decimal ml-5">
                    <li>2/5 = 0.4</li>
                    <li>60% = 0.6</li>
                    <li>0.4 × 0.6 = 0.24</li>
                    <li>0.24 × 250 = 60</li>
                  </ol>
                </div>
              </div>
            </Example>
          </Section>

          <div className="mt-8 p-6 bg-violet-50 rounded-lg border border-violet-100">
            <h2 className="text-xl font-semibold mb-4">Keep Practicing!</h2>
            <p className="text-gray-600">
              With practice and persistence, working with fractions, decimals, and percentages will become more
              intuitive and efficient. Regular practice will help you tackle complex calculations confidently and
              impress your colleagues and clients with your mental math skills.
            </p>
          </div>
        </main>
      </div>
    </div>
  );
};

export default ConversionsGuide;