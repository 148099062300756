import React, { useState } from 'react';
import { CheckCircle, Menu as MenuIcon, LayoutDashboard } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import LessonNavigation from '../components/LessonNavigation';
import { useAuth } from '@clerk/clerk-react'; // <-- Clerk Auth import

const FourPsMarketingPage = () => {
  const [buttonState, setButtonState] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const navigate = useNavigate();

  // If you're using Create React App, put REACT_APP_BACK_END_URL in your .env
  // e.g. REACT_APP_BACK_END_URL=https://your-backend.com
  const url = process.env.REACT_APP_BACK_END_URL;

  // Clerk auth helpers (if your backend requires token-based auth)
  const { userId, getToken } = useAuth();

  const activeLessonTitle = "4 Ps of Marketing";
  const prevLessonPath = "/3cs"; // Adjust as needed
  const nextLessonPath = "/market"; // Adjust as needed

  // Handle the POST request to mark the lesson as completed
  const handleMarkCompleted = async () => {
    try {
      // Retrieve Clerk's session token if the server needs it
      const token = await getToken();

      // Post to your backend
      const response = await fetch(`${url}/api/marked-completed`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // if required
        },
        body: JSON.stringify({
          pageTitle: '4 Ps of Marketing',
          clerkUserId: userId,
        }),
      });

      if (!response.ok) {
        throw new Error(`Failed to mark completed: ${response.statusText}`);
      }

      // Success => update button state
      setButtonState(true);
      console.log('Lesson successfully marked as completed!');
    } catch (error) {
      console.error('Error marking lesson completed:', error);
      // Handle or display error message if needed
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex">
      {isSidebarOpen && (
        <Sidebar 
          isSidebarOpen={isSidebarOpen} 
          setIsSidebarOpen={setIsSidebarOpen} 
          activeLessonTitle={activeLessonTitle}
        />
      )}

      <div className={`flex-1 transition-all duration-300 ${isSidebarOpen ? 'ml-80' : 'ml-0'}`}>
        {/* Top Navigation */}
        <div className="bg-white border-b sticky top-0 z-40">
          <div className="h-16 flex items-center px-6">
            {!isSidebarOpen && (
              <button 
                onClick={() => setIsSidebarOpen(true)} 
                className="text-gray-500 hover:text-gray-700 mr-4"
              >
                <MenuIcon className="w-6 h-6" />
              </button>
            )}

            <button 
              onClick={() => navigate('/main')}
              className="flex items-center space-x-2 px-4 py-2 text-gray-600 hover:text-gray-900 hover:bg-gray-50 rounded-lg transition-colors duration-200"
            >
              <LayoutDashboard className="w-4 h-4" />
              <span>Back to Dashboard</span>
            </button>

            <div className="flex-1"></div>

            <LessonNavigation 
              prevLessonPath={prevLessonPath}
              nextLessonPath={nextLessonPath}
            />
          </div>
        </div>

        {/* Page Content */}
        <div className="max-w-4xl mx-auto px-8 py-12">
          {buttonState ? (
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              The 4 P's of Marketing
              <CheckCircle className="w-6 h-6 text-green-500 inline-block ml-2" />
            </h2>
          ) : (
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              The 4 P's of Marketing
            </h2>
          )}

          <div className="rounded-xl overflow-hidden shadow-lg mb-6">
            <div className="aspect-w-16 aspect-h-9">
              <video width="100%" height="auto" controls className="object-cover">
                <source src="/images/4pMarketings.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>

          <div className="p-6 rounded-xl mb-12" style={{ backgroundColor: '#F0F4FA' }}>
            <h3 className="text-2xl font-bold mb-4">The History of the 4 Ps: A Framework for Strategic Marketing</h3>
            <p className="text-gray-700 mb-4">
              The emergence of the 4 Ps of marketing can be traced back to the 1960s and the work of marketing professor E. Jerome McCarthy. Here's a breakdown of its origin story:
            </p>
            <ul className="list-disc list-inside mb-4 text-gray-700 pl-4">
              <li>
                <strong>The Rise of Marketing:</strong> In the post-war era, marketing emerged as a distinct discipline. Businesses were no longer just producing goods, they were actively trying to sell them in a competitive marketplace.
              </li>
              <li>
                <strong>The Need for a Framework:</strong> Marketers needed a way to think strategically about the factors that influenced a product's success. Simply creating a good product wasn't enough – it also needed to be priced right, distributed effectively, and promoted in a way that resonated with the target audience.
              </li>
              <li>
                <strong>McCarthy's Contribution:</strong> E. Jerome McCarthy introduced the marketing mix concept summarized as the 4 Ps: Product, Price, Place, and Promotion.
              </li>
              <li>
                <strong>Before the 4 Ps:</strong> Ideas existed, but McCarthy formalized them, making the 4 Ps a concise, memorable framework.
              </li>
              <li>
                <strong>The Impact of the 4 Ps:</strong> The 4 Ps became a cornerstone of marketing education and practice, helping businesses understand and manage the factors influencing product success.
              </li>
            </ul>
            <p className="text-gray-700">
              Now, let's delve into each P and explore how they form a powerful marketing strategy.
            </p>
          </div>

          <div className="mb-12">
            <h3 className="text-2xl font-bold mb-4">Product: The Foundation of Value</h3>
            <p className="text-gray-700 mb-4">
              The product is the cornerstone of your marketing strategy. It's more than just the physical good or intangible service. It encompasses the benefits, user experience, and overall value proposition. What problem does it solve? What need does it fulfill?
            </p>
            <p className="text-gray-700 mb-4">
              As a consultant, understanding these aspects helps in advising clients on product positioning and development.
            </p>
            <p className="text-gray-700 mb-4">
              Consider products you love and why. This mindset helps create vision boards and determine product-market fit.
            </p>
            <p className="text-gray-700 mb-4">
              Let's consider a tech firm entering the smartwatch arena. Questions arise: how to position features, address battery life issues, and meet consumer preferences?
            </p>

            <h4 className="text-lg font-semibold mb-2">Product scenario: Rethinking the Smartwatch</h4>
            <p className="text-gray-700 mb-4">
              If the smartwatch is feature-packed but has shorter battery life and bulky design, customers may be dissatisfied. As consultants, we might:
            </p>
            <ol className="list-decimal list-inside mb-4 text-gray-700 pl-4">
              <li>Complete a product market scan to identify leading features and reasons behind them.</li>
              <li>Conduct a consumer survey to rank product features and understand what's most important to buyers.</li>
              <li>Create consumer segmentation analyses to ensure the product targets the right audience.</li>
            </ol>
          </div>

          <div className="p-6 rounded-xl mb-12" style={{ backgroundColor: '#F0F4FA' }}>
            <h3 className="text-2xl font-bold mb-4">Price: The Balancing Act</h3>
            <p className="text-gray-700 mb-4">
              Price is about perceived value. Factors include production cost, competitor pricing, and market demand. Setting the right price is crucial. Too high alienates customers; too low erodes perceived value.
            </p>
            <p className="text-gray-700 mb-4">
              Consider if the product fits luxury or everyday markets. Luxury might mean premium pricing and features. Everyday goods might compete more on price.
            </p>

            <h4 className="text-lg font-semibold mb-2">Scenario 2: Luxury vs. Value Headphones</h4>
            <p className="text-gray-700 mb-4">
              Some headphone brands are premium (Apple, Bose) with high prices, others offer good quality at lower prices. Each brand matches their pricing strategy with their target consumer segment.
            </p>
            <p className="text-gray-700">
              Consultants must understand competitors' value propositions and determine where the client can leverage a competitive advantage to set their prices.
            </p>
          </div>

          <div className="mb-12">
            <h3 className="text-2xl font-bold mb-4">Place: Getting Your Product There</h3>
            <p className="text-gray-700 mb-4">
              Place refers to distribution channels and ensuring customers can access your product easily. It involves inventory management, supply chains, and whether sales are online or via brick-and-mortar.
            </p>
            <p className="text-gray-700 mb-4">
              Understanding a client's distribution model, inventory turnover, and logistics is critical to addressing pain points and seizing opportunities.
            </p>
            <h4 className="text-lg font-semibold mb-2">Scenario 3: Expanding the Reach of Coffee Cups</h4>
            <p className="text-gray-700 mb-4">
              Cupco, a coffee cup manufacturer, sells locally. To expand to the East Coast, they must consider distribution models: online presence, sales teams, and possibly new manufacturing or offices. The right place strategy ensures customers find their products conveniently.
            </p>
          </div>

          <div className="p-6 rounded-xl mb-12" style={{ backgroundColor: '#F0F4FA' }}>
            <h3 className="text-2xl font-bold mb-4">Promotion: Spreading the Word</h3>
            <p className="text-gray-700 mb-4">
              Promotion creates awareness and drives sales. It includes advertising, PR, social media marketing, and more. Effective promotion resonates with the target audience.
            </p>
            <p className="text-gray-700 mb-4">
              Consultants may find clients relying on traditional methods or needing to explore new channels. The goal is to identify the best promotional mix within budget constraints.
            </p>
            <h4 className="text-lg font-semibold mb-2">Scenario 4: Launching a New Fitness App</h4>
            <p className="text-gray-700 mb-4">
              FitMe, a new fitness app with a limited budget, might leverage social media and partnerships instead of expensive advertising. By targeting fitness communities online, it can generate buzz cost-effectively.
            </p>
            <p className="text-gray-700">
              Consultants guide clients to creatively reach their audience, often forming strategic partnerships or focusing on influencer marketing.
            </p>
          </div>

          <div className="mb-12">
            <h3 className="text-2xl font-bold mb-4">The Ps in Action: A Case Study</h3>
            <p className="text-gray-700 mb-4">
              Consider an organic food delivery service using apps and subscriptions. By applying the 4 Ps, we can ask:
            </p>
            <ul className="list-disc list-inside mb-4 text-gray-700 pl-4">
              <li><strong>Product:</strong> Is variety sufficient? Could meal kits add value?</li>
              <li><strong>Price:</strong> Is it competitive? Could flexible tiers or discounts help?</li>
              <li><strong>Place:</strong> Should we expand delivery areas or partner with stores for pick-up?</li>
              <li><strong>Promotion:</strong> Are we using social media effectively? Influencer partnerships?</li>
            </ul>
            <div className="rounded-xl overflow-hidden shadow-lg mb-6">
              <div className="aspect-w-16 aspect-h-9">
                <video width="100%" height="auto" controls className="object-cover">
                  <source src="/images/4pscase.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>

          <div className="mb-12">
            <h2 className="text-2xl font-bold mb-4">Conclusion</h2>
            <p className="text-gray-700 mb-4">
              Understanding the 4Ps framework is crucial for consultants. It provides a structured approach to analyze and optimize marketing strategies.
            </p>
            <p className="text-gray-700 mb-4">
              By mastering the 4Ps, consultants can offer insights that improve market positioning and business performance.
            </p>
            <p className="text-gray-700">
              Ultimately, the 4Ps equip consultants with the tools to deliver impactful solutions and maintain a competitive edge in a dynamic market.
            </p>
          </div>

          {/* Completion Button */}
          <div className="text-center mt-12">
            {buttonState ? (
              <button 
                type="button"
                className="px-8 py-3 bg-green-500 text-white rounded-xl shadow-lg flex items-center justify-center space-x-2 mx-auto hover:bg-green-600 transition-colors duration-200"
              >
                <CheckCircle className="w-5 h-5" />
                <span>Completed Session</span>
              </button>
            ) : (
              <button 
                type="button"
                onClick={handleMarkCompleted}
                className="px-8 py-3 bg-gradient-to-r from-indigo-500 to-purple-500 text-white rounded-xl shadow-lg flex items-center justify-center space-x-2 mx-auto hover:shadow-xl transition-all duration-200"
              >
                <span>Mark Lesson Completed</span>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FourPsMarketingPage;
