import React, { useState } from 'react';
import {
  ChevronDown,
  ChevronUp,
  Brain,
  Calculator,
  Plus,
  Minus,
  X,
  Divide
} from 'lucide-react';
import Navbar from '../components/Navbar';
import MathSideBar from '../components/MathSideBar';

const MentalMathGuide = () => {
  const [openSections, setOpenSections] = useState({
    addition: true,
    subtraction: false,
    multiplication: false,
    division: false,
  });

  const toggleSection = (section) => {
    setOpenSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const Section = ({ title, children, isOpen, onToggle, icon: Icon }) => (
    <div className="mb-8 rounded-lg bg-white shadow-lg overflow-hidden">
      <button
        onClick={onToggle}
        className="w-full px-6 py-4 flex items-center justify-between bg-gradient-to-r from-violet-600 to-pink-500 text-white"
      >
        <div className="flex items-center gap-3">
          <Icon size={24} />
          <h2 className="text-xl font-semibold">{title}</h2>
        </div>
        {isOpen ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
      </button>
      {isOpen && (
        <div className="p-6 bg-violet-50">
          {children}
        </div>
      )}
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />
      <MathSideBar />

      {/* Main content area accommodating the sidebar */}
      <div className="lg:ml-72">
        <main className="pt-20 px-4 pb-8 max-w-4xl mx-auto">
          <div className="text-center mb-12">
            <div className="inline-block p-3 rounded-full bg-gradient-to-br from-purple-600 to-purple-400 mb-6">
              <Brain className="w-12 h-12 text-white" />
            </div>
            <h1 className="text-4xl font-bold mb-4 bg-gradient-to-r from-purple-600 to-purple-400 bg-clip-text text-transparent">
              Mastering Basic Arithmetic Shortcuts
            </h1>
            <p className="text-gray-600 text-lg max-w-2xl mx-auto">
              Learn essential mental math techniques to excel in case interviews and consulting work
            </p>
          </div>

          <div className="bg-white rounded-xl p-6 shadow-lg mb-8">
            <h2 className="text-2xl font-semibold mb-4">Introduction to Mental Math Shortcuts</h2>
            <p className="text-gray-600 leading-relaxed">
              As an aspiring management consultant, being able to perform quick mental calculations can be a
              valuable skill during case interviews and on the job. By mastering a few basic arithmetic
              shortcuts, you can save time, impress interviewers, and solve problems more efficiently.
            </p>
          </div>

          <Section
            title="Addition Shortcuts"
            isOpen={openSections.addition}
            onToggle={() => toggleSection('addition')}
            icon={Plus}
          >
            <ul className="space-y-6">
              <li>
                <h3 className="font-semibold text-lg mb-2">Breaking numbers into parts</h3>
                <p className="text-gray-600">
                  When adding large numbers, break them down into smaller, more manageable parts. For example,
                  when adding 48 + 35, you can think of it as (40 + 30) + (8 + 5) = 70 + 13 = 83.
                </p>
              </li>
              <li>
                <h3 className="font-semibold text-lg mb-2">Rounding and compensating</h3>
                <p className="text-gray-600">
                  Round one number to the nearest tens place, perform the addition, and then compensate for the
                  rounding. For example, 78 + 24 can be calculated as (78 + 20) + 4 = 98 + 4 = 102.
                </p>
              </li>
            </ul>
          </Section>

          <Section
            title="Subtraction Shortcuts"
            isOpen={openSections.subtraction}
            onToggle={() => toggleSection('subtraction')}
            icon={Minus}
          >
            <ul className="space-y-6">
              <li>
                <h3 className="font-semibold text-lg mb-2">Adding up</h3>
                <p className="text-gray-600">
                  Instead of subtracting, add up from the smaller number to the larger one. For example, to
                  calculate 92 - 78, think of it as 78 + ? = 92. You can quickly determine that 78 + 14 = 92,
                  so 92 - 78 = 14.
                </p>
              </li>
              <li>
                <h3 className="font-semibold text-lg mb-2">Rounding and compensating</h3>
                <p className="text-gray-600">
                  Similar to addition, round the number you're subtracting to the nearest tens place, perform
                  the subtraction, and then compensate. For example, 103 - 28 can be calculated as (103 - 30)
                  + 2 = 73 + 2 = 75.
                </p>
              </li>
            </ul>
          </Section>

          <Section
            title="Multiplication Shortcuts"
            isOpen={openSections.multiplication}
            onToggle={() => toggleSection('multiplication')}
            icon={X}
          >
            <ul className="space-y-6">
              <li>
                <h3 className="font-semibold text-lg mb-2">Multiplying by powers of 2</h3>
                <p className="text-gray-600">
                  To multiply a number by a power of 2 (e.g., 2, 4, 8, 16), you can use simple doubling. For
                  example, to calculate 12 × 8, start with 12, double it to get 24, double 24 to get 48, and
                  double 48 to get 96.
                </p>
              </li>
              <li>
                <h3 className="font-semibold text-lg mb-2">Multiplying by 11</h3>
                <p className="text-gray-600">
                  To multiply a two-digit number by 11, add the digits together and place the sum between the
                  original digits. If the sum is greater than 9, carry the tens place to the hundreds place.
                  For example, 47 × 11 = 4_(4+7)_7 = 517.
                </p>
              </li>
            </ul>
          </Section>

          <Section
            title="Division Shortcuts"
            isOpen={openSections.division}
            onToggle={() => toggleSection('division')}
            icon={Divide}
          >
            <ul className="space-y-6">
              <li>
                <h3 className="font-semibold text-lg mb-2">Dividing by powers of 2</h3>
                <p className="text-gray-600">
                  To divide a number by a power of 2, you can use simple halving. For example, to calculate
                  96 ÷ 8, start with 96, halve it to get 48, halve 48 to get 24, and halve 24 to get 12.
                </p>
              </li>
              <li>
                <h3 className="font-semibold text-lg mb-2">Dividing by 5</h3>
                <p className="text-gray-600">
                  To divide a number by 5, multiply it by 2 and then move the decimal point one place to the
                  left. For example, 135 ÷ 5 can be calculated as (135 × 2) ÷ 10 = 270 ÷ 10 = 27.
                </p>
              </li>
            </ul>
          </Section>

          <div className="mt-8 p-6 bg-purple-50 rounded-lg border border-violet-100">
            <h2 className="text-xl font-semibold mb-4">Keep Practicing!</h2>
            <p className="text-gray-600">
              By practicing these shortcuts and incorporating them into your mental math toolkit, you'll be
              able to perform calculations more quickly and accurately. Remember, the key to mastering mental
              math is consistent practice. Challenge yourself with various problems and work on applying these
              techniques until they become second nature.
            </p>
          </div>
        </main>
      </div>
    </div>
  );
};

export default MentalMathGuide;