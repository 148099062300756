import React, { useState } from 'react';
import { CheckCircle, Menu as MenuIcon, LayoutDashboard } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import LessonNavigation from '../components/LessonNavigation';
import { useAuth } from '@clerk/clerk-react'; // <-- Clerk Auth import

const BcgMatrixPage = () => {
  const [buttonState, setButtonState] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const navigate = useNavigate();

  // If you're using Create React App, define REACT_APP_BACK_END_URL in your .env
  // e.g. REACT_APP_BACK_END_URL=https://your-backend.com
  const url = process.env.REACT_APP_BACK_END_URL;

  // Clerk auth helpers (if your backend requires token-based auth)
  const { userId, getToken } = useAuth();

  const activeLessonTitle = 'BCG Matrix';
  const prevLessonPath = '/break-even'; // Adjust as needed
  const nextLessonPath = '/main';       // Adjust as needed

  // Handle the POST request to mark this lesson as completed
  const handleMarkCompleted = async () => {
    try {
      // Retrieve Clerk's session token if your server needs it
      const token = await getToken();

      // POST to your backend
      const response = await fetch(`${url}/api/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // If your server requires the token
        },
        body: JSON.stringify({
          pageTitle: 'BCG Matrix', // Must match your lesson's title
          clerkUserId: userId,
        }),
      });

      if (!response.ok) {
        throw new Error(`Failed to mark completed: ${response.statusText}`);
      }

      // If successful, set button to "Completed Session"
      setButtonState(true);
      console.log('Lesson successfully marked as completed!');
    } catch (error) {
      console.error('Error marking lesson completed:', error);
      // Optionally handle or display an error message
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex">
      {/* Sidebar */}
      {isSidebarOpen && (
        <Sidebar
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
          activeLessonTitle={activeLessonTitle}
        />
      )}

      {/* Main Content */}
      <div className={`flex-1 transition-all duration-300 ${isSidebarOpen ? 'ml-80' : 'ml-0'}`}>
        
        {/* Top Navigation */}
        <div className="bg-white border-b sticky top-0 z-40">
          <div className="h-16 flex items-center px-6">
            {!isSidebarOpen && (
              <button 
                onClick={() => setIsSidebarOpen(true)} 
                className="text-gray-500 hover:text-gray-700 mr-4"
              >
                <MenuIcon className="w-6 h-6" />
              </button>
            )}

            <button 
              onClick={() => navigate('/main')}
              className="flex items-center space-x-2 px-4 py-2 text-gray-600 hover:text-gray-900 hover:bg-gray-50 rounded-lg transition-colors duration-200"
            >
              <LayoutDashboard className="w-4 h-4" />
              <span>Back to Dashboard</span>
            </button>

            <div className="flex-1"></div>

            <LessonNavigation
              prevLessonPath={prevLessonPath}
              nextLessonPath={nextLessonPath}
            />
          </div>
        </div>

        {/* Page Content */}
        <div className="max-w-4xl mx-auto px-8 py-12">
          {buttonState ? (
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              BCG Matrix
              <CheckCircle className="w-6 h-6 text-green-500 inline-block ml-2" />
            </h2>
          ) : (
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              BCG Matrix
            </h2>
          )}

          <div className="rounded-xl overflow-hidden shadow-lg mb-6">
            <div className="aspect-w-16 aspect-h-9">
              <video width="100%" height="auto" controls className="object-cover">
                <source src="/images/bcg.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>

          <div className="p-6 rounded-xl mb-12 bg-[#F0F4FA]">
            <h3 className="text-2xl font-bold mb-4">
              Mastering the Growth Share (BCG) Matrix: A Comprehensive Guide for Aspiring Management Consultants
            </h3>
          </div>

          <p className="text-gray-700 mb-6">
            As an aspiring management consultant, you'll encounter various frameworks to analyze business 
            problems. The BCG Matrix, also known as the Growth-Share Matrix, is one such tool. Let's dive 
            into its history, structure, and application, and then walk through a case example.
          </p>

          <div className="p-6 rounded-xl mb-12 bg-[#F0F4FA]">
            <h3 className="text-2xl font-bold mb-4">History of the BCG Matrix</h3>
            <p className="text-gray-700 mb-4">
              Developed by Bruce Henderson of the Boston Consulting Group in 1970, the BCG Matrix 
              offers a simple, visual method to analyze a company's product portfolio and guide 
              resource allocation decisions.
            </p>
          </div>

          <h3 className="text-2xl font-bold mb-4">Understanding the BCG Matrix</h3>
          <p className="text-gray-700 mb-4">
            The BCG Matrix is a 2x2 grid that classifies products or business units based on their 
            market share and market growth rate:
          </p>
          <ul className="list-disc list-inside mb-4 text-gray-700 pl-4">
            <li><strong>Stars:</strong> High market share, high growth. Require investment to maintain leadership.</li>
            <li><strong>Cash Cows:</strong> High market share, low growth. Generate steady cash flow.</li>
            <li><strong>Question Marks:</strong> Low market share, high growth. Need investment to grow or decide to divest.</li>
            <li><strong>Dogs:</strong> Low market share, low growth. Often considered for divestment.</li>
          </ul>

          <div className="p-6 rounded-xl mb-12 bg-[#F0F4FA]">
            <h3 className="text-2xl font-bold mb-4">Using the Growth Share Matrix</h3>
            <ol className="list-decimal list-inside mb-4 text-gray-700 pl-4">
              <li>Define business units/products.</li>
              <li>Assess their relative market share and growth rate.</li>
              <li>Plot them in the matrix.</li>
              <li>Analyze and develop strategies for each category (invest in Stars, optimize Cash Cows, evaluate Question Marks, consider divesting Dogs).</li>
              <li>Regularly review and update as markets evolve.</li>
            </ol>
          </div>

          <div className="mb-12 flex flex-col items-center">
            <div className="image-container max-w-xl w-full">
              <img src="/images/bcg.png" alt="BCG Matrix" className="w-full h-auto" />
            </div>
          </div>

          <h3 className="text-2xl font-bold mb-4">Case Example</h3>
          <p className="text-gray-700 mb-4">
            Consider FutureTech Inc., which sells smartphones, laptops, smart home devices, and digital cameras.
          </p>
          <ul className="list-disc list-inside mb-4 text-gray-700 pl-4">
            <li>Smartphones: Star (High share, high growth)</li>
            <li>Laptops: Cash Cow (High share, low growth)</li>
            <li>Smart Home Devices: Question Mark (Low share, high growth)</li>
            <li>Digital Cameras: Dog (Low share, low growth)</li>
          </ul>

          <div className="p-6 rounded-xl mb-12 bg-[#F0F4FA]">
            <p className="text-gray-700 mb-4">
              <strong>Step 1: Verify and Validate Data</strong> - Ensure accuracy in market share, growth rates, and category placement.
            </p>
            <p className="text-gray-700 mb-4">
              <strong>Step 2: Analyze Positions</strong> - For smartphones (Stars), invest in R&D and branding. 
              For laptops (Cash Cows), maintain profitability. For smart home devices (Question Marks), evaluate 
              potential and consider investing if promising. For digital cameras (Dogs), consider divestment.
            </p>
            <p className="text-gray-700 mb-4">
              <strong>Step 3: Develop Recommendations</strong> - Allocate resources and investments accordingly. 
              Focus on Stars and promising Question Marks, use Cash Cow profits, and exit Dog markets.
            </p>
            <p className="text-gray-700 mb-4">
              <strong>Step 4: Consider Broader Factors</strong> - Account for technology shifts, consumer trends, 
              regulatory changes, and competition. Adjust strategies and have contingency plans.
            </p>
            <p className="text-gray-700 mb-4">
              <strong>Step 5: Present Findings</strong> - Show the BCG Matrix analysis, strategic recommendations, 
              investment priorities, and risk mitigation strategies to FutureTech's management.
            </p>
          </div>

          <h3 className="text-2xl font-bold mb-4">Conclusion</h3>
          <p className="text-gray-700 mb-4">
            The BCG Matrix helps analyze product portfolios and guide strategic decisions. By understanding its 
            categories and applying it diligently, consultants can provide valuable insights. Continuously review 
            and adapt strategies as markets evolve to maintain a competitive edge.
          </p>

          <div className="text-center mt-12">
  {buttonState ? (
    <button
      type="button"
      className="px-8 py-3 bg-green-500 text-white rounded-xl shadow-lg
                 flex items-center justify-center space-x-2
                 mx-auto hover:bg-green-600 transition-colors duration-200"
    >
      <CheckCircle className="w-5 h-5" />
      <span>Completed Session</span>
    </button>
  ) : (
    <button
      type="button"
      onClick={handleMarkCompleted}
      className="px-8 py-3 bg-gradient-to-r from-indigo-500 to-purple-500
                 text-white rounded-xl shadow-lg flex items-center justify-center
                 space-x-2 mx-auto hover:shadow-xl transition-all duration-200"
    >
      <span>Mark Lesson Completed</span>
    </button>
  )}
</div>


        </div>
      </div>
    </div>
  );
};

export default BcgMatrixPage;
