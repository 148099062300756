import React from 'react';
import { 
  ChevronRight, 
  CheckCircle,
  Lock,
  BookOpen
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const LessonsList = ({ lessons }) => {
  const navigate = useNavigate();

  const handleLessonClick = (lesson) => {
    // If the lesson is locked, don't navigate
    if (lesson.isLocked) return;

    // Navigate to the lesson's path
    navigate(lesson.path);
  };

  return (
    <div className="flex-1 overflow-y-auto p-4 space-y-2">
      {lessons.map((lesson, index) => (
        <div 
          key={index} 
          onClick={() => handleLessonClick(lesson)}
          className={`p-3 rounded-lg transition-all duration-200 cursor-pointer ${
            lesson.isActive
              ? 'bg-gradient-to-r from-indigo-500 to-purple-500 text-white'
              : lesson.isLocked
                ? 'text-gray-400 cursor-not-allowed'
                : 'hover:bg-gray-50 text-gray-700'
          }`}
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-3">
              {lesson.isCompleted ? (
                <CheckCircle className="w-5 h-5 text-green-500" />
              ) : lesson.isLocked ? (
                <Lock className="w-5 h-5" />
              ) : (
                <BookOpen className="w-5 h-5" />
              )}
              <span className="font-medium">{lesson.title}</span>
            </div>
            {lesson.isActive && <ChevronRight className="w-5 h-5" />}
          </div>
        </div>
      ))}
    </div>
  );
};

export default LessonsList;
