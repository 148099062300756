import React, { useState } from 'react';
import { CheckCircle, Menu as MenuIcon, LayoutDashboard } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import LessonNavigation from '../components/LessonNavigation';
import { useAuth } from '@clerk/clerk-react'; // <-- Clerk React import

const MarketSizingPage = () => {
  const [buttonState, setButtonState] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const navigate = useNavigate();

  // If you're using Create React App, put REACT_APP_BACK_END_URL in your .env
  // e.g. REACT_APP_BACK_END_URL=https://your-backend.com
  const url = process.env.REACT_APP_BACK_END_URL;

  // Clerk auth helpers (if your backend requires token-based auth)
  const { userId, getToken } = useAuth();

  const activeLessonTitle = 'Market Sizing';
  const prevLessonPath = '/4ps';  // Adjust as needed
  const nextLessonPath = '/porters'; // Adjust as needed

  // Handle the POST request to mark the lesson as completed
  const handleMarkCompleted = async () => {
    try {
      // Retrieve Clerk's session token if the server needs it
      const token = await getToken();

      // Make the POST request to your backend
      const response = await fetch(`${url}/api/marked-completed`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // if your server requires it
        },
        body: JSON.stringify({
          pageTitle: 'Market Sizing', // Must match your sidebar lesson title if you want the checkmark
          clerkUserId: userId,
        }),
      });

      if (!response.ok) {
        throw new Error(`Failed to mark completed: ${response.statusText}`);
      }

      // If the server responds with success, show the completed button
      setButtonState(true);
      console.log('Lesson successfully marked as completed!');
    } catch (error) {
      console.error('Error marking lesson completed:', error);
      // Optionally display or handle the error
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex">
      {isSidebarOpen && (
        <Sidebar 
          isSidebarOpen={isSidebarOpen} 
          setIsSidebarOpen={setIsSidebarOpen}
          activeLessonTitle={activeLessonTitle}
        />
      )}

      <div className={`flex-1 transition-all duration-300 ${isSidebarOpen ? 'ml-80' : 'ml-0'}`}>
        {/* Top Navigation */}
        <div className="bg-white border-b sticky top-0 z-40">
          <div className="h-16 flex items-center px-6">
            {!isSidebarOpen && (
              <button 
                onClick={() => setIsSidebarOpen(true)} 
                className="text-gray-500 hover:text-gray-700 mr-4"
              >
                <MenuIcon className="w-6 h-6" />
              </button>
            )}

            <button 
              onClick={() => navigate('/main')}
              className="flex items-center space-x-2 px-4 py-2 text-gray-600 hover:text-gray-900 hover:bg-gray-50 rounded-lg transition-colors duration-200"
            >
              <LayoutDashboard className="w-4 h-4" />
              <span>Back to Dashboard</span>
            </button>

            <div className="flex-1"></div>

            <LessonNavigation 
              prevLessonPath={prevLessonPath}
              nextLessonPath={nextLessonPath}
            />
          </div>
        </div>

        {/* Page Content */}
        <div className="max-w-4xl mx-auto px-8 py-12">
          {buttonState ? (
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              Market Size Questions in Management Consulting
              <CheckCircle className="w-6 h-6 text-green-500 inline-block ml-2" />
            </h2>
          ) : (
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              Market Size Questions in Management Consulting
            </h2>
          )}

          <div className="rounded-xl overflow-hidden shadow-lg mb-6">
            <div className="aspect-w-16 aspect-h-9">
              <video width="100%" height="auto" controls className="object-cover">
                <source src="/images/marketsizing.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>

          <div className="p-6 rounded-xl mb-12" style={{ backgroundColor: '#F0F4FA' }}>
            <h3 className="text-2xl font-bold mb-4">What are Market Size Questions?</h3>
            <p className="text-gray-700 mb-4">
              Market sizing cases are a staple in management consulting interviews, testing your ability 
              to break down complex problems, make reasonable assumptions, and perform quick calculations. 
              These cases might involve estimating the size of a market in terms of revenue, units sold, or 
              number of potential customers.
            </p>
            <p className="text-gray-700 mb-4">
              We recommend using Aurora Prep's Quantitative practice section to test your math skills. 
              Being comfortable with on-the-fly estimations is crucial for success.
            </p>
            <br />
            <h3 className="text-xl font-bold mb-4">Understanding Market Sizing Cases:</h3>
            <p className="text-gray-700 mb-4">
              Market sizing cases often start with a prompt like "Estimate the number of cars sold in Germany 
              each year." You'll need to ask clarifying questions, make assumptions, and do quick calculations.
            </p>
            <br />
            <h3 className="text-xl font-bold mb-4">Key Steps in Solving Market Sizing Cases:</h3>
            <ol className="list-decimal list-inside mb-4 text-gray-700 pl-4">
              <li><strong>Clarify the Problem:</strong> Ask questions to understand specifics and assumptions.</li>
              <li><strong>Break Down the Problem:</strong> Identify key components or segments to consider.</li>
              <li><strong>Make Reasonable Assumptions:</strong> Estimate key variables based on knowledge and logic.</li>
              <li><strong>Perform Calculations:</strong> Use assumptions and structure to calculate market size, showing work and thought process.</li>
              <li><strong>Sense-check Your Answer:</strong> Evaluate if the estimate makes sense and compare to benchmarks.</li>
            </ol>
            <br />
            <h3 className="text-xl font-bold mb-4">Tips for Acing Market Sizing Cases:</h3>
            <ol className="list-decimal list-inside mb-4 text-gray-700 pl-4">
              <li>Use Round Numbers to simplify calculations.</li>
              <li>Estimate with Ranges if unsure.</li>
              <li>Explain Your Assumptions clearly.</li>
              <li>Structure Your Approach logically.</li>
              <li>Practice Mental Math to be quick and confident.</li>
            </ol>
            <p className="text-gray-700 mb-4">
              Below, we've prepared a sample dialogue to illustrate how to implement this strategy in a real case interview.
            </p>
          </div>

          <div className="mb-12">
            <h3 className="text-xl font-bold mb-4">Sample Market Sizing Case:</h3>
            <br />
            <p className="text-gray-700 mb-4"><strong>Interviewer:</strong> Estimate the number of smartphones sold in the United States each year.</p>
            <p className="text-gray-700 mb-4"><strong>Applicant:</strong> Let's break it down into key components:</p>
            <ol className="list-decimal list-inside mb-4 text-gray-700 pl-4">
              <li>Population of the United States</li>
              <li>Percentage that uses smartphones</li>
              <li>Average lifespan of a smartphone</li>
              <li>Replacement rate of smartphones</li>
            </ol>
            <p className="text-gray-700 mb-4">
              US population ~330 million. Approximately 80% own a smartphone → 264 million owners.
            </p>
            <p className="text-gray-700 mb-4">
              Average lifespan ~2.5 years. Assume 90% replace within that timeframe.
            </p>
            <pre className="bg-gray-100 p-4 rounded mb-4 overflow-auto text-gray-700">
{`US population: 330 million
Smartphone owners: 80% of 330 million = 264 million
Annual replacement: (264 million * 90%) / 2.5 ≈ 95 million smartphones/year`}
            </pre>
            <p className="text-gray-700 mb-4">
              Thus, ~95 million smartphones sold annually.
            </p>
            <p className="text-gray-700 mb-4"><strong>Interviewer:</strong> How might you sense-check this?</p>
            <p className="text-gray-700 mb-4"><strong>Aurora Prep Consultant:</strong> Compare with publicly available data or estimate revenue from major smartphone companies and divide by average selling price.</p>
            <p className="text-gray-700">
              <strong>Interviewer:</strong> Excellent. You've shown a well-structured approach and clear assumptions.
            </p>
          </div>

          <div className="p-6 rounded-xl mb-12" style={{ backgroundColor: '#F0F4FA' }}>
            <h3 className="text-xl font-bold mb-4">Conclusion</h3>
            <p className="text-gray-700 mb-4">
              Market sizing cases can be tackled with a structured approach: clarify, break down, assume, calculate, and sense-check. By mastering this, you'll impress interviewers and demonstrate strong analytical skills.
            </p>
            <ol className="list-decimal list-inside mb-4 text-gray-700 pl-4">
              <li>Break down the problem into components.</li>
              <li>Make reasonable assumptions.</li>
              <li>Perform basic calculations.</li>
              <li>Explain your thought process clearly.</li>
            </ol>
          </div>

          <div className="text-center mt-12">
            {buttonState ? (
              <button
                type="button"
                className="px-8 py-3 bg-green-500 text-white rounded-xl shadow-lg flex items-center justify-center space-x-2 mx-auto hover:bg-green-600 transition-colors duration-200"
              >
                <CheckCircle className="w-5 h-5" />
                <span>Completed Session</span>
              </button>
            ) : (
              <button
                type="button"
                onClick={handleMarkCompleted}
                className="px-8 py-3 bg-gradient-to-r from-indigo-500 to-purple-500 text-white rounded-xl shadow-lg flex items-center justify-center space-x-2 mx-auto hover:shadow-xl transition-all duration-200"
              >
                <span>Mark Lesson Completed</span>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketSizingPage;
