import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserButton } from '@clerk/clerk-react';
import { LayoutDashboard } from 'lucide-react';

const Navbar = () => {
  const navigate = useNavigate();

  return (
    <nav className="w-full bg-white bg-opacity-90 backdrop-blur-lg border-b border-gray-100 sticky top-0 z-50">
      <div className="max-w-7xl mx-auto px-6">
        <div className="flex items-center justify-between h-16">
          {/* Logo and Brand */}
          <Link 
            to="/main"
            className="flex items-center gap-3 text-2xl font-bold bg-gradient-to-r from-violet-600 to-pink-500 bg-clip-text text-transparent hover:opacity-90 transition-opacity"
          >
            <img 
              src="/images/ap_logo.svg" 
              alt="Aurora Prep Logo" 
              className="h-8 w-auto"
            />
            <span>Aurora Prep</span>
          </Link>

          {/* Navigation Items */}
          <div className="flex items-center gap-6">
            {/* Dashboard Button */}
            <button
              onClick={() => navigate('/main')}
              className="flex items-center gap-2 px-4 py-2 rounded-lg text-gray-600 hover:text-gray-900 hover:bg-gray-50 transition-all duration-200"
            >
              <LayoutDashboard className="w-4 h-4" />
              <span>Dashboard</span>
            </button>

            {/* User Profile Button */}
            <UserButton 
              afterSignOutUrl="/"
              appearance={{
                elements: {
                  userButtonAvatarBox: 'w-8 h-8 rounded-full',
                  userButtonTrigger: 'focus:shadow-none'
                }
              }}
            />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
