import React, { useState } from 'react';
import {
  Menu as MenuIcon,
  PlayCircle,
  CheckCircle,
  Target,
  BrainCircuit,
  LayoutDashboard,
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import LessonNavigation from '../components/LessonNavigation';
import { useAuth } from '@clerk/clerk-react'; // <-- import Clerk Auth

const IntroductionPage = () => {
  const [buttonState, setButtonState] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const navigate = useNavigate();

  // Pull in environment variable from .env (e.g., REACT_APP_BACK_END_URL)
  const url = process.env.REACT_APP_BACK_END_URL;

  // Clerk auth helpers
  const { userId, getToken } = useAuth();

  const activeLessonTitle = 'Introduction';
  const prevLessonPath = '/main';
  const nextLessonPath = '/profit';

  // This function marks "Introduction" as completed in the DB
  const handleMarkCompleted = async () => {
    try {
      const token = await getToken(); // retrieve Clerk session token
      const response = await fetch(`${url}/api/marked-completed`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // pass token if your backend requires auth
        },
        body: JSON.stringify({
          pageTitle: 'Introduction', // <-- Must match the lesson title in your DB
          clerkUserId: userId,      // optional, if server expects userId 
        }),
      });

      if (!response.ok) {
        throw new Error(`Failed to mark completed: ${response.statusText}`);
      }

      // Mark button as complete in the UI
      setButtonState(true);
      console.log('Lesson successfully marked as completed!');
    } catch (error) {
      console.error('Error marking lesson completed:', error);
      // Show error message or handle error
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex">
      {/* Sidebar */}
      {isSidebarOpen && (
        <Sidebar
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
          activeLessonTitle={activeLessonTitle}
        />
      )}

      {/* Main Content */}
      <div className={`flex-1 transition-all duration-300 ${isSidebarOpen ? 'ml-80' : 'ml-0'}`}>
        {/* Top Navigation Bar */}
        <div className="bg-white border-b sticky top-0 z-40">
          <div className="h-16 flex items-center px-6">
            {/* Toggle Sidebar button (visible only when sidebar is closed) */}
            {!isSidebarOpen && (
              <button
                onClick={() => setIsSidebarOpen(true)}
                className="text-gray-500 hover:text-gray-700 mr-4"
              >
                <MenuIcon className="w-6 h-6" />
              </button>
            )}

            {/* Dashboard button on the far left */}
            <button
              onClick={() => navigate('/main')}
              className="flex items-center space-x-2 px-4 py-2 text-gray-600 hover:text-gray-900 hover:bg-gray-50 rounded-lg transition-colors duration-200"
            >
              <LayoutDashboard className="w-4 h-4" />
              <span>Back to Dashboard</span>
            </button>

            {/* Spacer to push other buttons to the right */}
            <div className="flex-1"></div>

            {/* Previous and Next buttons */}
            <LessonNavigation prevLessonPath={prevLessonPath} nextLessonPath={nextLessonPath} />
          </div>
        </div>

        {/* Lesson Content */}
        <div className="max-w-4xl mx-auto px-8 py-12">
          {/* Header Section */}
          <div className="mb-12 text-center">
            <h1 className="text-4xl font-bold mb-4 bg-gradient-to-r from-indigo-500 to-purple-500 text-transparent bg-clip-text">
              Introduction to Management Consulting
            </h1>
            <p className="text-lg text-gray-600 max-w-2xl mx-auto">
              Master the fundamentals of management consulting and prepare for success in case
              interviews
            </p>
          </div>

          {/* Video Section */}
          <div className="mb-12 rounded-xl overflow-hidden shadow-lg">
            <div className="aspect-w-16 aspect-h-9">
              <video width="100%" height="auto" controls className="object-cover">
                <source src="/images/auroraprep.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>

          {/* Content Cards */}
          <div className="grid md:grid-cols-2 gap-6 mb-12">
            <div className="bg-white rounded-xl p-6 shadow-sm hover:shadow-md transition-shadow duration-200">
              <div className="flex items-center space-x-3 mb-4">
                <Target className="w-6 h-6 text-indigo-500" />
                <h3 className="text-xl font-semibold">Interview Process</h3>
              </div>
              <ul className="space-y-3 text-gray-600">
                <li className="flex items-center space-x-2">
                  <CheckCircle className="w-4 h-4 text-green-500" />
                  <span>Screening Interviews</span>
                </li>
                <li className="flex items-center space-x-2">
                  <CheckCircle className="w-4 h-4 text-green-500" />
                  <span>Behavioral & Fit Assessment</span>
                </li>
                <li className="flex items-center space-x-2">
                  <CheckCircle className="w-4 h-4 text-green-500" />
                  <span>Case Interviews</span>
                </li>
                <li className="flex items-center space-x-2">
                  <CheckCircle className="w-4 h-4 text-green-500" />
                  <span>Technical Skills Evaluation</span>
                </li>
              </ul>
            </div>

            <div className="bg-white rounded-xl p-6 shadow-sm hover:shadow-md transition-shadow duration-200">
              <div className="flex items-center space-x-3 mb-4">
                <BrainCircuit className="w-6 h-6 text-purple-500" />
                <h3 className="text-xl font-semibold">Key Skills Assessed</h3>
              </div>
              <ul className="space-y-3 text-gray-600">
                <li className="flex items-center space-x-2">
                  <CheckCircle className="w-4 h-4 text-green-500" />
                  <span>Analytical Problem-Solving</span>
                </li>
                <li className="flex items-center space-x-2">
                  <CheckCircle className="w-4 h-4 text-green-500" />
                  <span>Business Acumen</span>
                </li>
                <li className="flex items-center space-x-2">
                  <CheckCircle className="w-4 h-4 text-green-500" />
                  <span>Communication Skills</span>
                </li>
                <li className="flex items-center space-x-2">
                  <CheckCircle className="w-4 h-4 text-green-500" />
                  <span>Strategic Thinking</span>
                </li>
              </ul>
            </div>
          </div>

          {/* Completion Button */}
          <div className="text-center">
            {buttonState ? (
              <button
                type="button"
                className="px-8 py-3 bg-green-500 text-white rounded-xl shadow-lg flex items-center justify-center space-x-2 mx-auto hover:bg-green-600 transition-colors duration-200"
              >
                <CheckCircle className="w-5 h-5" />
                <span>Lesson Completed</span>
              </button>
            ) : (
              <button
                type="button"
                onClick={handleMarkCompleted}
                className="px-8 py-3 bg-gradient-to-r from-indigo-500 to-purple-500 text-white rounded-xl shadow-lg flex items-center justify-center space-x-2 mx-auto hover:shadow-xl transition-all duration-200"
              >
                <PlayCircle className="w-5 h-5" />
                <span>Mark as Complete</span>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntroductionPage;
