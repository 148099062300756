import React from 'react';
import { Helmet } from 'react-helmet';

const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        {/* Google Tag Manager (gtag.js) */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-6YQYMBQQNY"
        />
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-6YQYMBQQNY');
          `}
        </script>

        {/* Bing Ads / UET */}
        <script>
          {`
            (function(w,d,t,r,u) {
              var f,n,i;
              w[u] = w[u] || [];
              f = function() {
                var o = {ti: "97123631", enableAutoSpaTracking: true};
                o.q = w[u];
                w[u] = new UET(o);
                w[u].push("pageLoad");
              },
              n = d.createElement(t);
              n.src = r;
              n.async = 1;
              n.onload = n.onreadystatechange = function() {
                var s = this.readyState;
                if(!s || s==="loaded" || s==="complete") {
                  f();
                  n.onload = n.onreadystatechange = null;
                }
              };
              i = d.getElementsByTagName(t)[0];
              i.parentNode.insertBefore(n,i);
            })(window,document,"script","//bat.bing.com/bat.js","uetq");

            // Use static 0 if no dynamic revenue is available
            window.uetq = window.uetq || [];
            window.uetq.push('event', '', {
              "revenue_value": 0,
              "currency": "USD"
            });
          `}
        </script>

        {/* Basic Meta Tags */}
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          name="description"
          content="Aurora Prep, LLC offers top-notch consulting prep, consulting case prep, and consulting interview prep. Discover our subscription packages for tailored consulting preparation."
        />
        <meta
          name="keywords"
          content="consulting prep, consulting case prep, consulting interview prep, subscription packages, consulting preparation, AI-driven tutor, interactive cases, insights dashboard"
        />
        <title>Privacy Policy for Aurora Prep LLC</title>
      </Helmet>

      {/* Page Content */}
      <div className="min-h-screen bg-gray-100 py-10 px-4 md:px-0">
        <div className="max-w-3xl mx-auto bg-white shadow-lg rounded-lg p-6 md:p-12 text-gray-800 font-sans">
          <h1 className="text-3xl md:text-4xl font-bold mb-6 text-gray-900">
            Privacy Policy for Aurora Prep LLC
          </h1>
          <p className="mb-4">
            At Aurora Prep LLC, we are committed to protecting the privacy and
            security of our users' personal information. This Privacy Policy
            outlines how we collect, use, disclose, and safeguard your
            information when you use our website and consulting prep training
            program. By accessing or using our services, you consent to the
            terms of this Privacy Policy.
          </p>

          {/* 1. Information We Collect */}
          <h2 className="text-2xl font-semibold mt-8 mb-4 text-gray-900">
            1. Information We Collect
          </h2>
          <p className="mb-4">
            <strong>1.1 Personal Information:</strong> When you subscribe to our
            consulting prep training program, we collect personal information
            such as your name, email address, and payment details.
          </p>
          <p className="mb-4">
            <strong>1.2 Usage Data:</strong> We collect information about how
            you interact with our website and platform, including your IP
            address, browser type, referring/exit pages, and operating system.
          </p>
          <p className="mb-4">
            <strong>1.3 Assessment Data:</strong> We collect and store your test
            scores and performance metrics to provide personalized dashboards
            and track your progress over time.
          </p>

          {/* 2. Use of Information */}
          <h2 className="text-2xl font-semibold mt-8 mb-4 text-gray-900">
            2. Use of Information
          </h2>
          <p className="mb-4">
            <strong>2.1</strong> We use the collected information to provide,
            maintain, and improve our services, as well as to personalize your
            experience and send relevant communications.
          </p>
          <p className="mb-4">
            <strong>2.2</strong> We may use your email address to send you
            updates, newsletters, and promotional offers related to our
            consulting prep training program. You can opt out of these
            communications at any time.
          </p>
          <p className="mb-4">
            <strong>2.3</strong> We use aggregated, anonymized data for
            research, analytics, and to enhance our platform and learning
            modules.
          </p>

          {/* 3. Disclosure of Information */}
          <h2 className="text-2xl font-semibold mt-8 mb-4 text-gray-900">
            3. Disclosure of Information
          </h2>
          <p className="mb-4">
            <strong>3.1</strong> We may disclose your information to trusted
            third-party service providers who assist us in operating our
            website, conducting our business, or providing services to you,
            subject to confidentiality obligations.
          </p>
          <p className="mb-4">
            <strong>3.2</strong> We may release your information when we believe
            it is necessary to comply with the law, enforce our site policies,
            or protect our or others' rights, property, or safety.
          </p>

          {/* 4. Data Security */}
          <h2 className="text-2xl font-semibold mt-8 mb-4 text-gray-900">
            4. Data Security
          </h2>
          <p className="mb-4">
            <strong>4.1</strong> We implement reasonable security measures to
            protect your personal information from unauthorized access,
            alteration, disclosure, or destruction.
          </p>
          <p className="mb-4">
            <strong>4.2</strong> However, no method of transmission over the
            internet or electronic storage is 100% secure, and we cannot
            guarantee absolute security.
          </p>

          {/* 5. Your Rights */}
          <h2 className="text-2xl font-semibold mt-8 mb-4 text-gray-900">
            5. Your Rights
          </h2>
          <p className="mb-4">
            <strong>5.1</strong> You have the right to access, update, correct,
            or delete your personal information. You can exercise these rights
            by contacting us at{' '}
            <a
              href="mailto:support@auroraprep.com"
              className="text-blue-600 underline"
            >
              support@auroraprep.com
            </a>
            .
          </p>
          <p className="mb-4">
            <strong>5.2</strong> You may also have the right to object to or
            restrict the processing of your personal data, as well as the right
            to data portability, as provided under applicable data protection
            laws.
          </p>

          {/* 6. Third-Party Links */}
          <h2 className="text-2xl font-semibold mt-8 mb-4 text-gray-900">
            6. Third-Party Links
          </h2>
          <p className="mb-4">
            <strong>6.1</strong> Our website may contain links to third-party
            websites. We have no control over and assume no responsibility for
            the content, privacy policies, or practices of any third-party
            sites.
          </p>

          {/* 7. Children's Privacy */}
          <h2 className="text-2xl font-semibold mt-8 mb-4 text-gray-900">
            7. Children's Privacy
          </h2>
          <p className="mb-4">
            <strong>7.1</strong> Our services are not intended for children
            under the age of 16. We do not knowingly collect personal
            information from children under 16. If you believe we have collected
            such information, please contact us immediately.
          </p>

          {/* 8. Changes to this Privacy Policy */}
          <h2 className="text-2xl font-semibold mt-8 mb-4 text-gray-900">
            8. Changes to this Privacy Policy
          </h2>
          <p className="mb-4">
            <strong>8.1</strong> We may update our Privacy Policy from time to
            time. We will notify you of any changes by posting the new Privacy
            Policy on this page and updating the "Last Updated" date.
          </p>

          {/* 9. Contact Us */}
          <h2 className="text-2xl font-semibold mt-8 mb-4 text-gray-900">
            9. Contact Us
          </h2>
          <p className="mb-4">
            If you have any questions or concerns about this Privacy Policy,
            please contact us at{' '}
            <a
              href="mailto:support@auroraprep.com"
              className="text-blue-600 underline"
            >
              support@auroraprep.com
            </a>
            .
          </p>
          <p className="mb-4">This Privacy Policy is effective as of April 16, 2024.</p>
          <p className="mb-4">Aurora Prep LLC is a Delaware corporation.</p>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
