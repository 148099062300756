import React, { useState, useRef, useEffect } from 'react';
import { MessageCircle, X, Send, Bot, Loader2 } from 'lucide-react';
import DOMPurify from 'dompurify';
import { useAuth } from "@clerk/clerk-react";

const ChatWidget = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const messagesEndRef = useRef(null);
  const chatContainerRef = useRef(null);


  const url = process.env.REACT_APP_BACK_END_URL;
  console.log(url);


const { getToken } = useAuth();

  
  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: 'smooth'
      });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!input.trim()) return;

    setMessages(prev => [...prev, { text: input, sender: 'user' }]);
    setInput('');
    setIsTyping(true);

    try {
      const response = await fetch(`${url}/api/chat`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ input }),
      });

      if (!response.ok) throw new Error("Network response was not ok");

      const data = await response.json();
      const aiResponse = data.response || data.error || "An error occurred. Please try again.";

      setMessages(prev => [...prev, { text: aiResponse, sender: 'ai' }]);
    } catch (error) {
      console.error('Error:', error);
      setMessages(prev => [...prev, { text: "An error occurred. Please try again.", sender: 'ai' }]);
    } finally {
      setIsTyping(false);
    }
  };

  const handleSummarizeNotes = async () => {
    setIsTyping(true);
  
    try {
      // Retrieve Clerk token
      const token = await getToken(); // Use the appropriate template name if set up in Clerk
  
      const response = await fetch(`${url}/api/summarize-notes`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Include Clerk token
        },
        body: JSON.stringify({}),
      });
  
      if (!response.ok) throw new Error("Network response was not ok");
  
      const data = await response.json();
      const aiResponse = data.summary || data.error || "An error occurred. Please try again.";
  
      setMessages(prev => [...prev, { text: aiResponse, sender: 'ai' }]);
    } catch (error) {
      console.error('Error:', error);
      setMessages(prev => [...prev, { text: "An error occurred. Please try again.", sender: 'ai' }]);
    } finally {
      setIsTyping(false);
    }
  };
  const Message = ({ message }) => {
    const formatText = (text) => {
      const lines = text.split('\n');
      let formattedContent = [];
      let currentOrderedList = [];
      let currentUnorderedList = [];
      let listCounter = 1;
      let isInCodeBlock = false;
      let codeContent = '';

      const processLine = (line) => {
        // Handle inline code
        line = line.replace(/`([^`]+)`/g, '<code class="bg-gray-200 text-gray-800 px-1 rounded">$1</code>');

        // Handle bold text
        line = line.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
                   .replace(/__(.*?)__/g, '<strong>$1</strong>');

        // Handle italic text
        line = line.replace(/\*(.*?)\*/g, '<em>$1</em>')
                   .replace(/_(.*?)_/g, '<em>$1</em>');

        return line;
      };

      const flushLists = (index) => {
        if (currentOrderedList.length > 0) {
          formattedContent.push(
            <ol key={`ordered-list-${index}`} className="list-decimal pl-8 my-2 space-y-1">
              {currentOrderedList}
            </ol>
          );
          currentOrderedList = [];
          listCounter = 1;
        }
        if (currentUnorderedList.length > 0) {
          formattedContent.push(
            <ul key={`unordered-list-${index}`} className="list-disc pl-8 my-2 space-y-1">
              {currentUnorderedList}
            </ul>
          );
          currentUnorderedList = [];
        }
      };

      lines.forEach((line, index) => {
        // Handle code blocks
        if (line.startsWith('```')) {
          flushLists(index);
          if (isInCodeBlock) {
            formattedContent.push(
              <pre key={`code-${index}`} className="bg-gray-800 text-gray-100 p-3 rounded-lg my-2 overflow-x-auto">
                <code>{codeContent.trim()}</code>
              </pre>
            );
            codeContent = '';
            isInCodeBlock = false;
          } else {
            isInCodeBlock = true;
          }
          return;
        }

        if (isInCodeBlock) {
          codeContent += line + '\n';
          return;
        }

        // Handle numbered lists
        const numberedListMatch = line.match(/^(\d+)\.\s+(.+)/);
        if (numberedListMatch) {
          if (currentUnorderedList.length > 0) flushLists(index);
          currentOrderedList.push(
            <li
              key={`list-${index}`}
              className="leading-relaxed"
              dangerouslySetInnerHTML={{
                __html: processLine(numberedListMatch[2])
              }}
            />
          );
          return;
        }

        // Handle bullet points
        const bulletListMatch = line.match(/^[-•]\s+(.+)/);
        if (bulletListMatch) {
          if (currentOrderedList.length > 0) flushLists(index);
          currentUnorderedList.push(
            <li
              key={`list-${index}`}
              className="leading-relaxed"
              dangerouslySetInnerHTML={{
                __html: processLine(bulletListMatch[1])
              }}
            />
          );
          return;
        }

        // Flush lists if necessary
        if (line.trim() && !numberedListMatch && !bulletListMatch) {
          flushLists(index);
          formattedContent.push(
            <p
              key={`text-${index}`}
              className="mb-2 leading-relaxed"
              dangerouslySetInnerHTML={{
                __html: processLine(line)
              }}
            />
          );
        } else if (!line.trim()) {
          flushLists(index);
          formattedContent.push(<div key={`space-${index}`} className="h-2" />);
        }
      });

      // Flush any remaining lists
      flushLists(lines.length);

      return formattedContent;
    };

    return (
      <div
        className={`group flex items-start gap-3 mb-4 ${
          message.sender === 'user' ? 'flex-row-reverse' : ''
        }`}
      >
        <div
          className={`w-8 h-8 rounded-full flex items-center justify-center flex-shrink-0 ${
            message.sender === 'user' ? 'bg-blue-600 text-white' : 'bg-gray-100'
          }`}
        >
          {message.sender === 'user' ? '🚀' : '👩‍🏫'}
        </div>
        <div
          className={`relative max-w-[90%] px-4 py-2 rounded-2xl transition-all ${
            message.sender === 'user'
              ? 'bg-blue-600 text-white'
              : 'bg-gray-100 text-gray-800'
          }`}
        >
          <div className="text-sm leading-relaxed">
            {formatText(message.text)}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="fixed bottom-6 right-6 z-50">
      {isOpen && (
        <div className="absolute bottom-20 right-0 w-96 h-[600px] bg-white rounded-2xl shadow-2xl flex flex-col overflow-hidden animate-in slide-in-from-bottom duration-300">
          {/* Header */}
          <div className="p-4 bg-white border-b border-gray-100">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-3">
                <div className="w-10 h-10 rounded-full bg-gradient-to-r from-blue-600 to-purple-600 flex items-center justify-center">
                  <Bot size={24} className="text-white" />
                </div>
                <div>
                  <h3 className="font-semibold text-gray-900">AI Assistant</h3>
                  <p className="text-sm text-gray-500">Always here to help</p>
                </div>
              </div>
              <button
                onClick={toggleChat}
                className="p-2 hover:bg-gray-100 rounded-full transition-colors"
              >
                <X size={20} className="text-gray-500" />
              </button>
            </div>
          </div>

          {/* "Summarize All Notes" Button */}
          <div className="p-4 bg-white border-b border-gray-100">
            <button
              onClick={handleSummarizeNotes}
              className="w-full px-4 py-2 bg-blue-600 text-white rounded-full hover:bg-blue-700 transition-all"
            >
              Summarize All Notes
            </button>
          </div>

          {/* Messages Container */}
          <div
            ref={chatContainerRef}
            className="flex-1 p-4 overflow-y-auto space-y-4 bg-white/50 backdrop-blur-sm"
          >
            {messages.length === 0 ? (
              <div className="h-full flex flex-col items-center justify-center text-center p-6">
                <div className="w-16 h-16 rounded-full bg-gradient-to-r from-blue-600 to-purple-600 flex items-center justify-center mb-4">
                  <Bot size={32} className="text-white" />
                </div>
                <h3 className="text-xl font-semibold text-gray-900 mb-2">Welcome!</h3>
                <p className="text-gray-500 max-w-[240px]">
                  I'm your AI assistant. How can I help you today?
                </p>
              </div>
            ) : (
              <>
                {messages.map((msg, idx) => (
                  <Message key={idx} message={msg} />
                ))}
                {isTyping && (
                  <div className="flex items-center gap-2 text-gray-500">
                    <Loader2 size={16} className="animate-spin" />
                    <span className="text-sm">AI is typing...</span>
                  </div>
                )}
                <div ref={messagesEndRef} />
              </>
            )}
          </div>

          {/* Input Form */}
          <form
            onSubmit={handleSubmit}
            className="p-4 bg-white border-t border-gray-100"
          >
            <div className="flex gap-2">
              <input
                type="text"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder="Type your message..."
                className="flex-1 px-4 py-2 bg-gray-100 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all"
              />
              <button
                type="submit"
                className="p-2 rounded-full bg-gradient-to-r from-blue-600 to-purple-600 text-white hover:shadow-lg transition-all duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
                disabled={!input.trim()}
              >
                <Send size={20} className="mr-1" />
              </button>
            </div>
          </form>
        </div>
      )}

      {/* Chat Toggle Button */}
      <button
        onClick={toggleChat}
        className={`group p-4 rounded-full shadow-lg transition-all duration-300 ${
          isOpen
            ? 'bg-white hover:bg-gray-50 rotate-0'
            : 'bg-gradient-to-r from-blue-600 to-purple-600 hover:shadow-xl rotate-6 hover:rotate-0'
        }`}
      >
        <MessageCircle
          size={24}
          className={`transition-colors ${
            isOpen ? 'text-gray-600' : 'text-white'
          }`}
        />
      </button>
    </div>
  );
};

export default ChatWidget;