import React, { useState, useEffect } from 'react';

const ReviewsSection = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const reviews = [
    {
      text: "Aurora Prep has provided me the frameworks necessary to confidently tackle case study interviews. Whether you're an experienced consultant such as myself or you're entirely new to casing and the consulting industry, there's a lot to learn here. 100% recommend this to anyone looking to prepare for case studies, as well as anyone who is interested in learning more about how business leaders think through key decisions.",
      author: "Connor",
      credentials: "Northwestern (Kellogg) '25"
    },
    {
      text: "Aurora prep is such a valuable resource for learning how to succeed in the case interview. There are so many great tools on the platform. My favorite was the Case Prep Simulations that gave me really useful feedback almost immediately. I would highly recommend Aurora prep to anyone looking to break into consulting!",
      author: "Jack",
      credentials: "UCLA '24"
    },
    {
      text: "Aurora Prep was a great change of pace from just reading books on case prep. The interactive case prep was extremely helpful, and the automated resume reviewer was really insightful as I was trying to craft my resume to standout without having had prior consulting experience. Would recommend to anyone.",
      author: "Christian",
      credentials: "Consultant at Big 4"
    }
  ];

  return (
    <section className="relative py-20 overflow-hidden bg-gradient-to-br from-slate-50 to-white">
      {/* Background Elements */}
      <div className="absolute inset-0 bg-gradient-to-br from-indigo-50/70 via-purple-50/70 to-pink-50/70" />
      <div className="absolute top-0 right-0 w-96 h-96 bg-purple-100/30 rounded-full blur-3xl -z-10 transform translate-x-1/2 -translate-y-1/2" />
      <div className="absolute bottom-0 left-0 w-96 h-96 bg-indigo-100/30 rounded-full blur-3xl -z-10 transform -translate-x-1/2 translate-y-1/2" />

      <div className="container mx-auto px-6 lg:px-8">
        <div 
          className={`text-center mb-16 transition-all duration-700 transform ${
            isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-8'
          }`}
        >
          <h2 className="text-4xl lg:text-5xl font-bold text-gray-900 tracking-tight">
            What Our Users Say
          </h2>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-7xl mx-auto">
          {reviews.map((review, index) => (
            <div
              key={index}
              className={`backdrop-blur-sm bg-white/50 p-8 rounded-2xl shadow-sm
                transition-all duration-700 transform
                ${isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-8'}`}
              style={{ transitionDelay: `${index * 200}ms` }}
            >
              {/* Quote Icon */}
              <div className="mb-6">
                <svg 
                  className="w-8 h-8 text-indigo-400" 
                  fill="currentColor" 
                  viewBox="0 0 24 24"
                >
                  <path d="M14.017 21v-7.391c0-5.704 3.731-9.57 8.983-10.609l.995 2.151c-2.432.917-3.995 3.638-3.995 5.849h4v10h-9.983zm-14.017 0v-7.391c0-5.704 3.748-9.57 9-10.609l.996 2.151c-2.433.917-3.996 3.638-3.996 5.849h3.999v10h-9.999z" />
                </svg>
              </div>
              
              {/* Review Text */}
              <p className="text-slate-600 leading-relaxed mb-6">
                {review.text}
              </p>

              {/* Author Info */}
              <div className="mt-auto">
                <p className="font-semibold text-gray-900">{review.author}</p>
                <p className="text-sm text-slate-500">{review.credentials}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ReviewsSection;