import React, { useState } from 'react';
import { Upload, FileText, CheckCircle, AlertTriangle } from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import ProcessingAnimation from '../components/ProcessingAnimation'; // <-- Make sure path is correct, consider ProcessingAnimation.jsx if needed

const ResumeGrader = () => {
  const [file, setFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');
  const [fileType, setFileType] = useState('');
  const [grade, setGrade] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  // Make sure REACT_APP_BACK_END_URL is set in your .env
  const url = process.env.REACT_APP_BACK_END_URL;

  const handleFileUpload = (event) => {
    const uploadedFile = event.target.files[0];
    if (!uploadedFile) return;

    setFile(uploadedFile);
    setFileType(uploadedFile.type);
    setError('');
    setPreviewUrl(URL.createObjectURL(uploadedFile));
  };

  const gradeResumeWithChatGPT = async () => {
    if (!file) {
      setError('No file selected.');
      return;
    }
    console.log("Setting loading to true"); // Debugging log
    setLoading(true); // <--- Triggers the overlay
    console.log("Loading state is now:", loading); // Debugging log
    setError('');
    setGrade('');

    try {
      const formData = new FormData();
      formData.append('resumeFile', file);

      const response = await fetch(`${url}/api/grade`, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to fetch grade from the server');
      }

      const data = await response.json();
      let feedback = data.result || '';
      feedback = feedback.trim();
      // Clean up headings
      feedback = feedback.replace(/(?:^|\n)(#{1,3})\s+/g, '\n\n$1 ');
      feedback = feedback.replace(/\n\s*\n/g, '\n\n');

      setGrade(feedback);
    } catch (err) {
      setError(err.message);
    } finally {
      console.log("Setting loading to false"); // Debugging log
      setLoading(false);
      console.log("Loading state is now:", loading); // Debugging log
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-4 bg-[#F8F7FF] relative">
      {/* 1) The processing animation overlay */}
      <ProcessingAnimation
        isLoading={loading}
        onComplete={() => {
          // Optional: something after animation completes
          console.log("-----progesss-----")
        }}
      />

      {/* 2) The rest of your resume grader UI */}
      <div className="mb-12 text-center">
        <div className="inline-flex items-center gap-2 mb-2 bg-white px-4 py-2 rounded-full">
          <FileText className="h-4 w-4 text-purple-600" />
          <span className="text-sm font-medium text-purple-600">
            Introducing the future of resume grading
          </span>
        </div>
        <h1 className="text-5xl font-bold text-gray-900 mb-4">Grade Better.</h1>
        <h2 className="text-5xl font-bold bg-gradient-to-r from-purple-600 to-pink-500 text-transparent bg-clip-text mb-6">
          Work Smarter.
        </h2>
        <p className="text-xl text-gray-600 max-w-2xl mx-auto">
          Your resume deserves expert feedback. Upload, analyze, and improve your resume with our modern AI-powered grading tool.
        </p>
      </div>

      <div className="bg-white rounded-2xl shadow-sm p-8">
        <div className="space-y-6">
          {/* ---- File upload area ---- */}
          <div className="border-2 border-dashed border-gray-200 rounded-xl p-8 text-center bg-gray-50">
            <input
              type="file"
              onChange={handleFileUpload}
              accept=".pdf,.doc,.docx"
              className="hidden"
              id="resume-upload"
            />
            <label
              htmlFor="resume-upload"
              className="cursor-pointer flex flex-col items-center gap-3"
            >
              <div className="h-12 w-12 bg-purple-100 rounded-full flex items-center justify-center">
                <Upload className="h-6 w-6 text-purple-600" />
              </div>
              <span className="text-gray-600 text-lg">
                Click to upload your resume
              </span>
              <span className="text-sm text-gray-500">
                Supports PDF and Word documents
              </span>
            </label>
          </div>

          {/* ---- Error if any ---- */}
          {error && (
            <div className="bg-red-50 border border-red-100 rounded-lg p-4 text-red-700">
              <div className="flex items-center">
                <AlertTriangle className="h-4 w-4 mr-2" />
                <p>{error}</p>
              </div>
            </div>
          )}

          {/* ---- Resume Preview & Grade button ---- */}
          {previewUrl && (
            <div className="space-y-4">
              <h3 className="text-lg font-semibold text-gray-900">Preview</h3>
              <div className="bg-gray-50 rounded-xl h-96 overflow-hidden">
                {fileType.includes('pdf') ? (
                  <iframe
                    src={previewUrl}
                    className="w-full h-full"
                    title="Resume Preview"
                  />
                ) : (
                  <div className="w-full h-full flex items-center justify-center">
                    <p className="text-gray-500">
                      Preview not available for Word documents
                    </p>
                  </div>
                )}
              </div>

              <button
                onClick={gradeResumeWithChatGPT}
                disabled={!file || loading}
                className="w-full bg-gradient-to-r from-purple-600 to-pink-500 text-white py-3 px-6 rounded-lg font-medium
                           hover:from-purple-700 hover:to-pink-600 transition-all duration-200
                           disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center gap-2"
              >
                <CheckCircle className="h-5 w-5" />
                {loading ? 'Analyzing Resume...' : 'Grade Resume'}
              </button>
            </div>
          )}

          {/* ---- Graded Feedback ---- */}
          {grade && (
            <div className="mt-8 space-y-6">
              <div className="prose prose-lg max-w-none">
                <ReactMarkdown
                  remarkPlugins={[remarkGfm]}
                  components={{
                    h1: ({ node, ...props }) => (
                      <h1 className="text-3xl font-bold text-gray-900 mb-4" {...props} />
                    ),
                    h2: ({ node, ...props }) => (
                      <h2 className="text-2xl font-semibold text-gray-900 mb-3" {...props} />
                    ),
                    h3: ({ node, ...props }) => (
                      <h3 className="text-xl font-medium text-gray-900 mb-2" {...props} />
                    ),
                    p: ({ node, ...props }) => (
                      <p className="text-gray-600 mb-4 leading-relaxed" {...props} />
                    ),
                    ul: ({ node, ...props }) => (
                      <ul className="list-disc pl-6 mb-4 space-y-2 text-gray-600" {...props} />
                    ),
                    li: ({ node, ...props }) => <li className="mb-2" {...props} />,
                  }}
                >
                  {grade}
                </ReactMarkdown>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResumeGrader;