import React, { useState, useEffect } from 'react';
import { Lock, Eye, EyeOff, Copy, Plus, Trash2, Save, Search, Key, Globe, User, Settings, X } from 'lucide-react';

const PasswordManager = () => {
  const [passwords, setPasswords] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [showPassword, setShowPassword] = useState({});
  const [notification, setNotification] = useState('');
  const [passwordSettings, setPasswordSettings] = useState({
    length: 16,
    includeNumbers: true,
    includeSpecialChars: true,
    includeLowercase: true,
    includeUppercase: true
  });
  const [newEntry, setNewEntry] = useState({
    title: '',
    username: '',
    password: '',
    website: ''
  });

  useEffect(() => {
    const storedPasswords = localStorage.getItem('passwords');
    if (storedPasswords) {
      setPasswords(JSON.parse(storedPasswords));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('passwords', JSON.stringify(passwords));
  }, [passwords]);

  const generatePassword = () => {
    let charset = '';
    if (passwordSettings.includeLowercase) charset += 'abcdefghijklmnopqrstuvwxyz';
    if (passwordSettings.includeUppercase) charset += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    if (passwordSettings.includeNumbers) charset += '0123456789';
    if (passwordSettings.includeSpecialChars) charset += '!@#$%^&*()_+-=[]{}|;:,.<>?';
    
    if (charset === '') {
      setNotification('Please select at least one character type');
      return;
    }

    let password = '';
    for (let i = 0; i < passwordSettings.length; i++) {
      password += charset.charAt(Math.floor(Math.random() * charset.length));
    }
    setNewEntry({ ...newEntry, password });
  };


  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setNotification('Copied to clipboard');
  };

  const togglePasswordVisibility = (id) => {
    setShowPassword(prev => ({
      ...prev,
      [id]: !prev[id]
    }));
  };

  const deletePassword = (id) => {
    setPasswords(passwords.filter(p => p.id !== id));
    setNotification('Password deleted');
  };

  const filteredPasswords = passwords.filter(p => 
    p.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    p.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
    p.website.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Basic Validation
    if (!newEntry.title || !newEntry.password) {
        setNotification('Title and password are required');
        return;
    }

    // Save the New Password
    const updatedPasswords = [...passwords, { ...newEntry, id: Date.now() }];
    setPasswords(updatedPasswords);

    // Clear Entry and Close Modal
    setNewEntry({ title: '', username: '', password: '', website: '' });
    setShowModal(false);
    setNotification('Password saved successfully');
};


  return (
    <div className="min-h-screen bg-gray-50">
      {/* Sidebar */}
      <div className="fixed left-0 top-0 h-full w-80 bg-white border-r border-gray-200 p-6">
        <div className="flex items-center gap-3 mb-8">
          <div className="h-10 w-10 rounded-xl bg-indigo-600 flex items-center justify-center">
            <Lock className="h-5 w-5 text-white" />
          </div>
          <h1 className="text-xl font-semibold">Password Vault</h1>
        </div>

        <div className="relative mb-6">
          <Search className="absolute left-3 top-2.5 h-4 w-4 text-gray-400" />
          <input
            type="text"
            placeholder="Search passwords..."
            className="w-full pl-10 pr-4 py-2 bg-gray-50 rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <button
          onClick={() => setShowModal(true)}
          className="w-full py-2 px-4 bg-indigo-600 text-white rounded-lg flex items-center justify-center gap-2 hover:bg-indigo-700 transition-colors"
        >
          <Plus className="h-4 w-4" />
          Add Password
        </button>
      </div>

      {/* Main Content */}
      <div className="ml-80 p-8">
        {notification && (
          <div className="fixed top-4 right-4 bg-gray-900 text-white px-4 py-2 rounded-lg shadow-lg flex items-center gap-2">
            <span>{notification}</span>
            <button onClick={() => setNotification('')}>
              <X className="h-4 w-4" />
            </button>
          </div>
        )}

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {filteredPasswords.map((entry) => (
            <div
              key={entry.id}
              className="bg-white p-6 rounded-xl border border-gray-100 hover:shadow-lg transition-all duration-200"
            >
              <div className="flex justify-between items-start mb-4">
                <h3 className="font-medium text-lg">{entry.title}</h3>
                <button
                  onClick={() => deletePassword(entry.id)}
                  className="text-gray-400 hover:text-red-500 transition-colors"
                >
                  <Trash2 className="h-4 w-4" />
                </button>
              </div>
              
              <div className="space-y-2">
                {entry.username && (
                  <div className="flex items-center gap-2 text-gray-600">
                    <User className="h-4 w-4" />
                    <span className="text-sm">{entry.username}</span>
                  </div>
                )}
                {entry.website && (
                  <div className="flex items-center gap-2 text-gray-600">
                    <Globe className="h-4 w-4" />
                    <span className="text-sm">{entry.website}</span>
                  </div>
                )}
                <div className="flex items-center gap-2">
                  <div className="flex-1 bg-gray-50 rounded-lg p-2 font-mono text-sm">
                    {showPassword[entry.id] ? entry.password : '••••••••'}
                  </div>
                  <button
                    onClick={() => togglePasswordVisibility(entry.id)}
                    className="p-2 text-gray-400 hover:text-indigo-600 transition-colors"
                  >
                    {showPassword[entry.id] ? <EyeOff className="h-4 w-4" /> : <Eye className="h-4 w-4" />}
                  </button>
                  <button
                    onClick={() => copyToClipboard(entry.password)}
                    className="p-2 text-gray-400 hover:text-indigo-600 transition-colors"
                  >
                    <Copy className="h-4 w-4" />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Modal */}
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-xl w-full max-w-lg p-6">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-semibold">Add New Password</h2>
              <button onClick={() => setShowModal(false)} className="text-gray-400 hover:text-gray-600">
                <X className="h-5 w-5" />
              </button>
            </div>

            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="text-sm font-medium text-gray-700 mb-1 block">Title</label>
                <input
                  type="text"
                  placeholder="e.g., Gmail Account"
                  className="w-full p-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  value={newEntry.title}
                  onChange={(e) => setNewEntry({ ...newEntry, title: e.target.value })}
                />
              </div>

              <div>
                <label className="text-sm font-medium text-gray-700 mb-1 block">Username</label>
                <input
                  type="text"
                  placeholder="e.g., john@gmail.com"
                  className="w-full p-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  value={newEntry.username}
                  onChange={(e) => setNewEntry({ ...newEntry, username: e.target.value })}
                />
              </div>

              <div>
                <label className="text-sm font-medium text-gray-700 mb-1 block">Website</label>
                <input
                  type="text"
                  placeholder="e.g., gmail.com"
                  className="w-full p-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  value={newEntry.website}
                  onChange={(e) => setNewEntry({ ...newEntry, website: e.target.value })}
                />
              </div>

              <div>
                <label className="text-sm font-medium text-gray-700 mb-1 block">Password</label>
                <div className="flex gap-2">
                  <div className="relative flex-1">
                    <input
                      type={showPassword.new ? "text" : "password"}
                      placeholder="Enter password"
                      className="w-full p-2 pr-10 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                      value={newEntry.password}
                      onChange={(e) => setNewEntry({ ...newEntry, password: e.target.value })}
                    />
                    <button
                      type="button"
                      className="absolute right-3 top-2.5 text-gray-400 hover:text-gray-600"
                      onClick={() => togglePasswordVisibility('new')}
                    >
                      {showPassword.new ? <EyeOff className="h-4 w-4" /> : <Eye className="h-4 w-4" />}
                    </button>
                  </div>
                  <button
                    type="button"
                    onClick={generatePassword}
                    className="px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors"
                  >
                    Generate
                  </button>
                </div>
              </div>

              <div className="bg-gray-50 rounded-lg p-4">
                <div className="flex items-center gap-2 mb-4">
                  <Settings className="h-4 w-4 text-gray-600" />
                  <h3 className="font-medium text-gray-700">Password Settings</h3>
                </div>

                <div className="space-y-4">
                  <div>
                    <label className="text-sm text-gray-600 mb-1 block">Length: {passwordSettings.length}</label>
                    <input
                      type="range"
                      min="8"
                      max="32"
                      value={passwordSettings.length}
                      onChange={(e) => setPasswordSettings({
                        ...passwordSettings,
                        length: parseInt(e.target.value)
                      })}
                      className="w-full"
                    />
                  </div>

                  <div className="grid grid-cols-2 gap-2">
                    <label className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        checked={passwordSettings.includeLowercase}
                        onChange={(e) => setPasswordSettings({
                          ...passwordSettings,
                          includeLowercase: e.target.checked
                        })}
                        className="rounded border-gray-300"
                      />
                      <span className="text-sm text-gray-600">Lowercase</span>
                    </label>
                    <label className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        checked={passwordSettings.includeUppercase}
                        onChange={(e) => setPasswordSettings({
                          ...passwordSettings,
                          includeUppercase: e.target.checked
                        })}
                        className="rounded border-gray-300"
                      />
                      <span className="text-sm text-gray-600">Uppercase</span>
                    </label>
                    <label className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        checked={passwordSettings.includeNumbers}
                        onChange={(e) => setPasswordSettings({
                          ...passwordSettings,
                          includeNumbers: e.target.checked
                        })}
                        className="rounded border-gray-300"
                      />
                      <span className="text-sm text-gray-600">Numbers</span>
                    </label>
                    <label className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        checked={passwordSettings.includeSpecialChars}
                        onChange={(e) => setPasswordSettings({
                          ...passwordSettings,
                          includeSpecialChars: e.target.checked
                        })}
                        className="rounded border-gray-300"
                      />
                      <span className="text-sm text-gray-600">Symbols</span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="flex justify-end gap-2 mt-6">
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg transition-colors"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors flex items-center gap-2"
                >
                  <Save className="h-4 w-4" />
                  Save Password
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default PasswordManager;