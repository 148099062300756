import React, { useEffect, useState } from 'react';
import { useAuth } from '@clerk/clerk-react';
import { Search, Sparkles } from 'lucide-react';
import Navbar from '../components/Navbar'; // Adjust if your Navbar path is different
import CaseStudyGrid from '../components/CaseStudyGrid';

const CaseStudySelection = () => {
  const [caseStudies, setCaseStudies] = useState([]);
  const [topics, setTopics] = useState([]);
  const [difficultyLevels, setDifficultyLevels] = useState([]);
  const [selectedDifficulty, setSelectedDifficulty] = useState('all');
  const [selectedArea, setSelectedArea] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');

  const url = process.env.REACT_APP_BACK_END_URL; // e.g., http://localhost:5000
  const { getToken } = useAuth();

  useEffect(() => {
    const fetchCaseStudies = async () => {
      try {
        const token = await getToken();
        const response = await fetch(`${url}/api/mainCases`, {
          headers: {
            Authorization: `Bearer ${token}`
          },
        });

        if (!response.ok) {
          const errText = await response.text();
          console.error('Error fetching case studies:', errText);
          return;
        }

        const data = await response.json();
        // Make sure data.caseStudies is an array.
        setCaseStudies(data.caseStudies || []);
        // Optionally load "topics" or "difficultyLevels" if your backend provides them
        setTopics(data.topics || []);
        setDifficultyLevels(data.difficultyLevels || []);
      } catch (err) {
        console.error('Error:', err);
      }
    };
    fetchCaseStudies();
  }, [url, getToken]);

  // Example list of areas if not returned by your backend
  const areas = [
    'Profitability',
    'SWOT',
    "3Cs",
    '4Ps',
    'Market Sizing',
    'Marketing Strategy',
    "Porter's Five Forces",
    'Growth Strategy',
    'Marketing Entry/Expansion',
    'Synergies',
    'Cost Modeling',
    'BCG Growth Share Matrix',
  ];

  // Filter logic
  const filterCaseStudies = () => {
    return caseStudies.filter((study) => {
      const matchesDifficulty =
        selectedDifficulty === 'all' ||
        study.difficultyLevel === selectedDifficulty;

      const matchesArea =
        selectedArea === 'all' || study.area === selectedArea;

      // Search across study.title or study.company
      const title = study.title ? study.title.toLowerCase() : '';
      const company = study.company ? study.company.toLowerCase() : '';
      const query = searchTerm.toLowerCase();
      const matchesSearch = title.includes(query) || company.includes(query);

      return matchesDifficulty && matchesArea && matchesSearch;
    });
  };

  return (
    <>
      <Navbar />
      <div className="min-h-screen bg-gradient-to-br from-slate-50 to-purple-50">
        {/* -- Page Header -- */}
        <div className="w-full bg-white bg-opacity-90 backdrop-blur-lg border-b border-gray-100">
          <div className="max-w-7xl mx-auto py-8 px-6">
            <div className="flex items-center gap-2 text-violet-600 mb-2">
              <Sparkles className="w-5 h-5" />
              <span className="text-sm font-medium">Case Study Explorer</span>
            </div>
            <h1 className="text-5xl font-bold text-gray-900 mb-4">
              Practice Case Studies
            </h1>
            <p className="text-lg text-gray-600">
              Choose from our collection of real-world consulting case studies
            </p>
          </div>
        </div>

        {/* -- Main Content -- */}
        <div className="max-w-7xl mx-auto px-6 py-12">
          {/* -- Filters & Search -- */}
          <div className="bg-white bg-opacity-90 backdrop-blur-lg rounded-2xl shadow-lg p-6 mb-8">
            <div className="flex flex-wrap gap-4">
              {/* -- Search Field -- */}
              <div className="flex-1 min-w-[300px]">
                <div className="relative">
                  <Search className="absolute left-4 top-3.5 w-5 h-5 text-gray-400" />
                  <input
                    type="text"
                    placeholder="Search case studies..."
                    className="w-full pl-12 pr-4 py-3 rounded-xl border border-gray-200 focus:ring-2 focus:ring-violet-500 focus:border-transparent transition-all duration-200"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
              </div>

              {/* -- Difficulty Filter -- */}
              <div className="flex gap-4">
                <select
                  value={selectedDifficulty}
                  onChange={(e) => setSelectedDifficulty(e.target.value)}
                  className="pl-4 pr-10 py-3 rounded-xl border border-gray-200 focus:ring-2 focus:ring-violet-500 focus:border-transparent transition-all duration-200"
                >
                  <option value="all">All Difficulties</option>
                  {difficultyLevels.map((lvl) => (
                    <option key={lvl} value={lvl}>
                      {lvl.charAt(0).toUpperCase() + lvl.slice(1)}
                    </option>
                  ))}
                </select>

                {/* -- Area/Topic Filter -- */}
                <select
                  value={selectedArea}
                  onChange={(e) => setSelectedArea(e.target.value)}
                  className="pl-4 pr-10 py-3 rounded-xl border border-gray-200 focus:ring-2 focus:ring-violet-500 focus:border-transparent transition-all duration-200"
                >
                  <option value="all">All Areas</option>
                  {areas.map((area) => (
                    <option key={area} value={area}>
                      {area}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          {/* -- Case Studies Grid -- */}
          {/*
            Instead of manually mapping over filterCaseStudies(),
            we pass the array into CaseStudyGrid as a prop.
          */}
          <CaseStudyGrid caseStudies={filterCaseStudies()} />
        </div>
      </div>
    </>
  );
};

export default CaseStudySelection;
