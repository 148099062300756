import React, { useState } from 'react';
import { CheckCircle, Menu as MenuIcon, LayoutDashboard } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import LessonNavigation from '../components/LessonNavigation';
import { useAuth } from '@clerk/clerk-react'; // <-- Clerk React import

const PortersFiveForcesPage = () => {
  const [buttonState, setButtonState] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const navigate = useNavigate();

  // If you're using Create React App, define REACT_APP_BACK_END_URL in your .env
  // e.g. REACT_APP_BACK_END_URL=https://your-backend.com
  const url = process.env.REACT_APP_BACK_END_URL;

  // Clerk auth helpers (if your server requires an auth token)
  const { userId, getToken } = useAuth();

  const activeLessonTitle = "Porter's 5 Forces";
  const prevLessonPath = "/market"; // Adjust as needed
  const nextLessonPath = "/growth"; // Adjust as needed

  // Handle the POST request to mark the lesson as completed
  const handleMarkCompleted = async () => {
    try {
      // Retrieve Clerk's session token if needed by your server
      const token = await getToken();

      // Post to your backend
      const response = await fetch(`${url}/api/marked-completed`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // if required by your backend
        },
        body: JSON.stringify({
          pageTitle: "Porter's 5 Forces", // Must match your lesson title in Sidebar
          clerkUserId: userId,
        }),
      });

      if (!response.ok) {
        throw new Error(`Failed to mark completed: ${response.statusText}`);
      }

      // If successful, switch the button to show "Completed Session"
      setButtonState(true);
      console.log("Lesson successfully marked as completed!");
    } catch (error) {
      console.error("Error marking lesson completed:", error);
      // Optionally show an error message to the user
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex">
      {/* Sidebar */}
      {isSidebarOpen && (
        <Sidebar 
          isSidebarOpen={isSidebarOpen} 
          setIsSidebarOpen={setIsSidebarOpen} 
          activeLessonTitle={activeLessonTitle}
        />
      )}

      {/* Main Content */}
      <div className={`flex-1 transition-all duration-300 ${isSidebarOpen ? 'ml-80' : 'ml-0'}`}>
        
        {/* Top Navigation */}
        <div className="bg-white border-b sticky top-0 z-40">
          <div className="h-16 flex items-center px-6">
            {!isSidebarOpen && (
              <button 
                onClick={() => setIsSidebarOpen(true)} 
                className="text-gray-500 hover:text-gray-700 mr-4"
              >
                <MenuIcon className="w-6 h-6" />
              </button>
            )}

            <button 
              onClick={() => navigate('/main')}
              className="flex items-center space-x-2 px-4 py-2 text-gray-600 hover:text-gray-900 hover:bg-gray-50 rounded-lg transition-colors duration-200"
            >
              <LayoutDashboard className="w-4 h-4" />
              <span>Back to Dashboard</span>
            </button>

            <div className="flex-1"></div>

            <LessonNavigation 
              prevLessonPath={prevLessonPath}
              nextLessonPath={nextLessonPath}
            />
          </div>
        </div>

        {/* Page Content */}
        <div className="max-w-4xl mx-auto px-8 py-12">
          {buttonState ? (
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              Porter's Five Forces Questions in Management Consulting
              <CheckCircle className="w-6 h-6 text-green-500 inline-block ml-2" />
            </h2>
          ) : (
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              Porter's Five Forces Questions in Management Consulting
            </h2>
          )}

          <div className="rounded-xl overflow-hidden shadow-lg mb-6">
            <div className="aspect-w-16 aspect-h-9">
              <video width="100%" height="auto" controls className="object-cover">
                <source src="/images/porterfive.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>

          <div className="p-6 rounded-xl mb-12" style={{ backgroundColor: '#F0F4FA' }}>
            <h3 className="text-2xl font-bold mb-4">What are Porter's Five Forces?</h3>
            <p className="text-gray-700 mb-4">
              In management consulting interviews, candidates often analyze a company's competitive position 
              and industry dynamics. Porter's Five Forces, developed by Michael Porter at Harvard Business 
              School, is a widely used framework for assessing competitive intensity and industry attractiveness.
            </p>
            <p className="text-gray-700 mb-4">
              Published in 1979 in the Harvard Business Review, Porter's work was groundbreaking, emphasizing 
              that competition is not just from direct rivals but also from new entrants, substitutes, suppliers, 
              and buyers.
            </p>
            <p className="text-gray-700 mb-4">
              As a consultant, you'll help firms position themselves strategically by considering all these 
              forces. In this article, we'll guide you through the components of Porter's Five Forces and provide 
              tips for applying it in interviews.
            </p>
            <br />
            <h3 className="text-xl font-bold mb-4">Understanding Porter's Five Forces:</h3>
            <p className="text-gray-700 mb-4">
              The framework examines five factors influencing competition within an industry:
            </p>
            <ol className="list-decimal list-inside mb-4 text-gray-700 pl-4">
              <li><strong>Threat of New Entrants:</strong> Consider entry barriers, resources of potential entrants, and how easily new players can join.</li>
              <li><strong>Bargaining Power of Suppliers:</strong> Evaluate how much suppliers can influence prices or quality.</li>
              <li><strong>Bargaining Power of Buyers:</strong> Assess how buyers can demand lower prices or better quality.</li>
              <li><strong>Threat of Substitute Products/Services:</strong> Identify alternatives that can fulfill similar needs.</li>
              <li><strong>Rivalry Among Existing Competitors:</strong> Examine the intensity of competition, number of players, and market growth.</li>
            </ol>
          </div>

          <div className="mb-12">
            <h3 className="text-2xl font-bold mb-4">Applying Porter's Five Forces in Case Interviews:</h3>
            <p className="text-gray-700 mb-4">
              In a case involving industry analysis, start by identifying the relevant industry and key players. 
              Then, consider each force:
            </p>

            <h5 className="text-lg font-semibold mb-2">Threat of New Entrants:</h5>
            <ul className="list-disc list-inside mb-4 text-gray-700 pl-4">
              <li>Analyze entry barriers like capital requirements, regulations, and brand loyalty.</li>
              <li>Consider the impact of well-funded firms (e.g., Amazon) entering and disrupting the market.</li>
            </ul>

            <h5 className="text-lg font-semibold mb-2">Bargaining Power of Suppliers:</h5>
            <ul className="list-disc list-inside mb-4 text-gray-700 pl-4">
              <li>Evaluate supplier concentration and uniqueness of their offerings.</li>
              <li>Assess the cost of switching to alternative suppliers.</li>
            </ul>

            <h5 className="text-lg font-semibold mb-2">Bargaining Power of Buyers:</h5>
            <ul className="list-disc list-inside mb-4 text-gray-700 pl-4">
              <li>Analyze buyer concentration and their price sensitivity.</li>
              <li>Consider how easily buyers can switch to alternatives.</li>
            </ul>

            <h5 className="text-lg font-semibold mb-2">Threat of Substitute Products/Services:</h5>
            <ul className="list-disc list-inside mb-4 text-gray-700 pl-4">
              <li>Identify substitutes fulfilling similar needs.</li>
              <li>Compare price and performance of substitutes to current products.</li>
            </ul>

            <h5 className="text-lg font-semibold mb-2">Rivalry Among Existing Competitors:</h5>
            <ul className="list-disc list-inside mb-4 text-gray-700 pl-4">
              <li>Evaluate the number, size, and growth of competitors.</li>
              <li>Consider product differentiation and exit barriers influencing rivalry.</li>
            </ul>
            <br />
            <h3 className="text-2xl font-bold mb-4">Tips for Acing Porter's Five Forces in Interviews:</h3>
            <ul className="list-disc list-inside mb-4 text-gray-700 pl-4">
              <li><strong>Be Exhaustive:</strong> Thoroughly examine each force.</li>
              <li><strong>Use Examples:</strong> Support analysis with relevant case or industry examples.</li>
              <li><strong>Identify Interrelationships:</strong> Show how forces influence each other.</li>
              <li><strong>Draw Implications:</strong> Discuss overall industry attractiveness.</li>
              <li><strong>Practice with Diverse Industries:</strong> Get comfortable applying the framework broadly.</li>
            </ul>
            <p className="text-gray-700 mb-4">
              We'll illustrate this with an example involving the VHS/DVD rental market (Blockbuster vs. Netflix).
            </p>
          </div>

          <div className="p-6 rounded-xl mb-12" style={{ backgroundColor: '#F0F4FA' }}>
            <h3 className="text-2xl font-bold mb-4">Sample Analysis of the VHS/DVD Rental Market</h3>
            <p className="text-gray-700 mb-4">
              In the 90s and early 2000s, Blockbuster dominated entertainment rentals via physical stores. 
              Using Porter's framework at the time:
            </p>
            <ul className="list-disc list-inside mb-4 text-gray-700 pl-4">
              <li><strong>Competitors:</strong> Few large rental competitors. Movie theaters and media distributors existed, but Blockbuster was a key player.</li>
              <li><strong>Suppliers:</strong> Wholesalers of entertainment media. Blockbuster's large volume likely gave it bargaining power.</li>
              <li><strong>Consumers:</strong> Price-sensitive families preferring rentals over expensive theater outings. Price sensitivity was a key force.</li>
              <li><strong>Substitutes:</strong> Purchasing media outright. At the time, not very strong substitutes.</li>
            </ul>
            <p className="text-gray-700 mb-4">
              The major overlooked force was the threat of new entrants. Netflix disrupted the model by offering 
              digital kiosks and mail delivery, eventually streaming services. Blockbuster underestimated this 
              new entrant threat.
            </p>
            <p className="text-gray-700">
              By ignoring Netflix's innovative approach, Blockbuster missed an opportunity to adapt, and the new 
              entrant force ultimately reshaped the industry.
            </p>
          </div>

          <div className="mb-12">
            <h2 className="text-2xl font-bold mb-4">Conclusion</h2>
            <p className="text-gray-700 mb-4">
              Porter's Five Forces is a powerful tool for understanding industry competitiveness. By examining 
              each force and their interrelationships, you show strategic thinking and business acumen. 
              Practice applying it across industries and use examples. With a strong handle on Porter's Five 
              Forces, you'll excel in industry analysis questions in consulting interviews.
            </p>
          </div>

          {/* Completion Button */}
          <div className="text-center mt-12">
            {buttonState ? (
              <button
                type="button"
                className="px-8 py-3 bg-green-500 text-white rounded-xl shadow-lg flex items-center justify-center space-x-2 mx-auto hover:bg-green-600 transition-colors duration-200"
              >
                <CheckCircle className="w-5 h-5" />
                <span>Completed Session</span>
              </button>
            ) : (
              <button
                type="button"
                onClick={handleMarkCompleted}
                className="px-8 py-3 bg-gradient-to-r from-indigo-500 to-purple-500 text-white rounded-xl shadow-lg flex items-center justify-center space-x-2 mx-auto hover:shadow-xl transition-all duration-200"
              >
                <span>Mark Lesson Completed</span>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortersFiveForcesPage;
