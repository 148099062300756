import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, ArrowRight } from 'lucide-react';

const LessonNavigation = ({ prevLessonPath, nextLessonPath }) => {
  const navigate = useNavigate();

  return (
    <div className="flex space-x-4">
      {prevLessonPath && (
        <button
          onClick={() => navigate(prevLessonPath)}
          className="flex items-center space-x-2 px-4 py-2 text-gray-600 hover:text-gray-900"
        >
          <ArrowLeft className="w-4 h-4" />
          <span>Previous</span>
        </button>
      )}

      {nextLessonPath && (
        <button
          onClick={() => navigate(nextLessonPath)}
          className="flex items-center space-x-2 px-4 py-2 bg-gradient-to-r from-indigo-500 to-purple-500 text-white rounded-lg hover:shadow-lg transition-all duration-200"
        >
          <span>Next Lesson</span>
          <ArrowRight className="w-4 h-4" />
        </button>
      )}
    </div>
  );
};

export default LessonNavigation;
