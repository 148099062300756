import React from 'react';
import { Link } from 'react-router-dom';
import { Award, MessageSquare, X, Check, AlertTriangle, Info } from 'lucide-react';

/**
 * Expects props:
 * - open: boolean (whether the popup is visible)
 * - onClose: function to close the popup
 * - feedback: object like { grade, strengths[], improvements[], summary }
 */
const Popup = ({ open, onClose, feedback = {} }) => {
  if (!open) return null;

  // Destructure from feedback
  const { grade, strengths = [], improvements = [], summary = '' } = feedback;

  // Color the grade text
  const getGradeColor = (g = '') => {
    const gradeUpper = g.toUpperCase();
    if (!gradeUpper) return 'text-gray-500';
    if (['A+', 'A', 'A-'].includes(gradeUpper)) return 'text-green-500';
    if (['B+', 'B', 'B-'].includes(gradeUpper)) return 'text-green-500';
    if (['C+', 'C', 'C-'].includes(gradeUpper)) return 'text-yellow-500';
    return 'text-red-500'; // covers D or F
  };

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center p-4 
                 bg-black/50 backdrop-blur-sm animate-in fade-in duration-300"
      onClick={onClose}
    >
      <div
        className="bg-gradient-to-br from-white to-purple-50 w-full max-w-2xl 
                   rounded-2xl shadow-2xl relative overflow-hidden"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="relative p-8 max-h-[80vh] overflow-y-auto">
          {/* --- Header (Grade) --- */}
          <div className="flex items-center justify-between mb-8">
            <div className="flex items-center gap-4">
              <div className="p-3 bg-gradient-to-br from-purple-500 to-violet-600 rounded-xl shadow-lg">
                <Award className="w-8 h-8 text-white" />
              </div>
              <div>
                <h4 className="text-sm font-medium text-purple-600 mb-1">
                  Your Grade
                </h4>
                <h2 className={`text-4xl font-bold ${getGradeColor(grade)}`}>
                  {grade || 'N/A'}
                </h2>
              </div>
            </div>
            <button
              onClick={onClose}
              className="p-2 hover:bg-purple-100 rounded-lg transition-colors"
              aria-label="Close modal"
            >
              <X className="w-5 h-5 text-purple-600" />
            </button>
          </div>

          {/* --- Instructor Feedback Title --- */}
          <div className="flex items-center gap-3 mb-4">
            <MessageSquare className="w-5 h-5 text-purple-600" />
            <h3 className="text-lg font-semibold text-gray-900">
              Instructor Feedback
            </h3>
          </div>

          {/* --- Strengths --- */}
          {strengths.length > 0 && (
            <div className="mb-8 space-y-4">
              <h4 className="text-lg font-semibold text-gray-800">Strengths</h4>
              <ul className="pl-6 list-disc space-y-2">
                {strengths.map((item, idx) => (
                  <li key={idx} className="text-gray-700 leading-relaxed">
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          )}

          {/* --- Areas for Improvement --- */}
          {improvements.length > 0 && (
            <div className="mb-8 space-y-4">
              <h4 className="text-lg font-semibold text-gray-800">
                Areas for Improvement
              </h4>
              <ul className="space-y-3">
                {improvements.map((item, idx) => (
                  <li
                    key={idx}
                    className="bg-amber-50 border border-amber-100 
                               p-4 rounded-lg text-gray-700 leading-relaxed"
                  >
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          )}

          {/* --- Summary --- */}
          {summary && (
            <div className="mb-8 space-y-2">
              <h4 className="text-lg font-semibold text-gray-800">Summary</h4>
              <div className="bg-blue-50 border border-blue-100 
                              p-4 rounded-lg text-gray-700 leading-relaxed"
              >
                {summary}
              </div>
            </div>
          )}

          {/* --- Action Buttons --- */}
          <div className="flex justify-end gap-4 mt-4">
            <Link
              to="/dashboard"
              className="px-6 py-2.5 bg-gradient-to-r from-purple-500 to-violet-600 
                         text-white rounded-lg shadow-lg hover:opacity-90 transition-opacity"
            >
              See Performance
            </Link>
            <Link
              to="/cases"
              className="px-6 py-2.5 bg-gradient-to-r from-purple-500 to-violet-600 
                         text-white rounded-lg shadow-lg hover:opacity-90 transition-opacity"
            >
              Try Another Case?
            </Link>
            <button
              onClick={onClose}
              className="px-6 py-2.5 bg-gradient-to-r from-purple-500 to-violet-600 
                         text-white rounded-lg shadow-lg hover:opacity-90 transition-opacity"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;
