import React, { useState } from 'react';
import { CheckCircle, Menu as MenuIcon, LayoutDashboard } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import LessonNavigation from '../components/LessonNavigation';
import { useAuth } from '@clerk/clerk-react'; // <-- Clerk React import

const ThreeCsModelPage = () => {
  const [buttonState, setButtonState] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const navigate = useNavigate();

  // If you're using Create React App, put REACT_APP_BACK_END_URL in your .env
  // e.g. REACT_APP_BACK_END_URL=https://your-backend.com
  const url = process.env.REACT_APP_BACK_END_URL;

  // Grab the Clerk auth helpers (if your backend requires the token)
  const { userId, getToken } = useAuth();

  // Set the current lesson title for sidebar highlighting
  const activeLessonTitle = "The 3 C's Model";

  // Define previous and next lesson paths as needed
  const prevLessonPath = "/swot"; 
  const nextLessonPath = "/4ps"; 

  // Handle marking this lesson as completed
  const handleMarkCompleted = async () => {
    try {
      // Retrieve Clerk's session token if required by your server
      const token = await getToken();

      // Post request to your backend
      const response = await fetch(`${url}/api/marked-completed`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // if your backend needs it
        },
        body: JSON.stringify({
          pageTitle: "The 3 C's Model", // Must match what's in your lessons array
          clerkUserId: userId,         // If your server needs the user ID
        }),
      });

      if (!response.ok) {
        throw new Error(`Failed to mark completed: ${response.statusText}`);
      }

      // If successful, update the button state
      setButtonState(true);
      console.log("Lesson successfully marked as completed!");
    } catch (error) {
      console.error("Error marking lesson completed:", error);
      // Optionally show an error message to the user
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex">
      {/* Sidebar */}
      {isSidebarOpen && (
        <Sidebar
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
          activeLessonTitle={activeLessonTitle}
        />
      )}

      {/* Main Content Area */}
      <div className={`flex-1 transition-all duration-300 ${isSidebarOpen ? 'ml-80' : 'ml-0'}`}>
        {/* Top Navigation Bar */}
        <div className="bg-white border-b sticky top-0 z-40">
          <div className="h-16 flex items-center px-6">
            {/* Toggle Sidebar button (visible only when sidebar is closed) */}
            {!isSidebarOpen && (
              <button
                onClick={() => setIsSidebarOpen(true)}
                className="text-gray-500 hover:text-gray-700 mr-4"
              >
                <MenuIcon className="w-6 h-6" />
              </button>
            )}

            {/* Dashboard button on the far left */}
            <button
              onClick={() => navigate('/main')}
              className="flex items-center space-x-2 px-4 py-2 text-gray-600 hover:text-gray-900 hover:bg-gray-50 rounded-lg transition-colors duration-200"
            >
              <LayoutDashboard className="w-4 h-4" />
              <span>Back to Dashboard</span>
            </button>

            {/* Spacer to push other buttons to the right */}
            <div className="flex-1"></div>

            {/* Previous and Next Lesson buttons */}
            <LessonNavigation
              prevLessonPath={prevLessonPath}
              nextLessonPath={nextLessonPath}
            />
          </div>
        </div>

        {/* Page Content */}
        <div className="max-w-4xl mx-auto px-8 py-12">
          {/* Heading and Video Section */}
          {buttonState ? (
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              Introduction to the 3 C's Framework
              <CheckCircle className="w-6 h-6 text-green-500 inline-block ml-2" />
            </h2>
          ) : (
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              Introduction to the 3 C's Framework
            </h2>
          )}

          <div className="rounded-xl overflow-hidden shadow-lg mb-6">
            <div className="aspect-w-16 aspect-h-9">
              <video width="100%" height="auto" controls className="object-cover">
                <source src="/images/CModel.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>

          <div className="p-6 rounded-xl mb-12" style={{ backgroundColor: '#F0F4FA' }}>
            <h2 className="text-2xl font-bold mb-4">Understanding the 3C's Strategic Framework</h2>
            <p className="text-gray-700 mb-4">
              In the world of management consulting, various strategic frameworks have been developed 
              to help businesses analyze and improve their performance. One such framework that has 
              gained significant traction among consulting firms is the 3C's framework. This article 
              will delve into the history of the 3C's framework, explain its components and how it is 
              used, and provide a detailed case study to illustrate its real-life application.
            </p>
            <br />
            <h3 className="text-xl font-bold mb-4">History of the 3C's Framework</h3>
            <p className="text-gray-700 mb-4">
              The 3C's framework was introduced by Japanese organizational theorist Kenichi Ohmae in 
              his 1982 book "The Mind of the Strategist." Ohmae, a former partner at McKinsey & Company, 
              developed this framework as a tool for strategic thinking and decision-making. The 3C's 
              stand for Company, Customers, and Competitors, which Ohmae believed were the three key 
              factors that businesses must consider when formulating their strategies.
            </p>
            <p className="text-gray-700">
              Over the years, the 3C's framework has been adopted and refined by various consulting firms, 
              becoming a staple in their strategic toolkits. Its simplicity and effectiveness in analyzing 
              a company's position in the market have made it a popular choice for consultants and business 
              leaders alike.
            </p>
          </div>

          <div className="mb-12">
            <h3 className="text-xl font-bold mb-4">Understanding the 3C's Framework</h3>
            <br />
            <h4 className="text-lg font-semibold mb-2">1. Company</h4>
            <p className="text-gray-700 mb-4">
              The first C focuses on the internal aspects of a business, including its strengths, 
              weaknesses, resources, and capabilities. By assessing these factors, a company can 
              identify its core competencies and areas for improvement. This is crucial as a business 
              looks to understand what their competitive edge will be against competitors, but also 
              understanding which areas they may not want to compete in given a lack of strength.
            </p>
            <p className="text-gray-700 mb-4">
              From our prior lessons, you saw that some headphone companies choose to compete in the 
              retail market. It may not make sense for them to expand into a luxury brand given their 
              core competencies are in creating affordable headphones. Expanding into the luxury segment 
              may require significant R&D expense, new distribution channels, and marketing expenses 
              that would otherwise eat into their current budgets. New luxury products may also require 
              setting up brand new divisions and hiring talent that is familiar with the technology 
              needed to deliver a higher-quality product.
            </p>
            <br />
            <h4 className="text-lg font-semibold mb-2">2. Customers</h4>
            <p className="text-gray-700 mb-4">
              The second C emphasizes the importance of understanding and meeting customer needs. This 
              involves analyzing customer segments, preferences, and behavior to develop products or 
              services that create value for the target market.
            </p>
            <p className="text-gray-700 mb-4">
              Similarly you've seen in other frameworks like the 4P's, the need for understanding the 
              target customer. We saw similar analyses done in the Product and Price analyses in prior 
              videos. Here in the 3C's, the customer is also emphasized. A strong consulting firm will 
              go into heavy detail creating personas that describe various aspects of the target customer. 
              Firms focus on specifics such as demographics, education, interests, and more to define 
              who the target customer is, often resulting in journey maps that describe a "day in the 
              life" of the consumer. By understanding consumers, firms can best identify "where, when, 
              and how" to market to these individuals.
            </p>
            <br />
            <h4 className="text-lg font-semibold mb-2">3. Competitors</h4>
            <p className="text-gray-700 mb-4">
              The third C highlights the need to evaluate the competitive landscape. By studying 
              competitors' strategies, strengths, and weaknesses, a company can identify opportunities 
              and threats in the market and develop a competitive advantage.
            </p>
            <p className="text-gray-700 mb-4">
              In analyzing a competitor landscape, firms may look at variables such as "size, brand 
              loyalty, price point, customer segment, and competitive advantage" of competing firms. 
              Through this analysis, firms can identify the weak spots in competing firms, but also 
              best prepare for how competitors may respond to your strategies.
            </p>
            <p className="text-gray-700 mb-4">
              For example, for market leaders, they may analyze how new entrants may attempt to 
              threaten their products through disruption. This kind of preparation allows firms to 
              prepare for various scenarios to quickly respond.
            </p>
            <p className="text-gray-700 mb-4">
              On the other hand, new entrants may analyze incumbents seeking the weak points in their 
              products or services in an attempt to capitalize on this. You may be familiar with the 
              eyeglasses brand, Warby Parker, which only a few years ago disrupted the eyewear industry 
              by eliminating the middle man. They saw that incumbents were charging on average $500 - 
              $1,000 in glasses; however, by going direct to manufacturers, they were able to create 
              a new market of affordable, chic, and trendy glasses that have taken the market by storm.
            </p>
            <p className="text-gray-700">
              Using the 3C's framework involves analyzing each component individually and then 
              synthesizing the insights to develop a comprehensive strategy. This process helps 
              businesses align their internal capabilities with customer needs while positioning 
              themselves effectively against competitors.
            </p>
          </div>

          <div className="p-6 rounded-xl mb-12" style={{ backgroundColor: '#F0F4FA' }}>
            <h3 className="text-xl font-bold mb-4">Case Study: Applying the 3C's Framework in the Automotive Industry</h3>
            <p className="text-gray-700 mb-4">
              Let's consider a case study of an automotive company, AutoTech Inc., which is looking to 
              expand its market share in the electric vehicle (EV) battery segment. They are a 
              pre-revenue firm with $300M in venture capital backing. They hope their proprietary battery 
              technology, lasting roughly 20% longer than most EV's in the market, will provide an advantage. 
              They have hired your firm to analyze the market and help them understand their best approach forward.
            </p>
            <br />
            <h4 className="text-lg font-semibold mb-2">Company Analysis</h4>
            <p className="text-gray-700 mb-4">
              AutoTech Inc. has a strong proprietary battery lasting longer than industry averages. 
              They have strong partnerships with raw material producers in China and with manufacturing 
              plants in Vietnam. With $300M in venture funding, they can invest in advertising, 
              recruiting sales talent, and exploring other avenues.
            </p>
            <p className="text-gray-700 mb-4">
              However, AutoTech is still new. They have no significant deals with leading vehicle 
              manufacturers. The EV market is small but growing at 20% CAGR over the next ten years. 
              To generate revenue, AutoTech must convince EV producers to use their batteries over 
              incumbents. This may require relationship building, marketing spend, and potential 
              discounts to build trust. Incumbents may also improve their battery life to compete.
            </p>
            <br />
            <h4 className="text-lg font-semibold mb-2">Customer Analysis</h4>
            <p className="text-gray-700 mb-4">
              Customers in the EV segment prioritize driving range, charging infrastructure, and 
              environmental impact. Demand for affordable, long-range EVs with fast charging is 
              growing. AutoTech's longer-lasting battery is a positive. We should inquire about 
              charging speed capability and compatibility with common charging stations.
            </p>
            <p className="text-gray-700 mb-4">
              These customers appear not to be highly price-sensitive and value quality and range, 
              potentially allowing AutoTech to charge a premium if they maintain their competitive 
              advantage.
            </p>
            <br />
            <h4 className="text-lg font-semibold mb-2">Competitor Analysis</h4>
            <p className="text-gray-700 mb-4">
              The EV battery market is highly competitive, with established players and traditional 
              automakers seeking to create their own batteries. Some have first-mover advantages, 
              established distribution, and supply chains. However, opportunities exist for 
              differentiation in drive time, charging speed, and sustainable manufacturing.
            </p>
          </div>

          <div className="container mb-12">
            <h4 className="text-lg font-semibold mb-2">Strategy Formulation</h4>
            <p className="text-gray-700 mb-4">
              Based on the 3C's analysis, AutoTech Inc. can develop a strategy that leverages its 
              strengths while addressing customer needs and competitive threats. Key elements may include:
            </p>
            <ol className="list-decimal list-inside mb-4 text-gray-700 pl-4">
              <li>Use funds to improve technology (driving range, charging speed) and possibly create their own charging stations.</li>
              <li>Collaborate with charging infrastructure providers for revenue sharing.</li>
              <li>Offer competitive pricing and financing options to attract EV producers and steal market share from incumbents.</li>
              <li>Explore partnerships with distributors to white-label products, similar to Amazon Basics or Trader Joe's model.</li>
            </ol>
            <p className="text-gray-700">
              By implementing this strategy, AutoTech Inc. can improve its position in the EV market 
              and capture a larger share of growing customer demand.
            </p>
          </div>

          <div className="p-6 rounded-xl mb-12" style={{ backgroundColor: '#F0F4FA' }}>
            <h3 className="text-xl font-bold mb-4">Conclusion</h3>
            <p className="text-gray-700 mb-4">
              The 3C's framework, focusing on Company, Customers, and Competitors, provides a structured 
              approach to analyze strategic positions. The AutoTech Inc. case demonstrates how applying 
              the 3C's can help identify opportunities and challenges, and formulate strategies aligning 
              capabilities with customer needs and competitive dynamics.
            </p>
            <p className="text-gray-700">
              By embracing this framework, businesses can enhance decision-making and create sustainable 
              competitive advantages in their markets.
            </p>
          </div>

          {/* Completion Button */}
          <div className="text-center mt-12">
            {buttonState ? (
              <button
                type="button"
                className="px-8 py-3 bg-green-500 text-white rounded-xl shadow-lg flex items-center justify-center space-x-2 mx-auto hover:bg-green-600 transition-colors duration-200"
              >
                <CheckCircle className="w-5 h-5" />
                <span>Completed Session</span>
              </button>
            ) : (
              <button
                type="button"
                onClick={handleMarkCompleted}
                className="px-8 py-3 bg-gradient-to-r from-indigo-500 to-purple-500 text-white rounded-xl shadow-lg flex items-center justify-center space-x-2 mx-auto hover:shadow-xl transition-all duration-200"
              >
                <span>Mark Lesson Completed</span>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThreeCsModelPage;
