import React from 'react';
import { Link } from 'react-router-dom';
import { Clock, Users, ArrowRight, Book } from 'lucide-react';

// ------------------ Single Card ------------------
const CaseStudyCard = ({ study }) => {
  const getDifficultyColor = (difficulty) => {
    switch ((difficulty || '').toLowerCase()) {
      case 'beginner':
        return 'bg-emerald-50 text-emerald-700 ring-1 ring-emerald-600/20';
      case 'intermediate':
        return 'bg-amber-50 text-amber-700 ring-1 ring-amber-600/20';
      case 'advanced':
        return 'bg-rose-50 text-rose-700 ring-1 ring-rose-600/20';
      default:
        return 'bg-gray-50 text-gray-700 ring-1 ring-gray-600/20';
    }
  };

  const getTimeEstimate = (difficulty) => {
    switch ((difficulty || '').toLowerCase()) {
      case 'easy':
        return '10 minutes';
      case 'medium':
        return '20 minutes';
      case 'hard':
        return '30 minutes';
      default:
        return '15 minutes';
    }
  };

  return (
    <div className="group relative bg-white rounded-2xl shadow-sm ring-1 ring-gray-200 hover:shadow-lg hover:ring-gray-300 transition-all duration-200">
      {study.isNew && (
        <div className="absolute -top-2 -right-2 z-10">
          <span className="px-3 py-1 text-xs font-semibold bg-violet-100 text-violet-700 rounded-full ring-2 ring-white">
            New
          </span>
        </div>
      )}

      <div className="p-6">
        {/* Header / Difficulty / Time */}
        <div className="mb-4">
          <div className="flex items-center gap-2 mb-2">
            <span
              className={`
                px-2.5 py-1 text-xs font-medium rounded-full 
                ${getDifficultyColor(study.difficultyLevel)}
              `}
            >
              {study.difficultyLevel}
            </span>
            <span className="text-xs text-gray-500">•</span>
            <div className="flex items-center text-gray-500 text-xs">
              <Clock className="w-3.5 h-3.5 mr-1" />
              {getTimeEstimate(study.difficultyLevel)}
            </div>
          </div>
          <h3 className="text-lg font-semibold text-gray-900 mb-1 group-hover:text-violet-600 transition-colors">
            {study.title}
          </h3>
          <p className="text-sm text-gray-600">{study.company}</p>
        </div>

        {/* Main Content */}
        <div className="space-y-4">
          {/* Framework/Area Label */}
          <div className="flex items-center gap-2">
            <Book className="w-4 h-4 text-gray-400" />
            <span className="text-sm text-gray-600">
              {study.area}
            </span>
          </div>

          {/* Completion Rate */}
          {study.completionRate && (
            <div className="flex items-center gap-2">
              <Users className="w-4 h-4 text-gray-400" />
              <div className="flex-1">
                <div className="h-2 w-full bg-gray-100 rounded-full overflow-hidden">
                  <div
                    className="h-full bg-violet-500 rounded-full"
                    style={{ width: `${study.completionRate}%` }}
                  />
                </div>
                <p className="text-xs text-gray-500 mt-1">
                  {study.completionRate}% completion rate
                </p>
              </div>
            </div>
          )}
        </div>

        {/* Gradient "Start Case Study" Button */}
        <Link
          to={`/case/${study._id}`}
          className="mt-6 inline-flex items-center justify-center w-full 
                     px-4 py-3 text-sm font-medium text-white
                     bg-gradient-to-r from-purple-500 to-pink-500 
                     rounded-xl hover:opacity-90 transition-all duration-200"
        >
          Start Case Study
          <ArrowRight className="w-4 h-4 ml-2" />
        </Link>
      </div>
    </div>
  );
};

// ------------------ Main Grid Component ------------------
const CaseStudyGrid = ({ caseStudies = [] }) => {
  // Provide a default empty array so we never try to map over `undefined`.
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {caseStudies.map((study) => (
        <CaseStudyCard key={study._id} study={study} />
      ))}
    </div>
  );
};

export default CaseStudyGrid;
