import React, { useState } from 'react';
import {
  ChevronDown,
  ChevronUp,
  Calculator,
  Target,
  BarChart,
  Layers,
  Compass,
  MessageCircle
} from 'lucide-react';
import Navbar from '../components/Navbar';
import MathSideBar from '../components/MathSideBar';

const ApproximationGuide = () => {
  const [openSections, setOpenSections] = useState({
    significant: true,
    rounding: false,
    margins: false,
    breakdown: false,
    benchmark: false,
    communication: false,
  });

  const toggleSection = (section) => {
    setOpenSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  // Reusable Section component
  const Section = ({ title, children, isOpen, onToggle, icon: Icon }) => (
    <div className="mb-8 rounded-lg bg-white shadow-lg overflow-hidden">
      <button
        onClick={onToggle}
        className="w-full px-6 py-4 flex items-center justify-between bg-gradient-to-r from-violet-600 to-pink-500 text-white"
      >
        <div className="flex items-center gap-3">
          <Icon size={24} />
          <h2 className="text-xl font-semibold">{title}</h2>
        </div>
        {isOpen ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
      </button>
      {isOpen && (
        <div className="p-6 bg-violet-50">
          {children}
        </div>
      )}
    </div>
  );

  // Reusable Example component
  const Example = ({ title, children }) => (
    <div className="mt-4 p-4 bg-white rounded-lg shadow-sm border border-violet-100">
      <h4 className="font-semibold text-lg mb-2">{title}</h4>
      {children}
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />
      <MathSideBar />

      {/* Main content area (to accommodate sidebar) */}
      <div className="lg:ml-72">
        {/* Page content container */}
        <main className="pt-20 px-4 pb-8 max-w-4xl mx-auto">
          {/* Header Section */}
          <div className="text-center mb-12">
            <div className="inline-block p-3 rounded-full bg-gradient-to-br from-violet-600 to-pink-500 mb-6">
              <Target className="w-12 h-12 text-white" />
            </div>
            <h1 className="text-4xl font-bold mb-4 bg-gradient-to-r from-violet-600 to-pink-500 bg-clip-text text-transparent">
              Approximation and Margins of Error
            </h1>
            <p className="text-gray-600 text-lg max-w-2xl mx-auto">
              Master the art of making quick, informed estimates while maintaining accuracy
            </p>
          </div>

          {/* Intro Paragraph */}
          <div className="bg-white rounded-xl p-6 shadow-lg mb-8">
            <p className="text-gray-600 leading-relaxed">
              In many real-world situations, especially in management consulting, precise calculations may not be
              necessary or even possible. By mastering approximation techniques and understanding error margins, you
              can make quick, informed estimates while maintaining a reasonable level of accuracy.
            </p>
          </div>

          {/* Sections */}
          <Section
            title="Significant Figures"
            isOpen={openSections.significant}
            onToggle={() => toggleSection('significant')}
            icon={Calculator}
          >
            <p className="text-gray-600 mb-4">
              Significant figures refer to the number of digits in a value that are considered meaningful. When making
              approximations, it's essential to consider the appropriate number of significant figures based on the
              context.
            </p>
            <Example title="Example: Company Revenue Estimation">
              <div className="space-y-2 text-gray-600">
                <p>Exact value: $37,492,158</p>
                <p>Approximation: $37,000,000 (two significant figures)</p>
                <p className="mt-2 text-violet-600 font-medium">
                  Presentation tip: "The company's annual revenue is approximately $37 million."
                </p>
              </div>
            </Example>
          </Section>

          <Section
            title="Rounding Techniques"
            isOpen={openSections.rounding}
            onToggle={() => toggleSection('rounding')}
            icon={Target}
          >
            <p className="text-gray-600 mb-4">
              Round numbers to the nearest easy-to-work-with values, such as powers of 10 or easily divisible numbers.
            </p>
            <Example title="Example: Office Renovation Budget">
              <div className="space-y-4">
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <h5 className="font-medium mb-2">Actual Costs:</h5>
                    <ul className="space-y-1 text-gray-600">
                      <li>Flooring: $12,750</li>
                      <li>Painting: $8,200</li>
                      <li>Furniture: $21,300</li>
                      <li>Lighting: $5,900</li>
                    </ul>
                  </div>
                  <div>
                    <h5 className="font-medium mb-2">Rounded Costs:</h5>
                    <ul className="space-y-1 text-gray-600">
                      <li>Flooring: $13,000</li>
                      <li>Painting: $8,000</li>
                      <li>Furniture: $21,000</li>
                      <li>Lighting: $6,000</li>
                    </ul>
                  </div>
                </div>
                <p className="text-violet-600">
                  Total approximation: $48,000 (actual: $48,150)
                </p>
              </div>
            </Example>
          </Section>

          <Section
            title="Error Margins"
            isOpen={openSections.margins}
            onToggle={() => toggleSection('margins')}
            icon={BarChart}
          >
            <p className="text-gray-600 mb-4">
              Consider the potential error margin – the range within which the actual value is likely to fall. This
              helps gauge accuracy and make informed decisions.
            </p>
            <Example title="Example: Conference Attendance Estimation">
              <div className="space-y-2 text-gray-600">
                <p>Last year's attendance: 1,200</p>
                <p>Company growth: ~15%</p>
                <div className="mt-4">
                  <p className="font-medium">Estimated Range:</p>
                  <ul className="list-disc ml-6 mt-2">
                    <li>Lower bound (10% growth): 1,320 attendees</li>
                    <li>Upper bound (20% growth): 1,440 attendees</li>
                  </ul>
                </div>
              </div>
            </Example>
          </Section>

          <Section
            title="Breaking Down Complex Problems"
            isOpen={openSections.breakdown}
            onToggle={() => toggleSection('breakdown')}
            icon={Layers}
          >
            <p className="text-gray-600 mb-4">
              Break complex problems into smaller, more manageable components. Estimate each component separately and
              then combine the results.
            </p>
            <Example title="Example: City Water Consumption">
              <div className="space-y-4 text-gray-600">
                <div>
                  <p className="font-medium">Components:</p>
                  <ul className="list-disc ml-6 mt-2">
                    <li>Population: 500,000 people</li>
                    <li>Daily usage per person: 80-100 gallons</li>
                  </ul>
                </div>
                <div>
                  <p className="font-medium">Calculations:</p>
                  <ul className="list-disc ml-6 mt-2">
                    <li>Residential: 500,000 × 80 = 40M gallons</li>
                    <li>Commercial (30%): 12M gallons</li>
                    <li>Total: 52M gallons daily</li>
                  </ul>
                </div>
              </div>
            </Example>
          </Section>

          <Section
            title="Benchmark Values"
            isOpen={openSections.benchmark}
            onToggle={() => toggleSection('benchmark')}
            icon={Compass}
          >
            <p className="text-gray-600 mb-4">
              Use known quantities as reference points for your approximations to quickly assess reasonableness.
            </p>
            <Example title="Example: Parking Lot Capacity">
              <div className="space-y-3 text-gray-600">
                <p className="font-medium">Known dimensions:</p>
                <ul className="list-disc ml-6">
                  <li>Parking space: 9' × 18'</li>
                  <li>Lot size: 300' × 600'</li>
                </ul>
                <p className="font-medium mt-4">Calculations:</p>
                <ul className="list-disc ml-6">
                  <li>Spaces per row: 300' ÷ 10' ≈ 30</li>
                  <li>Number of rows: 600' ÷ 20' ≈ 30</li>
                  <li>Total spaces: 900</li>
                  <li>At 80% occupancy: ~720 cars</li>
                </ul>
              </div>
            </Example>
          </Section>

          <Section
            title="Communicating Assumptions"
            isOpen={openSections.communication}
            onToggle={() => toggleSection('communication')}
            icon={MessageCircle}
          >
            <p className="text-gray-600 mb-4">
              Clearly communicate assumptions and limitations when presenting approximations to help stakeholders make
              informed decisions.
            </p>
            <Example title="Example: Software Cost Savings">
              <div className="space-y-4 text-gray-600">
                <div>
                  <p className="font-medium">Key Assumptions:</p>
                  <ul className="list-disc ml-6 mt-2">
                    <li>90% adoption rate among 1,000 employees</li>
                    <li>30 minutes saved per employee daily</li>
                    <li>Average hourly wage: $30</li>
                  </ul>
                </div>
                <div>
                  <p className="font-medium">Estimated Savings:</p>
                  <ul className="list-disc ml-6 mt-2">
                    <li>Daily time saved: 450 hours</li>
                    <li>Daily cost savings: $13,500</li>
                    <li>Annual savings: ~$3.5 million</li>
                  </ul>
                </div>
              </div>
            </Example>
          </Section>

          {/* Concluding Note */}
          <div className="mt-8 p-6 bg-violet-50 rounded-lg border border-violet-100">
            <h2 className="text-xl font-semibold mb-4">Master Your Approximations</h2>
            <p className="text-gray-600">
              By applying these techniques and regularly practicing approximation, you'll enhance your mental math skills
              and become more confident in making quick, informed estimates. Remember that in many situations, a
              well-reasoned estimate is just as valuable as a precise calculation.
            </p>
          </div>
        </main>
      </div>
    </div>
  );
};

export default ApproximationGuide;