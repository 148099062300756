import React, { useEffect, useState } from 'react';
import {
  Radar,
  Doughnut,
  Pie,
  Line,
  Bar
} from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto';
import { useAuth } from '@clerk/clerk-react';
import {
  Sparkles,
  Award,
  TrendingUp,
  BrainCircuit,
  CheckCircle,
  XCircle,
  HelpCircle
} from 'lucide-react';
import Navbar from '../components/Navbar'; // Adjust path if needed

const Dashboard = () => {
  const { getToken } = useAuth();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const url = process.env.REACT_APP_BACK_END_URL;

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const token = await getToken();
        const response = await fetch(`${url}/api/dashboard`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }

        const jsonData = await response.json();
        setData(jsonData);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching dashboard data:', err);
        setError(true);
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, [getToken, url]);

  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-slate-50 to-purple-50 p-6 flex items-center justify-center">
        <div className="text-violet-600 animate-pulse">Loading...</div>
      </div>
    );
  }

  if (error || !data) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-slate-50 to-purple-50 p-6 flex items-center justify-center">
        <div className="text-red-600">Error loading data.</div>
      </div>
    );
  }

  const {
    averageCaseStudyGrade,
    avgMath,
    averageResumeGrade,
    percentComplete,
    lineChartData,
    totalQuestions,
    correctQuestions,
    barGraphData,
    bestGrade,
    worstGrade,
    bestGradeTopic,
    worstGradeTopic,
    regressionDetails,
    mlGradePrediction
  } = data;

  const isGradeNaN = averageCaseStudyGrade == null;
  const isMathGradeNaN = avgMath == null;

  // Radar Data
  const radarData = {
    labels: ['Case Studies', 'Math Practice', 'Resume Review', 'Course Completion'],
    datasets: [{
      label: 'Consulting Prep Readiness',
      data: [
        averageCaseStudyGrade || 0,
        avgMath || 0,
        averageResumeGrade || 0,
        percentComplete ? parseFloat(percentComplete.toFixed(0)) : 0
      ],
      backgroundColor: 'rgba(75, 192, 192, 0.5)',
      borderColor: 'rgba(75, 192, 192, 1)',
      borderWidth: 1
    }]
  };

  // Doughnut Chart (Average Case Study Grade)
  const doughnutData = {
    labels: ['Grade', 'Remaining'],
    datasets: [{
      data: [averageCaseStudyGrade || 0, 100 - (averageCaseStudyGrade || 0)],
      backgroundColor: [
        'rgba(75, 192, 192, 0.6)',
        'rgba(201, 203, 207, 0.3)'
      ],
      borderWidth: 1
    }]
  };

  // Pie Chart (Math Performance)
  const pieData = {
    labels: ['Correct', 'Incorrect'],
    datasets: [{
      data: [avgMath || 0, 100 - (avgMath || 0)],
      backgroundColor: [
        'rgba(75, 192, 192, 0.6)',
        'rgba(255, 99, 132, 0.6)'
      ],
      borderWidth: 1
    }]
  };

  // Line Chart (Last N Case Studies)
  const lineData = {
    labels: lineChartData.labels,
    datasets: [{
      label: `Last ${lineChartData.labels.length} Case Study Grades`,
      data: lineChartData.data,
      fill: false,
      borderColor: 'rgb(75, 192, 192)',
      tension: 0.1
    }]
  };

  // Bar Chart (Average Grades by Topic)
  const avgByTopicData = {
    labels: barGraphData.labels,
    datasets: [{
      label: 'Average Grade',
      data: barGraphData.data,
      backgroundColor: 'rgba(54, 162, 235, 0.2)',
      borderColor: 'rgba(54, 162, 235, 1)',
      borderWidth: 1
    }]
  };

  // If you need a specific text explaining Math performance:
  const mathPerformanceDescription = isMathGradeNaN
    ? "No math questions have been attempted yet. Try a few questions to see your performance."
    : `You have attempted ${totalQuestions} math questions and answered ${correctQuestions} correctly. Average math performance score is ${avgMath.toFixed(0)}%.`;

  // Next attempt forecast
  const nextAttemptForecast = (regressionDetails && regressionDetails.x > 0 && regressionDetails.y && mlGradePrediction)
    ? <>Based on your previous submissions, your next attempt (<strong>{regressionDetails.x.toFixed(0)}</strong>) is forecasted to achieve a grade of approximately 
        <strong> {Math.min(regressionDetails.y.toFixed(0), 100)}%</strong>. Another model predicts <strong>{Math.min(mlGradePrediction.toFixed(0), 100)}%</strong>. These are estimates derived from past performance.
      </>
    : "To calculate a forecast for your next attempt, you must have at least one attempt in your history.";

  // Chart font styling (non-radar)
  const genericChartOptions = {
    plugins: {
      legend: {
        labels: {
          font: {
            family: 'Inter, system-ui, sans-serif'
          }
        }
      }
    }
  };

  const radarChartOptions = {
    scales: {
      r: {
        // Force min/max
        min: 0,
        max: 100,
        // or suggestedMin: 0, suggestedMax: 100,
        // or beginAtZero: true,
        ticks: {
          // Show ticks from 0–100
          stepSize: 10,
        }
      }
    }
  };

  return (
    <>
      {/* Navbar */}
      <Navbar />

      <div className="min-h-screen bg-gradient-to-br from-slate-50 to-purple-50">
        {/* Header Banner */}
        <div className="w-full bg-white bg-opacity-90 backdrop-blur-lg border-b border-gray-100">
          <div className="max-w-7xl mx-auto py-8 px-6">
            <div className="flex items-center gap-2 text-violet-600 mb-2">
              <Sparkles className="w-5 h-5" />
              <span className="text-sm font-medium">Performance Analytics</span>
            </div>
            <h1 className="text-5xl font-bold text-gray-900 mb-4">
              Your Dashboard
            </h1>
            <p className="text-lg text-gray-600">
              Track your progress and performance across all learning areas
            </p>
          </div>
        </div>

        <div className="max-w-7xl mx-auto px-6 py-12">
          {/* Overall Stats Cards */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
            {/* Card 1: Case Study Grade */}
            <div className="bg-white bg-opacity-90 backdrop-blur-lg rounded-2xl shadow-lg p-6">
              <div className="flex items-center gap-2 text-violet-600 mb-2">
                <Award className="w-5 h-5" />
                <span className="font-medium">Case Study Grade</span>
              </div>
              <div className="text-3xl font-bold text-gray-900">
                {isGradeNaN ? '0%' : `${averageCaseStudyGrade.toFixed(0)}%`}
              </div>
              <p className="text-sm text-gray-600 mt-2">
                {averageCaseStudyGrade >= 83 ? '✨ Excellent progress' : '🎯 Keep improving'}
              </p>
            </div>

            {/* Card 2: Math Performance */}
            <div className="bg-white bg-opacity-90 backdrop-blur-lg rounded-2xl shadow-lg p-6">
              <div className="flex items-center gap-2 text-violet-600 mb-2">
                <BrainCircuit className="w-5 h-5" />
                <span className="font-medium">Math Performance</span>
              </div>
              <div className="text-3xl font-bold text-gray-900">
                {isMathGradeNaN ? '0%' : `${avgMath.toFixed(0)}%`}
              </div>
              <p className="text-sm text-gray-600 mt-2">
                {avgMath >= 83 ? '✨ Strong understanding' : '🎯 Practice more'}
              </p>
            </div>

            {/* Card 3: Course Progress */}
            <div className="bg-white bg-opacity-90 backdrop-blur-lg rounded-2xl shadow-lg p-6">
              <div className="flex items-center gap-2 text-violet-600 mb-2">
                <TrendingUp className="w-5 h-5" />
                <span className="font-medium">Course Progress</span>
              </div>
              <div className="text-3xl font-bold text-gray-900">
                {percentComplete ? `${percentComplete.toFixed(0)}%` : '0%'}
              </div>
              <p className="text-sm text-gray-600 mt-2">
                {percentComplete > 83 ? '✨ Almost there!' : '🎯 Keep going'}
              </p>
            </div>

            {/* Card 4: Resume Score */}
            <div className="bg-white bg-opacity-90 backdrop-blur-lg rounded-2xl shadow-lg p-6">
              <div className="flex items-center gap-2 text-violet-600 mb-2">
                <Award className="w-5 h-5" />
                <span className="font-medium">Resume Score</span>
              </div>
              <div className="text-3xl font-bold text-gray-900">
                {`${averageResumeGrade.toFixed(0)}%`}
              </div>
              <p className="text-sm text-gray-600 mt-2">
                {averageResumeGrade >= 83 ? '✨ Well optimized' : '🎯 Needs review'}
              </p>
            </div>
          </div>

          {/* Charts Grid */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            {/* Radar Chart */}
            <div className="bg-white bg-opacity-90 backdrop-blur-lg rounded-2xl shadow-lg p-6">
              <h3 className="text-lg font-semibold text-gray-900 mb-4 flex items-center gap-2 relative group">
                <HelpCircle className="w-5 h-5 text-violet-600 cursor-pointer" />
                {/* Tooltip */}
                <span className="
                  absolute 
                  left-0 
                  top-8 
                  w-64 
                  bg-gray-800 
                  text-white 
                  text-xs 
                  rounded 
                  px-2 
                  py-1 
                  opacity-0 
                  group-hover:opacity-100 
                  transition-opacity 
                  z-10
                ">
                  Radar chart showing your overall readiness for interviews through your performance on Case Studies,
                  quick Math skills, Resume, and Course knowledge.
                </span>
                <span>Overall Readiness</span>
              </h3>
              <Radar data={radarData} options={radarChartOptions} />
            </div>

            {/* Line Chart (Performance Trend) */}
            <div className="bg-white bg-opacity-90 backdrop-blur-lg rounded-2xl shadow-lg p-6">
              <h3 className="text-lg font-semibold text-gray-900 mb-4 flex items-center gap-2 relative group">
                <HelpCircle className="w-5 h-5 text-violet-600 cursor-pointer" />
                {/* Tooltip */}
                <span className="
                  absolute 
                  left-0 
                  top-8 
                  w-64 
                  bg-gray-800 
                  text-white 
                  text-xs 
                  rounded 
                  px-2 
                  py-1 
                  opacity-0 
                  group-hover:opacity-100 
                  transition-opacity 
                  z-10
                ">
                  Line chart displaying your recent Case Study grades
                  over time to identify trends.
                </span>
                <span>Performance Trend</span>
              </h3>
              <Line data={lineData} options={genericChartOptions} />
            </div>

            {/* Bar Chart (Topic Performance) */}
            <div className="bg-white bg-opacity-90 backdrop-blur-lg rounded-2xl shadow-lg p-6">
              <h3 className="text-lg font-semibold text-gray-900 mb-4 flex items-center gap-2 relative group">
                <HelpCircle className="w-5 h-5 text-violet-600 cursor-pointer" />
                {/* Tooltip */}
                <span className="
                  absolute 
                  left-0 
                  top-8 
                  w-64 
                  bg-gray-800 
                  text-white 
                  text-xs 
                  rounded 
                  px-2 
                  py-1 
                  opacity-0 
                  group-hover:opacity-100 
                  transition-opacity 
                  z-10
                ">
                  Bar chart showing the average grade by topic, helping
                  you see which areas you excel in or need to improve.
                </span>
                <span>Topic Performance</span>
              </h3>
              <Bar data={avgByTopicData} options={genericChartOptions} />
            </div>

            {/* Pie & Doughnut Charts */}
            <div className="grid grid-cols-2 gap-6">
              {/* Pie Chart: Math Progress */}
              <div className="bg-white bg-opacity-90 backdrop-blur-lg rounded-2xl shadow-lg p-6">
                <h3 className="text-lg font-semibold text-gray-900 mb-4 flex items-center gap-2 relative group">
                  <HelpCircle className="w-5 h-5 text-violet-600 cursor-pointer" />
                  {/* Tooltip */}
                  <span className="
                    absolute 
                    left-0 
                    top-8 
                    w-64 
                    bg-gray-800 
                    text-white 
                    text-xs 
                    rounded 
                    px-2 
                    py-1 
                    opacity-0 
                    group-hover:opacity-100 
                    transition-opacity 
                    z-10
                  ">
                    Pie chart showing the proportion of correct vs.
                    incorrect math questions.
                  </span>
                  <span>Math Progress</span>
                </h3>
                <Pie data={pieData} options={genericChartOptions} />
              </div>

              {/* Doughnut Chart: Case Studies */}
              <div className="bg-white bg-opacity-90 backdrop-blur-lg rounded-2xl shadow-lg p-6">
                <h3 className="text-lg font-semibold text-gray-900 mb-4 flex items-center gap-2 relative group">
                  <HelpCircle className="w-5 h-5 text-violet-600 cursor-pointer" />
                  {/* Tooltip */}
                  <span className="
                    absolute 
                    left-0 
                    top-8 
                    w-64 
                    bg-gray-800 
                    text-white 
                    text-xs 
                    rounded 
                    px-2 
                    py-1 
                    opacity-0 
                    group-hover:opacity-100 
                    transition-opacity 
                    z-10
                  ">
                    Doughnut chart reflecting your average Case Study
                    performance out of 100%.
                  </span>
                  <span>Case Studies</span>
                </h3>
                <Doughnut data={doughnutData} options={genericChartOptions} />
              </div>
            </div>
          </div>

          {/* Performance Highlights */}
          <div className="mt-8 bg-white bg-opacity-90 backdrop-blur-lg rounded-2xl shadow-lg p-6">
            <h3 className="text-lg font-semibold text-gray-900 mb-4">Performance Highlights</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="flex items-center gap-4">
                <CheckCircle className="w-8 h-8 text-green-500" />
                <div>
                  <div className="text-sm text-gray-600">Best Performance</div>
                  <div className="font-semibold text-gray-900">
                    {bestGrade}% in {bestGradeTopic}
                  </div>
                </div>
              </div>
              <div className="flex items-center gap-4">
                <XCircle className="w-8 h-8 text-red-500" />
                <div>
                  <div className="text-sm text-gray-600">Needs Improvement</div>
                  <div className="font-semibold text-gray-900">
                    {worstGrade}% in {worstGradeTopic}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* AI/ML Prediction */}
          <div className="mt-8 bg-gradient-to-r from-violet-600 to-pink-500 rounded-2xl shadow-lg p-6 text-white">
            <h3 className="text-lg font-semibold mb-4">AI Performance Prediction</h3>
            <p className="text-white/90">{nextAttemptForecast}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
