import React, { useRef } from 'react';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

const Resume = () => {
  // 1) Create a ref to the section we want to export
  const resumeRef = useRef(null);

  // 2) Function that handles the PDF generation
  const handleDownloadPdf = async () => {
    if (!resumeRef.current) return;

    try {
      // Use html2canvas to capture the Resume's DOM node
      const canvas = await html2canvas(resumeRef.current, {
        scale: 2, // Increase pixel density for sharper text
        useCORS: true, // Attempt to load images using CORS to avoid taint
      });
      
      const imgData = canvas.toDataURL('image/png');

      // Create a new jsPDF instance, set to 'p' (portrait), using points
      // and 'a4' format, but you can adjust as needed
      const pdf = new jsPDF('p', 'pt', 'a4');

      // Calculate width/height to fit the A4 page
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      // Add the captured image to the PDF
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, 0);

      // We used 'height=0', so jsPDF will scale the image proportionally 
      // to maintain aspect ratio. If your resume is very tall, 
      // you may need additional logic for multi-page export.

      // Save the PDF and trigger download
      pdf.save('Joel_C_Henriques_Resume.pdf');
    } catch (error) {
      console.error('Failed to generate PDF', error);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 py-8 px-4">
      {/* Resume Container (Referenced by resumeRef) */}
      <div
        ref={resumeRef}
        className="max-w-3xl mx-auto p-6 bg-white text-gray-900 shadow-lg"
      >
        {/* Header */}
        <header className="text-center mb-6">
          <h1 className="text-2xl font-bold mb-1">JOEL C. HENRIQUES</h1>
          <p className="text-sm">
            Chicago, IL | 872.255.9993 | Joel.Henriques@alumni.ucla.edu | LinkedIn
          </p>
        </header>

        {/* Education */}
        <section className="mb-6">
          <h2 className="text-lg font-bold border-b border-gray-800 mb-2">
            EDUCATION
          </h2>
          <div className="text-sm space-y-1">
            <p><span className="font-semibold">MIT Sloan</span> (Post-Grad in Mgmt, Innovation & Tech, 2028)</p>
            <p><span className="font-semibold">Columbia Business School</span> (Exec Program, 2026)</p>
            <p><span className="font-semibold">UCLA</span> (Cert. Machine Learning & Data Science, 2024)</p>
            <p><span className="font-semibold">Harvard Business School Online</span> (Data Science CORe, 2024)</p>
            <p><span className="font-semibold">University of Oklahoma</span> (BA Economics, 2020)</p>
          </div>
        </section>

        {/* Professional Experience */}
        <section className="mb-6">
          <h2 className="text-lg font-bold border-b border-gray-800 mb-2">
            PROFESSIONAL EXPERIENCE
          </h2>

          {/* UI Solutions Group */}
          <div className="mb-4">
            <div className="flex justify-between">
              <div className="font-bold text-sm">UI Solutions Group</div>
              <div className="text-sm">Chicago, IL | 2020 – Present</div>
            </div>
            <div className="italic text-xs mb-2">Senior Software Engineer (Level 3)</div>
            <ul className="list-disc ml-5 text-xs space-y-1">
              <li>Integrated AI LLMs, driving new revenue and product innovation.</li>
              <li>Automated CI/CD (Jenkins, Python, Java) to reduce manual efforts by 98%.</li>
              <li>Created a SQL-based web tool for direct cloud data queries.</li>
            </ul>

            <div className="italic text-xs mt-3 mb-2">Software Engineer (Level 1–2)</div>
            <ul className="list-disc ml-5 text-xs space-y-1">
              <li>Eliminated 70% of security risks with rigorous code reviews/testing.</li>
              <li>Led client support & training, boosting efficiency by 96%.</li>
              <li>Enhanced security to detect 100% of misconfigured accounts.</li>
              <li>Personalized UIs improved engagement by 40% and reduced support by 25%.</li>
            </ul>

            <div className="italic text-xs mt-3 mb-2">Management Consultant</div>
            <ul className="list-disc ml-5 text-xs space-y-1">
              <li>Built 99.9%-accurate revenue algorithms for energy companies.</li>
              <li>Scaled database architectures (100M–18B records), increasing query speeds by 40%.</li>
            </ul>
          </div>

          {/* Beachton Capital Partners */}
          <div>
            <div className="flex justify-between">
              <div className="font-bold text-sm">Beachton Capital Partners</div>
              <div className="text-sm">Oklahoma City, OK | 2019 – 2020</div>
            </div>
            <div className="italic text-xs mb-2">Private Equity Analyst, Intern</div>
            <ul className="list-disc ml-5 text-xs space-y-1">
              <li>Evaluated $15–50M acquisitions, applying DCF/LBO models to boost revenue by 15%.</li>
              <li>Analyzed confidential data to inform strategic growth and investments.</li>
            </ul>
          </div>
        </section>

        {/* Notable Projects */}
        <section className="mb-6">
          <h2 className="text-lg font-bold border-b border-gray-800 mb-2">
            NOTABLE PROJECTS
          </h2>
          <div className="text-xs mb-2">
            <span className="font-semibold">Management Consulting Training</span> 
            {' '}(auroraprep.com | NodeJS, TensorFlow, MongoDB, Express)
          </div>
          <ul className="list-disc ml-5 text-xs space-y-1">
            <li>Engineered ML-driven interview readiness tool, improving placement by 30%.</li>
          </ul>
        </section>

        {/* Skills & Certs */}
        <section>
          <h2 className="text-lg font-bold border-b border-gray-800 mb-2">
            TECHNICAL SKILLS & CERTIFICATIONS
          </h2>
          <div className="text-xs space-y-1">
            <p>
              <span className="font-semibold">Skills:</span> Java, Python, NodeJS, JavaScript, React, MongoDB, SQL, Jenkins, Linux, TensorFlow, Pandas, AWS
            </p>
            <p>
              <span className="font-semibold">Certifications:</span> Oracle Java SE 8, Python for Data Science & ML, Bloomberg
            </p>
          </div>
        </section>
      </div>

      {/* Button to export to PDF */}
      <div className="text-center mt-6">
        <button
          onClick={handleDownloadPdf}
          className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors"
        >
          Export to PDF
        </button>
      </div>
    </div>
  );
};

export default Resume;