import React, { useState, useEffect } from 'react';
import { Lock, Eye, EyeOff, Copy, Plus, Trash2, Save, Search, Key, Globe, User, Settings, X } from 'lucide-react'; 
import { useNavigate } from 'react-router-dom';
// import './Test.css';




const Test = () => {
    const [count, setCount] = useState(0);

    const [randomEmoji, setEmoji] = useState("");
    const [isWinner, setWinner] = useState("");

    function rand(){
        let arr = ["🍎", "🍌", "🍒", "🍓", "🍑", "🍍", "🥭", "🥝", "🍇", "🍉"];
        let first = Math.floor(Math.random() * arr.length)
        let second = Math.floor(Math.random() * arr.length)
        let third = Math.floor(Math.random() * arr.length)
        let winnerLoser = false
        if (first === second && first === third){
            winnerLoser = true;
        }
        return {"arr":[arr[first], arr[second], arr[third]],
            "isWinner": winnerLoser ? "🥂🥂🥂 Winner 🥂🥂🥂" : ""};
    }

    return (
        <div>
            <h1>Streak: <strong>{count}</strong></h1>
            <h2>{[randomEmoji]}</h2>
            <button onClick={() =>{ 
                let returnedVal = rand()
                setEmoji(returnedVal["arr"])
                setWinner(returnedVal["isWinner"])
                if (returnedVal["isWinner"].toString() === "🥂🥂🥂 Winner 🥂🥂🥂".toString()){
                    setCount(count + 1)
                }
                }}>Run</button>
            <br />
            <br />
            <button onClick={() => setCount(count + 1)}>Increment</button>
            <button onClick={() => setCount(count - 1)}>Decrement</button>

            {isWinner && <div className="winner">{isWinner}</div>}
        </div>

        
    );

};


export default Test;
